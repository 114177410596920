import React, { useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";

function EditBucket(props) {
  const [bucket, setBucket] = React.useState({});

  useEffect(() => {
    setBucket(props.bucket);
  }, [props.bucket]);

  return (
    <div>
      <Modal
        size="md"
        centered
        show={props.visibility}
        onHide={() => props.hideModal()}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title>Edit Bucket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name :</Form.Label>
              <Form.Control
                maxLength={30}
                type="text"
                onChange={(e) => setBucket({ ...bucket, name: e.target.value })}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    props.saveFunction(bucket);
                  }
                }}
                value={bucket.name}
              />
              <Form.Text className="text-muted text-right">
                {bucket.name ? bucket.name.length : 0}/30
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            block
            className="bg-primary"
            onClick={() => {
              props.saveFunction(bucket);
            }}
            disabled={bucket.name === "" ? true : false}
            style={{ cursor: bucket.name === "" ? "not-allowed" : "pointer" }}
          >
            Save Bucket
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditBucket;
