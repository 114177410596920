import React, { useEffect, useState } from 'react';
import './user_register.css';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
const UserRegister = ({ visitsdate }) => {
    const [users, setUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default value, can be set by the user
    const navigate = useNavigate();
    useEffect(() => {
        getData();
    }, [currentPage]);

    const getData = async () => {
        const response = await axios.post(`${CX_DEPLOYER}engagement/byVisitedDate`, {
            VisitedDate: visitsdate,
            page: currentPage,
            itemsPerPage: itemsPerPage,
        });
        if (response.data.success) {
            setUser(response.data?.trackData);
            setTotalPages(response?.data?.totalPages);
            setCurrentPage(response?.data?.currentPage);
        }
    };
    const handlepage = userid => {
        localStorage.setItem('visitsdate', visitsdate);
        navigate(`/viewVisitedPages/${userid}`);
    };
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };
    return (
        <div>
            <div className="mainContent">
                <div className="container">
                    <div style={{ padding: '10px' }}>
                        <h3>User Activity at this Date {visitsdate}</h3>
                    </div>

                    <div className="tableFixHead">
                        <table className="table table-bordered text-center table-hover">
                            <thead className="thead-grey">
                                <tr>
                                    <th scope="col">SNo.</th>
                                    <th scope="col">UserName</th>
                                    <th scope="col">Organization</th>
                                    <th scope="col">Total Number of Pages</th>
                                    <th scope="col">View all Pages</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users &&
                                    users.map((user, key) =>
                                        user.userId ? (
                                            <tr key={key}>
                                                <td>{key}</td>
                                                <td>
                                                    {user.userId ? user.userId.username : 'N/A'}
                                                </td>
                                                <td>{user?.organizationId?.name}</td>
                                                <td>{user.VisitedPages.length}</td>

                                                <td>
                                                    {/* <Link to={`/viewVisitedPages/${user.UserId?user.UserId._id:""}`}> */}
                                                    <button
                                                        className="btn btn-view"
                                                        style={{
                                                            fontSize: '0.8rem',
                                                            padding: '0.155rem 0.5rem',
                                                        }}
                                                        onClick={() =>
                                                            user.userId
                                                                ? handlepage(user.userId._id)
                                                                : ''
                                                        }
                                                    >
                                                        view
                                                    </button>
                                                    {/* </Link> */}
                                                </td>
                                            </tr>
                                        ) : null
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <ReactPaginate
                    pageCount={totalPages}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    );
};

export default UserRegister;
