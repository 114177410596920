import React, { Component } from 'react';
import axios from 'axios';
import { API_HOST } from '../services/url';
import './addCategory.css';
import { Link } from 'react-router-dom';
class AddFormCategory extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            category: '',
            categories: [],
            editing: true,
            catId: '',
            editCatName: '',
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.getCategory = this.getCategory.bind(this);
    }
    async getCategory() {
        const token = localStorage.getItem('cxtoken');
        axios
            .get(`${API_HOST}form/prefilledFormTemplate/category`, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                // if(res.data.success){
                this.setState({ categories: res.data.data });
                // }
            });
    }
    async componentDidMount() {
        await this.getCategory();
    }

    async onSubmit(e) {
        e.preventDefault();

        const token = localStorage.getItem('cxtoken');
        axios
            .post(
                `${API_HOST}form/prefilledFormTemplate/category`,
                { category: this.state.category },
                {
                    headers: { 'x-access-token': token },
                }
            )
            .then(res => {
                this.setState({ category: '' });
                this.getCategory();
            })
            .catch(error => {
                alert('Failed to add category');
            });
    }
    async deleteCat(catId) {
        const token = localStorage.getItem('cxtoken');
        axios
            .delete(`${API_HOST}form/prefilledFormTemplate/category/${catId}`, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                this.getCategory();
            })
            .catch(error => {
                alert('Failed to add category');
            });
    }
    async onEdit(category) {
        this.setState({
            editing: true,
            catId: category._id,
            editCatName: category.category,
        });
    }
    async editCategory(catId) {
        const token = localStorage.getItem('cxtoken');
        axios
            .put(
                `${API_HOST}form/prefilledFormTemplate/category/${catId}`,
                { category: this.state.editCatName },
                {
                    headers: { 'x-access-token': token },
                }
            )
            .then(res => {
                this.setState({ catId: '', editCatName: '', editing: false });
                this.getCategory();
            })
            .catch(error => {
                alert('Failed to add category');
            });
    }
    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 col-md-9 mx-auto">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h4 className="card-title text-center">Add category</h4>
                                    <form className="form-signin" method="POST">
                                        <div className="  add-category-css">
                                            <div className="add-category-input-field">
                                                <input
                                                    placeholder="Add New category"
                                                    id="title"
                                                    type="text"
                                                    className="form-control"
                                                    name="category"
                                                    value={this.state.category}
                                                    onChange={e => this.change(e)}
                                                    required
                                                />
                                            </div>
                                            <div className="add-category-button-css">
                                                <button
                                                    type="button"
                                                    className="btn btn-info btn-block"
                                                    onClick={e => this.onSubmit(e)}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                    {this.state.categories?.map((item, index) => (
                                        <div className="  label-category-css" key={index}>
                                            <div className="add-category-input-field">
                                                {this.state.editing &&
                                                this.state.catId === item._id ? (
                                                    <input
                                                        placeholder="Add new category"
                                                        id="title"
                                                        type="text"
                                                        className="form-control"
                                                        name="editCatName"
                                                        value={this.state.editCatName}
                                                        onChange={e => this.change(e)}
                                                        required
                                                    />
                                                ) : (
                                                    <label>{item.category}</label>
                                                )}
                                            </div>
                                            <div className="label-category-button-css gap-2">
                                                {this.state.editing &&
                                                this.state.catId === item._id ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-info btn-block"
                                                        onClick={() => this.editCategory(item._id)}
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="btn btn-info btn-block"
                                                        onClick={() => this.onEdit(item)}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                                <button
                                                    type="button"
                                                    className="btn btn-info text-decoration-none text-white btn-block"
                                                >
                                                    <Link
                                                        className="text-decoration-none text-white"
                                                        to={'/view-form-template'}
                                                        state={{
                                                            id: item._id,
                                                            category: item.category,
                                                        }}
                                                    >
                                                        View
                                                    </Link>
                                                </button>
                                                <button
                                                        type="button"
                                                    className="btn btn-danger btn-block"
                                                    onClick={e => this.deleteCat(item._id)}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddFormCategory;
