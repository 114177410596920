import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import HoverOverlay from './HoverOverlay';
import styles from './AddCardPage.module.css';

const AddCardPage = ({
    handleAddField,
    handleAddNewSection,
    setChildData,
    updateVideoData,
    sectionIndex,
    formId,
    isQuiz,
    formTypeToggle,
}) => {
    const [showVideoCard, setShowVideoCard] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoData, setVideoData] = useState(null);
    const handleVideoButtonClick = () => {
        setShowVideoCard(true);
    };
    const handleCancelVideo = () => {
        setShowVideoCard(false);
        setVideoUrl('');
    };
    const handleVideoSelect = () => {
        // Implement the logic to save the selected video URL and perform any necessary actions
        // For now, let's just log the video URL to the console

        videoData['type'] = 'video';
        setVideoData(videoData);

        setChildData(videoData);
        updateVideoData(videoData);
        // Reset the video card state
        setShowVideoCard(false);
        setVideoUrl('');
    };
    const handleSearchVideo = async () => {
        const mockVideoData = {
            id: 'VIDEO_ID',
            type: 'video',
            snippet: {
                title: 'Test Video',
                thumbnails: {
                    default: {
                        url: 'https://example.com/video-thumbnail.jpg',
                        width: 120,
                        height: 90,
                    },
                },
            },
        };
        setVideoData(mockVideoData);
        try {
            // Call the YouTube Data API to fetch video details
            const response = await fetch(
                `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${extractVideoId(
                    videoUrl
                )}&key=AIzaSyA73rcfOlq5jhGYVpwc1oe6lUtBpVyc5_w`
            );

            const data = await response.json();

            if (data.items.length > 0) {
                setVideoData(data.items[0]);
            } else {
                setVideoData(null);
            }
        } catch (error) {
            console.error('Error searching video:', error);
        }
    };
    const extractVideoId = url => {
        // Extract the video ID from the provided URL
        const videoIdMatch = url.match(
            /^(?:https?:\/\/)?(?:www\.)?youtube(?:\.com|-\w{2,3}\.\w{2})\/watch\?v=([^\s&]+)/
        );

        if (videoIdMatch) {
            return videoIdMatch[1];
        }

        return null;
    };

    return (
        <>
            <Card style={{ padding: '0 12px' }} className="shadow text-muted">
                <div className={styles.addCard_options}>
                    <HoverOverlay
                        placement="left"
                        hoverText="Add Question"
                        mainInput={
                            <svg
                                onClick={() =>
                                    handleAddField(
                                        isQuiz ? 'multiple_choice' : 'text',
                                        sectionIndex
                                    )
                                }
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill="#6C757D"
                                    d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2m5 11h-4v4h-2v-4H7v-2h4V7h2v4h4z"
                                />
                            </svg>
                        }
                    />

                    {showVideoCard && (
                        <Card className={classNames(styles.addCard_videoCard, 'shadow text-muted')}>
                            <Card.Body>
                                <Card.Title>Select Video</Card.Title>
                                <div className={styles.videoCard_content}>
                                    <div className={styles.videoCard_searchBox}>
                                        <img
                                            src="https://static.vecteezy.com/system/resources/previews/003/399/771/original/youtube-icon-editorial-free-vector.jpg"
                                            alt="YouTube Logo"
                                            className={styles.videoCard_logoImage}
                                        />
                                        <input
                                            type="text"
                                            className={styles.videoCard_searchInput}
                                            placeholder="Paste YouTube video URL"
                                            value={videoUrl}
                                            onChange={e => setVideoUrl(e.target.value)}
                                        />
                                        <span className={styles.videoCard_searchIcon}>
                                            <i
                                                className="fa-solid fa-search "
                                                onClick={handleSearchVideo}
                                            ></i>
                                        </span>
                                    </div>

                                    {videoData && (
                                        <div className={styles.videoCard_selectedVideo}>
                                            {/* Display the video */}
                                            <iframe
                                                title="YouTube Video"
                                                width="100%"
                                                height="315"
                                                src={`https://www.youtube.com/embed/${videoData.id}`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.videoCard_buttons}>
                                    <Button variant="primary" onClick={handleVideoSelect}>
                                        Select
                                    </Button>
                                    <Button variant="secondary" onClick={handleCancelVideo}>
                                        Cancel
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    )}

                    <HoverOverlay
                        placement="left"
                        hoverText="Add Secton"
                        mainInput={
                            <svg
                                style={{
                                    fontSize: '1.5rem',
                                    display: 'flex',
                                    margin: '8px 0',
                                    justifyContent: 'center',
                                }}
                                onClick={() => handleAddNewSection('', 'addSection', formId)}
                                width="24"
                                height="24"
                                viewBox="0 0 512 512"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill="#6C757D"
                                    d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48M224 416H64V160h160zm224 0H288V160h160z"
                                />
                            </svg>
                        }
                    />
                </div>
            </Card>
            <Card>
                <div className={styles.addCard_options} style={{ padding: '10px' }}>
                    {!isQuiz ? (
                        <HoverOverlay
                            placement="left"
                            hoverText="Change Form Type to Quiz"
                            mainInput={
                                <svg
                                    onClick={formTypeToggle}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill="#000000"
                                        d="M5.615 20q-.69 0-1.152-.462Q4 19.075 4 18.385V5.615q0-.69.463-1.152Q4.925 4 5.615 4h12.77q.69 0 1.152.463q.463.462.463 1.152v12.77q0 .69-.462 1.152q-.463.463-1.153.463zm0-1h12.77q.23 0 .423-.192q.192-.193.192-.423V5.615q0-.23-.192-.423Q18.615 5 18.385 5H5.615q-.23 0-.423.192Q5 5.385 5 5.615v12.77q0 .23.192.423q.193.192.423.192"
                                    />
                                </svg>
                            }
                        />
                    ) : (
                        <HoverOverlay
                            placement="left"
                            hoverText="Change Form Type to Normal"
                            mainInput={
                                <svg
                                    onClick={formTypeToggle}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill="#000000"
                                        d="m10.6 15.508l6.396-6.396l-.708-.708l-5.688 5.688l-2.85-2.85l-.708.708zM5.615 20q-.69 0-1.152-.462Q4 19.075 4 18.385V5.615q0-.69.463-1.152Q4.925 4 5.615 4h12.77q.69 0 1.152.463q.463.462.463 1.152v12.77q0 .69-.462 1.152q-.463.463-1.153.463zm0-1h12.77q.23 0 .423-.192q.192-.193.192-.423V5.615q0-.23-.192-.423Q18.615 5 18.385 5H5.615q-.23 0-.423.192Q5 5.385 5 5.615v12.77q0 .23.192.423q.193.192.423.192M5 5v14z"
                                    />
                                </svg>
                            }
                        />
                    )}
                </div>
            </Card>
        </>
    );
};

export default AddCardPage;
