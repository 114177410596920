import React, { useEffect, useState } from 'react';
import '../UserEngagement/user_register.css';
import axios from 'axios';
import { API_HOST } from '../services/url';
import ReactPaginate from 'react-paginate';
import './inquiries.css';
const Inquiries = () => {
    const [inquiries, setInquiries] = useState([]);
    const [pageSize, setPageSize] = useState(15);
    const [pageNumber, setPageNumber] = useState();
    const [pageCount, setPageCount] = useState();
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
        getData(selected);
    };
    useEffect(() => {
        getData(0);
    }, []);

    const getData = async selected => {
        let payload = {
            page_size: pageSize,
            page_number: selected,
        };
        try {
            const response = await axios.post(`${API_HOST}cxdeployer/admin/inquiries`, payload);
            setInquiries(response.data.data);
            setPageCount(Math.ceil(response.data.metadata.total_data / pageSize));
        } catch (error) {}
    };
    return (
        <div>
            <div className="mainContent">
                <div className="container">
                    <div className="page-header text-center">
                        <h3>Inquiries</h3>
                    </div>
                    <div className="tableFixHead">
                        <table className="table table-bordered text-center table-hover">
                            <thead className="thead-grey">
                                <tr>
                                    <th scope="col">S.no</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Contact</th>
                                    <th scope="col">Message</th>
                                    <th scope="col">Platform</th>
                                    <th scope="col">Services</th>
                                    <th scope="col">Budget</th>
                                    <th scope="col">terms</th>
                                </tr>
                            </thead>
                            <tbody>
                                {inquiries &&
                                    inquiries.map((item, key) => (
                                        <tr>
                                            <td>{key + 1}</td>
                                            <td>{item.name ? item.name : 'N/A'}</td>
                                            <td>{item.email}</td>
                                            <td>{item.contact}</td>
                                            <td>{item.message}</td>
                                            <td>{item.platform ? String(item.platform) : ''}</td>
                                            <td>{item.service}</td>
                                            <td>{item.budget}</td>
                                            <td>{item.terms}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount} // Replace with your actual number of pages
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination-container"
                        pageClassName="pagination-item"
                        breakClassName="pagination-item"
                        previousClassName="pagination-item"
                        nextClassName="pagination-item"
                        activeClassName="pagination-item active"
                    />
                </div>
            </div>
        </div>
    );
};

export default Inquiries;
