import React, { Component } from 'react';
import axios from 'axios';
import { API_HOST } from '../services/url';
import { Link } from 'react-router-dom';

class ViewPlan extends Component {
    constructor(propos) {
        super();
        this.state = {
            data: [],
            loading: false,
        };
    }

    async getConversation() {
        const token = localStorage.getItem('cxtoken');
        this.setState({ loading: true });
        const response = await axios
            .get(API_HOST + `admin/webUpdate`, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                console.log('1', res?.data?.data);
                this.setState({
                    data: res?.data?.data,
                    loading: false,
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
  
    handleDelete = e => {
        e.preventDefault();
        const token = localStorage.getItem('cxtoken');
        const url = API_HOST + `admin/webUpdate/` + e.target.id;
        console.log(url);
        axios.delete(url, { headers: { 'x-access-token': token } }).then(response => {
            console.log(response);
            this.getConversation();
        });
    };

    renderTableData() {
        return this.state.data.map((row, index) => {
            const { updateDate,updateTime,heading } = row; //destructuring

            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{heading}</td>
                    <td>{updateDate}</td>
                    <td>{updateTime}</td>

                    <td>
                        <button className="btn btn-primary" type="button">
                            <Link
                                to={`/edit-update/${row._id}`}
                                state={{ id: row._id }}
                                className="text-decoration-none text-white"
                            >
                                Edit
                            </Link>
                        </button>
                    </td>
                    <td>
                        <button
                            className="btn btn-danger"
                            id={row._id}
                            type="button"
                            onClick={e =>
                                window.confirm('Are you sure you want to delete?') &&
                                this.handleDelete(e)
                            }
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            );
        });
    }

    componentDidMount() {
        this.getConversation();
    }
    render() {
        return (
            <div>
                <div className="mainContent">
                    <div className="container">
                        <div className="page-header text-center">
                            <h3>Whatsapp bot conversation Table</h3>
                        </div>
                        <table className="table table-bordered text-center table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">SNo.</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Time</th>
                                    <th scope="col" colSpan="3">
                                        Action
                                    </th>
                                </tr>
                            </thead>

                            {this.state.loading ? (
                                <div
                                    className=" d-flex align-items-center justify-content-center"
                                    style={{ height: '500px', width: '100%' }}
                                >
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <tbody>{this.renderTableData()}</tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewPlan;
