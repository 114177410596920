import { withStyles } from "@material-ui/core/styles";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import htmlReactParser from "html-react-parser";
import React, { Component, Fragment } from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Resizer from "react-image-file-resizer";
import ModalImage from "react-modal-image";
import { v4 as uuid } from "uuid";
import { Modal as EditingModal } from "../../../../Components";
import { CX_URL } from "../../../../services/url";
import "../../CJM.css";
// import Imagelibrary from "./Library";

import { Popover } from "@material-ui/core";
import {
	Item,
	Menu,
	MenuProvider,
	Separator,
	Submenu,
	contextMenu,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import Comment from "../../../../Components/Modal/Comment";
import Axios from "../../../../services/Axios";
import LaneModal from "../../../../Components/Modal/LaneModal";

const initialState = {
	mouseX: null,
	mouseY: null,
};
const useStyles = withStyles((theme) => ({
	root: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "25ch",
		},
	},
}));
const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			900,
			900,
			"JPEG",
			75,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64",
			200,
			200
		);
	});

export default class ImageLane extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				nodes: [],
			},
			cardWidth: "",
			img: "",
			nodeIdx: "",
			mouse: initialState,
			anchorEl: null,
			anchorEl1: null,
			comment: [],
			theInputKey: "",
			nodeId: "",
			idx: "",
			url: "",
			add: false,
			editing: false,
			error: true,
			showLibrary: false
		};
	}



	openImage = (image) => {
		try {
			document.getElementById("myModal").style.display = "block";
			document.getElementById("img01").src = CX_URL + image;
		} catch (error) {
			console.log(error.message);
		}
	};

	_handleImageChange = (e, idx, lane_id) => {
		e.preventDefault();
		try {
			const inputElement = e.target;
			inputElement.removeEventListener("change", this._handleImageChange);
			if (!this.state.error) {
				const file = inputElement.files[0];
				console.log(file);
				lane_id = lane_id;
				const formData = new FormData();
				formData.append("image", file);
				formData.append("date", Date(Date.now()));
				formData.append("gridID", idx.toString());
				formData.append("laneType", "image");
				formData.append("projectid", this.props.projectId)

				this.setState({ error: true }, () => {
					this.props.addNode(idx, lane_id, formData);
				});
			}
		} catch (error) {
			console.log(error.message);
		}
	};


	_handleImageEdit = (e, idx, id, comment, lane_id) => {
		console.log(idx, id, comment, lane_id);
		e.preventDefault();
		try {
			const inputElement = e.target;
			inputElement.removeEventListener("change", this._handleImageEdit);

			if (!this.state.error) {
				const file = inputElement.files[0];
				console.log(file);
				lane_id = lane_id;
				const formData = new FormData();
				formData.append("imageid", id);
				formData.append("image", file);
				formData.append("comment", comment);
				formData.append("projectid", this.props.projectId)
				formData.append("gridID", idx.toString());

				this.setState({ error: true }, () => {
					this.props.editImage(idx, lane_id, "image", formData);
				});
			}
		} catch (error) {
			console.log(error.message);
		}
	};

	image = (url, callback) => {
		const xhr = new XMLHttpRequest();
		xhr.onload = () => {
			const reader = new FileReader();
			reader.onloadend = () => {
				callback(reader.result);
			};
			reader.readAsDataURL(xhr.response);
		};
		xhr.open("GET", url);
		xhr.responseType = "blob";
		xhr.send();
	};
	ColorLuminance(hex, lum) {
		// validate hex string
		hex = String(hex).replace(/[^0-9a-f]/gi, "");
		if (hex.length < 6) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		lum = lum || 0;

		// convert to decimal and change luminosity
		var rgb = "#",
			c,
			i;
		for (i = 0; i < 3; i++) {
			c = parseInt(hex.substr(i * 2, 2), 16);
			c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
			rgb += ("00" + c).substr(c.length);
		}
		return rgb;
	}
	hexToRGB(hex, alpha) {
		var r = parseInt(hex.slice(1, 3), 16),
			g = parseInt(hex.slice(3, 5), 16),
			b = parseInt(hex.slice(5, 7), 16);

		if (alpha) {
			return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
		} else {
			return "rgb(" + r + ", " + g + ", " + b + ")";
		}
	}
	editLane = (html, color, icon) => {
		const newlane = {
			_id: this.props.lane._id,
			laneType: "image",
			laneName: html,
			color: color,
			icon: icon,
			date: Date(Date.now()),
			laneGridNo: this.props.lane.laneGridNo,
			expand: this.props.lane.expand,
		};
		this.props.editLane(this.props.lane._id, newlane);
	};
	openModal = (id) => {
		this.setState({
			editing: true
		})
	};
	onDragStart = (event, idx) => {
		console.log(idx);
		this.setState({ nodeIdx: idx });
	};
	onDragOver = (event) => {
		event.preventDefault();
	};
	onDrag = (event) => {
		event.preventDefault();
	};
	onDrop = (event, idx) => {
		let nodes = this.props.lane.nodes;
		let cardWidth = this.props.cardWidth;
		console.log(idx);
		console.log(event.target);
		let nodeIdx = nodes.findIndex((node) => node.gridID == this.state.nodeIdx);
		let dropIdx = nodes.findIndex((node) => node.gridID == idx);
		console.log("drop", dropIdx);
		if (dropIdx != -1) {
			for (var i = 0; i < nodes.length; i++) {
				let gridID = parseInt(nodes[i].gridID);
				if (gridID >= idx) nodes[i].gridID = gridID + 1;
				if (nodes[i].gridID == cardWidth) {
					cardWidth += 1;
				}
			}
		}
		console.log(nodeIdx);
		nodes[nodeIdx].gridID = idx;
		if (idx == cardWidth) cardWidth = cardWidth + 1;
		console.log(nodes);
		let lane = this.props.lane;
		lane.nodes = nodes;
		this.props.dragNode(lane, cardWidth);
	};
	handleMenuEvent = (e, id) => {
		console.log("file click", id)
		e.preventDefault();
		contextMenu.show({
			id: id,
			event: e,
			props: {
				foo: "bar",
			},
		});
	};
	addComment = (text) => {
		this.props.addComment(
			"ImageLane",

			this.props.lane._id,
			this.state.nodeIdx,
			text
		);
	};
	commentClick = (event, nodeIdx, comment, bool) => {
		console.log(event);
		if (bool)
			this.setState(
				{
					anchorEl: event.currentTarget,
					mouse: initialState,
					nodeIdx,
					comment,
				},
				console.log(this.state.anchorEl)
			);
		else
			this.setState(
				{
					anchorEl: event.event.target,
					mouse: initialState,
					nodeIdx,
					comment,
				},
				console.log(this.state.anchorEl)
			);
	};
	urlAdd = (event, idx, lane_id) => {
		this.setState({
			anchorEl1: event.event.target,
			mouse: initialState,
			idx,
			add: true,
		});
	};
	urlClick = (event, idx, nodeId, comment, lane_id) => {
		this.setState({
			anchorEl1: event.event.target,
			mouse: initialState,
			idx,
			add: false,
			comment,
			nodeId,
		});
	};
	byLibraryAdd = (event, idx, lane_id) => {
		this.setState(
			{
				idx,
				add: true,
				showLibrary: true
			},

		);
	};
	byLibraryClick = (event, idx, nodeId, comment, lane_id) => {
		this.setState(
			{
				idx,
				add: false,
				comment,
				nodeId,
			},
			() => {
				try {
					document.getElementById("library").style.display = "block";
				} catch (error) {
					console.log(error.message);
				}
			}
		);
	};




	urlSubmit = async (e) => {
		console.log("props id project", this.props.projectId)
		e.preventDefault();
		const fileExtension = this.state.url.split(".").pop().toLowerCase();

		const validExtensions = ["png", "jpeg", "jpg", "gif", "PNG", "JPG", "JPEG", "GIF"];
		const isValidExtension = validExtensions.includes(fileExtension);

		if (!isValidExtension) {

			this.setState({ url: "" });
			return;
		}

		try {
			document.getElementById("outlined-search").value = "";
		} catch (error) {
			console.log(error.message);
		}
		try {
			var that = this;
			await Axios.post("/premap/customermap/lanes/imageUrl", { url: this.state.url },)  // this api call for convert image url to base 64 without cors error
				.then(async function (file) {
					console.log(file, "image")

					const formData = new FormData();
					let base64Data = file.data;
					// const imageBuffer = Buffer.from(base64Data, 'base64');
					// const imageBlob = new Blob([imageBuffer], { type: 'image/jpeg' });
					const byteCharacters = atob(base64Data);
					const byteNumbers = new Array(byteCharacters.length);

					for (let i = 0; i < byteCharacters.length; i++) {
						byteNumbers[i] = byteCharacters.charCodeAt(i);
					}

					const byteArray = new Uint8Array(byteNumbers);
					const imageBlob = new Blob([byteArray], { type: 'image/jpeg' });

					if (
						(fileExtension === "png") ||
						fileExtension === "jpg" ||
						fileExtension === "jpeg" ||
						fileExtension === "PNG" ||
						fileExtension === "JPG" ||
						fileExtension === "JPEG"

					) {
						console.log('imageadd')
						resizeFile(imageBlob).then(() => {
							formData.append('image', imageBlob);
							formData.append('projectid', that.props.projectId)
							formData.append("date", Date(Date.now()));
							formData.append("gridID", that.state.idx.toString());
							formData.append("laneType", "image");
							that.props.addNode(that.state.idx, that.props.lane._id, formData);

						}).catch((error) => {
							console.log('resize error')
						})

					}

				});
		} catch (e) {
			console.log("error ", e);

		}
		this.setState({ url: "" });
	};

	byLibrary = async (url) => {
		try {
			var that = this;
			await Axios.post("imageUrl", { url },)  // this api call for convert image url to base 64 without cors error
				.then(async function (file) {
					const formData = new FormData();
					let base64Data = file.data;
					const imageBuffer = Buffer.from(base64Data, 'base64');
					const imageBlob = new Blob([imageBuffer], { type: 'image/jpeg' });

					if (that.state.add) {

						formData.append('image', imageBlob);

						formData.append("date", Date(Date.now()));
						formData.append("gridID", that.state.idx.toString());
						formData.append("laneType", "image");
						that.props.addNode(that.state.idx, that.props.lane._id, formData);
					} else {
						formData.append("imageid", that.state.nodeId);
						formData.append("image", file);
						formData.append("comment", that.state.comment);
						formData.append("gridID", that.state.idx.toString());
						that.props.editImage(
							that.state.idx,
							that.props.lane._id,
							"image",
							formData
						);
						console.log("form data", formData);
					}
				}
				);

			// window.atob(this.state.url.split(',')[1]);
			// console.log('base64');
		} catch (e) {
			// something failed

			// if you want to be specific and only catch the error which means
			// the base 64 was invalid, then check for 'e.code === 5'.
			// (because 'DOMException.INVALID_CHARACTER_ERR === 5')
			console.log("error ", e);

		}
		this.setState({ url: "" });
	};

	onAddClick = (id, idx) => {
		document.getElementById(id).click();
		console.log("idx onAddClick", idx, id)
		document
			.getElementById(id)
			.addEventListener(
				"change",
				(e) => this._handleImageChange(e, idx, this.props.lane._id),
				false
			);
		this.setState({ error: false });
	};
	onEditClick = (id, idx, node_id, nodecomment) => {
		document.getElementById(id).click();
		document
			.getElementById(id)
			.addEventListener(
				"change",
				(e) =>
					this._handleImageEdit(
						e,
						idx,
						node_id,
						nodecomment,
						this.props.lane._id
					),
				false
			);
		this.setState({ error: false });
	};

	handleChange = (event) => {
		this.setState({ url: event.target.value });
	};
	commentClose = () => {
		this.setState({ anchorEl: null });
	};

	functionThatResetsTheFileInput() {
		let randomString = Math.random().toString(36);

		this.setState({
			theInputKey: randomString,
		});
	}

	render = () => {
		let {
			lane: { nodes },
			cardWidth,
		} = this.props;
		const uniqueid = uuid();
		const { anchorEl, anchorEl1, comment, nodeIdx, url } = this.state;
		const open = Boolean(anchorEl);
		const open1 = Boolean(anchorEl1);
		const chatid = open ? "simple-popover" : undefined;
		const Urlid = open1 ? "simple-url-popover" : undefined;
		return (
			<div
				className="lane d-flex"
				style={{
					background: this.hexToRGB(`${this.props.lane.color}`, 0.2),
					// width: `${cardWidth * 291 + "px"}`,
					borderTop: "1px solid " + `${this.props.lane.color}`,
					width: "200%",
					overflowX: 'auto'
				}}
			>
				{this.state.editing &&
					<LaneModal
						title="Edit Lane"
						handleSubmit={this.editLane}
						html={this.props.lane.laneName}
						color={this.props.lane.color}
						history={this.props.lane.history}
						icon={this.props.lane.icon}
						show={this.state.editing}
						close={() => this.setState({
							editing: false
						})}
					/>}
				{/* <Imagelibrary id="library" byLibrary={this.byLibrary} show={this.state.showLibrary} close={() => this.setState({showLibrary: false})} /> */}
				<div class="parentlane">
					<div
						className="lanename"
						data-toggle="modal"
						data-target={"#" + uniqueid + "lane"}
						data-dismiss="modal"
						onClick={() => this.openModal(uniqueid)}
						style={{
							background: this.hexToRGB(`${this.props.lane.color}`, 0.4),
						}}
					>
						{htmlReactParser(this.props.lane.laneName || "")}
					</div>
					<div style={{ marginLeft: "-30px" }}>
						{" "}
						{this.props.lane.icon ? (
							<div style={{ marginBottom: "70px" }}>
								<IconPickerItem
									icon={this.props.lane.icon}
									size={18}
									color="#000"
								/>
							</div>
						) : (
							<div style={{ marginBottom: "100px", marginLeft: "23px" }}></div>
						)}
						<div className="shift d-flex">
							<div
								onClick={() => this.props.shiftlane(this.props.lane._id, 1)}
								class="shiftdown"
							>
								<FaChevronDown aria />
							</div>
							<div
								onClick={() => this.props.shiftlane(this.props.lane._id, -1)}
								class="shiftup"
							>
								<FaChevronUp />
							</div>
						</div>
						<div
							class="deletelaneicon"
							onClick={() =>
								// window.confirm(`Are you sure you want to delete lane?`) &&
								this.props.deleteLane(
									this.props.lane._id,
									this.props.lane.laneType
								)
							}
						>
							<RiDeleteBin6Line className="text-danger" />
						</div>
					</div>
				</div>
				<div className="nodes" onDragOver={(event) => this.onDragOver(event)}>
					{Array(cardWidth)
						.fill(0)
						.map((_, idx) => {
							const node = nodes.find((node) => parseInt(node.gridID) == idx);
							const nodeindex = nodes.findIndex(
								(node) => parseInt(node.gridID) == idx
							);
							if (node) {
								return (
									<Fragment>
										<div className="addnode-middle">
											<PhotoCamera
												onClick={(e) => {
													this.handleMenuEvent(e, this.props.lane._id + idx);
												}}
											/>

											<Menu id={this.props.lane._id + idx}>
												<Item
													onClick={() => {
														this.onAddClick("fil" + `${idx}`, idx);
														// document.getElementById("fil" + `${idx}`).click();
														// document
														//   .getElementById("fil" + `${idx}`)
														//   .addEventListener(
														//     "change",
														//     (e) =>
														//       this._handleImageChange(
														//         e,
														//         idx,
														//         this.props.lane._id
														//       ),
														//     false
														//   );
													}}
												>
													Upload from Computer
													<input
														class="changeimage"
														id={"fil" + `${idx}`}
														key={this.state.theInputKey || ""}
														type="file"
														accept="image/*"
														style={{ display: "none" }}
														onClick={(e) => {
															e.target.value = null;
														}}
														ref={(ref) => {
															this.fileInput = ref;
														}}
													/>
												</Item>
												<Item
													aria-describedby={Urlid}
													variant="contained"
													onClick={(e) => {
														this.urlAdd(e, idx, this.props.lane._id);
													}}
												>
													By URL
												</Item>
												<Item
													onClick={(e) => {
														this.byLibraryAdd(e, idx, this.props.lane._id);
													}}
												>
													From Library
												</Item>
											</Menu>
										</div>
										<div
											class="parent"
											key={idx}
											draggable="true"
											onDragStart={(event) => this.onDragStart(event, idx)}
											onDrag={(event) => {
												this.onDrag(event);
											}}
											style={{ borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
											onDrop={(event) => this.onDrop(event, idx)}
										>
											<MenuProvider id={node._id}>
												<div
													style={{
														display: "flex",
														width: "200px",
														height: "110px",
														alignItems: "center",
														justifyContent: "center",
														cursor: "pointer",
													}}
												>
													<ModalImage
														className="modalsmallimage"
														small={node.image}
														large={node.image}
													/>
												</div>
											</MenuProvider>
											<Menu id={node._id}>
												<Submenu label="Change Image">
													<Item
														onClick={() => {
															this.onEditClick(
																"files" + `${node.gridID}`,
																idx,
																node._id,
																node.comment
															);
															// document
															//   .getElementById("files" + `${node.gridID}`)
															//   .click();
															// document
															//   .getElementById("files" + `${node.gridID}`)
															//   .addEventListener(
															//     "change",
															//     (e) =>
															//       this._handleImageEdit(
															//         e,
															//         idx,
															//         node._id,
															//         node.comment,
															//         this.props.lane._id
															//       ),
															//     false
															//   );
														}}
													>
														Upload from Computer
														<input
															class="changeimage"
															id={"files" + `${node.gridID}`}
															key={this.state.theInputKey || ""}
															type="file"
															accept="image/*"
															style={{ display: "none" }}
															onClick={(e) => {
																e.target.value = null;
															}}
															ref={(ref) => {
																this.fileInput = ref;
															}}
														/>
													</Item>
													<Item
														aria-describedby={Urlid}
														variant="contained"
														onClick={(e) => {
															this.urlClick(
																e,
																idx,
																node._id,
																node.comment,
																this.props.lane._id
															);
														}}
													>
														By URL
													</Item>
													<Item
														onClick={(e) => {
															this.byLibraryClick(
																e,
																idx,
																node._id,
																node.comment,
																this.props.lane._id
															);
														}}
													>
														From Library
													</Item>
												</Submenu>
												<Separator />
												{/* <Item
													onClick={(e) => {
														// window.confirm(
														//   `Are you sure you want to delete file?`
														// ) &&
														this.props.deleteNode(
															node._id,
															this.props.lane._id,
															this.props.lane.laneType
														);
													}}
												>
													Delete
												</Item> */}
												<Separator />
												<Item
													onClick={(e) => {
														this.commentClick(
															e,
															nodeindex,
															node.comment,
															false
														);
													}}
													aria-describedby={chatid}
													variant="contained"
												>
													Comment
												</Item>
											</Menu>

											{node.comment?.length != 0 ? (
												<div
													className="comment-icon"
													onClick={(e) => {
														this.commentClick(e, nodeindex, node.comment, true);
													}}
													aria-describedby={chatid}
													variant="contained"
													title="Comments"
												>
													<i class="fa fa-comments" aria-hidden="true"></i>
												</div>
											) : (
												""
											)}
											{/* <div
												class="deleteicon"
												onClick={() => {
													// window.confirm(
													//   `Are you sure you want to delete file?`
													// ) &&
													this.props.deleteNode(
														node._id,
														this.props.lane._id,
														this.props.lane.laneType
													);
												}}
											>
												<RiDeleteBin6Line className="text-danger" />
											</div> */}
										</div>
									</Fragment>
								);
							}
							return (
								<div
									className="addnode"
									index={idx}
									onDrop={(event) => this.onDrop(event, idx)}
								>
									<PhotoCamera
										onClick={(e) => {
											this.handleMenuEvent(e, this.props.lane._id + idx);
										}}
									/>

									<Menu id={this.props.lane._id + idx}>
										<Item
											onClick={() => {
												this.onAddClick("fil" + `${idx}`, idx);
												// console.log(document.getElementById("fil" + `${idx}`));
												// document.getElementById("fil" + `${idx}`).click();
												// document
												//   .getElementById("fil" + `${idx}`)
												//   .addEventListener(
												//     "change",
												//     (e) =>
												//       this._handleImageChange(
												//         e,
												//         idx,
												//         this.props.lane._id
												//       ),
												//     false
												//   );
											}}
										>
											Upload from Computer
											<input
												class="changeimage"
												id={"fil" + `${idx}`}
												key={this.state.theInputKey || ""}
												type="file"
												accept="image/*"
												style={{ display: "none" }}
												onClick={(e) => {
													console.log(e, "ccc");
													e.target.value = null;
												}}
												ref={(ref) => {
													this.fileInput = ref;
												}}
											/>
										</Item>
										<Item
											aria-describedby={Urlid}
											variant="contained"
											onClick={(e) => {
												this.urlAdd(e, idx, this.props.lane._id);
											}}
										>
											By URL
										</Item>
										<Item
											onClick={(e) => {
												this.byLibraryAdd(e, idx, this.props.lane._id);
											}}
										>
											From Library
										</Item>
									</Menu>
								</div>
							);
						})}
					<div
						className="addnode"
						index={cardWidth}
						onDrop={(event) => this.onDrop(event, cardWidth)}
					>
						<PhotoCamera
							onClick={(e) => {
								this.handleMenuEvent(e, this.props.lane._id + cardWidth);
							}}
						/>

						<Menu id={this.props.lane._id + cardWidth}>
							<Item
								onClick={() => {
									this.onAddClick("fil" + `${cardWidth}`, cardWidth);
									// console.log(document.getElementById("fil" + `${cardWidth}`));
									// document.getElementById("fil" + `${cardWidth}`).click();
									// document
									//   .getElementById("fil" + `${cardWidth}`)
									//   .addEventListener(
									//     "change",
									//     (e) =>
									//       this._handleImageChange(
									//         e,
									//         cardWidth,
									//         this.props.lane._id
									//       ),
									//     false
									//   );
								}}
							>
								Upload from Computer
								<input
									class="changeimage"
									id={"fil" + `${cardWidth}`}
									key={this.state.theInputKey || ""}
									type="file"
									accept="image/*"
									style={{ display: "none" }}
									onClick={(e) => {
										console.log(e, "ccc");
										e.target.value = null;
									}}
									ref={(ref) => {
										this.fileInput = ref;
									}}
								/>
							</Item>
							<Item
								aria-describedby={Urlid}
								variant="contained"
								onClick={(e) => {
									this.urlAdd(e, cardWidth, this.props.lane._id);
								}}
							>
								By URL
							</Item>
							<Item
								onClick={(e) => {
									this.byLibraryAdd(e, cardWidth, this.props.lane._id);
								}}
							>
								From Library
							</Item>
						</Menu>
					</div>
					<Popover
						id={chatid}
						open={open}
						anchorEl={anchorEl}
						onClose={this.commentClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						{nodeIdx !== "" && this.props.lane.nodes[nodeIdx] ? (
							<Comment
								addComment={this.addComment}
								comment={this.props.lane.nodes[nodeIdx].comment}
							/>
						) : (
							<Comment addComment={this.addComment} comment={comment} />
						)}
					</Popover>
					<Popover
						id={Urlid}
						open={open1}
						anchorEl={anchorEl1}
						onClose={() => {
							this.setState({ anchorEl1: null });
						}}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						<div
							style={{
								borderRadius: "8px",
								boxShadow:
									"0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)",
								padding: "16px",
							}}
						>
							<form
								noValidate
								autoComplete="off"
								style={{
									display: "flex",
									flexDirection: "column",
									gap: "8px",
								}}
							>
								<input
									id="outlined-search"
									placeholder="Enter Url"
									style={{
										boxShadow: "inset 0px 1px 2px 1px rgba(38, 39, 40, 0.2)",
										borderRadius: "8px",
										background: "#FAFBFC",
										border: "1px solid rgba(156, 176, 197, 0.5)",
										padding: "8px",
									}}
									type="text"
									value={url}
									onChange={this.handleChange}
								/>
								<button
									style={{
										background: "#888DFF",
										outline: "none",
										border: "none",
										fontWeight: 500,
										letterSpacing: "1.25px",
										color: "#fff",
										padding: "10px 16px",
										fontSize: "14px",
										borderRadius: "8px",
									}}
									onClick={(e) => {
										this.urlSubmit(e);
										this.setState({ anchorEl1: null });
									}}
								>
									Submit
								</button>
							</form>
						</div>
					</Popover>
					{/**Image popup modal */}
					<div id="myModal" class="modal">
						<span class="close" onClick={() => this.onClose()}>
							&times;
						</span>
						<img className="img01 modal-content" id="img01" />
					</div>
				</div>

			</div>
		);
	};
}
