import React, { useState ,useCallback} from "react";
import "./style/add_card.css";
import {  } from "react-icons/gr";
import {  BsLink45Deg } from "react-icons/bs";
import { BiCheckSquare } from "react-icons/bi";
import CardTitle from "./card/cardTitle";
import AssignMemberTime from "./card/assignMemberTime";
import Attachments from "./card/attachments";
import CheckList from "./card/checkList";
import ShowCheckItem from "./card/showCheckItem";
import {  useSelector } from "react-redux";
import LabelPOP from "./card/labelPOP";
import AttachmentModal from './card/attachmentModal'
import { HiMenuAlt2 } from "react-icons/hi";
import moment from "moment";
import "./style/EditCard.css";

const AddCard = ({ handleSelectCard, card, bucket }) => {;
  const { selectedBoard } = useSelector((store) => store.boardRoot);
  const [show, setShow] = useState(false);
  const [showdesc, setShowdesc] = useState(false);
  const handleSelectCard1 = () => {
    handleSelectCard(card, bucket);
  }
 
  const handleonclose = useCallback(() => {
    setShow(false)
    setShowdesc(false)
  },[show])
 


  return (
    <>
      <li
        className="d-block justify-content-between Card"
        data-bs-toggle="modal" data-bs-target="#staticBackdrop"
        onClick={() => handleSelectCard1()}
        style={{ wordBreak: 'break-all' }}
      >
        {card && card.cardLabel && card.cardLabel.length > 0 && (
          <div className="label_item">
            {selectedBoard &&
              selectedBoard.boardLabels &&
              selectedBoard.boardLabels.map((board) =>
                card.cardLabel.includes(board._id) ? (
                  <div
                    className="circle_labelBtn list-label_item_btn label_01item"
                    style={{ backgroundColor: board.labelBackgroundColor }}
                    key={board._id}
                  >
                  </div>
                ) : null
              )}
          </div>
        )}


        <div className="list-card-title" >
          {card.cardTitle}
        </div>


        <div className="list-bottom-icons-label" style={{ display: card === 0 ? 'none' : 'flex'  }}>

          {card && card.cardDate?.status ? (
            card.cardDate.startDate != "" || card.cardDate.dueDate != "" ?
              <div class="list_pop_section" style={{backgroundColor:
                card && card.cardDate && card.cardDate.status === 'pending' ?'#EF7564':"#7BC86C" ,color:"white"}}
               
                >
                {card && card.cardDate && card.cardDate.status === 'completed' &&
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                    style={{ cursor: "pointer" }}
                  />
                }
                <span >
                  {card && card.cardDate && card.cardDate.startDate &&

                    moment(card.cardDate.startDate).format("ll") !== "Invalid date"
                    ? moment(card.cardDate.startDate).format("DD-MMMM") + "-" : " "}
                  {card && card.cardDate && card.cardDate.dueDate &&

                    moment(card.cardDate.dueDate).format("ll") !== "Invalid date"
                    ? moment(card.cardDate.dueDate).format("DD-MMMM") + "" : " "}
                </span>
              </div>

              : null
          ) : (
            ""
          )}
          {card.cardDescription ? (<HiMenuAlt2 size={18} />) : null}
          {card.attachments?.length > 0 ? (<div><BsLink45Deg size={18} />{card.attachments?.length}</div>) : null}
          {card.checklist?.length > 0 ? (<div><BiCheckSquare size={18} />{card.checklist?.length}</div>) : null}

          
            </div>
          <br></br>
          <div className="list-member" style={{display: card === 0 ? 'none' : "flex", marginTop:'-20px', marginBottom:"-5px"}}>

          <div className="card-detail-item " >

            {selectedBoard &&
              selectedBoard.boardMember &&
              selectedBoard.boardMember.map((member,key) =>
                member.b_memberid ? (
                  card.cardMembers &&
                    card.cardMembers.includes(member.b_memberid._id) ? (
                    <>
                      <span key={key} className="popup_circle_view me-1" style={{
                        backgroundColor: member.b_memberid
                          ? member.b_memberid.userColor
                          : "namecircle_color",
                      }}>
                        {member &&
                          member.b_memberid &&
                          member.b_memberid.username &&
                          member.b_memberid.username
                            .split(" ")[0]
                            .charAt(0)
                            .toUpperCase()}
                        {member &&
                          member.b_memberid &&
                          member.b_memberid.username &&
                          member.b_memberid.username.split(" ").length > 1 &&
                          member.b_memberid.username
                            .split(" ")[1]
                            .charAt(0)
                            .toUpperCase()}
                      </span>
                    </>
                  ) : null
                ) : null
              )}
          
          </div>
        </div>
     

      </li>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        onclose={() => handleonclose()}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <CardTitle bucket={bucket} handleonclose={handleonclose}
              show={show}
              showdesc={showdesc}
            />

            <div className="modal-body">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-9">
                  <AssignMemberTime handleonclose={handleonclose}
                    show={show}
                    showdesc={showdesc}
                    setShowdesc={setShowdesc}
                    setShow={setShow}

                  />
                  <Attachments />
                  <ShowCheckItem />
                </div>

                <div className="col-xs-12 col-sm-12 col-md-3">
                  <LabelPOP /> 
                  <CheckList />
                  <AttachmentModal />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCard;