import React, { useEffect, useState } from 'react';
import './user_register.css';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';
import { Link, useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const UserRegister = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(8);
    const [selectedAppId, setSelectedAppId] = useState('All');
    const { userId } = useParams();

    useEffect(() => {
        getData();
    }, []);
    const allVisitedPages = [
        {
            appId: 1,
            product: 'Taskdeployer',
        },
        {
            appId: 3,
            product: 'Cxdeployer',
        },
        {
            appId: 4,
            product: 'Ideadeployer',
        },
        {
            appId: 5,
            product: 'Form',
        },
        {
            appId: 6,
            product: 'Whiteboard',
        },
        {
            appId: 7,
            product: 'Drive',
        },
    ];
    const handleAppIdChange = e => {
        const appId = e.target.value;
        console.log('jjj', appId);
        setSelectedAppId(appId);
    };
    const getData = async () => {
        let VisitedDate = localStorage.getItem('visitsdate');
        const response = await axios.post(`${CX_DEPLOYER}engagement/byUserId`, {
            userId,
            VisitedDate,
            page: currentPage,
            itemsPerPage: itemsPerPage,
        });

        if (response.data.success) {
            setData(response.data.userData.at(0).VisitedPages);
        }
    };

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const filteredData = data.filter(
        item => selectedAppId === 'All' || item.appId === parseInt(selectedAppId)
    );
    const currentData = filteredData.slice(startIdx, endIdx);

    const renderTableBody = () => {
        if (filteredData.length === 0) {
            return (
                <tr>
                    <td colSpan="3">No data found for the selected filter.</td>
                </tr>
            );
        }

        return currentData.map((item, index) => (
            <tr key={item._id}>
                <td>{index + 1}</td>
                <td>{item.pagename}</td>
                <td>
                    {item.visitedtime.hours +
                        ' : ' +
                        item.visitedtime.min +
                        ' : ' +
                        item.visitedtime.sec}
                </td>
            </tr>
        ));
    };

    return (
        <div>
            <div className="mainContent">
                <div className="container">
                    <Link to="/user_engagement">
                        <div className="">
                            <button type="button" className="btn btn-primary mt-2">
                                back
                            </button>
                        </div>
                    </Link>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '20px',
                        }}
                    >
                        <h3>User Engagement </h3>
                        <div>
                            <label htmlFor="appIdDropdown">Filter</label> &nbsp;
                            <select id="appIdDropdown" onChange={handleAppIdChange}>
                                <option value={'All'}>All</option>
                                {/* Add options for each unique appId in your data */}
                                {allVisitedPages.map(data => (
                                    <option key={data?.appId} value={data?.appId}>
                                        {data?.product}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="tableFixHead">
                        <table className="table table-bordered text-center table-hover">
                            <thead className="thead-grey">
                                <tr>
                                    <th scope="col">SNo.</th>
                                    <th scope="col">Pagename</th>
                                    <th scope="col">VisitedTime</th>
                                </tr>
                            </thead>
                            <tbody>{renderTableBody()}</tbody>
                        </table>
                    </div>
                </div>
                {filteredData.length > 0 && (
                    <ReactPaginate
                        pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                        pageRangeDisplayed={itemsPerPage}
                        marginPagesDisplayed={1}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        forcePage={currentPage - 1}
                    />
                )}
            </div>
        </div>
    );
};

export default UserRegister;
