import React from 'react';
import RoutePage from '../routePage/userPage';
const userPage = () => {
    const Data = [
        {
            id: '1',
            routeName: 'User Engagement',
            popuptext: [
                {
                    title: 'View',
                    link: '/user_engagement',
                },
            ],
        },
        {
            id: '2',
            routeName: 'All Users',
            popuptext: [
                {
                    title: 'Premium Users',
                    link: '/premiumUsers',
                },
                {
                    title: 'Users Registered',
                    link: '/users',
                },
            ],
        },
        {
            id: '3',
            routeName: 'Users Register',
            popuptext: [
                {
                    title: 'View',
                    link: '/user_register',
                },
            ],
        },
    ];
    return (
        <div>
            <RoutePage Data={Data} title={'UserPage'} />
        </div>
    );
};

export default userPage;
