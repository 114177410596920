import React, { useState, useEffect, useRef } from "react";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedCard } from "../../redux/action/card";
import { updateCard } from "../../redux/action/card";
import Form from "react-bootstrap/Form";
import { Button, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import './showCheckItem.css';
const ShowCheckItem = () => {
  const { selectedCard } = useSelector((store) => store.cardRoot);
  const { selectedBucket } = useSelector((store) => store.bucketRoot);

  const dispatch = useDispatch();
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [checklistNewItemId, setchecklistNewItemId] = useState("");
  const [checklistId, setChecklistId] = useState("");
  const [checklistItemId, setChecklistItemId] = useState("");
  const [listItemValue, setListItemValue] = useState("");
  const [checklistTitle, setCheckListTitle] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [checklistdeleteId, setChecklistdeleteId] = useState("");
  const popupRef = useRef(null);
  const [deleteItem, setDeleteItem] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState("")
  const [deleteItemName, setDeleteItemName] = useState("")
  const [deleteCheckListid, setDeleteCheckListid] = useState("")
  const [checklistdeleteTitle,setChecklistdeleteTitle]=useState("");
  const [item, setItem] = useState("");
  const handleAddItem = (id) => {
    setIsAddingItem(true);
    setchecklistNewItemId(id);
    setChecklistItemId("");
  };

  const handledeletePopup = (checklist) => {
    if (deletePopup === false) {
      setdeletePopup(true);
      setChecklistdeleteId(checklist._id);
      setChecklistdeleteTitle(checklist.checklistTitle);

    } else {
      setdeletePopup(false);
    }
  };

  const handleclosedeletepopup = () => {
    setdeletePopup(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setdeletePopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  const handlecloseremovepopup = () => {
    setdeletePopup(false);
  };

  const handleCancelAddItem = () => {
    setIsAddingItem(false);
  };

  const handleSaveNewItem = () => {
    let checklist = selectedCard && selectedCard.checklist;

    let findIndex = checklist.findIndex(item => String(item?._id) === checklistNewItemId);
    let sourceItem = checklist.find(item => String(item?._id) === checklistNewItemId);
    if (sourceItem) {
        sourceItem.checklistItem.push({
            itemTitle: item,
            itemStatus: false,
        });
    }
    checklist.splice(findIndex, 1, sourceItem);

    selectedCard.checklist = checklist;
    dispatch(setSelectedCard(selectedCard));

    let cardId = selectedCard?._id;
    let bucketId = selectedBucket?._id;
    let boardId = selectedBucket?.boardId;
    let payload = {
        checklist,
        update: 'checklist',
    };
console.log("checklist",payload)
    dispatch(updateCard(boardId, bucketId, cardId, payload));
    setItem("");
    setIsAddingItem(false);
    setChecklistId("");
  };

  const handleCheckItem = (checklistId, itemId) => {
    let checklist = selectedCard && selectedCard.checklist;

    let findIndex = checklist.findIndex(
      (item) => String(item._id) === checklistId
    );
    let sourceItem = checklist.find((item) => String(item._id) === checklistId);
    if (sourceItem) {
      let Index = sourceItem.checklistItem.findIndex(
        (item) => String(item._id) === itemId
      );
      let checkItem = sourceItem.checklistItem.find(
        (item) => item._id === itemId
      );
      checkItem.itemStatus = !checkItem.itemStatus;
      sourceItem.checklistItem.splice(Index, 1, checkItem);
    }

    selectedCard.checklist = checklist;
    dispatch(setSelectedCard(selectedCard));

    let cardId = selectedCard && selectedCard._id;
    let bucketId = selectedBucket && selectedBucket._id;
    let boardId = selectedBucket && selectedBucket.boardId;

    dispatch(updateCard(boardId, bucketId, cardId, {update:'checklist',checklist:checklist}));
    setItem("");
    setIsAddingItem(false);
  };
  const handleDeleteChecklist = () => {
    // console.log(checklistdeleteId, "checklistId ======");
    let checklist = selectedCard && selectedCard.checklist;
    let findIndex = checklist.findIndex(
      (item) => String(item._id) === checklistdeleteId
    );
    let sourceItem = checklist.find(
      (item) => String(item._id) === checklistdeleteId
    );
    if (sourceItem) {
      checklist.splice(findIndex, 1);
    }

    selectedCard.checklist = checklist;
    dispatch(setSelectedCard(selectedCard));
    let cardId = selectedCard && selectedCard._id;
    let bucketId = selectedBucket && selectedBucket._id;
    let boardId = selectedBucket && selectedBucket.boardId;

    dispatch(updateCard(boardId, bucketId, cardId, {update:'checklist',checklist:checklist}));
    setdeletePopup(false);
    setChecklistdeleteId("");
  };
  const onEnterNewItem = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSaveNewItem();

    }
  };
  const onEnterEditItem = (e, checklistId, listId) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleEditNewItem(checklistId, listId)
    }
  }
  const onEnterEditTitle = (e, checklistId) => {
    console.log("edit title", e, checklistId)
    if (e.keyCode === 13) {
      e.preventDefault();
      handleEditListTitle(checklistId)
    }
  }
  const handleEditCheckItem = (listItem) => {
    setChecklistItemId(listItem._id);
    setListItemValue(listItem.itemTitle);
    setIsAddingItem(false);
    setchecklistNewItemId("");
    setChecklistId("");

  };
  const handleEditNewItem = (checklistId, itemId) => {
    let checklist = selectedCard && selectedCard.checklist;

    let findIndex = checklist.findIndex(
      (item) => String(item._id) === checklistId
    );
    let sourceItem = checklist.find((item) => String(item._id) === checklistId);
    if (sourceItem) {
      let Index = sourceItem.checklistItem.findIndex(
        (item) => String(item._id) === itemId
      );
      let checkItem = sourceItem.checklistItem.find(
        (item) => item._id === itemId
      );
      checkItem.itemTitle = listItemValue;
      sourceItem.checklistItem.splice(Index, 1, checkItem);
    }
    console.log("source item ------------", sourceItem, checklist);

    selectedCard.checklist = checklist;
    dispatch(setSelectedCard(selectedCard));

    let cardId = selectedCard && selectedCard._id;
    let bucketId = selectedBucket && selectedBucket._id;
    let boardId = selectedBucket && selectedBucket.boardId;

    dispatch(updateCard(boardId, bucketId, cardId, {update:'checklist',checklist:checklist}));
    setItem("");
    setIsAddingItem(false);
    setChecklistItemId("");
  };

  const handleTitleEdit = (checklist) => {
    setChecklistId(checklist._id);
    setCheckListTitle(checklist.checklistTitle);
    setChecklistItemId();
    setListItemValue("");
    setIsAddingItem(false);
    setchecklistNewItemId("");
  };
  const handleEditListTitle = (checklistId) => {
    let checklist = selectedCard && selectedCard.checklist;

    let findIndex = checklist.findIndex(
      (item) => String(item._id) === checklistId
    );
    let sourceItem = checklist.find((item) => String(item._id) === checklistId);
    if (sourceItem) {
      sourceItem.checklistTitle = checklistTitle;
    }
    checklist.splice(findIndex, 1, sourceItem);

    selectedCard.checklist = checklist;
    dispatch(setSelectedCard(selectedCard));

    let cardId = selectedCard && selectedCard._id;
    let bucketId = selectedBucket && selectedBucket._id;
    let boardId = selectedBucket && selectedBucket.boardId;

    dispatch(updateCard(boardId, bucketId, cardId, {update:'checklist',checklist:checklist}));
    setChecklistId("");
    setCheckListTitle("");
  };
 
 
  const handleDeleteItemPopUp = (itemid, itemName, CheckId) => {
    setDeleteItemId(itemid);
    setDeleteItemName(itemName);
    setDeleteCheckListid(CheckId);
    if (deleteItem === true) {
      setDeleteItem(false)
    }
    else {
      setDeleteItem(true)
    }
  }
  const handleClose1 = () => {
    setDeleteItem(!deleteItem);
  };
  const handleDeleteItem = (itemId, checkListId) => {
    console.log("CHECk", checkListId);
    let checklist = selectedCard && selectedCard.checklist;
    let index = checklist.findIndex(items => items._id === checkListId);
    console.log(index, "INDEX")
    checklist = checklist.filter(items => items._id === checkListId)[0].checklistItem.filter(items => items._id !== itemId);
    console.log(checklist, "CHECKLIST")
    selectedCard.checklist[index].checklistItem = checklist;
    dispatch(setSelectedCard(selectedCard));

    let cardId = selectedCard && selectedCard._id;
    let bucketId = selectedBucket && selectedBucket._id;
    let boardId = selectedBucket && selectedBucket.boardId;

    dispatch(updateCard(boardId, bucketId, cardId, {update:'checklist',checklist:checklist}));
    setDeleteItem(!deleteItem);
  }

  return (
    <>
      {selectedCard &&
        selectedCard.checklist &&
        selectedCard.checklist.map((checklist,key) => (
          <>
            <div className="d-flex justify-content-between mt-3" key={key}>
              <div className="alignleft">
                <span className="boards-header-icon" style={{ marginLeft: "1px" }}>
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    <AiOutlineCheckSquare size={25} />
                  </span>
                </span>
                {checklistId === checklist?._id ? (
                  <div
                    className="d-block"
                    style={{ position: "relative", top: "-30px", left: "25px" }}
                  >
                    {/* <Form> */}
                    <input
                      className="form-control form-control-sm ml-10"
                      id="exampleFormControlTextarea1"
                      style={{ width: "540px" }}
                      placeholder="Add an item"
                      value={checklistTitle}
                      autoFocus
                      onKeyDown={(e) => onEnterEditTitle(e, checklist._id)}
                      onChange={(evt) => setCheckListTitle(evt.target.value)}
                    />
                    {/* </Form> */}

                    <Button
                      onClick={() => handleEditListTitle(checklist._id)}
                      className="m-2 mx-3"
                      style={{ fontSize: "0.9rem", padding: "0.21rem 0.54em" }}
                    >
                      save
                    </Button>
                    <Button
                      className="bg-light text-dark border-0  "
                      onClick={() => setChecklistId("")}
                      style={{ fontSize: "0.9rem", padding: "0.21rem 0.54em" }}
                    >
                      X
                    </Button>
                  </div>
                ) : (
                  <span
                    className=""
                    onClick={() => handleTitleEdit(checklist)}
                  >
                    {" "}
                    {checklist && checklist.checklistTitle}
                  </span>
                )}
              </div>
              <div className="alignright">
                {checklistId !== "" && checklist._id === checklistId ? null : (
                  <a className="nav-link boards--section ul__a" href="#">
                    <span
                      className="ul__text mx-2"
                      onClick={() => handledeletePopup(checklist)}
                    >
                      Delete{" "}
                    </span>
                  </a>
                )}

                {deletePopup ? (
                  <Card
                    ref={popupRef}
                    style={{
                      position: "absolute",
                      right: "305px",
                      top: "500px",
                      width: "360px",
                      zIndex: "1",
                    }}
                    className="shadow rounded-0 p-3"
                  >
                    <div className="d-flex mx-auto">
                      <p className="text-muted text-center" style={{fontSize:"14px"}}>
                      
                        Delete {checklistdeleteTitle}
                      </p>
                      <i
                        className="fa-solid fa-x text-muted"
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          right: "-80px",
                          top: "5px",
                          fontSize: "15px",
                        }}
                        onClick={() => handleclosedeletepopup()}
                      ></i>
                    </div>
                    <hr />
                    <p className=" text-muted" style={{ fontSize: "16px" }}>
                      Deleting a checklist is permanent and there is no way to
                      get it back.
                    </p>
                    <Button
                      variant="danger"
                      className="mx-1"
                      onClick={() => handleDeleteChecklist()}
                    >
                      Delete checklist
                    </Button>
                  </Card>
                ) : null}
              </div>
            </div>
            <div className="row my-2 gutter" style={{ marginTop: "-7px" }}>
            </div>
            {checklist &&
              checklist.checklistItem &&
              checklist.checklistItem.map((listItem,key) => (
                <>
                  <div
                    className="d-flex justify-content-between gutter Items"
                    id="MyID"
                    style={{ marginTop: "-17px", marginBottom: "23px" }}
                    key={key}
                  >
                    <div className="alignleft">
                      <div className="form-check">

                        <input
                          checked={listItem.itemStatus}
                          className="form-check-input border "
                          type="checkbox"
                          style={{
                            marginLeft: "-50px",
                            width: "19px",
                            height: "19px",
                            fontSize: "25px",
                            borderRadius: "0px",
                            borderColor: "#eeeeee"
                          }}
                          value=""
                          id="flexCheckDefault"
                          onClick={() =>
                            handleCheckItem(checklist._id, listItem._id)
                          }
                        />
                        {checklistItemId === listItem._id ? (
                          <div className="m-1">
                            <Form>
                              <textarea
                                className="form-control  ml-10 "
                                id="exampleFormControlTextarea1"
                                rows="2"
                                style={{ marginLeft: "-20px", width: "530px" }}
                                type="text"
                                placeholder="Add an item"
                                value={listItemValue}
                                onChange={(evt) =>
                                  setListItemValue(evt.target.value)
                                }
                                autoFocus
                                onKeyDown={(e) => onEnterEditItem(e, checklist._id, listItem._id)}
                              ></textarea>
                            </Form>
                            <div class="d-flex justify-content-between ">
                              <div className="alignleft">
                                <Button
                                  onClick={() =>
                                    handleEditNewItem(checklist._id, listItem._id)
                                  }
                                  className="mt-2"
                                  style={{ marginLeft: "-21px", marginRight:"10px",fontSize: "0.9rem", padding: "0.21rem 0.54em" }}
                                >
                                  save
                                </Button>
                                <Button
                                  className="bg-light text-dark border-0 mt-2"
                                  onClick={() => setChecklistItemId("")}
                                  style={{fontSize: "0.9rem", padding: "0.21rem 0.54em" ,width:"40px"}}

                                >
                                  X
                                </Button>
                              </div>
                             
                            </div>
                          </div>
                        ) : (
                          <label
                            className={listItem && listItem.itemStatus === true ? "font-weight-normal mt-2 form-check-label text-decoration-line-through text-muted font-weight-normal" : "form-check-label mt-2 text-muted"}
                            for="flexCheckDefault"
                            style={{ marginLeft: "-18px",fontSize: "16px" }}
                            onClick={() => handleEditCheckItem(listItem)}
                          >
                            <span className="text-muted font-weight-normal  " style={{ fontSize: "16px", marginLeft: "1px", color: "black", fontWeight: 'normal' }}>
                              {listItem && listItem.itemTitle && listItem.itemTitle}
                            </span>
                          </label>
                        )}
                      </div>
                    </div>
                    <p id={listItem._id} onClick={() => handleDeleteItemPopUp(listItem._id, listItem.itemTitle, checklist._id)} style={{ display: "none", cursor: "pointer", marginTop: "-6px", marginRight: "10px", marginBottom: "0px", fontSize: "20px" }}>...</p>
                    {
                    deleteItem ? <Card
                      ref={popupRef}
                      className="shadow rounded-0 shadow"
                      style={{ width: "335px", cursor: "default", position: "absolute", top: "296px", left: "561px"  }}
                    >
                      <section style={{ paddingLeft: "13px", paddingRight: "13px" }}>
                        <p
                          className=" text-center text-muted text_display1"
                          style={{ fontSize: "18px", marginTop: '5px',textOverflow:"ellipsis" ,width:"150px",marginLeft:"50px" }}
                        >
                          {deleteItemName}?
                          <span
                            style={{ position: "absolute", right: "10px", cursor: "pointer" }}
                            className="text-muted"
                            onClick={handleClose1}
                          >
                            <i class="fa-solid fa-xmark"></i>
                          </span>
                        </p>
                        <hr className="mt-1" />
                        <div className="px-3 pb-3">
                          <div>
                            <button className="justify-content-between Delete-btn" style={{ background: "white", border: "none", position: "relative", left: "-22px", letterSpacing: "0.5px" }} onClick={() => handleDeleteItem(deleteItemId, deleteCheckListid)}>
                              Delete
                            </button>
                          </div>
                        </div>
                      </section>
                    </Card> : ""
                  }
                  </div>
                  
                </>
              ))}

            {isAddingItem && checklistNewItemId === checklist._id ? (
              <div className="m-1" style={{ position: "relative", top: "-18px", left: "10px" }}>

                <textarea
                  className="form-control ml-10"
                  id="exampleFormControlTextarea1"
                  rows="1"
                  placeholder="Add an item"
                  style={{ marginLeft: "22px", width: "530px" }}
                  value={item}
                  onChange={(e) => setItem(e.target.value)}
                  autoFocus
                  onKeyDown={(e) => onEnterNewItem(e)}
                ></textarea>

                <Button
                  onClick={() => handleSaveNewItem()}
                  disabled={!item}
                  className="mt-2"
                  style={{ marginLeft: "28px", fontSize: "0.9rem", padding: "0.21rem 0.54em" }}
                >
                  Add
                </Button>
                <Button
                  className="bg-light text-dark border-0 mt-2 ms-2"
                  onClick={handleCancelAddItem}
                  style={{ fontSize: "0.9rem", padding: "0.21rem 0.54em" }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <button
                className="button3 boards--section ul__a p-2 border-0  "
                style={{  marginLeft: "35px", top: "-11px", backgroundColor: "rgba(223, 223, 223, 0.90)", color: "#172b4d" }}
                onClick={() => handleAddItem(checklist._id)}
              >
                <span className="ul_text mx-3 " style={{ fontSize: "0.9rem" }}>Add an item</span>
              </button>
            )}
          </>
        ))}
    </>
  );
};

export default React.memo(ShowCheckItem);