import React, { useState } from "react";
import { SwatchesPicker } from "react-color";

export default (props) => {
	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const popover = {
		position: 'absolute',
		zIndex: '999',
		top: '2px'
	}
	const cover = {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	}
	const handleClick = () => {
		setDisplayColorPicker(!displayColorPicker);
	};

	const handleClose = () => {
		setDisplayColorPicker(false);
	};
	const onChange = (color) => {
		props.onChange(color.hex, props.name);
		handleClose();
	}
	return (
		<div className="feild" style={{ backgroundColor: `${props.color}99` }}>
			<div className="colorpicker-container" id={props.name}>
				<div style={{ backgroundColor: props.color }} onClick={handleClick} className="colorpicker p-0" />
				{displayColorPicker ? <div style={popover}>
					<div style={cover} onClick={handleClose} />
					<SwatchesPicker name={props.name} value={props.color} onChange={onChange} />
				</div> : null}

			</div>
			{props.children}
		</div>
	);
}