import React, { useEffect } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import { backendUrl as url } from "../../backendUrl/url";
const axios = require("axios");

function AddBucket(props) {
  const [bucketName, setBucketName] = React.useState("");
  const [bucketNameLength, setBucketNameLength] = React.useState(0);
  useEffect(() => {
    setBucketName("");
  }, [props.visibility]);

  const handleSaveBucket = () => {
    const newBucket = {
      name: bucketName,
      rank: props.rank,
      prefilledKanban: props.ideaId,
    };
    axios
      .post(
        `${url}api/prefilledbuckets/add/${props.ideaId}`,
        { bucket: newBucket },
        {
          headers: {
            "x-access-token": props.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        props.toggleVisibility();
        props.refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Modal
        size="md"
        centered
        show={props.visibility}
        onHide={() => props.hideModal()}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title>Add New Lane</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name :</Form.Label>
              <Form.Control
                maxLength={30}
                type="text"
                onChange={(e) => {
                  setBucketName(e.target.value);
                  setBucketNameLength(e.target.value.length);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleSaveBucket();
                  }
                }}
                value={bucketName}
              />
              <Form.Text className="text-muted text-right">
                {bucketNameLength}/30
              </Form.Text>
            </Form.Group>
          </Form>
          <Alert
            variant="danger"
            className="text-center"
            style={{ padding: "5px 10px 5px 10px" }}
            show={props.buckets.indexOf(bucketName) !== -1 ? true : false}
          >
            Lane Already Exists
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            block
            className="bg-primary"
            onClick={() => {
              handleSaveBucket();
            }}
            disabled={
              bucketName === "" || props.buckets.indexOf(bucketName) !== -1
                ? true
                : false
            }
            style={{
              cursor:
                bucketName === "" || props.buckets.indexOf(bucketName) !== -1
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            Save Lane
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddBucket;
