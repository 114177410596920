import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React, { useEffect, useState, Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, ContentState, EditorState, Modifier } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import htmlReactParser from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import { SwatchesPicker } from 'react-color';
import PropTypes from 'prop-types';


const getEditorState = html => {
    const contentBlock = htmlToDraft(html || '<span></span>');
    if (!contentBlock) return null;

    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

    return EditorState.createWithContent(contentState);
};

export default function Modal(props) {
    const { title } = props;
    const [editorState, setEditorState] = useState('');
    const [status, setStatus] = useState(props.status || '');
    const [isVisible, setVisible] = useState(false);
    const [color, setColor] = useState(props.color || '#20b2aa');
    const [icon, setIcon] = useState('');
    //console.log(props.history)

    const handleEditSubmit = () => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        console.log(draftToHtml(editorState.getCurrentContent()));
        setEditorState(getEditorState('<span><span>'));
        window.$(`#${props.modalId} .close`).click();
        /**
         * Props: Edit Function here.
         */
        props.handleSubmit(html, color, icon);
    };
    const _getLengthOfSelectedText = () => {
        const currentSelection = editorState.getSelection();
        const isCollapsed = currentSelection.isCollapsed();

        let length = 0;

        if (!isCollapsed) {
            const currentContent = editorState.getCurrentContent();
            const startKey = currentSelection.getStartKey();
            const endKey = currentSelection.getEndKey();
            const startBlock = currentContent.getBlockForKey(startKey);
            const isStartAndEndBlockAreTheSame = startKey === endKey;
            const startBlockTextLength = startBlock.getLength();
            const startSelectedTextLength =
                startBlockTextLength - currentSelection.getStartOffset();
            const endSelectedTextLength = currentSelection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);
            console.log(currentSelection);
            if (isStartAndEndBlockAreTheSame) {
                length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
            } else {
                let currentKey = startKey;

                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } else {
                        length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                    }

                    currentKey = currentContent.getKeyAfter(currentKey);
                }
            }
        }

        return length;
    };
    const clearInlineStyles = () => {
        const styles = ['colorPicker'];
        const currentContent = editorState.getCurrentContent();

        // console.log(convertToRaw(currentContent));
        // const contentWithoutStyles = _.reduce(
        //   styles,
        //   (newContentState, style) =>
        //     Modifier.replaceText(
        //       newContentState,
        //       editorState.getCurrentContent(),

        //     ),
        //   editorState.getCurrentContent()
        // );

        setEditorState(getEditorState(currentContent.getPlainText('')));

        //  return EditorState.push(
        //     editorState,
        //     contentWithoutStyles,
        //     "change-inline-style"
        //   );
    };

    const _handleBeforeInput = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = _getLengthOfSelectedText();

        if (currentContentLength - selectedTextLength > 50 - 1) {
            console.log('you can type max 50 characters');

            return 'handled';
        }
    };

    const _handlePastedText = pastedText => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = _getLengthOfSelectedText();

        if (currentContentLength + pastedText.length - selectedTextLength > 50) {
            console.log('you can type max 50 characters');

            return 'handled';
        }
    };

    useEffect(() => {
        setEditorState(getEditorState(props.html));

        setColor(props.color || '#20b2aa');
        setIcon(props.icon);
        var wrap = document.querySelectorAll('.rdw-colorpicker-wrapper');
        for (let i = 0; i < wrap.length; i++) wrap[i].attributes.title.value = 'Text Color';
    }, [props.html, props.color, props.icon]);

    return (
        <div className="modal" data-backdrop="" id={props.modalId}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content border-0" style={{ width: 'auto' }}>
                    <div className="modal-header bg-primary">
                        <h5 className="modal-title text-white">
                            {title.replace(/<[^>]*>/g, '') /*html to plain text*/}
                        </h5>
                        <button
                            type="button"
                            className="close text-white mt-1 p-0"
                            data-dismiss="modal"
                            aria-label="Close"
                            style={{ background: "inherit", border: "none", fontSize: "30px" }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-2">
                            {/*props.title == "Edit Lane" || props.title == "Add Lane" ? (
                ""
              ) : (
                  <div className="col-3">
                    <select
                      className="form-control form-control-sm"
                      onChange={e => setStatus(e.target.value)}
                      value={status}
                    >
                      <option>Status</option>
                      <option value="start">Start</option>
                      <option value="stop">Stop</option>
                      <option value="continue">Continue</option>
                    </select>
                  </div>
              )*/}
                            <div className="col-2" style={{ minWidth: '10%' }}>
                                <div
                                    style={{
                                        ...styles.colorPicker,
                                        backgroundColor: color,
                                    }}
                                    onClick={() => setVisible(!isVisible)}
                                    title="Fill Color"
                                ></div>
                                {isVisible && (
                                    <div style={{ position: "absolute", zIndex: "100" }}>
                                        <SwatchesPicker
                                            color={color}
                                            onChangeComplete={(color_para) => {
                                                setColor(color_para.hex);
                                                setVisible(false);
                                            }}
                                        />
                                    </div>
                                )}

                            </div>
                            {/* <div className="col-2" style={{ maxWidth: "10%" }}>
                <MaterialUiIconPicker
                  label="Icon"
                  onPick={(icon) => setIcon(icon.name)}
                  modalTitle="Icon"
                  labelIcon={icon || InsertEmoticon}
                />
              </div> */}

                            <div className="col-2" style={{ maxWidth: '10%' }}>
                                <div
                                    className="removebutton"
                                    onClick={editorState => clearInlineStyles(editorState)}
                                    title="Remove All Formatting"
                                >
                                    <i className="fas fa-remove-format"></i>
                                </div>
                            </div>
                        </div>
                        {props.title == 'Edit Lane' || props.title == 'Add Lane' ? (
                            <div>
                                <Editor
                                    editorState={editorState}
                                    editorClassName="demo-editor"
                                    onEditorStateChange={newState => setEditorState(newState)}
                                    handleBeforeInput={_handleBeforeInput}
                                    handlePastedText={_handlePastedText}
                                    toolbar={{
                                        options: [
                                            'history',
                                            'fontFamily',
                                            'fontSize',
                                            'inline',
                                            'textAlign',
                                            'list',
                                            'link',

                                            'colorPicker',
                                        ],

                                        textAlign: { inDropdown: true },
                                        list: { inDropdown: true },
                                        inline: {
                                            options: ['bold', 'italic', 'underline'],
                                        },

                                        history: {
                                            options: ['undo', 'redo'],
                                        },
                                    }}
                                />
                                <div style={{ float: 'right' }}>
                                    {editorState
                                        ? editorState.getCurrentContent().getPlainText('').length
                                        : ''}
                                    /50
                                </div>
                            </div>
                        ) : (
                            <Editor
                                editorState={editorState}
                                editorClassName="demo-editor"
                                onEditorStateChange={newState => setEditorState(newState)}
                                toolbar={{
                                    options: [
                                        'history',
                                        'fontFamily',
                                        'fontSize',
                                        'inline',
                                        'textAlign',
                                        'list',
                                        'link',

                                        'colorPicker',
                                    ],

                                    textAlign: { inDropdown: true },
                                    list: { inDropdown: true },
                                    inline: {
                                        options: ['bold', 'italic', 'underline'],
                                    },
                                    history: {
                                        options: ['undo', 'redo'],
                                    },
                                }}
                            />
                        )}
                        <button
                            type="button"
                            onClick={handleEditSubmit}
                            className="btn btn-block bg-primary text-white mb-3 mt-3"
                        >
                            Submit
                        </button>
                        {props.history && (
                            <div className="card">
                                <div
                                    className="card-body"
                                    style={{
                                        maxHeight: '150px',
                                        overflow: 'auto',
                                    }}
                                >
                                    {props.history.map(history => {
                                        return (
                                            <div>
                                                <small>[{history.date.split('GMT')[0]}] </small>
                                                <small>
                                                    {history.action} {htmlReactParser(history.html)}
                                                </small>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    colorPicker: {
        width: '40px',
        height: '20px',
        backgroundColor: '#000',
    },
};

Modal.propTypes = {
    /**
     * Id of the Modal to pop-up
     */
    modalId: PropTypes.string,

    /**
     * Color of the header of this modal as well as
     * the color picker
     */
    color: PropTypes.string,

    /**
     * Title for the modal. The html content will be
     * replace with the plain text
     */
    title: PropTypes.string,

    /**
     * The html content in the editor
     */
    html: PropTypes.string,

    /**
     * @type <Function(html:String, color:String, icon:Component)>
     * @param {String} html html content will be passes as the parameter on submit
     * @param {String} color The selected color in the editor will be passed as the parameter
     */
    handleSubmit: PropTypes.func,
};
