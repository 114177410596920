import React, { Component} from 'react';

import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import QuillEditor from '../Editor/quill';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';

class Help extends Component {
    constructor(propos) {
        super(propos);
        var date = new Date();
        if (date.getMonth() < 10) var mm = '0' + (date.getMonth() + 1);
        else var mm = date.getMonth() + 1;

        var year = date.getFullYear();
        var displayDate = date.getDate();
        this.state = {
            title: '',
            description: '',
            selectedFile2: null,
            selectedFile: null,
            tags: [],
            content: '',
        };
        //   this.onSubmit = this.onSubmit.bind(this);
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    async onSubmit(e) {
        e.preventDefault();
        const data = {
            title: this.state.title,
            content: this.state.content,

            tags: this.state.tags,
        };
        // const data = new FormData();
        // data
        // data.append("title", this.state.title);
        // data.append("content", this.state.content);
        // data.append("files", this.state.selectedFile);

        // data.append("tags", this.state.tags);

        axios.post(CX_DEPLOYER + '/help', data).then(res => {
            console.log(res.data.msg);
            alert('Help Added');
            window.location.reload();
        });
    }
    onEditorChange = content => {
        this.setState({ content });
    };

    onFileChange = files => {
        this.setState({ selectedFile: files[0] });
    };

    render() {
        console.log(this.state.tags);
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-9 col-md-7 col-lg-8 mx-auto">
                        <div className="card card-signin my-5">
                            <div className="card-body">
                                <h4 className="card-title text-center">Add Help</h4>
                                <form className="form-signin" method="POST">
                                    <div className="form-group">
                                        <label for="email">Title</label>
                                        <input
                                            id="title"
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            value={this.state.title}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label for="subtitle">Content</label>
                                        <QuillEditor
                                            placeholder={'Start Posting Something'}
                                            onEditorChange={this.onEditorChange}
                                            onFilesChange={this.onFileChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label for="tags">Tags</label>
                                        <Autocomplete
                                            multiple
                                            id="tags-filled"
                                            options={[]}
                                            freeSolo
                                            onChange={(e, newValue) =>
                                                this.setState({ tags: newValue })
                                            }
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        label={option}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Add Tag"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="form-group no-margin">
                                        <button
                                            className="btn btn-info btn-block"
                                            onClick={e => this.onSubmit(e)}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Help;
