import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Rating from '@mui/material/Rating';
import Select from '@mui/material/Select';
import axios from 'axios';
import classNames from 'classnames/bind';

import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Overlay, Popover } from 'react-bootstrap';
import { AiOutlineDownCircle } from 'react-icons/ai';
import { BiTime } from 'react-icons/bi';
import { BsImage, BsStar, BsTelephoneFill, BsTextCenter } from 'react-icons/bs';
import { CgCalendarDates } from 'react-icons/cg';
import { CiTimer } from 'react-icons/ci';
import { FaRegCircleDot } from 'react-icons/fa6';
import { ImCross } from 'react-icons/im';
import { IoIosCheckbox } from 'react-icons/io';
import { MdShortText } from 'react-icons/md';
import { TfiText } from 'react-icons/tfi';
import { TiDelete } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import { editForm, sendFormImage } from '../redux/action/formSlice';
import {
    addQuestionOnEnter,
    deleteQuestion,
    editFormOnEnter,
    editQuestionOnEnter,
    editSectionOnEnter,
    fetchFormQuestions,
    handleSectionRemove,
} from '../redux/action/previewSlice';
import { KANBAN_CX_DEPLOYER_FORM, cookieToken } from '../services/url';
import AddCardPage from './AddCardPage';
import styles from './CreateForm.module.css';
import HoverOverlay from './HoverOverlay';
import _debounce from 'lodash/debounce';

const FormTemplate = () => {
    const [showTimer, setShowTimer] = useState(false);
    const [targetTimer, setTargetTimer] = useState(null);
    const timeRef = useRef(null);
    const [timer, setTimer] = useState('00:00');
    const [formDate, setFormDate] = useState(null);
    const dateRef = useRef(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [targetDatePicker, setTargetDatePicker] = useState(null);
    const [newOptionIndex, setNewOptionIndex] = useState(null);

    useEffect(() => {
        if (newOptionIndex !== null) {
            let toFind;

            switch (newOptionIndex.type) {
                case 'multiple_choice':
                    toFind = `multiple_optionInput_${newOptionIndex.index}_${newOptionIndex.fieldid}`;
                    break;

                case 'checkbox':
                    toFind = `check_optionInput_${newOptionIndex.index}_${newOptionIndex.fieldid}`;
                    break;

                case 'dropdown':
                    toFind = `dropdown_optionInput_${newOptionIndex.index}_${newOptionIndex.fieldid}`;
                    break;

                default:
                    toFind = null;
                    break;
            }

            const inputElement = document.getElementById(toFind);
            if (inputElement) {
                inputElement.focus();
            }

            // Reset the new option index
            setNewOptionIndex(null);
        }
    }, [newOptionIndex]);

    const handleClickTimer = event => {
        setShowTimer(!showTimer);
        setTargetTimer(event.target);
    };
    const navigate = useNavigate();
    const location = useLocation();
    const { formId } = location.state || {};
    // const [toShow, setToShow] = useState(false);
    // const [formId, setFormID] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchFormQuestions(formId));
    }, [dispatch, formId]);

    const formData1 = useSelector(state => state.preview.formData);

    const [isEditing, setIsEditing] = useState(false);

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [buttonText, setButtonText] = useState('Edit Form');

    const [childData, setChildData] = useState(null);
    // const handleClick = useCallback(() => {
    //   setIsHovered(true);
    // }, [setIsHovered]);
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    const [formData, setFormData] = useState({
        formId: '',
        formType: '',
        submitButtonText: '',
        videoData: null,
        imageTheme: null,
        sections: [],
        deleteQuestions: [],
        timer: '',
        date: '',
        isQuiz: '',
    });
    const [isQuiz, setIsQuiz] = useState(false);
    useEffect(() => {
        // Check if formData1 is available and not empty
        if (formData1 && Object.keys(formData1).length > 0) {
            setFormData({
                formId: formData1.formId || '',
                formType: formData1.formType || '',
                submitButtonText: formData1.submitButtonText || '',
                videoData: null,
                sections: formData1.sections || [],
                imageTheme: formData1.imageTheme ? formData1.imageTheme : null,
                timer: formData1.timer,
                date: formData1?.dateTime,
                isQuiz: formData1?.isQuiz,
            });
            setIsQuiz(formData1?.isQuiz);
            setTimer(formData1.timer);
            setFormDate(formData1.date);
        }
    }, [formData1]);

    const formTypeToggle = useCallback(() => {
        setIsQuiz(prev => {
            const data = { update: 'isQuiz', value: !prev };
            dispatch(editForm({ formId: formId, formData: data }));

            // Update the isQuiz property in formData synchronously
            setFormData(prevFormData => {
                return { ...prevFormData, isQuiz: !prev };
            });

            return !prev;
        });
    }, [dispatch, formId]);

    const handleFormTitleChange = useCallback((e, sectionIndex) => {
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };
            updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the array
            updatedFormData.sections[sectionIndex] = {
                ...prevFormData.sections[sectionIndex], // Shallow copy of the item
                title: e.target.value,
            };
            return updatedFormData;
        });
    }, []);

    const handleFormTitleChangeUndo = useCallback(
        title => {
            setUndoStack(prevUndoStack => [...prevUndoStack, { ...formData, title: title }]);
        },
        [formData]
    );

    const handleFormDescriptionChange = useCallback((e, sectionIndex) => {
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };
            updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the array
            updatedFormData.sections[sectionIndex] = {
                ...prevFormData.sections[sectionIndex], // Shallow copy of the section
                description: e.target.value, // Update the description
            };
            return updatedFormData;
        });
    }, []);

    const handleFormDescriptionChangeUndo = useCallback(
        title => {
            setUndoStack(prevUndoStack => [...prevUndoStack, { ...formData, description: title }]);
        },
        [formData]
    );
    const handleSubmitButtonTextChange = useCallback((e, sectionIndex) => {
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };
            updatedFormData.submitButtonText = e.target.value;
            // updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the array
            // updatedFormData.sections[sectionIndex] = {
            //   ...prevFormData.sections[sectionIndex], // Shallow copy of the section
            //   submitButtonText: e.target.value, // Update the button text
            // };
            return updatedFormData;
        });
    }, []);

    const handleSubmitButtonTextChangeUndo = useCallback(
        title => {
            setUndoStack(prevUndoStack => [
                ...prevUndoStack,
                { ...formData, submitButtonText: title },
            ]);
        },
        [formData]
    );

    const handleAddField = useCallback(
        _debounce((type, sectionIndex) => {
            const currentURL = window.location.href;

            setFormData(prevFormData => {
                const updatedFormData = { ...prevFormData };
                updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the sections array

                let newField = {
                    id: new Date().getTime(),
                    question: '',
                    required: false,
                    type: type,
                    options: [],
                    img: null,
                    qindex: prevFormData?.sections[sectionIndex]?.questions?.length,
                    sectionId: prevFormData?.sections[sectionIndex]._id,
                    formId: updatedFormData?.formId,
                };
                if (type === 'multiple_choice') {
                    newField.points = 1;
                }

                dispatch(addQuestionOnEnter(newField)).then(res => {
                    newField._id = res.payload._id;
                });
                updatedFormData.sections[sectionIndex] = {
                    ...prevFormData.sections[sectionIndex], // Shallow copy of the section
                    questions: [...prevFormData.sections[sectionIndex].questions, newField],
                };

                return updatedFormData;
            });
        }, 500),
        []
    );

    const handleCopyField = useCallback(
        (fieldId, sectionIndex) => {
            // const currentURL = window.location.href;
            setFormData(prevFormData => {
                const updatedFormData = { ...prevFormData };
                updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the array

                const fieldToCopy = updatedFormData.sections[sectionIndex].questions.find(
                    field => field.id === fieldId
                );

                if (fieldToCopy) {
                    const newField = {
                        ...fieldToCopy,
                        id: Date.now().toString(),
                        qindex: updatedFormData.sections[sectionIndex].questions.length,
                    };
                    dispatch(addQuestionOnEnter(newField));

                    updatedFormData.sections[sectionIndex] = {
                        ...updatedFormData.sections[sectionIndex], // Shallow copy of the section
                        questions: [...updatedFormData.sections[sectionIndex].questions, newField],
                    };

                    return updatedFormData;
                }

                return prevFormData; // Return the unchanged state if the field is not found
            });
        },
        [dispatch]
    );

    // const [questionInputs, setQuestionInputs] = useState({});

    const handleUpdateQuestion = useCallback((fieldId, question, sectionIndex) => {
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };
            updatedFormData.sections = [...prevFormData.sections];

            const updatedQuestions = prevFormData.sections[sectionIndex]?.questions.map(field =>
                field.id === fieldId ? { ...field, question } : field
            );

            updatedFormData.sections[sectionIndex] = {
                ...prevFormData.sections[sectionIndex],
                questions: updatedQuestions,
            };

            return updatedFormData;
        });
    }, []);

    const handleUpdatePoints = useCallback(
        (fieldId, points, sectionIndex, questionId) => {
            setFormData(prevFormData => {
                const updatedFormData = { ...prevFormData };
                updatedFormData.sections = [...prevFormData.sections];

                const updatedQuestions = prevFormData.sections[sectionIndex]?.questions.map(field =>
                    field.id === fieldId ? { ...field, points } : field
                );

                updatedFormData.sections[sectionIndex] = {
                    ...prevFormData.sections[sectionIndex],
                    questions: updatedQuestions,
                };

                return updatedFormData;
            });
            const data = {
                update: 'points',
                value: points,
                questionId,
            };

            if (points !== '') {
                dispatch(editQuestionOnEnter({ ...data }));
            }
        },
        [dispatch]
    );

    const handleUpdateQuestionRequired = useCallback(
        (fieldId, question, required, sectionIndex) => {
            setFormData(prevFormData => {
                const updatedFormData = { ...prevFormData };
                updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the array

                const updatedFields = prevFormData.sections[sectionIndex].questions.map(field =>
                    field.id === fieldId ? { ...field, required } : field
                );

                updatedFormData.sections[sectionIndex] = {
                    ...prevFormData.sections[sectionIndex], // Shallow copy of the section
                    questions: updatedFields,
                };

                return updatedFormData;
            });
        },
        []
    );

    // const handleUpdateQuestionUndo = useCallback(
    //   (fieldId, question, required) => {
    //     const updatedFields = formData.questions.map((field) =>
    //       field.id === fieldId ? { ...field, question, required } : field
    //     );

    //     setUndoStack([...undoStack, { ...formData, questions: updatedFields }]);
    //   },
    //   [formData, setUndoStack, undoStack]
    // );
    const handleUpdateFieldType = useCallback((fieldId, type, sectionIndex) => {
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };
            updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the array

            const updatedFields = prevFormData.sections[sectionIndex].questions.map(field =>
                field.id === fieldId ? { ...field, type } : field
            );

            updatedFormData.sections[sectionIndex] = {
                ...prevFormData.sections[sectionIndex], // Shallow copy of the section
                questions: updatedFields,
            };

            return updatedFormData;
        });
    }, []);

    // -------------------------------Add option to multiple choice or checkbox field---------------------------------------------------
    const handleAddOption = useCallback(
        _debounce((fieldId, sectionIndex) => {
            setFormData(prevFormData => {
                const updatedFormData = { ...prevFormData };
                updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the array

                const updatedFields = prevFormData.sections[sectionIndex].questions.map(field => {
                    if (
                        field.id === fieldId &&
                        (field.type === 'multiple_choice' || field.type === 'checkbox')
                    ) {
                        const newOption = {
                            // option: `Option ${field.options.length + 1}`,
                            option: '',
                        };
                        const value = newOption;
                        const questionId = field._id;
                        const update = 'Addoptions';
                        dispatch(editQuestionOnEnter({ value, update, questionId })).then(() => {
                            setNewOptionIndex({
                                index: field.options.length,
                                type: field.type,
                                fieldid: field.id,
                            });
                        });

                        return { ...field, options: [...field.options, newOption] };
                    }
                    return field;
                });

                updatedFormData.sections[sectionIndex] = {
                    ...prevFormData.sections[sectionIndex], // Shallow copy of the section
                    questions: updatedFields,
                };

                return updatedFormData;
            });
        }, 500),
        []
    );

    // --------------------------------------------------------------------mcg

    const handleAddOptionmcg = useCallback(
        fieldId => {
            const updatedFields = formData.questions.map(field => {
                if (
                    field.id === fieldId &&
                    (field.type === 'multiple_choice_option' || field.type === 'checkbox_grid')
                ) {
                    const newOption = { option: '' };
                    return { ...field, options: [...field.options, newOption] };
                }
                return field;
            });

            setFormData({ ...formData, questions: updatedFields });
        },
        [formData]
    );

    const handleRemoveField = (fieldId, sectionIndex) => {
        if (sectionIndex >= 0 && sectionIndex < formData.sections.length) {
            // Find the question index in the section
            const questionIndex = formData.sections[sectionIndex].questions.findIndex(
                question => question.id === fieldId
            );

            if (questionIndex !== -1) {
                // Remove the question from the section's questions array
                const updatedQuestions = [...formData.sections[sectionIndex].questions].filter(
                    question => question.id !== fieldId
                );
                // updatedQuestions.splice(questionIndex, 1);

                // Update the form data with the modified questions array
                const updatedFormData = {
                    ...formData,
                    sections: [
                        ...formData.sections.slice(0, sectionIndex),
                        {
                            ...formData.sections[sectionIndex],
                            questions: updatedQuestions,
                        },
                        ...formData.sections.slice(sectionIndex + 1),
                    ],
                };

                // Update the state with the new form data
                setFormData(updatedFormData);

                // Call the API to update the form
                const removedQuestion = formData.sections[sectionIndex].questions[questionIndex];
                const currentSection = formData.sections[sectionIndex];

                dispatch(
                    deleteQuestion({
                        questionID: removedQuestion._id,
                        sectionId: currentSection._id,
                    })
                );
            }
        }
    };

    const handleUndos = fieldId => {
        const updatedFields = formData.questions.filter(field => field.id === fieldId);

        const updatedFormQuestions = formData.questions.map(field =>
            field.id === fieldId ? updatedFields[0] : field
        );

        setFormData({ ...formData, questions: updatedFormQuestions });
    };
    //----------------------------------------- Update option in multiple choice option or checkbox grid field---------------------------------------------------

    const handleUpdateOptionmcg = (fieldId, optionIndex, option, sectionIndex) => {
        const updatedFields = formData.sections[sectionIndex].questions.map(field => {
            if (
                field.id === fieldId &&
                (field.type === 'multiple_choice_option' || field.type === 'checkbox_grid')
            ) {
                const options = [...field.options];
                options[optionIndex] = { option };
                return { ...field, options: options };
            }
            return field;
        });
        const updatedFormData = { ...formData };
        updatedFormData.sections[sectionIndex].questions = updatedFields;

        setFormData(updatedFormData);
    };

    // --------------------------------------------------------------------mcg

    const handleUpdateOption = (fieldId, optionIndex, option, sectionIndex) => {
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };
            updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the array

            const updatedFields = prevFormData.sections[sectionIndex].questions.map(field => {
                if (field.id === fieldId) {
                    const options = [...field.options];
                    options[optionIndex] = { option, _id: options[optionIndex]._id };

                    return { ...field, options };
                }
                return field;
            });

            updatedFormData.sections[sectionIndex] = {
                ...prevFormData.sections[sectionIndex], // Shallow copy of the section
                questions: updatedFields,
            };

            return updatedFormData;
        });
    };

    // --------------------------------------------------------------------mcg

    const handleRemoveOption = useCallback(
        _debounce((fieldId, optionIndex, sectionIndex) => {
            setFormData(prevFormData => {
                const updatedFormData = { ...prevFormData };
                updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the array

                const updatedFields = prevFormData.sections[sectionIndex].questions.map(field => {
                    if (
                        field.id === fieldId &&
                        (field.type === 'multiple_choice' || field.type === 'checkbox')
                    ) {
                        const options = [...field.options];
                        options.splice(optionIndex, 1);
                        const value = options;
                        const update = 'editoptions';
                        const questionId = field._id;
                        dispatch(editQuestionOnEnter({ value, update, questionId }));
                        return { ...field, options };
                    }
                    return field;
                });

                updatedFormData.sections[sectionIndex] = {
                    ...prevFormData.sections[sectionIndex], // Shallow copy of the section
                    questions: updatedFields,
                };

                return updatedFormData;
            });
        }, 500),
        []
    );

    const handleEmptyOption = (fieldId, optionIndex, sectionIndex) => {
        formData.sections[sectionIndex].questions.forEach(field => {
            if (field.id === fieldId && field.options) {
                field.options.forEach(option => {
                    if (option?.option && option.option.trim() === '') {
                        handleUpdateOption(
                            fieldId,
                            optionIndex,
                            `Option${optionIndex + 1}`,
                            sectionIndex
                        );
                    }
                });
            }
        });
    };

    // --------------------------------------------------------------------mcg

    const handleRemoveOptionmcg = (fieldId, optionIndex) => {
        const updatedFields = formData.questions.map(field => {
            if (
                field.id === fieldId &&
                (field.type === 'multiple_choice_option' || field.type === 'checkbox_grid')
            ) {
                const options = [...field.options];
                options.splice(optionIndex, 1);
                return { ...field, options: options };
            }
            return field;
        });

        setFormData({ ...formData, questions: updatedFields });
    };

    //------------------------------------------This is for dropdown handle --------------------------------------------------------------------//

    // -----------------------------Add option to dropdown field---------------------------
    const dhandleAddOption = (fieldId, sectionIndex) => {
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };
            updatedFormData.sections = [...prevFormData.sections];

            const updatedFields = prevFormData.sections[sectionIndex].questions.map(field => {
                if (field.id === fieldId && field.type === 'dropdown') {
                    const newOption = {
                        //  option: `Option ${field.options.length + 1}`
                        option: '',
                    };
                    setNewOptionIndex({
                        index: field.options.length,
                        type: field.type,
                        fieldid: field.id,
                    });
                    return { ...field, options: [...field.options, newOption] };
                }
                return field;
            });

            updatedFormData.sections[sectionIndex] = {
                ...prevFormData.sections[sectionIndex],
                questions: updatedFields,
            };

            return updatedFormData;
        });
    };

    // -----------------------Update option in dropdown field-----------------------------------------------

    const dhandleUpdateOption = (fieldId, optionIndex, option, sectionIndex) => {
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };
            updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the sections array

            const updatedFields = prevFormData.sections[sectionIndex].questions.map(field => {
                if (field.id === fieldId && field.type === 'dropdown') {
                    const options = [...field.options];
                    options[optionIndex] = { option, _id: options[optionIndex]._id };
                    return { ...field, options: options };
                }
                return field;
            });

            updatedFormData.sections[sectionIndex] = {
                ...prevFormData.sections[sectionIndex], // Shallow copy of the section
                questions: updatedFields,
            };

            return updatedFormData;
        });
    };

    // --------------------------------------Remove option from dropdown field--------------------------------

    const dhandleRemoveOption = (fieldId, optionIndex, sectionIndex) => {
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };
            updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the sections array

            const updatedFields = prevFormData.sections[sectionIndex].questions.map(field => {
                if (field.id === fieldId && field.type === 'dropdown') {
                    const options = [...field.options];
                    options.splice(optionIndex, 1);
                    const value = options;
                    const update = 'editoptions';
                    const questionId = field._id;
                    dispatch(editQuestionOnEnter({ value, update, questionId }));
                    return { ...field, options: options };
                }
                return field;
            });

            updatedFormData.sections[sectionIndex] = {
                ...prevFormData.sections[sectionIndex], // Shallow copy of the section
                questions: updatedFields,
            };

            return updatedFormData;
        });
    };

    //------------------------------------------------- end This is for dropdown handle --------------------------------------------------------------------//

    //--------------------Handle date----------------------

    const handleUpdatedate = (fieldId, date) => {
        const updatedFields = formData.questions.map(field =>
            field.id === fieldId ? { ...field, dateValue: date } : field
        );

        setFormData({ ...formData, questions: updatedFields });
    };

    //--------------------Handle time----------------------

    const handleUpdatetime = (fieldId, time) => {
        const updatedFields = formData.questions.map(field =>
            field.id === fieldId ? { ...field, timeValue: time } : field
        );

        setFormData({ ...formData, questions: updatedFields });
    };

    //--------------------Handle image----------------------

    const handleUpdateimage = (fieldId, image) => {
        const updatedFields = formData.questions.map(field =>
            field.id === fieldId ? { ...field, image: image } : field
        );

        setFormData({ ...formData, questions: updatedFields });
    };

    // --------------------------------------------Handle APi -----------------------------------------------------------------------

    const handleAddForm = async () => {
        if (!isEditing) {
            try {
                if (formData.sections[0].title.trim() === '') {
                    return;
                }

                if (
                    formData.sections[0].questions.length === 0 ||
                    formData.sections[0].questions.some(q => {
                        if (q?.qImgUrl) {
                            return false;
                        }
                        return q.question.trim() === '';
                    })
                ) {
                    return;
                }
                setIsButtonDisabled(true);
                const editResponse = await dispatch(editForm({ formId, formData, buttonText }));
                if (formData.imageTheme !== themeImage) {
                    const Data = new FormData();
                    Data.append('form-attach', themeImage);
                    Data.append('formId', formId);
                    dispatch(sendFormImage({ formId: formId, data: Data }))
                        .then(() => {
                            navigate('/');
                        })
                        .catch(error => {
                            console.error('Error adding form:', error);
                            throw error;
                        });
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
    };
    const handleUpdateVideoData = videoData => {
        setFormData({ ...formData, videoData });
    };

    const handleBlur = () => {
        setIsEditing(false);
    };
    const handleUndo = useCallback(() => {
        if (undoStack.length > 0) {
            const prevFormData = undoStack.pop();
            setFormData(prevFormData);
            setUndoStack([...undoStack]);
            setRedoStack([...redoStack, formData]);
        }
    }, [undoStack, redoStack, formData]);

    const handleRedo = useCallback(() => {
        if (redoStack.length > 0) {
            const nextFormData = redoStack.pop();
            setFormData(nextFormData);
            setRedoStack([...redoStack]);
            setUndoStack([...undoStack, formData]);
        }
    }, [redoStack, undoStack, formData]);
    // const [selectedImg, setSelectedImg] = useState(null);
    // const [selectedImgId, setSelectedImgId] = useState(null);

    // const handleImageChange = useCallback(
    //   (event, fieldId) => {

    //     const file = event.target.files[0];

    //     // Ensure that a file was selected
    //     if (file) {
    //       const reader = new FileReader();

    //       // When the file is loaded, set the image source to the data URL
    //       reader.onload = (e) => {
    //         setSelectedImg(e.target.result);
    //         const imgDataUrl = e.target.result;
    //         const updatedQuestions = formData.questions.map((question) => {
    //           if (question.id === selectedImgId) {
    //             // Update the img field for the specific question with the new image data URL
    //             return { ...question, img: imgDataUrl };
    //           }
    //           return question;
    //         });

    //         // Update the formData state with the new array
    //         setFormData({ ...formData, questions: updatedQuestions });
    //         setUndoStack([
    //           ...undoStack,
    //           { ...formData, questions: updatedQuestions },
    //         ]);
    //       };

    //       // Read the file as a data URL
    //       reader.readAsDataURL(file);
    //     }
    //   },
    //   [formData, undoStack, selectedImgId]
    // );

    // const fileInputRef = useRef(null);
    // const handleIconClick = useCallback((id) => {

    //   setSelectedImgId(id);

    //   // Programmatically trigger the click event on the hidden file input
    //   fileInputRef.current.click();
    // }, []);
    const [themeImage, setThemeImage] = useState(null);
    // const [selectImageUrl, setSelectedImageUrl] = useState(null);
    const handleImageUrl = async data => {
        // Update the formData with the new themeImage
        // console.log()
        setFormData({
            ...formData,
            imageTheme: data,
        });
    };

    const handleImageChange = async (event, fieldId, index, sectionIndex) => {
        const file = event.target.files[0];
        if (file) {
            const QImgFormData = new FormData();
            QImgFormData.append('questionImage', file);

            try {
                const response = await axios.post(
                    `${KANBAN_CX_DEPLOYER_FORM}question/questionImage`,
                    QImgFormData,
                    {
                        headers: {
                            'x-access-token': cookieToken,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response?.data?.success) {
                    // setQuesImageUrl(response.data.url);

                    handleUpdateQuestionImgUrl(fieldId, response?.data?.url, sectionIndex);
                    console.log('file uploaded');
                } else {
                    console.log('file not uploaded');
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    const handleUpdateQuestionImgUrl = (fieldId, qImgUrl, sectionIndex) => {
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };
            updatedFormData.sections = [...prevFormData.sections];

            const updatedFields = prevFormData.sections[sectionIndex].questions.map(field => {
                if (field.id === fieldId) {
                    const questionId = field._id;
                    dispatch(
                        editQuestionOnEnter({
                            value: qImgUrl,
                            update: 'qImgUrl',
                            questionId,
                        })
                    );
                    return { ...field, qImgUrl };
                }
                return field;
            });

            updatedFormData.sections[sectionIndex] = {
                ...prevFormData.sections[sectionIndex], // Shallow copy of the section
                questions: updatedFields,
            };

            return updatedFormData;
        });
    };

    // const handleRemoveQuestionImage = (fieldId, indexSect) => {
    //   console.log(fieldId, indexSect)
    //   const updatedFields = formData?.sections[indexSect].questions.map((field) =>
    //     field.id === fieldId ? { ...field, qImgUrl: "" } : field,
    //   );

    //   setFormData({ ...formData, questions: updatedFields });
    //   // dispatch(fetchFormQuestions(formId))
    // };

    const handleRemoveQuestionImage = (fieldId, indexSect) => {
        const updatedFields = formData?.sections[indexSect].questions.map(field =>
            field.id === fieldId ? { ...field, qImgUrl: '' } : field
        );
        const questionId = formData?.sections[indexSect].questions.find(
            field => field.id === fieldId
        )?._id;

        dispatch(
            editQuestionOnEnter({
                value: '',
                update: 'qImgUrl',
                questionId,
            })
        );

        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };
            updatedFormData.sections = [...prevFormData.sections];
            updatedFormData.sections[indexSect] = {
                ...prevFormData.sections[indexSect], // Shallow copy of the section
                questions: updatedFields,
            };
            return updatedFormData;
        });
    };

    const handleSectionSaveOnEnter = (value, update, sectionId) => {
        dispatch(editSectionOnEnter({ value, update, sectionId }));
    };
    const handleQuestionSaveOnEnter = (value, update, questionId) => {
        // console.log(value,update,questionId)
        dispatch(editQuestionOnEnter({ value, update, questionId }));
    };
    const handleQuestionSaveAnswerOnEnter = (value, update, questionId) => {
        // console.log(value,update,questionId)
        dispatch(editQuestionOnEnter({ value, update, questionId }));
    };

    const handleEditFormOnEnter = (value, update, formId) => {
        dispatch(editFormOnEnter({ value, update, formId }));
    };
    const handleAddNewSection = (value, update, formId) => {
        dispatch(editFormOnEnter({ value, update, formId }));
    };
    const [showDelteModal, setShowDeleteModal] = useState(false);
    const [deleteSectionDetails, setDeleteSectonDetails] = useState(null);
    const handleCloseDeletePopup = () => {
        setDeleteSectonDetails(null);
        setShowDeleteModal(false);

        // setAnchorEle(null);
    };
    const handleDeleteSection = ({ sectionId, update, formId }) => {
        handleEditFormOnEnter(sectionId, update, formId);
        dispatch(handleSectionRemove(sectionId));
        setShowDeleteModal(false);
    };

    const handleClickDatePicker = event => {
        setShowDatePicker(!showDatePicker);
        setTargetDatePicker(event.target);
    };
    const handleImageTheme = url => {
        dispatch(editFormOnEnter({ value: url, update: 'image', formId }));
    };

    const [addingAnswer, setAddingAnswer] = useState(null);
    const [answerFieldId, setAnswerFieldId] = useState(null);
    const handleAddAnswerClick = useCallback(fieldId => {
        setAddingAnswer(fieldId);
        setAnswerFieldId(fieldId);
    }, []);
    const handleAddAnswer = (fieldId, sectionIndex, answerIndex, questionId) => {
        setFormData(prevFormData => {
            const updatedSections = [...prevFormData.sections];
            const updatedQuestions = updatedSections[sectionIndex].questions.map(field => {
                if (field.id === fieldId) {
                    if (field.type === 'multiple_choice' || field.type === 'checkbox') {
                        return { ...field, answer: answerIndex };
                    }
                }
                return field;
            });

            updatedSections[sectionIndex] = {
                ...updatedSections[sectionIndex],
                questions: updatedQuestions,
            };
            setUndoStack(prevUndoStack => [
                ...prevUndoStack,
                { ...formData, questions: updatedQuestions },
            ]);

            return { ...prevFormData, sections: updatedSections };
        });
        handleQuestionSaveAnswerOnEnter(answerIndex, 'editanswer', questionId);
        setAnswerFieldId(null);
        setAddingAnswer(null);
    };

    return (
        <>
            <Dialog
                open={showDelteModal}
                onClose={() => setShowDeleteModal(false)}
                aria-labelledby="responsive-dialog-title"
                fullWidth="sm"
            >
                <DialogTitle id="responsive-dialog-title">{'Confirm Deletion'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete Section and its Questions?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseDeletePopup}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleDeleteSection({ ...deleteSectionDetails })}
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            {/* dialog box */}

            <>
                {formData?.sections?.map((section, sectionIndex) => (
                    <div key={section._id}>
                        <div className={styles.root_wrapper}>
                            <div className={styles.root_col}>
                                {formData.imageTheme !== null && (
                                    <div className="Nothing">
                                        <img
                                            className={styles.customize_theme}
                                            src={formData.imageTheme}
                                            alt=""
                                        />
                                    </div>
                                )}

                                <div className={styles.titlepage_container}>
                                    <div className={styles.section_header_bar}>
                                        {formData.sections.length > 1 && (
                                            <span className={styles.section_indicator}>{`section ${
                                                sectionIndex + 1
                                            }/${formData.sections.length}`}</span>
                                        )}
                                        {sectionIndex === 0 && timer && timer !== '00:00' && (
                                            <div className={styles.timer_div}>
                                                <CiTimer fontSize={32} />
                                                {timer}
                                            </div>
                                        )}

                                        {formData.sections.length > 1 && (
                                            <span
                                                className={styles.section_delete_button}
                                                onClick={() => {
                                                    setShowDeleteModal(true);
                                                    setDeleteSectonDetails({
                                                        sectionId: section._id,
                                                        update: 'removeSection',
                                                        formId,
                                                    });
                                                    // handleEditFormOnEnter(
                                                    //   section._id,
                                                    //   "removeSection",
                                                    //   formId,
                                                    // );
                                                    // dispatch(handleSectionRemove(section._id));
                                                }}
                                            >
                                                <TiDelete />
                                            </span>
                                        )}
                                    </div>
                                    <Card className={classNames(styles.titlepage_card, 'shadow')}>
                                        <span className={styles.titlepagecard_span} />

                                        <input
                                            type="text"
                                            className={styles.title_input}
                                            value={section.title}
                                            onChange={e => handleFormTitleChange(e, sectionIndex)}
                                            onBlur={() => {
                                                handleFormTitleChangeUndo(section.title);
                                                handleSectionSaveOnEnter(
                                                    section.title,
                                                    'title',
                                                    section._id
                                                );
                                            }}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter') {
                                                    handleSectionSaveOnEnter(
                                                        section.title,
                                                        'title',
                                                        section._id
                                                    );
                                                }
                                            }}
                                        />
                                        <input
                                            type="text"
                                            className={styles.description_input}
                                            value={section.description}
                                            onChange={e =>
                                                handleFormDescriptionChange(e, sectionIndex)
                                            }
                                            onBlur={() => {
                                                handleFormDescriptionChangeUndo(
                                                    formData.description
                                                );
                                                handleSectionSaveOnEnter(
                                                    section.description,
                                                    'description',
                                                    section._id
                                                );
                                            }}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter') {
                                                    handleSectionSaveOnEnter(
                                                        section.description,
                                                        'description',
                                                        section._id
                                                    );
                                                }
                                            }}
                                        />
                                        {sectionIndex === 0 && (
                                            <input
                                                type="text"
                                                className={styles.description_input}
                                                value={formData.submitButtonText}
                                                onChange={e =>
                                                    handleSubmitButtonTextChange(e, sectionIndex)
                                                }
                                                onBlur={() => {
                                                    handleSubmitButtonTextChangeUndo(
                                                        section.submitButtonText
                                                    );
                                                    handleEditFormOnEnter(
                                                        formData.submitButtonText,
                                                        'submitButtonText',
                                                        formId
                                                    );
                                                    // handleSectionSaveOnEnter(
                                                    //   section.submitButtonText,
                                                    //   "submitButtonText",
                                                    //   section._id,
                                                    // )t
                                                }}
                                                onKeyDown={e => {
                                                    if (e.key === 'Enter') {
                                                        handleEditFormOnEnter(
                                                            formData.submitButtonText,
                                                            'submitButtonText',
                                                            formId
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                    </Card>
                                </div>

                                {/* ------------------------------------------------Render  field type ------------------------------------------------*/}

                                <div className={styles.questionpage_container}>
                                    {section?.questions?.map((field, index) => (
                                        <Card
                                            key={field.id}
                                            className={classNames(
                                                styles.questionpage_card,
                                                'shadow'
                                            )}
                                        >
                                            <div className={styles.inputandSelect_container}>
                                                <div className={styles.inputandImg_container}>
                                                    <input
                                                        type="text"
                                                        placeholder={`Enter Question ${index + 1}`}
                                                        className={styles.question_input}
                                                        value={field.question ? field.question : ''}
                                                        onChange={e =>
                                                            handleUpdateQuestion(
                                                                field.id,
                                                                e.target.value,
                                                                sectionIndex
                                                            )
                                                        }
                                                        onBlur={() => {
                                                            if (field.question === '') {
                                                                handleQuestionSaveOnEnter(
                                                                    `Question ${index + 1}`,
                                                                    'question',
                                                                    field._id
                                                                );
                                                            } else {
                                                                handleQuestionSaveOnEnter(
                                                                    field.question,
                                                                    'question',
                                                                    field._id
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {field.qImgUrl ? (
                                                        <div
                                                            key={field.id}
                                                            className={styles.removeQuestionImage}
                                                            onClick={() =>
                                                                handleRemoveQuestionImage(
                                                                    field.id,
                                                                    sectionIndex
                                                                )
                                                            }
                                                        >
                                                            <ImCross />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <div className="select_image">
                                                        <label htmlFor={`fileInput-${index}`}>
                                                            <BsImage />
                                                        </label>

                                                        <input
                                                            type="file"
                                                            id={`fileInput-${index}`}
                                                            style={{ display: 'none' }}
                                                            onChange={e =>
                                                                handleImageChange(
                                                                    e,
                                                                    field.id,
                                                                    index,
                                                                    sectionIndex
                                                                )
                                                            }
                                                            accept="image/*"
                                                        />
                                                    </div>
                                                    <FormControl
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                            m: 2,
                                                            minWidth: 150,
                                                        }}
                                                    >
                                                        <Select
                                                            sx={{
                                                                height: '30px',
                                                                '& .MuiSelect-select': {
                                                                    padding: '0px 14px',
                                                                },
                                                            }}
                                                            value={field.type}
                                                            label="Age"
                                                            // onChange={handleChange}
                                                            onChange={e => {
                                                                handleUpdateFieldType(
                                                                    field.id,
                                                                    e.target.value,
                                                                    sectionIndex
                                                                );
                                                                handleQuestionSaveOnEnter(
                                                                    e.target.value,
                                                                    'type',
                                                                    field._id
                                                                );
                                                            }}
                                                        >
                                                            <MenuItem selected value="text">
                                                                <TfiText className="mx-2" />
                                                                Text
                                                            </MenuItem>

                                                            <MenuItem value="multiple_choice">
                                                                <FaRegCircleDot className="mx-2" />
                                                                Multiple choice
                                                            </MenuItem>
                                                            {!isQuiz && (
                                                                <MenuItem value="checkbox">
                                                                    <IoIosCheckbox className="mx-2" />
                                                                    Checkbox
                                                                </MenuItem>
                                                            )}
                                                            <MenuItem value="short_answer">
                                                                <MdShortText className="mx-2" />
                                                                Short Answer
                                                            </MenuItem>
                                                            <MenuItem value="paragraph">
                                                                <BsTextCenter className="mx-2" />
                                                                Paragraph
                                                            </MenuItem>
                                                            <MenuItem value="dropdown">
                                                                <AiOutlineDownCircle className="mx-2" />
                                                                Dropdown
                                                            </MenuItem>
                                                            <MenuItem value="phone">
                                                                <BsTelephoneFill className="mx-2" />
                                                                Phone Number
                                                            </MenuItem>
                                                            <MenuItem value="address">
                                                                <BsTextCenter className="mx-2" />
                                                                Address
                                                            </MenuItem>
                                                            <MenuItem value="time">
                                                                <BiTime className="mx-2" />
                                                                Time
                                                            </MenuItem>
                                                            <MenuItem value="date">
                                                                <CgCalendarDates className="mx-2" />
                                                                Date
                                                            </MenuItem>
                                                            <MenuItem value="rating">
                                                                <BsStar className="mx-2" />
                                                                Rating
                                                            </MenuItem>
                                                            <MenuItem value="file_upload">
                                                                <BsStar className="mx-2" />
                                                                Document upload
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>

                                            {field?.qImgUrl && (
                                                <div key={field.id} className={styles.qImgUrl_div}>
                                                    <img
                                                        src={field?.qImgUrl}
                                                        alt="Question "
                                                        className={styles.qImgUrl}
                                                    />
                                                </div>
                                            )}
                                            {field.img && (
                                                <div className={styles.image_question}>
                                                    <img
                                                        className="img-fluid"
                                                        src={field.img}
                                                        alt="Selected"
                                                    />
                                                </div>
                                            )}

                                            {field.type === 'linear_scale' && (
                                                <>
                                                    <input
                                                        className={styles.options_input}
                                                        placeholder="label(optional)"
                                                    />

                                                    <input
                                                        className={styles.options_input}
                                                        placeholder="label(optional)"
                                                    />
                                                </>
                                            )}

                                            {/* -------------------------------------------- Address -------------------------------------- */}
                                            {field.type === 'address' && (
                                                <input
                                                    type="text"
                                                    className={styles.paragraph_input}
                                                    placeholder="Enter the address"
                                                    disabled
                                                />
                                            )}
                                            {/* --------------------------------------------text--------------------------------------- */}

                                            {field.type === 'text' && (
                                                <input
                                                    type="text"
                                                    className={styles.paragraph_input}
                                                    placeholder="Enter text"
                                                    disabled
                                                />
                                            )}
                                            {/* -------------------------e-------------------ShortAnswer--------------------------------------- */}

                                            {field.type === 'short_answer' && (
                                                <textarea
                                                    className={`${styles.paragraph_input} ${styles.text_area}`}
                                                    rows="1"
                                                    placeholder="short_answer"
                                                    disabled
                                                />
                                            )}
                                            {/* --------------------------------------------paragraph-------------------------------------- */}

                                            {field.type === 'paragraph' && (
                                                <textarea
                                                    className={`${styles.paragraph_input} ${styles.text_area}`}
                                                    rows="3"
                                                    placeholder="paragraph"
                                                    disabled
                                                />
                                            )}
                                            {/* --------------------------------------------choose file-------------------------------------- */}
                                            {field.type === 'file_upload' && (
                                                <input
                                                    type="file"
                                                    className={styles.paragraph_input}
                                                    accept=".jpg, .jpeg, .png, .pdf" // Limiting accepted file types
                                                    required // Making the field mandatory
                                                    disabled
                                                />
                                            )}
                                            {/* -------------------------------------------- Number -------------------------------------- */}
                                            {field.type === 'number' && (
                                                <input
                                                    type="number"
                                                    className={styles.paragraph_input}
                                                    placeholder="Number"
                                                    min={0} // Setting a minimum value
                                                    max={100} // Setting a maximum value
                                                    step={1} // Setting the step value (increments)
                                                    required // Making the field mandatory
                                                    disabled
                                                />
                                            )}
                                            {/* -------------------------------------------- Email -------------------------------------- */}
                                            {field.type === 'email' && (
                                                <input
                                                    type="email"
                                                    className={styles.paragraph_input}
                                                    placeholder="Email"
                                                    required // Making the field mandatory
                                                    disabled
                                                />
                                            )}
                                            {/* -------------------------------------------- Phone-------------------------------------- */}
                                            {field.type === 'phone' && (
                                                <input
                                                    type="tel" // Using 'tel' input type for phone numbers
                                                    className={styles.paragraph_input}
                                                    placeholder="Phone number"
                                                    pattern="[0-9]{10}" // Adding a pattern for valid phone numbers (10 digits)
                                                    required // Making the field mandatory
                                                    disabled
                                                />
                                            )}
                                            {/* --------------------------------------------Link-------------------------------------- */}
                                            {field.type === 'link' && (
                                                <input
                                                    type="url"
                                                    className={styles.paragraph_input}
                                                    placeholder="Link"
                                                    required // Making the field mandatory
                                                    disabled
                                                />
                                            )}
                                            {/* -------------------------------------------- Rating -------------------------------------- */}
                                            {field.type === 'rating' && (
                                                <Rating
                                                    className="mx-3"
                                                    name="half-rating-read"
                                                    defaultValue={0}
                                                    precision={0.5}
                                                    readOnly
                                                />
                                            )}

                                            {/* -------------------------------------------- Signature -------------------------------------- */}
                                            {field.type === 'signature' && (
                                                <input
                                                    type="text"
                                                    className={styles.paragraph_input}
                                                    placeholder="Signature"
                                                    required // Making the field mandatory
                                                    disabled
                                                />
                                            )}
                                            {/* -------------------------------------------- Opinion Scale -------------------------------------- */}
                                            {field.type === 'opinion_scale' && (
                                                <input
                                                    type="range" // Using 'range' input type for opinion scale
                                                    className={styles.paragraph_input}
                                                    min={1} // Setting the minimum scale value
                                                    max={5} // Setting the maximum scale value
                                                    step={1} // Setting the step value (increments)
                                                    required // Making the field mandatory
                                                    disabled
                                                />
                                            )}
                                            {/* -------------------------------------------- statement -------------------------------------- */}
                                            {field.type === 'statement' && (
                                                <input
                                                    type="text"
                                                    className={styles.paragraph_input}
                                                    placeholder="Statement"
                                                    required // Making the field mandatory
                                                    disabled
                                                />
                                            )}
                                            {/* -------------------------------------------- Ranking -------------------------------------- */}
                                            {field.type === 'ranking' && (
                                                <input
                                                    type="number"
                                                    className={styles.paragraph_input}
                                                    placeholder="Ranking"
                                                    min={1} // Setting a minimum ranking value
                                                    max={10} // Setting a maximum ranking value
                                                    step={1} // Setting the step value (increments)
                                                    required // Making the field mandatory
                                                    disabled
                                                />
                                            )}
                                            {/* -------------------------------------------- Website Link -------------------------------------- */}
                                            {field.type === 'website_link' && (
                                                <input
                                                    type="text"
                                                    className={styles.paragraph_input}
                                                    placeholder="Website link"
                                                    disabled
                                                />
                                            )}
                                            {/* --------------------------------------------dropdown--------------------------------------- */}

                                            {/* {field.type === "dropdown" && (
                  <input type="text" className="options_input"
                  />
                )} */}

                                            {/* --------------------------------------------date--------------------------------------- */}

                                            {field.type === 'date' && (
                                                <div className="date_input_container">
                                                    <label
                                                        htmlFor={`date_${field.id}`}
                                                        className="date_label"
                                                    />
                                                    <input
                                                        type="date"
                                                        id={`date_${field.id}`}
                                                        className={styles.date_input}
                                                        value={field.dateValue}
                                                        onChange={e =>
                                                            handleUpdatedate(
                                                                field.id,
                                                                e.target.value
                                                            )
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                            )}

                                            {/* --------------------------------------------time-------------------------------------- */}

                                            {field.type === 'time' && (
                                                <input
                                                    type="time"
                                                    className={styles.time_input}
                                                    value={field.timeValue}
                                                    onChange={e =>
                                                        handleUpdatetime(field.id, e.target.value)
                                                    }
                                                    disabled
                                                />
                                            )}
                                            {/* --------------------------------------------image--------------------------------------- */}

                                            {field.type === 'image' && (
                                                <div>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        className={styles.image_input}
                                                        onChange={e =>
                                                            handleUpdateimage(
                                                                field.id,
                                                                e.target.files[0]
                                                            )
                                                        }
                                                    />
                                                    {field.image && (
                                                        <img
                                                            src={URL.createObjectURL(field.image)}
                                                            alt="uploaded"
                                                            className="image_preview"
                                                        />
                                                    )}
                                                </div>
                                            )}

                                            {/*------------------------------------ This is for dropdown  ---------------------------------------------------*/}

                                            {field.type === 'dropdown' && (
                                                <div className={styles.field_options}>
                                                    {field.options.map((option, index) => (
                                                        <div key={option}>
                                                            <input
                                                                id={`dropdown_optionInput_${index}_${field.id}`}
                                                                type="text"
                                                                className={styles.options_input}
                                                                value={option.option}
                                                                placeholder={`Option ${index + 1}`}
                                                                onChange={e =>
                                                                    dhandleUpdateOption(
                                                                        field.id,
                                                                        index,
                                                                        e.target.value,
                                                                        sectionIndex
                                                                    )
                                                                }
                                                                onClick={e =>
                                                                    dhandleUpdateOption(
                                                                        field.id,
                                                                        index,
                                                                        e.target.value,
                                                                        sectionIndex
                                                                    )
                                                                }
                                                                onFocus={e =>
                                                                    dhandleUpdateOption(
                                                                        field.id,
                                                                        index,
                                                                        e.target.value,
                                                                        sectionIndex
                                                                    )
                                                                }
                                                                onBlur={() => {
                                                                    handleQuestionSaveOnEnter(
                                                                        field.options,
                                                                        'editoptions',
                                                                        field._id
                                                                    );
                                                                }}
                                                                onKeyDown={e => {
                                                                    if (e.key === 'Enter') {
                                                                        dhandleAddOption(
                                                                            field.id,
                                                                            sectionIndex
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                            <i
                                                                className="fa-solid fa-lg fa-multiply text-danger"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() =>
                                                                    dhandleRemoveOption(
                                                                        field.id,
                                                                        index,
                                                                        sectionIndex
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ))}
                                                    <button
                                                        // className="button button-secondary"
                                                        type="button"
                                                        className={`${styles.button} ${styles.button_secondry}`}
                                                        onClick={() =>
                                                            dhandleAddOption(field.id, sectionIndex)
                                                        }
                                                    >
                                                        Add Option
                                                    </button>
                                                </div>
                                            )}

                                            {/* ---------------------multiple choice option ---------------------------------- */}

                                            {(field.type === 'multiple_choice_option' ||
                                                field.type === 'checkbox_grid ') && (
                                                <>
                                                    <div className={styles.parent}>
                                                        <div className={styles.row}>
                                                            {field.options.map((option, index) => (
                                                                <div key={option}>
                                                                    {field.type ===
                                                                    'multiple_choice_option' ? (
                                                                        <i className="fa-regular fa-square text-muted" />
                                                                    ) : (
                                                                        <i className="fa-regular fa-circle text-muted" />
                                                                    )}

                                                                    <input
                                                                        type="text"
                                                                        className={
                                                                            styles.options_input
                                                                        }
                                                                        value={option.option}
                                                                        onChange={e =>
                                                                            handleUpdateOptionmcg(
                                                                                field.id,
                                                                                index,
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        placeholder="Row"
                                                                    />

                                                                    <i
                                                                        className="fa-solid fa-lg fa-multiply text-danger"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() =>
                                                                            handleRemoveOptionmcg(
                                                                                field.id,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            ))}

                                                            <button
                                                                type="button"
                                                                className="button button-secondary"
                                                                onClick={() =>
                                                                    handleAddOptionmcg(field.id)
                                                                }
                                                            >
                                                                Add Row
                                                            </button>
                                                        </div>

                                                        <div className={styles.column}>
                                                            {field.options.map((option, index) => (
                                                                <div key={option}>
                                                                    {field.type ===
                                                                    'multiple_choice_option' ? (
                                                                        <i className="fa-regular fa-square text-muted" />
                                                                    ) : (
                                                                        <i className="fa-regular fa-circle text-muted" />
                                                                    )}

                                                                    <input
                                                                        type="text"
                                                                        className={
                                                                            styles.options_input
                                                                        }
                                                                        value={option.option}
                                                                        onChange={e =>
                                                                            handleUpdateOptionmcg(
                                                                                field.id,
                                                                                index,
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        placeholder="Column"
                                                                    />

                                                                    <i
                                                                        className="fa-solid fa-lg fa-multiply text-danger"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() =>
                                                                            handleRemoveOptionmcg(
                                                                                field.id,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            ))}

                                                            <button
                                                                type="button"
                                                                className="button button-secondary"
                                                                onClick={() =>
                                                                    handleAddOptionmcg(field.id)
                                                                }
                                                            >
                                                                Add Column
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {/*------------------------------------------- MultipleChoice ----------------------------*/}

                                            {field.type === 'multiple_choice' && (
                                                <div className={styles.field_options}>
                                                    {field.options.map((option, index) => (
                                                        <div key={option}>
                                                            {field.answer?.toString() ===
                                                                index?.toString() && isQuiz ? (
                                                               
                                                                <i className="text-success fa fa-check" aria-hidden="true" />
                                                            ) : (
                                                                <i className="fa fa-regular fa-circle text-muted" />
                                                            )}
                                                            <input
                                                                id={`multiple_optionInput_${index}_${field.id}`}
                                                                type="text"
                                                                className={styles.options_input}
                                                                value={option.option}
                                                                placeholder={`Option ${index + 1}`}
                                                                onChange={e =>
                                                                    handleUpdateOption(
                                                                        field.id,
                                                                        index,
                                                                        e.target.value,
                                                                        sectionIndex
                                                                    )
                                                                }
                                                                onClick={e =>
                                                                    handleUpdateOption(
                                                                        field.id,
                                                                        index,
                                                                        e.target.value,
                                                                        sectionIndex
                                                                    )
                                                                }
                                                                onFocus={e =>
                                                                    handleUpdateOption(
                                                                        field.id,
                                                                        index,
                                                                        e.target.value,
                                                                        sectionIndex
                                                                    )
                                                                }
                                                                onBlur={() => {
                                                                    handleQuestionSaveOnEnter(
                                                                        field.options,
                                                                        'editoptions',
                                                                        field._id
                                                                    );
                                                                }}
                                                                onKeyDown={e => {
                                                                    if (e.key === 'Enter') {
                                                                        if (
                                                                            field.options.length -
                                                                                1 ===
                                                                                index &&
                                                                            e.target.value.length
                                                                        ) {
                                                                            handleQuestionSaveOnEnter(
                                                                                field.options,
                                                                                'editoptions',
                                                                                field._id
                                                                            );
                                                                            handleAddOption(
                                                                                field.id,
                                                                                sectionIndex
                                                                            );
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            {/* {field.options.length > 1 && ( */}
                                                            {isQuiz ? (
                                                                <>
                                                                    {addingAnswer !== field.id ? (
                                                                        <i
                                                                            className="fa fa-solid fa-lg fa-times text-danger"
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleRemoveOption(
                                                                                    field.id,
                                                                                    index,
                                                                                    sectionIndex
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <i
                                                                            class="fa fa-check text-success"
                                                                            aria-hidden="true"
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleAddAnswer(
                                                                                    field.id,
                                                                                    sectionIndex,
                                                                                    index,
                                                                                    field._id
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <i
                                                                    className="fa-solid fa-lg fa-times text-danger"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                        handleRemoveOption(
                                                                            field.id,
                                                                            index,
                                                                            sectionIndex
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                            {/* )} */}
                                                        </div>
                                                    ))}
                                                    <button
                                                        type="button"
                                                        // className="button button-secondary"
                                                        className={`${styles.button} ${styles.button_secondry}`}
                                                        onClick={() =>
                                                            handleAddOption(field.id, sectionIndex)
                                                        }
                                                    >
                                                        Add Option
                                                    </button>
                                                </div>
                                            )}

                                            {/*------------------------------------------- Checkbox ----------------------------*/}
                                            {!isQuiz && field.type === 'checkbox' && (
                                                <div className={styles.field_options}>
                                                    {field.options.map((option, index) => (
                                                        <div key={option}>
                                                            <i className="fa-regular fa-square text-muted" />
                                                            <input
                                                                id={`check_optionInput_${index}_${field.id}`}
                                                                type="text"
                                                                className={styles.options_input}
                                                                value={option.option}
                                                                placeholder={`Option ${index + 1}`}
                                                                onChange={e =>
                                                                    handleUpdateOption(
                                                                        field.id,
                                                                        index,
                                                                        e.target.value,
                                                                        sectionIndex
                                                                    )
                                                                }
                                                                onClick={e =>
                                                                    handleUpdateOption(
                                                                        field.id,
                                                                        index,
                                                                        e.target.value,
                                                                        sectionIndex
                                                                    )
                                                                }
                                                                onFocus={e =>
                                                                    handleUpdateOption(
                                                                        field.id,
                                                                        index,
                                                                        e.target.value,
                                                                        sectionIndex
                                                                    )
                                                                }
                                                                onBlur={() => {
                                                                    handleQuestionSaveOnEnter(
                                                                        field.options,
                                                                        'editoptions',
                                                                        field._id
                                                                    );
                                                                }}
                                                                onKeyDown={e => {
                                                                    if (e.key === 'Enter') {
                                                                        handleAddOption(
                                                                            field.id,
                                                                            sectionIndex
                                                                        );
                                                                    }
                                                                }}
                                                            />

                                                            {/* {field.options.length > 1 && ( */}
                                                            <i
                                                                className="fa fa-solid fa-lg fa-times text-danger"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() =>
                                                                    handleRemoveOption(
                                                                        field.id,
                                                                        index,
                                                                        sectionIndex
                                                                    )
                                                                }
                                                            />
                                                            {/* )} */}
                                                        </div>
                                                    ))}
                                                    <button
                                                        // className="button button-secondary"

                                                        type="button"
                                                        className={`${styles.button} ${styles.button_secondry}`}
                                                        onClick={() =>
                                                            handleAddOption(field.id, sectionIndex)
                                                        }
                                                    >
                                                        Add Option
                                                    </button>
                                                </div>
                                            )}

                                            <div className={styles.questioncard_options}>
                                                {isQuiz &&
                                                addingAnswer !== field.id &&
                                                field.type === 'multiple_choice' ? (
                                                    <div className="text-muted d-flex align-items-center gap-1 w-100">
                                                        <span
                                                            className="text-muted d-flex align-items-center gap-1 w-100"
                                                            onClick={() =>
                                                                handleAddAnswerClick(
                                                                    field.id,
                                                                    sectionIndex
                                                                )
                                                            }
                                                        >
                                                            <i className="fa-solid fa-list-check" />
                                                            Add Answer
                                                        </span>

                                                        <span className="text-muted d-flex align-items-center gap-1 w-100">
                                                            <input
                                                                type="number"
                                                                placeholder={`Enter points ${
                                                                    index + 1
                                                                }`}
                                                                className={styles.points_input}
                                                                value={field.points}
                                                                onChange={e =>
                                                                    handleUpdatePoints(
                                                                        field.id,
                                                                        e.target.value,
                                                                        sectionIndex,
                                                                        field._id
                                                                    )
                                                                }
                                                                // onBlur={(e) => {
                                                                //   handleAutoTypeUpdate(
                                                                //     e.target.value,
                                                                //     field.id,
                                                                //     sectionIndex,
                                                                //   );
                                                                // }}
                                                                // onMouseLeave={() =>
                                                                //   handleUpdateQuestionUndo(
                                                                //     field.id,
                                                                //     field.question,
                                                                //     field.required,
                                                                //   )
                                                                // }
                                                            />
                                                        </span>
                                                    </div>
                                                ) : null}
                                                <svg
                                                    style={{ marginRight: '7px', marginTop: '4px' }}
                                                    onClick={() =>
                                                        handleCopyField(field.id, sectionIndex)
                                                    }
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 448 512"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill="#6C757D"
                                                        d="M208 0h124.1C344.8 0 357 5.1 366 14.1L433.9 82c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48M48 128h80v64H64v256h192v-32h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48"
                                                    />
                                                </svg>
                                                <svg
                                                    style={{ marginRight: '7px', marginTop: '4px' }}
                                                    onClick={() =>
                                                        handleRemoveField(field.id, sectionIndex)
                                                    }
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 448 512"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill="#6C757D"
                                                        d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16M53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
                                                    />
                                                </svg>
                                                <span className="ml-10 required_span">
                                                    Required
                                                </span>
                                                <Switch
                                                    className={styles.smallSwitch}
                                                    checked={field.required}
                                                    onChange={checked => {
                                                        handleUpdateQuestionRequired(
                                                            field.id,
                                                            field.question,
                                                            checked,
                                                            sectionIndex
                                                        );
                                                        handleQuestionSaveOnEnter(
                                                            checked,
                                                            'required',
                                                            field._id
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Card>
                                    ))}

                                    {childData && (
                                        <Card className="videopage_card shadow">
                                            <iframe
                                                title="YouTube Video"
                                                width="40%"
                                                height="250"
                                                src={`https://www.youtube.com/embed/${childData.id}`}
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                        </Card>
                                    )}

                                    {/* <div>
                    {isEditing ? (
                      <input
                        type="add-text"
                        value={buttonText}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        autoFocus


                      />
                    ) : (
                      <button
                        className="add-button"
                        onClick={handleAddForm}

                        disabled={isButtonDisabled}
                      >
                        {buttonText}
                      </button>
                    )}
                  </div> */}

                                    <div className={styles.button_container} />
                                </div>

                                <div />
                            </div>
                            <div className={`${styles.root_col} ${styles.root_col_2}`}>
                                {sectionIndex === 0 && (
                                    <>
                                        <HoverOverlay
                                            placement="left"
                                            hoverText="Auto Submit timer"
                                            mainInput={
                                                <Card
                                                    className={`${styles.timer_btn} `}
                                                    onClick={handleClickTimer}
                                                    ref={timeRef}
                                                >
                                                    <svg
                                                        width="21"
                                                        height="24"
                                                        viewBox="0 0 448 512"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill="#000000"
                                                            d="M432 304c0 114.9-93.1 208-208 208S16 418.9 16 304c0-104 76.3-190.2 176-205.5V64h-28c-6.6 0-12-5.4-12-12V12c0-6.6 5.4-12 12-12h120c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-28v34.5c37.5 5.8 71.7 21.6 99.7 44.6l27.5-27.5c4.7-4.7 12.3-4.7 17 0l28.3 28.3c4.7 4.7 4.7 12.3 0 17l-29.4 29.4l-.6.6C419.7 223.3 432 262.2 432 304m-176 36V188.5c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12V340c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12"
                                                        />
                                                    </svg>
                                                </Card>
                                            }
                                        />
                                        <Overlay
                                            show={showTimer}
                                            target={targetTimer}
                                            placement="bottom"
                                            container={timeRef}
                                            containerPadding={100}
                                        >
                                            <Popover id="popover-contained">
                                                <Popover.Header as="h3">Time</Popover.Header>
                                                <Popover.Body>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <input
                                                            type="time"
                                                            value={timer}
                                                            onClick={e => e.stopPropagation()}
                                                            onChange={e => {
                                                                setFormData({
                                                                    ...formData,
                                                                    timer: e.target.value,
                                                                });
                                                                setTimer(e.target.value);
                                                            }}
                                                            style={{ margin: '0.25rem' }}
                                                        />
                                                        <Button
                                                            size="sm"
                                                            onClick={() => {
                                                                handleEditFormOnEnter(
                                                                    timer,
                                                                    'timer',
                                                                    formId
                                                                );
                                                                setShowTimer(false);
                                                            }}
                                                        >
                                                            done
                                                        </Button>
                                                    </div>
                                                </Popover.Body>
                                            </Popover>
                                        </Overlay>
                                    </>
                                )}

                                {sectionIndex === 0 && (
                                    <>
                                        <HoverOverlay
                                            placement="left"
                                            hoverText="Form Date"
                                            mainInput={
                                                <Card
                                                    className={`${styles.timer_btn} `}
                                                    onClick={handleClickDatePicker}
                                                    ref={dateRef}
                                                >
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill="#000000"
                                                            d="M21 20V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2M9 18H7v-2h2zm0-4H7v-2h2zm4 4h-2v-2h2zm0-4h-2v-2h2zm4 4h-2v-2h2zm0-4h-2v-2h2zm2-5H5V7h14z"
                                                        />
                                                    </svg>
                                                </Card>
                                            }
                                        />
                                        <Overlay
                                            show={showDatePicker}
                                            target={targetDatePicker}
                                            placement="top"
                                            container={dateRef}
                                            containerPadding={100}
                                        >
                                            <Popover id="popover-contained">
                                                <Popover.Header as="h3">Calendar</Popover.Header>
                                                <Popover.Body>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <input
                                                            type="date"
                                                            value={formDate}
                                                            onClick={e => e.stopPropagation()}
                                                            onChange={e => {
                                                                setFormData({
                                                                    ...formData,
                                                                    date: e.target.value,
                                                                });
                                                                setFormDate(e.target.value);
                                                            }}
                                                            style={{ margin: '0.25rem' }}
                                                        />
                                                        <Button
                                                            size="sm"
                                                            onClick={() => {
                                                                handleEditFormOnEnter(
                                                                    formDate,
                                                                    'date-time',
                                                                    formId
                                                                );
                                                                setShowDatePicker(false);
                                                            }}
                                                        >
                                                            done
                                                        </Button>
                                                    </div>
                                                </Popover.Body>
                                            </Popover>
                                        </Overlay>
                                    </>
                                )}
                                <AddCardPage
                                    handleAddField={handleAddField}
                                    handleAddNewSection={handleAddNewSection}
                                    setChildData={setChildData}
                                    updateVideoData={handleUpdateVideoData}
                                    sectionIndex={sectionIndex}
                                    formId={formId}
                                    formType={formData.formType}
                                    formTypeToggle={formTypeToggle}
                                    isQuiz={isQuiz}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </>
        </>
    );
};
export default FormTemplate;
