import React, { useState, useEffect } from 'react';
import styles from './graph.module.css';

import { PureComponent } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

function Graph(props) {
    return (
        <div className={styles.graph}>
            <div style={{ display: 'inline-flex' }}>
                <h6>{props.text} </h6>
                <h6> - </h6>
                <h6>{props.text1}</h6>
            </div>

            <LineChart
                width={1000}
                height={500}
                data={props.data}
                margin={{
                    top: 40,
                    right: 50,
                    left: 20,
                    bottom: -10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={props.info} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                    type="monotone"
                    dataKey="Active_users"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                />
            </LineChart>
        </div>
    );
}

export default Graph;
