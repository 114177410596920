import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';
import { API_HOST } from '../services/url';

axios.defaults.headers.common['x-access-token'] = localStorage.getItem('cxtoken');

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 700,
        height: 450,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export default function Imagelibrary(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    useEffect(() => {
        axios
            .get(CX_DEPLOYER + '/library')
            .then(res => {
                console.log(res);
                setData(res.data.result);
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <div className="container" >
            <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-8 mx-auto">
                    <div className="card card-signin my-5">
                        <div className="card-body">
                            <h4 className="card-title text-center">View Images</h4>
                            <div className={classes.root}>
                                <GridList cellHeight={180} className={classes.gridList} cols={3}>
                                    {data.length != 0 ? (
                                        data.map(tile => (
                                            <GridListTile
                                                key={tile.imageUrl}
                                                style={{ cursor: 'pointer', padding: '5px' }}
                                            >
                                                <img
                                                    src={ tile.imageUrl}
                                                    alt={tile.title}
                                                />
                                                <GridListTileBar
                                                    title={tile.title}
                                                    actionIcon={
                                                        <IconButton
                                                            aria-label={`info about ${tile.title}`}
                                                            className={classes.icon}
                                                        ></IconButton>
                                                    }
                                                />
                                            </GridListTile>
                                        ))
                                    ) : (
                                        <h4 align="center">No Photos</h4>
                                    )}
                                </GridList>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
