import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_HOST } from '../../services/url';

const initialState = {
    loading: true,
    fileLoading: false,
    isSubmitting: false,
    formId: null,
    formTitle: null,
    submitButtonText: 'Submit',
    imageTheme: null,
    submissionData: {
        formId: '',
        reply: [],
        user: '',
    },
    formErrors: [],
    formType: '',
    isQuiz: '',
};

export const uploadFiles = createAsyncThunk(
    'slice/uploadFile',
    async ({ file, id, qid, alert }, { getState, rejectWithValue }) => {
        try {
            const fileData = new FormData();
            fileData.append('formId', id);
            fileData.append('uploadImage', file);

            const response = await axios.post(
                `${API_HOST}form/submission/submission/uploadFile`,
                fileData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.message === 'Network Error') {
                alert.error('File Too Large');
            }
            const filePath = response.data.data.uploadUrl;
            return { filePath, qid };
        } catch (error) {
            if (error) {
                alert.error('File Too Large');
            }
            return rejectWithValue('Failed to upload file.');
        }
    }
);

export const setOpenedAt = createAsyncThunk('preview/setOpenedAt', async id => {
    try {
        await axios.get(`${API_HOST}form/form/getFormById/${id}`, {
            headers: {
                'x-access-token': localStorage.getItem('cxtoken'),
            },
        });
    } catch (error) {
        console.error(error);
    }
});

export const fetchFormQuestions = createAsyncThunk('preview/fetchFormQuestions', async id => {
    try {
        const response = await axios.get(
            `${API_HOST}form/prefilledFormTemplate/form/getFormById/${id}`
        );
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
});

export const submitForm = createAsyncThunk('preview/submitForm', async payload => {
    const response = await axios.post(`${API_HOST}form/submission/submission`, payload, {
        headers: {
            // "x-access-token": Cookies.get("account_token"),
            'Content-Type': 'application/json',
        },
    });
    return response.data;
});
export const editFormOnEnter = createAsyncThunk(
    'preview/editFormOnEnter',
    async ({ value, update, formId }) => {
        const response = await axios.patch(
            `${API_HOST}form/prefilledFormTemplate/form/edit/${formId}`,
            { value, update },
            {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    }
);

export const editSectionOnEnter = createAsyncThunk(
    'preview/editSectionOnEnter',
    async ({ value, update, sectionId }) => {
        const response = await axios.put(
            `${API_HOST}form/prefilledFormTemplate/section/editSection/${sectionId}`,
            { value, update },
            {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    }
);
export const editQuestionOnEnter = createAsyncThunk(
    'preview/editQuestionOnEnter',
    async ({ value, update, questionId }) => {
        const response = await axios.put(
            `${API_HOST}form/prefilledFormTemplate/question/editQuestion/${questionId}`,
            { value, update },
            {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    }
);
export const addQuestionOnEnter = createAsyncThunk('preview/addQuestionOnEnter', async newField => {
    const response = await axios.post(
        `${API_HOST}form/prefilledFormTemplate/question/addQuestion`,
        newField,
        {
            headers: {
                'x-access-token': localStorage.getItem('cxtoken'),
                'Content-Type': 'application/json',
            },
        }
    );
    return response.data;
});
export const deleteQuestion = createAsyncThunk(
    'preview/deleteQuestion',
    async ({ questionID, sectionId }) => {
        const response = await axios.post(
            `${API_HOST}form/prefilledFormTemplate/question/deleteQuestion`,
            { questionID, sectionId },
            {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    }
);

const previewSlice = createSlice({
    name: 'preview',
    initialState: initialState,
    reducers: {
        handleEmailChange: (state, action) => {
            state.submissionData = {
                ...state.submissionData,
                user: action.payload,
            };
        },

        handleInputChange: (state, action) => {
            const { questionId, value } = action.payload;

            state.submissionData.reply = state.submissionData.reply.map(item => {
                if (item.questionId === questionId) {
                    return {
                        ...item,
                        answer: value instanceof File ? value : value.toString(),
                    };
                }
                return item;
            });
        },

        handleChoiceChange: (state, action) => {
            const { questionId, optionTitle, optionId, optionIndex } = action.payload;

            state.submissionData.reply = state.submissionData.reply.map(item => {
                if (item.questionId === questionId) {
                    return {
                        ...item,
                        answer: {
                            optionId: optionId,
                            option: optionTitle,
                            optionIndex: optionIndex,
                        },
                    };
                }
                return item;
            });
        },

        handleCheckboxChange: (state, action) => {
            const { questionId, optionId, optionTitle, isChecked, optionIndex } = action.payload;

            console.log(optionIndex);

            state.submissionData.reply = state.submissionData.reply.map(item => {
                if (item.questionId === questionId) {
                    let answer = item.answer || [];
                    if (
                        isChecked
                        //  &&
                        // !answer.some((option) => option.optionId === optionId)
                    ) {
                        answer.push({
                            optionId: optionId,
                            option: optionTitle,
                            optionIndex: optionIndex,
                        });
                    } else if (!isChecked && answer.some(option => option.optionId === optionId)) {
                        answer = answer.filter(
                            selectedOption => selectedOption.optionId !== optionId
                        );
                    }
                    return { ...item, answer };
                }

                return item;
            });
        },

        setFormErrors: (state, action) => {
            state.formErrors = action.payload;
        },

        setSubmissionData: (state, action) => {
            state.submissionData = action.payload;
        },

        handleFileUpload: async (state, action) => {
            const { questionId, file } = action.payload;

            if (file instanceof File && file.size > 1024 * 1024) {
                state.formErrors.push('File size should be less than or equal to 1MB');
                return;
            }

            // state.submissionData.reply = state.submissionData.reply.map((item) => {
            //   if (item.questionId === questionId) {
            //     return {
            //       ...item,
            //       answer: file.name.toString(),
            //     };
            //   }
            //   return item;
            // });

            const uploadFile = async () => {
                try {
                    const fileData = new FormData();
                    fileData.append('formId', state.formId);
                    fileData.append('uploadImage', file);

                    const response = await axios.post(
                        `${API_HOST}form/form/submission/uploadFile`,
                        fileData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );

                    const filePath = response.data.data.uploadUrl;

                    return filePath;
                } catch (error) {
                    console.error(error);
                    // state.formErrors.push("Failed to upload file.");
                }
            };

            const res = await uploadFile();
            console.log('Res', res);

            // state.submissionData.reply = state.submissionData.reply.map(
            //     (item) => {
            //       if (item.questionId === questionId) {
            //         return {
            //           ...item,
            //           answer: res,
            //         };
            //       }
            //       return item;
            //     }
            //   );
        },
        handleSectionRemove: (state, action) => {
            state.sections = state.sections.filter(section => section._id !== action.payload);
            state.formData.sections = state.formData.sections.filter(
                section => section._id !== action.payload
            );
        },

        // handleFileUpload: (state, action) => {
        //   const { questionId, file } = action.payload;

        //   if (file instanceof File && file.size > 1024 * 1024) {
        //     return {
        //       ...state,
        //       formErrors: [...state.formErrors, "File size should be less than or equal to 1MB"],
        //     };
        //   }

        //   const fileData = new FormData();
        //   fileData.append("formId", state.formId);
        //   fileData.append("uploadImage", file);

        //   return axios
        //     .post(`${API_HOST}form/form/submission/uploadFile`, fileData, {
        //       headers: {
        //         "Content-Type": "multipart/form-data",
        //       },
        //     })
        //     .then((response) => {
        //       const filePath = response.data.data.uploadUrl;
        //       const updatedReply = state.submissionData.reply.map((item) => {
        //         if (item.questionId === questionId) {
        //           return {
        //             ...item,
        //             answer: filePath.toString(),
        //           };
        //         }
        //         return item;
        //       });

        //       return {
        //         ...state,
        //         submissionData: {
        //           ...state.submissionData,
        //           reply: updatedReply,
        //         },
        //       };
        //     })
        //     .catch((error) => {
        //       console.error(error);
        //       console.log("errors are given");
        //       return {
        //         ...state,
        //         formErrors: [...state.formErrors, "Failed to upload file."],
        //       };
        //     });
        // },
    },
    extraReducers: builder => {
        builder.addCase(editSectionOnEnter.fulfilled, (state, action) => {
            const updatedSection = action.payload;
            const sectionIndex = state.formData.sections.findIndex(
                section => section._id === updatedSection._id
            );

            if (sectionIndex !== -1) {
                state.formData.sections = state.formData.sections.map((section, index) => {
                    if (index === sectionIndex) {
                        return {
                            ...section,
                            title: updatedSection.title,
                            description: updatedSection.description,
                        };
                    }
                    return section;
                });

                state.sections = state.sections.map((section, index) => {
                    if (index === sectionIndex) {
                        return {
                            ...section,
                            title: updatedSection.title,
                            description: updatedSection.description,
                        };
                    }
                    return section;
                });
            }

            state.loading = false;
        });

        builder.addCase(fetchFormQuestions.pending, state => {
            state.loading = true;
        });

        builder.addCase(fetchFormQuestions.fulfilled, (state, action) => {
            const payload = action.payload;
            if (payload?._id) {
                state.formId = payload._id;
                state.formTitle = payload.formTitle;
                state.submitButtonText = payload.submitButtonText;
                state.imageTheme = payload.image;
                state.sections = payload.sections;
                state.sections.sort((a, b) => a._id.localeCompare(b._id));
                state.formType = payload.formType;
                state.isQuiz = payload.isQuiz;
                state.submitButtonText = payload.submitButtonText;
                state.timer = payload.timer;
                state.date = payload.date;

                state.sections.forEach(section => {
                    section.questions.sort((a, b) => a._id.localeCompare(b._id));
                });
                const allQuestions = [];
                payload.sections.forEach(section => {
                    section.questions.forEach(question => {
                        allQuestions.push(question);
                    });
                });

                state.formQuestions = allQuestions;

                state.formData = {
                    formId: payload._id,
                    submitButtonText: payload.submitButtonText,
                    imageTheme: payload.image,
                    sections: payload.sections,
                    formType: payload.formType,
                    isQuiz: payload.isQuiz,
                    timer: payload.timer,
                    date: payload.date,
                };
                state.formData.sections.sort((a, b) => a._id.localeCompare(b._id));

                let defaultReply = [];
                action.payload?.sections?.forEach(section => {
                    defaultReply = defaultReply.concat(
                        section.questions.map(question => ({
                            questionId: question._id,
                            type: question.type,
                            answer: '',
                            points: 0,
                        }))
                    );
                });
                state.submissionData = {
                    ...state.submissionData,
                    formId: action.payload._id,
                    reply: defaultReply,
                    user: '',
                };
            }
            state.loading = false;
        });

        builder.addCase(fetchFormQuestions.rejected, state => {
            state.loading = false;
            state.formErrors?.push('Failed to fetch form questions.');
        });

        builder.addCase(submitForm.pending, state => {
            state.isSubmitting = true;
        });

        builder.addCase(submitForm.fulfilled, state => {
            state.isSubmitting = false;
            // handle the successful submission response as needed
        });

        builder.addCase(submitForm.rejected, state => {
            state.isSubmitting = false;
            state.formErrors.push('Failed to submit form.');
        });
        builder.addCase(uploadFiles.pending, state => {
            state.fileLoading = true; // Set fileLoading to true when the uploadFiles action is pending.
        });

        builder.addCase(uploadFiles.fulfilled, (state, action) => {
            state.fileLoading = false;

            // Extract filePath and qid from the payload
            const { filePath, qid } = action.payload;

            // Update the state based on filePath and qid
            state.submissionData.reply = state.submissionData.reply.map(item => {
                if (item.questionId === qid) {
                    return {
                        ...item,
                        answer: filePath,
                    };
                }
                return item;
            });
        });

        builder.addCase(uploadFiles.rejected, state => {
            state.fileLoading = false;
            state.formErrors.push('Failed to upload file');
        });
        builder.addCase(addQuestionOnEnter.fulfilled, (state, action) => {
            state.fileLoading = false;
        });
        builder.addCase(addQuestionOnEnter.rejected, state => {
            state.fileLoading = false;
            state.formErrors.push('Failed to upload file');
        });
        builder.addCase(editQuestionOnEnter.fulfilled, (state, action) => {
            const updatedQuestion = action.payload;

            const sectionIndex = state.formData.sections.findIndex(section => {
                return section.questions.some(question => question._id === updatedQuestion._id);
            });

            if (sectionIndex !== -1) {
                state.formData = {
                    ...state.formData,
                    sections: state.formData.sections.map((section, index) => {
                        if (index === sectionIndex) {
                            return {
                                ...section,
                                questions: section.questions.map(question => {
                                    return question._id === updatedQuestion._id
                                        ? updatedQuestion
                                        : question;
                                }),
                            };
                        }
                        return section;
                    }),
                };
                state.sections = state.sections.map(section => {
                    if (section._id === state.formData.sections[sectionIndex]._id) {
                        return {
                            ...section,
                            questions: section.questions.map(question => {
                                return question._id === updatedQuestion._id
                                    ? updatedQuestion
                                    : question;
                            }),
                        };
                    }
                    return section;
                });
            }

            state.fileLoading = false;
        });

        builder.addCase(editFormOnEnter.fulfilled, (state, action) => {
            if (action.payload.title === 'New Section') {
                state.formData.sections.push(action.payload);
                state.sections.push(action.payload);
            } else {
                // Handle other form property updates
                const updatedForm = action.payload;

                // Check which property was updated
                if (updatedForm.submitButtonText !== undefined) {
                    state.submitButtonText = updatedForm.submitButtonText;
                    state.formData.submitButtonText = updatedForm.submitButtonText;
                }
                if (updatedForm.timer !== undefined) {
                    state.timer = updatedForm.timer;
                    state.formData.timer = updatedForm.timer;
                }
                if (updatedForm.formType !== undefined) {
                    state.formType = updatedForm.formType;
                    state.formData.formType = updatedForm.formType;
                }
                if (updatedForm.date !== undefined) {
                    state.date = updatedForm.date;
                    state.formData.date = updatedForm.date;
                }
                if (updatedForm.image !== undefined) {
                    state.imageTheme = updatedForm.image;
                    state.formData.imageTheme = updatedForm.image;
                }
            }

            state.fileLoading = false;
        });
    },
});

export const {
    setFormId,
    handleEmailChange,
    handleSectionRemove,
    handleInputChange,
    handleChoiceChange,
    handleCheckboxChange,
    setFormErrors,
    setSubmissionData,
    handleFileUpload,
} = previewSlice.actions;

export default previewSlice.reducer;
