import axios from "axios"
import { KANBAN_CX_DEPLOYER, KANBAN_CX_DEPLOYER_FORM } from '../../services/url';

export const getCategory = () => {
    return async dispatch => {
        try {
            const response = await axios.get(KANBAN_CX_DEPLOYER + `category`, {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                },
            });

            if (response.data.success === true) {
                dispatch({
                    type: 'SET_CATEGORY',
                    payload: response.data.data,
                });
            }
        } catch (error) {
            console.log('Error in get all workspace ', error);
        }
    };
};

export const getFormCategory = () => {
    return async dispatch => {
        try {
            const response = await axios.get(KANBAN_CX_DEPLOYER_FORM + `category`, {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                },
            });

            if (response.data.success === true) {
                dispatch({
                    type: 'SET_CATEGORY',
                    payload: response.data.data,
                });
            }
        } catch (error) {
            console.log('Error in get all workspace ', error);
        }
    };
};