import React,{useState,useEffect} from "react";
import Axios from "axios";
import styles from "./card.module.css";


function Card(props) {
  return (

    <div className={styles.card}>
      <div className="card-body">
        <h5 className="card-title">{props.title}</h5>
        <p className="card-text">{props.data}</p>
      </div>
    </div>

  );
}

export default Card;
