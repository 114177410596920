import React, { useState, useEffect } from 'react';
import {
    Modal,
    Button,
    Dropdown,
    InputGroup,
    FormControl,
    Row,
    Col,
    Table,
    DropdownButton,
    Container,
    Form,
} from 'react-bootstrap';
import {
    Button as MUIButton,
    Checkbox,
    FormControlLabel,
    TextField,
    IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AddCircle, Cancel } from '@material-ui/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import image from '../images/img.png';
import docIcon from '../images/doc.png';
import pptIcon from '../images/ppt.png';
import exlIcon from '../images/excel.jpg';
import axios from 'axios';
import { backendUrl as url } from '../../backendUrl/url';
const token = localStorage.getItem('cxtoken');

function EditTask(props) {
    const [task, setTask] = useState({});
    const [uploadFiles, setUploadFiles] = useState(null);

    const [checklistName, setChecklistName] = useState('');
    const [checklist, setChecklist] = useState([]);

    const extension = {
        png: image,
        jpg: image,
        docx: docIcon,
        doc: docIcon,
        ppt: pptIcon,
        pptx: pptIcon,
        pps: pptIcon,
        pdf: docIcon,
        xlsx: exlIcon,
    };

    useEffect(() => {
        setTask(props.taskData);
        setChecklist(props.taskData.checklist);
    }, [props]);

    const handleFileUploadChange = event => {
        setUploadFiles(event.target.files);
    };
    const submitEditedTask = () => {
        let fileData = new FormData();
        if (uploadFiles !== null) {
            for (var i = 0; i < uploadFiles.length; i++) {
                fileData.append('files', uploadFiles[i]);
            }
        } else {
            fileData = null;
        }
        setUploadFiles(null);
        console.log(checklist);
        var task_ = { ...task };
        task_.checklist = checklist;
        props.saveFunction(task_, fileData);
    };
    const deleteAttachment = (event, attachment) => {
        //const attachment = event.target.id;
        console.log('id', task._id);
        console.log('attachment', attachment);
        axios
            .delete(`${url}api/user/tasks/deleteAttachment/`, {
                data: { taskId: task._id, fileName: attachment },
                headers: {
                    'x-access-token': token,
                },
            })
            .then(res => {
                console.log(res.data);
                props.hideModal();
                props.refetch();
            })
            .catch(err => {
                console.log(err);
            });
    };
    const handleChecklistAdd = event => {
        if (event.key === 'Enter') {
            console.log(event.target.value);
            var checklist_ = checklist;
            checklist_ = [
                ...checklist,
                { name: checklistName, isChecked: false, isDisabled: true },
            ];
            console.log(checklist_);
            setChecklist(checklist_);
            setChecklistName('');
        }
    };
    const handleChecklistEdit = (event, idx) => {
        if (event.key === 'Enter') {
            console.log(event.target.value);
            var checklist_ = [...checklist];
            console.log(checklist_);
            checklist_[idx].name = event.target.value;
            checklist_[idx].isDisabled = true;

            setChecklist(checklist_);
            setChecklistName('');
        }
    };
    const handleEnableEditChecklist = idx => {
        var checklist_ = [...checklist];
        checklist_[idx].isDisabled = false;
        setChecklist(checklist_);
    };

    const handleChecklistItemDelete = idx => {
        var checklist_ = [...checklist];
        checklist_.splice(idx, 1);
        setChecklist(checklist_);
    };

    if (task === {}) {
        return <div>Loading</div>;
    }

    return (
        <Modal size="lg" centered show={props.visibility} onHide={() => props.hideModal()}>
            <Modal.Header closeButton className="bg-primary">
                <Modal.Title>Edit Task</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '550px', overflowY: 'auto' }}>
                <InputGroup size="sm">
                    <InputGroup.Prepend>
                        <InputGroup.Text required>Name</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        type="text"
                        size="sm"
                        onChange={e => {
                            setTask({
                                ...task,
                                name: e.target.value,
                            });
                        }}
                        value={task.name || ''}
                        placeholder="Enter Name *"
                        maxLength="80"
                    />
                </InputGroup>
                <Form.Text className="text-muted text-right">
                    {task.name ? task.name.length : 0}/80
                </Form.Text>

                {/* <Row>
          <Col xs={2} className="d-flex pt-3">
            <i className="fas fa-user-plus pt-1"></i>{" "}
            <span className="pl-1">Assignees:</span>
          </Col>
          <Col>
            <Autocomplete
              id="edit-assignee"
              multiple
              fullWidth
              options={props.currentCompanyUsers}
              getOptionLabel={(option) => option.username}
              onChange={(e, newValue) =>
                setTask({ ...task, assignees: newValue })
              }
              value={task.assignees || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Add Assignee *"
                />
              )}
            />
          </Col>
        </Row>

        task.assignees ? (
          <Table
            striped
            bordered
            hover
            size="sm"
            className="mt-3 w-80 text-center"
          >
            <thead>
              <tr>
                {task.assignees
                  ? task.assignees.map((person, idx) => (
                      <th key={idx}>{person.username}</th>
                    ))
                  : null}
              </tr>
            </thead>
          </Table>
        ) : (
          ""
        )*/}

                <Row className="mt-3">
                    <Col>
                        <Row className="pl-3">
                            <Form.Group className="d-flex">
                                <Form.Label style={{ paddingRight: '70px' }}>Lane:</Form.Label>
                                <DropdownButton
                                    title={task.bucket !== undefined ? task.bucket.name : ''}
                                    size="sm"
                                    onSelect={eventKey => {
                                        const newBucket = props.buckets.filter(
                                            bucket => bucket._id === eventKey
                                        );
                                        setTask({ ...task, bucket: newBucket[0] });
                                    }}
                                >
                                    {props.buckets
                                        ? props.buckets.map((bucket, idx) => (
                                              <Dropdown.Item eventKey={bucket._id} key={idx}>
                                                  {bucket.name}
                                              </Dropdown.Item>
                                          ))
                                        : null}
                                </DropdownButton>
                            </Form.Group>
                        </Row>

                        <Row className="pl-3">
                            <Form.Group className="d-flex">
                                <Form.Label style={{ paddingRight: '42px' }}>Progress:</Form.Label>
                                <DropdownButton
                                    title={
                                        (task.progress === ''
                                            ? 'Select progress'
                                            : task.progress) || ''
                                    }
                                    size="sm"
                                    onSelect={eventKey => {
                                        setTask({ ...task, progress: eventKey });
                                    }}
                                >
                                    <Dropdown.Item eventKey="Not Started">
                                        Not Started
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="In Progress">
                                        In Progress
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="Completed">Completed</Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>
                        </Row>

                        <Row className="pl-3">
                            <Form.Group className="d-flex">
                                <Form.Label className="pr-3">Label Colour:</Form.Label>
                                <DropdownButton
                                    title={
                                        (task.label_color === '#3797a4'
                                            ? 'Default'
                                            : task.label_color) || ''
                                    }
                                    size="sm"
                                    onSelect={eventKey => {
                                        setTask({ ...task, label_color: eventKey });
                                    }}
                                >
                                    <Dropdown.Item eventKey="Tomato">Tomato</Dropdown.Item>
                                    <Dropdown.Item eventKey="MediumSeaGreen">
                                        MediumSeaGreen
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="Orange">Orange</Dropdown.Item>
                                    <Dropdown.Item eventKey="DodgerBlue">DodgerBlue</Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>
                        </Row>

                        <Row className="pl-3">
                            <Form.Group className="d-flex">
                                <Form.Label style={{ paddingRight: '55px' }}>Priority:</Form.Label>
                                <DropdownButton
                                    title={
                                        (task.priority === ''
                                            ? 'Select task Priority'
                                            : task.priority) || ''
                                    }
                                    size="sm"
                                    onSelect={eventKey => {
                                        setTask({
                                            ...task,
                                            priority: eventKey,
                                        });
                                    }}
                                >
                                    <Dropdown.Item eventKey="low">Low</Dropdown.Item>
                                    <Dropdown.Item eventKey="medium">Medium</Dropdown.Item>
                                    <Dropdown.Item eventKey="high">High</Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col className="mt-4">
                        <Row className="mb-3">
                            <Col className="d-flex pt-2">
                                <span className="mt-1">Start Date:</span>
                                <div style={{ paddingLeft: '44px' }}>
                                    <DatePicker
                                        selected={task.start_date}
                                        onChange={date => {
                                            setTask({ ...task, start_date: date });
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex mt-2">
                                <span className="mt-1">Due Date:</span>

                                <div className="pl-5 customDatePickerWidth">
                                    <DatePicker
                                        style={{ paddingLeft: '10px' }}
                                        minDate={task.start_date}
                                        selected={task.due_date}
                                        onChange={date => {
                                            setTask({ ...task, due_date: date });
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <InputGroup className="mt-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text required>Description :</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        size="sm"
                        as="textarea"
                        rows="2"
                        onChange={e => {
                            setTask({ ...task, description: e.target.value });
                        }}
                        value={task.description}
                        placeholder="Enter Description *"
                    />
                </InputGroup>
                <br />

                <Row>
                    <Col className="pl-3">
                        <span style={{ fontSize: '17px', fontWeight: 500 }}>Attachments:</span>
                    </Col>
                </Row>
                <Container id="attachments-container">
                    <Row className="add-task-attachment-row pl-2">
                        {task.attachments !== undefined && task.attachments.length > 0 ? (
                            task.attachments.map(attachment => (
                                <Col className="pt-2">
                                    <div style={{ position: 'relative' }}>
                                        {['png', 'jpg', 'jpeg'].indexOf(
                                            attachment.split('.').pop().toLowerCase()
                                        ) < 0 ? (
                                            <a
                                                className="attachment-link"
                                                href={url + 'user/' + attachment}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    className="task-img"
                                                    src={
                                                        extension[
                                                            attachment
                                                                .split('.')
                                                                .pop()
                                                                .toLowerCase()
                                                        ]
                                                    }
                                                    alt="Loading"
                                                ></img>
                                            </a>
                                        ) : (
                                            <a
                                                className="attachment-link"
                                                href={url + 'user/' + attachment}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    className="task-img"
                                                    src={url + 'user/' + attachment}
                                                    alt="Loading"
                                                ></img>
                                            </a>
                                        )}

                                        <button
                                            id={attachment}
                                            type="button"
                                            className="close"
                                            style={{ position: 'absolute', left: '34px' }}
                                            onClick={e => deleteAttachment(e, attachment)}
                                        >
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    {/*<IconButton
                    id={attachment}
                    variant="danger"
                    style={{
                      color: "red",
                    }}
                   
                  >
                    <DeleteIcon />
                  </IconButton>*/}
                                </Col>
                            ))
                        ) : (
                            <Col className="pl-5">None</Col>
                        )}
                    </Row>
                </Container>
                <Row style={{ marginTop: '1%' }}>
                    <Col className="pl-3 d-flex">
                        <span style={{ fontSize: '17px', fontWeight: 500 }}>Add more files:</span>
                        <div className="pl-3 d-flex">
                            <input
                                type="file"
                                id="files"
                                multiple
                                onChange={handleFileUploadChange}
                            ></input>
                            <IconButton
                                variant="contained"
                                style={{
                                    color: 'red',
                                    marginTop: '-10px',
                                }}
                                onClick={() => {
                                    setUploadFiles(null);
                                    document.getElementById('files').value = null;
                                }}
                                title="Remove all attachments"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="pl-3">
                        <span style={{ fontSize: '17px', fontWeight: 500 }}>Checklist:</span>
                    </Col>
                </Row>
                {checklist
                    ? checklist.map((item, idx) => (
                          <Row key={idx}>
                              <Col xs={2} style={{ paddingLeft: '69px' }}>
                                  <div className="d-flex h-100 align-items-center">
                                      <Cancel
                                          style={{ color: 'red', cursor: 'pointer' }}
                                          onClick={() => {
                                              handleChecklistItemDelete(idx);
                                          }}
                                      />
                                  </div>
                              </Col>
                              {item.isDisabled ? (
                                  <Col
                                      className="pl-2"
                                      style={{ textAlign: 'left', wordBreak: 'break-all' }}
                                  >
                                      <FormControlLabel
                                          control={
                                              <Checkbox
                                                  checked={item.isChecked}
                                                  name="add"
                                                  onChange={() => {
                                                      var checklist_ = [...checklist];
                                                      checklist_[idx].isChecked =
                                                          !checklist_[idx].isChecked;
                                                      setChecklist(checklist_);
                                                  }}
                                              />
                                          }
                                          label={item.name}
                                          style={
                                              checklist[idx].isChecked
                                                  ? { textDecoration: 'line-through' }
                                                  : {}
                                          }
                                      />
                                  </Col>
                              ) : (
                                  <Col className="pl-2">
                                      <TextField
                                          placeholder="Add an item"
                                          style={{ width: '90%' }}
                                          onKeyDown={e => handleChecklistEdit(e, idx)}
                                          defaultValue={item.name}
                                          onChange={event => {
                                              checklist[idx].name = event.target.value;
                                              setChecklist(checklist);
                                          }}
                                          inputProps={{ maxLength: 60 }}
                                      />
                                  </Col>
                              )}
                              {item.isDisabled ? (
                                  <Col xs={2}>
                                      <IconButton
                                          style={{ height: '30px', width: '30px' }}
                                          onClick={() => handleEnableEditChecklist(idx)}
                                      >
                                          <EditIcon />
                                      </IconButton>
                                  </Col>
                              ) : (
                                  ''
                              )}
                          </Row>
                      ))
                    : ''}
                <Row>
                    <Col xs={2} className="pl-5 text-center">
                        <AddCircle style={{ color: '#34abeb' }} />
                    </Col>
                    <Col className="pl-2">
                        <TextField
                            placeholder="Add an item"
                            style={{ width: '90%' }}
                            onKeyDown={handleChecklistAdd}
                            value={checklistName}
                            onChange={event => {
                                setChecklistName(event.target.value);
                            }}
                            inputProps={{ maxLength: 60 }}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => {
                        props.deleteFunction(task);
                    }}
                >
                    <i className="far fa-trash-alt mr-2"></i>Delete Task
                </Button>
                <Button
                    className="bg-primary"
                    onClick={() => submitEditedTask()}
                    disabled={task.name === '' ? true : false}
                >
                    Save Edits
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditTask;
