import React from "react";

export default function Blog() {
  document.title =
    "View Blogs | Persona | Empathy | Value | Journey Mapping | Innovation | Kanban | CXDeployer";
  return (
    <div className="pt-5">
      <div className="container">
        <div className="container">
          <img
            src="https://blog.morethanmetrics.com/wp-content/uploads/2020/05/customer-support-staff-ready-for-work-1024x683.jpg"
            className="img-fluid"
            alt="alasfas"
          />
          <div className="w-100">
            <h1>Lorem Epsum is a dummy text</h1>
            <div className="d-flex w-100 justify-content-between lead ">
              <div>
                <p>Joseph Albari</p>
              </div>
              <div>
                <p>12 march 2020</p>
              </div>
            </div>
            <div>
              <h3>Lorem Epsum</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <h3>Where does it come from?</h3>
              <p>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
