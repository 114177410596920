import React, { Component } from 'react';
import axios from 'axios';
import { API_HOST } from '../services/url';
import withRouter from '../withRouter';
import QuillEditor from './quill';

class EditUpdate extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            heading: '',
            content: '',
            selectedFile: '',
        };
        //   this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {
        axios
            .get(API_HOST + `admin/webUpdate/${this.props.params.id}`, {
                headers: { 'x-access-token': localStorage.getItem('cxtoken') },
            })
            .then(res => {
                console.log(res,'update-----')
                this.setState({
                    heading: res.data.data.heading,
                    content: res.data.data.content,
                });
            });
    }
    async onSubmit(e) {
        e.preventDefault();
        let payload = {
            heading: this.state.heading,
            content: this.state.content,
        };

        const token = localStorage.getItem('cxtoken');
        axios
            .put(API_HOST + `admin/webUpdate/${this.props.params.id}`, payload, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                alert('Update Edited');
                window.location.replace('/update');
            });
    }
    onEditorChange = content => {
        this.setState({ content });
    };
    onFileChange = files => {
        console.log(files);
        this.setState({ selectedFile: files[0] });
    };
    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    render() {
        return (
            <div>
                <div className="container" >
                    <div className="row">
                        <div className="col-sm-9 col-md-9 mx-auto">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h4 className="card-title text-center">Edit Update</h4>
                                    <form className="form-signin" method="POST">
                                        <div className="form-group">
                                            <label for="email">Heading</label>
                                            <input
                                                id="title"
                                                type="text"
                                                className="form-control"
                                                name="heading"
                                                value={this.state.heading}
                                                onChange={e => this.change(e)}
                                                required
                                            />
                                        </div>
                                      
                                        <div className="form-group">
                                            <label for="email">content</label>
                                            <QuillEditor
                                                placeholder={'Start Posting Something'}
                                                onEditorChange={this.onEditorChange}
                                                onFilesChange={this.onFileChange}
                                                value={this.state.content}
                                            />
                                        </div>
                                        <div className="form-group no-margin">
                                            <button
                                                className="btn btn-info btn-block"
                                                onClick={e => this.onSubmit(e)}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EditUpdate);
