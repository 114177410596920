import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Dropdown,
  InputGroup,
  FormControl,
  Row,
  Col,
  Table,
  Container,
  Form,
  DropdownButton,
} from "react-bootstrap";
import {
  Button as MUIButton,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@material-ui/core";

import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Cancel, AddCircle } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

function AddTask(props) {
  const [task, setTask] = useState({
    name: "",
    description: "",
    assignees: [],
    assignee: "",
    rank: "",
    bucket: "",
    start_date: "",
    due_date: "",
    progress: "",
    label_color: "#3797a4",
    priority: "",
    attachments: [],
  });
  const [uploadFiles, setUploadFiles] = useState(null);
  const [checklistName, setChecklistName] = useState("");
  const [checklist, setChecklist] = useState([]);
  useEffect(() => {
    setChecklist([]);
    setTask({
      name: "",
      description: "",
      assignees: [],
      rank: "",
      bucket: props.bucket._id,
      start_date: "",
      due_date: "",
      progress: "",
      label_color: "#3797a4",
      priority: "",
      attachments: [],
      prefilledKanban: props.ideaId,
    });
  }, [props]);

  const submitAddTask = (task) => {
    task.checklist = checklist;
    let fileData = new FormData();
    if (uploadFiles !== null) {
      for (var i = 0; i < uploadFiles.length; i++) {
        fileData.append("files", uploadFiles[i]);
      }
    } else {
      fileData = null;
    }
    setUploadFiles(null);
    props.saveFunction(task, fileData);
  };

  const handleFileUploadChange = (event) => {
    setUploadFiles(event.target.files);
  };

  const handleChecklistAdd = (event) => {
    if (event.key === "Enter") {
      console.log(event.target.value);
      var checklist_ = checklist;
      console.log(checklist_);
      checklist_ = [
        ...checklist,
        { name: checklistName, isChecked: false, isDisabled: true },
      ];
      setChecklist(checklist_);
      setChecklistName("");
    }
  };
  const handleChecklistEdit = (event, idx) => {
    if (event.key === "Enter") {
      console.log(event.target.value);
      var checklist_ = [...checklist];
      console.log(checklist_);
      checklist_[idx].name = event.target.value;
      checklist_[idx].isDisabled = true;

      setChecklist(checklist_);
      setChecklistName("");
    }
  };
  const handleEnableEditChecklist = (idx) => {
    var checklist_ = [...checklist];
    checklist_[idx].isDisabled = false;
    setChecklist(checklist_);
  };
  const handleChecklistItemDelete = (idx) => {
    var checklist_ = [...checklist];
    checklist_.splice(idx, 1);
    setChecklist(checklist_);
  };

  return (
    <Modal
      size="lg"
      centered
      show={props.visibility}
      onHide={() => props.hideModal()}
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title>Enter Task</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "550px", overflowY: "auto" }}>
        <InputGroup size="sm">
          <InputGroup.Prepend>
            <InputGroup.Text required>Name</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            size="sm"
            onChange={(e) => {
              setTask({
                ...task,
                name: e.target.value,
              });
            }}
            placeholder="Enter Name *"
            maxLength="80"
          />
        </InputGroup>
        <Form.Text className="text-muted text-right">
          {task.name.length}/80
        </Form.Text>

        {/*<Row>
          <Col xs={2} className="text-center">
            <i className="fas fa-user-plus"></i> Assignees:
          </Col>

          <Col>
            <Autocomplete
              id="add-assignee"
              multiple
              fullWidth
              options={props.currentCompanyUsers}
              getOptionLabel={(option) => option.username}
              onChange={(e, newValue) =>
                setTask({ ...task, assignees: newValue })
              }
              value={task.assignees || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Add Assignee"
                />
              )}
            />
          </Col>
              </Row>*/}

        {/* <Table
          striped
          bordered
          hover
          size="sm"
          className="mt-3 w-80 text-center"
        >
          <thead>
            <tr>
              {task.assignees
                ? task.assignees.map((person, idx) => (
                    <th key={idx}>{person.username}</th>
                  ))
                : null}
            </tr>
          </thead>
                </Table>*/}
        <Row className="mt-3">
          <Col>
            <Row className="pl-3">
              <Form.Group className="d-flex">
                <Form.Label style={{ paddingRight: "70px" }}>Lane:</Form.Label>
                <DropdownButton
                  inline
                  title={props.bucket.name}
                  size="sm"
                  onSelect={(eventKey) => {
                    setTask({ ...task, bucket: eventKey });
                  }}
                >
                  {props.buckets
                    ? props.buckets.map((bucket, idx) => (
                        <Dropdown.Item eventKey={bucket._id} key={idx}>
                          {bucket.name}
                        </Dropdown.Item>
                      ))
                    : null}
                </DropdownButton>
              </Form.Group>
            </Row>

            <Row className="pl-3">
              <Form.Group className="d-flex">
                <Form.Label style={{ paddingRight: "42px" }}>
                  Progress:
                </Form.Label>
                <DropdownButton
                  inline
                  title={
                    task.progress === ""
                      ? "Select task progress"
                      : task.progress
                  }
                  size="sm"
                  onSelect={(eventKey) => {
                    setTask({ ...task, progress: eventKey });
                  }}
                >
                  <Dropdown.Item eventKey="Not Started">
                    Not Started
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="In Progress">
                    In Progress
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Completed">Completed</Dropdown.Item>
                </DropdownButton>
              </Form.Group>
            </Row>

            <Row className="pl-3">
              <Form.Group className="d-flex">
                <Form.Label className="pr-3">Label Colour:</Form.Label>
                <DropdownButton
                  inline
                  title={
                    task.label_color === "#3797a4"
                      ? "Default"
                      : task.label_color
                  }
                  size="sm"
                  onSelect={(eventKey) => {
                    setTask({ ...task, label_color: eventKey });
                  }}
                >
                  <Dropdown.Item eventKey="Tomato">Tomato</Dropdown.Item>
                  <Dropdown.Item eventKey="MediumSeaGreen">
                    MediumSeaGreen
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Orange">Orange</Dropdown.Item>
                  <Dropdown.Item eventKey="DodgerBlue">
                    DodgerBlue
                  </Dropdown.Item>
                </DropdownButton>
              </Form.Group>
            </Row>

            <Row className="pl-3">
              <Form.Group className="d-flex">
                <Form.Label style={{ paddingRight: "55px" }}>
                  Priority:
                </Form.Label>
                <DropdownButton
                  inline
                  title={
                    task.priority === ""
                      ? "Select task Priority"
                      : task.priority
                  }
                  size="sm"
                  onSelect={(eventKey) => {
                    setTask({
                      ...task,
                      priority: eventKey,
                    });
                  }}
                >
                  <Dropdown.Item eventKey="low">Low</Dropdown.Item>
                  <Dropdown.Item eventKey="medium">Medium</Dropdown.Item>
                  <Dropdown.Item eventKey="high">High</Dropdown.Item>
                </DropdownButton>
              </Form.Group>
            </Row>
          </Col>

          <Col className="mt-4">
            <Row className="mb-3">
              <Col className="d-flex pt-2">
                <span className="mt-2">Start Date:</span>
                <div style={{ paddingLeft: "44px" }}>
                  <DatePicker
                    selected={task.start_date}
                    onChange={(date) => {
                      setTask({ ...task, start_date: date });
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex mt-2">
                <span className="mt-2">Due Date:</span>

                <div className="pl-5 customDatePickerWidth">
                  <DatePicker
                    minDate={task.start_date}
                    selected={task.due_date}
                    onChange={(date) => {
                      setTask({ ...task, due_date: date });
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <InputGroup className="mt-3">
          <InputGroup.Prepend>
            <InputGroup.Text required>Description :</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            size="sm"
            as="textarea"
            rows="2"
            onChange={(e) => {
              setTask({ ...task, description: e.target.value });
            }}
            placeholder="Enter Description *"
          />
        </InputGroup>
        <br />

        <Row>
          <Col className="pl-3  d-flex">
            <span style={{ fontSize: "17px", fontWeight: 500 }}>
              Attachments:
            </span>{" "}
            <div className="pl-3 d-flex">
              <input
                type="file"
                id="files"
                multiple
                onChange={handleFileUploadChange}
              ></input>

              <IconButton
                variant="contained"
                style={{
                  color: "red",
                  marginTop: "-10px",
                }}
                onClick={() => {
                  setUploadFiles(null);
                  document.getElementById("files").value = null;
                }}
                title="Remove all attachments"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="pl-3">
            <span style={{ fontSize: "17px", fontWeight: 500 }}>
              Checklist:
            </span>
          </Col>
        </Row>
        {checklist
          ? checklist.map((item, idx) => (
              <Row key={idx}>
                <Col xs={2} style={{ paddingLeft: "69px" }}>
                  <div className="d-flex h-100 align-items-center">
                    <Cancel
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => {
                        handleChecklistItemDelete(idx);
                      }}
                    />
                  </div>
                </Col>

                {item.isDisabled ? (
                  <Col
                    className="pl-2"
                    style={{ textAlign: "left", wordBreak: "break-all" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.isChecked}
                          name="add"
                          onChange={() => {
                            var checklist_ = [...checklist];
                            checklist_[idx].isChecked = !checklist_[idx]
                              .isChecked;
                            setChecklist(checklist_);
                          }}
                        />
                      }
                      label={item.name}
                      style={
                        checklist[idx].isChecked
                          ? { textDecoration: "line-through" }
                          : {}
                      }
                    />
                  </Col>
                ) : (
                  <Col className="pl-2">
                    <TextField
                      placeholder="Add an item"
                      style={{ width: "90%" }}
                      onKeyDown={(e) => handleChecklistEdit(e, idx)}
                      defaultValue={item.name}
                      onChange={(event) => {
                        checklist[idx].name = event.target.value;
                        setChecklist(checklist);
                      }}
                      inputProps={{ maxLength: 60 }}
                    />
                  </Col>
                )}
                {item.isDisabled ? (
                  <Col xs={2}>
                    <IconButton
                      style={{ height: "30px", width: "30px" }}
                      onClick={() => handleEnableEditChecklist(idx)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            ))
          : ""}
        <Row>
          <Col xs={2} className="pl-5 text-center">
            <AddCircle style={{ color: "#34abeb" }} />
          </Col>
          <Col className="pl-2">
            <TextField
              placeholder="Add an item"
              style={{ width: "90%" }}
              onKeyDown={handleChecklistAdd}
              value={checklistName}
              onChange={(event) => {
                setChecklistName(event.target.value);
              }}
              inputProps={{ maxLength: 60 }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          block
          className="bg-primary"
          onClick={() => {
            submitAddTask(task);
          }}
          disabled={task.name === "" || task.description === ""}
        >
          Add Task
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddTask;
