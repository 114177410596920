import React, { Component} from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';

class editPlan extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            planName: '',
            INR: 0,
            USD: 0,
            storage: 0,
            totalAssociatedUser: 0,
            validity: '',
        };
        //   this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount = async () => {
        const token = localStorage.getItem('cxtoken');

        await axios
            .get(CX_DEPLOYER + `/stripe/getPlan`, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                console.log(res);
                let plan = res.data.data;
                for (var i = 0; i < plan.length; i++) {
                    if (this.props.match.params.planid == plan[i]._id) {
                        this.setState({
                            data: res.data,
                            _id: plan[i]._id,
                            planName: plan[i].planName,
                            INR: plan[i].price.INR,
                            USD: plan[i].price.USD,
                            storage: plan[i].storage,
                            validity: plan[i].validity,
                            totalAssociatedUser: plan[i].totalAssociatedUser,
                        });
                    }
                }
            });
    };
    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    async onSubmit(e) {
        e.preventDefault();
        const data = {
            planName: this.state.planName,
            price: { INR: this.state.INR, USD: this.state.USD },
            storage: this.state.storage,
            totalAssociatedUser: this.state.totalAssociatedUser,
            validity: this.state.validity,
        };

        console.log(data);
        const token = localStorage.getItem('cxtoken');
        axios
            .put(CX_DEPLOYER + '/stripe/updatePlan/' + this.props.match.params.planid, data, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                console.log(res.data.msg);
                alert(res.data.msg);
                window.location.reload();
            });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-9 col-md-9 mx-auto">
                        <div className="card card-signin my-5">
                            <div className="card-body">
                                <h4 className="card-title text-center">Edit Plan</h4>
                                <form className="form-signin" method="POST">
                                    <div className="form-group">
                                        <label for="email">Plan Name</label>
                                        <input
                                            id="planName"
                                            type="text"
                                            className="form-control"
                                            name="planName"
                                            value={this.state.planName}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="INRprice">Indian Price</label>
                                        <input
                                            id="INR"
                                            type="number"
                                            className="form-control"
                                            name="INR"
                                            value={this.state.INR}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="INRprice">Others Price(in USD)</label>
                                        <input
                                            id="USD"
                                            type="number"
                                            className="form-control"
                                            name="USD"
                                            value={this.state.USD}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label for="INRprice">Storage Limit(in MB)</label>
                                        <input
                                            id="storage"
                                            type="number"
                                            className="form-control"
                                            name="storage"
                                            value={this.state.storage}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="INRprice">Validity</label>
                                        <select
                                            id="validity"
                                            className="form-control"
                                            name="validity"
                                            value={this.state.validity}
                                            onChange={e => this.change(e)}
                                            required
                                        >
                                            <option value="">Select Validity</option>

                                            <option value="Annual">Annual</option>

                                            <option value="Lifetime">Lifetime</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="users">Users Limit</label>
                                        <input
                                            id="totalAssociatedUser"
                                            type="number"
                                            className="form-control"
                                            name="totalAssociatedUser"
                                            value={this.state.totalAssociatedUser}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>

                                    <div className="form-group no-margin">
                                        <button
                                            className="btn btn-info btn-block"
                                            onClick={e => this.onSubmit(e)}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default editPlan;
