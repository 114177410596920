import React, { Component } from "react";
import Feild from "./Components/Feild";
import FeildContainer from "./Components/FeildContainer";
import {CKEditor}  from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-build-balloon-custom";
import { API_HOST } from "../../services/url";
import AddModals from "./Components/AddModals";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-resizable/css/styles.css";
import "react-grid-layout/css/styles.css";
import SaveTemplateDialog from "./Components/SaveTemplateDialog";
const CX_URL = API_HOST + "user/";

const ReactGridLayout = WidthProvider(RGL);
export default class PersonaForm extends Component {
  constructor() {
    super();
    this.state = {
      formData: this.initialState,
      showModal: false
    };
  }

  initialState = {
    photo: "",
    age: {
      value: "",
      color: "#efeff0",
    },
    name: {
      value: "",
      color: "#efeff0",
    },
    gender: {
      value: "",
      color: "#efeff0",
    },
    location: {
      value: "",
      color: "#efeff0",
    },
    maratialStatus: {
      value: "",
      color: "#efeff0",
    },
    personalityType: {
      value: "",
      color: "#efeff0",
    },
    feilds: [
      { color: "#efeff0", gridNo: "0", name: "History", content: "" },
      {
        color: "#efeff0",
        gridNo: "1",
        name: "Lifestyle",
        content: "",
      },
      {
        color: "#efeff0",
        gridNo: "2",
        name: "Background",
        content: "",
      },
      {
        color: "#efeff0",
        gridNo: "3",
        name: "Digital Habits",
        content: "",
      },
      { color: "#efeff0", gridNo: "4", name: "Goals", content: "" },
    ],
    fieldLayout: [
      { i: "0", x: 0, y: 0, w: 12, h: 3, minH: 3, minW: 3 },
      { i: "1", x: 0, y: 3, w: 12, h: 3, minH: 3, minW: 3 },
      { i: "2", x: 0, y: 6, w: 12, h: 3, minH: 3, minW: 3 },
      { i: "3", x: 0, y: 9, w: 12, h: 3, minH: 3, minW: 3 },
      { i: "4", x: 0, y: 12, w: 12, h: 3, minH: 3, minW: 3 },
    ],
  };

  componentDidMount = () => {
    const { formData } = this.props;
    if (formData) {
      console.log("Reached", formData);
      this.setState({ formData: { ...this.initialState, ...formData } });
    } else {
      this.setState({ formData: this.initialState });
    }
  };

  componentWillReceiveProps = (next) => {
    if (next.formData)
      this.setState(
        { formData: { ...this.initialState, ...next.formData } },
        () => console.log(this.state)
      );
    else this.setState({ formData: this.initialState });
  };

  handleChange = (e, name) => {
    const { formData } = this.state;

    if (name === "photo") {
      formData[name] = e.target.files[0];
      if (FileReader && e.target.files[0]) {
        var fr = new FileReader();
        fr.onload = function () {
          document.getElementById("img").src = fr.result;
        };
        this.setState({ formData });
        fr.readAsDataURL(e.target.files[0]);
      }
      return 0;
    }

    formData[name].value = e.target.value;
    this.setState({ formData: formData }, () => console.log(this.state));
  };

  handleColorChange = (value, name) => {
    const { formData } = this.state;

    if (formData[name]) {
      formData[name].color = value;
    } else {
      const idx = formData.feilds.findIndex((a) => a.name === name);
      formData.feilds[idx].color = value;
    }

    this.setState({ formData });
  };

  handleEditorChange = (data, name, isName, idx) => {
    const { formData } = this.state;

    if (formData[name]) {
      formData[name].value = data;
    } else {
      // const idx = formData.feilds.findIndex((a) => a.name === name);
      if (idx === -1) return false;

      if (isName) {
        formData.feilds[idx].name = data;
      } else {
        formData.feilds[idx].content = data;
      }
    }
    this.setState({ formData });
  };

  handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === destination.index) return;
    const formData = this.state.formData;
    const feilds = Array.from(formData.feilds);
    const feild = { ...feilds[source.index] };
    console.log(feild);
    feilds.splice(source.index, 1);
    console.log(feilds.splice(destination.index, 0, feild));
    formData.feilds = feilds;
    this.setState({ formData });
  };

  handleAddField = (fieldName) => {
    const formData = { ...this.state.formData };
    const newFields = formData.feilds;
    newFields.push({
      color: "#efeff0",
      gridNo: "0",
      name: fieldName,
      content: "",
    });
    const fieldLayout = formData.fieldLayout;

    let newFieldLayout = formData.fieldLayout.concat({
      i: "" + fieldLayout.length,
      x: 0,
      y: Infinity,
      w: 12,
      h: 3,
      minH: 3,
      minW: 3,
    });
    formData.fieldLayout = newFieldLayout;
    this.setState({ formData: formData });
  };

  handleDeleteField = (index) => {
    const formData = { ...this.state.formData };
    const newFields = formData.feilds;
    newFields.splice(index, 1);
    formData.feilds = newFields;
    let newFieldLayout = [...formData.fieldLayout];
    newFieldLayout.splice(index, 1);
    for (let i = 0; i < newFieldLayout.length; i++) {
      let element = newFieldLayout[i];
      element.i = "" + i;
    }
    formData.fieldLayout = newFieldLayout;
    this.setState({ formData: formData });
  };
  handleShowModal = (value) => {
    this.setState({ showModal: value });
  }
  handleAddCard = (html, color, icon, index) => {
    const formData = { ...this.state.formData };
    const feild = formData.feilds[index];
    const cardObj = {
      html: html,
      color: color,
    };
    if (feild.content && Array.isArray(feild.content)) {
      feild.content.push(cardObj);
    } else {
      feild.content = [cardObj];
    }
    this.setState({ formData: formData });
  };
  handleEditCard = (html, color, icon, card_index, index) => {
    const formData = { ...this.state.formData };
    const feild = formData.feilds[index];
    const cardObj = {
      html: html,
      color: color,
    };
    if (feild.content && Array.isArray(feild.content)) {
      feild.content[card_index] = cardObj;
    } else {
      feild.content = [cardObj];
    }
    this.setState({ formData: formData });
  };
  handleDeleteCard = (card_index, index) => {
    const formData = { ...this.state.formData };
    const feild = formData.feilds[index];
    feild.content.splice(card_index, 1);
    this.setState({ formData: formData });
  };
  onLayoutChange = (layout) => {
    const formData = { ...this.state.formData };
    formData.fieldLayout = layout;
    this.setState({ formData: formData });
  };
  handleSaveTemplateSubmit = (name) => {
    const fields = [...this.state.formData.feilds];
    const fieldsWithoutContent = fields.map((field, i) => {
      return {
        color: fields[i].color,
        name: fields[i].name,
        gridNo: 0,
        content: [],
      };
    });
    this.props.handleSaveTemplateSubmit(
      name,
      this.state.formData.fieldLayout,
      fieldsWithoutContent
    );
  };
  submit = (e) => {
    e.preventDefault();
    // const { formData } = this.state;
    // if (!formData.name.value) {
    //   alert("Name is Required");
    //   window.scrollTo(0, 0);
    //   return;
    // } else if (!formData.age.value) {
    //   alert("Age is required");
    //   window.scrollTo(0, 0);
    //   return;
    // } else if (!formData.gender.value) {
    //   alert("Gender is required");
    //   window.scrollTo(0, 0);
    //   return;
    // } else if (!formData.location.value) {
    //   alert("Location is required");
    //   window.scrollTo(0, 0);
    //   return;
    // } else if (!formData.maratialStatus.value) {
    //   alert("Martial Status is required");
    //   window.$("html,body").animate({ scrollTop: 0 }, "slow");
    //   return;
    // } else if (!formData.personalityType.value) {
    //   alert("Personality type is required");
    //   window.scrollTo(0, 0);
    //   return;
    // }

    this.props.handleSubmit({ ...this.state.formData });
  };

  render = () => {
    const {
      age,
      name,
      location,
      photo,
      personalityType,
      gender,
      maratialStatus,
      feilds,
    } = this.state.formData;
    return (
      <React.Fragment>
        <form
          className="border"
          onSubmit={this.submit}
          style={{
            marginBottom: "5em",
            minWidth: "100%",
            overflowY: "auto",
            left: "203px",
            position: "relative",
          }}
        >
          <div className="container-fluid my-3">
            <div className="row no-gutter">
              <div className="col-12">
                <div className="row">
                  <div className="col-3 pb-3">
                    <div className="custom-file-container h-100">
                      <input
                        type="file"
                        onChange={(e) => this.handleChange(e, "photo")}
                        className="custom-file d-none"
                        id="customFileLang"
                        lang="es"
                      />
                      <label
                        className="label-custome-file d-flex align-items-center justify-content-center c-pointer"
                        htmlFor="customFileLang"
                      >
                        {photo ? (
                          <img
                            className="persona-img"
                            src={CX_URL + photo}
                            id="img"
                            alt=""
                          />
                        ) : (
                          "Photo"
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-9">
                    {/* Name and Personality */}
                    <div className="row">
                      <div className="col-4 px-2">
                        <FeildContainer
                          color={name.color}
                          name="name"
                          onChange={this.handleColorChange}
                        >
                          <div
                            className="form-group pl-4"
                            style={{ backgroundColor: `${name.color}00` }}
                          >
                            <label
                              htmlFor="name"
                              className="text-uppercase required"
                            >
                              Name
                            </label>
                            <div style={styles.input}>
                              <CKEditor
                                onInit={(editor) => {
                                  editor.setData(name.value);
                                }}
                                editor={Editor}
                                data={name.value}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.handleEditorChange(data, "name");
                                }}
                              />
                            </div>
                          </div>
                        </FeildContainer>
                      </div>
                      <div className="col-4 px-2">
                        <FeildContainer
                          color={personalityType.color}
                          name="personalityType"
                          onChange={this.handleColorChange}
                        >
                          <div
                            className="form-group pl-4"
                            style={{
                              backgroundColor: `${personalityType.color}00`,
                            }}
                          >
                            <label
                              htmlFor="personality"
                              className="text-uppercase required"
                            >
                              Personality Type
                            </label>
                            <div style={styles.input}>
                              <CKEditor
                                editor={Editor}
                                onInit={(editor) => {
                                  editor.setData(personalityType.value);
                                }}
                                data={personalityType.value}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.handleEditorChange(
                                    data,
                                    "personalityType"
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </FeildContainer>
                      </div>
                      <div className="col-4 px-2">
                        <FeildContainer
                          color={age.color}
                          name="age"
                          onChange={this.handleColorChange}
                        >
                          <div
                            className="form-group pl-4"
                            style={{ backgroundColor: `${age.color}00` }}
                          >
                            <label
                              htmlFor="age"
                              className="text-uppercase required"
                            >
                              Age
                            </label>
                            <div style={styles.input}>
                              <CKEditor
                                editor={Editor}
                                onInit={(editor) => {
                                  editor.setData(age.value);
                                }}
                                data={age.value}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.handleEditorChange(data, "age");
                                }}
                              />
                            </div>
                          </div>
                        </FeildContainer>
                      </div>
                      <div className="col-4 px-2">
                        <FeildContainer
                          color={location.color}
                          name="location"
                          onChange={this.handleColorChange}
                        >
                          <div
                            className="form-group pl-4"
                            style={{ backgroundColor: `${location.color}00` }}
                          >
                            <label
                              htmlFor="location"
                              className="text-uppercase required"
                            >
                              Location
                            </label>
                            <div style={styles.input}>
                              <CKEditor
                                onInit={(editor) => {
                                  editor.setData(location.value);
                                }}
                                editor={Editor}
                                data={location.value}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.handleEditorChange(data, "location");
                                }}
                              />
                            </div>
                          </div>
                        </FeildContainer>
                      </div>
                      <div className="col-4 px-2">
                        <FeildContainer
                          color={gender.color}
                          name="gender"
                          onChange={this.handleColorChange}
                        >
                          <div
                            className="form-group pl-4"
                            style={{ backgroundColor: `${gender.color}00` }}
                          >
                            <label className="text-uppercase required">
                              Select Gender
                            </label>
                            <select
                              className="custom-select  bg-transparent"
                              onChange={(e) => this.handleChange(e, "gender")}
                              value={gender.value}
                            >
                              <option>Select Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                        </FeildContainer>
                      </div>
                      <div className="col-4 px-2">
                        <FeildContainer
                          color={maratialStatus.color}
                          name="maratialStatus"
                          onChange={this.handleColorChange}
                        >
                          <div
                            className="form-group pl-4"
                            style={{
                              backgroundColor: `${maratialStatus.color}00`,
                            }}
                          >
                            <label className="text-uppercase required">
                              Marital Status
                            </label>
                            <select
                              className="custom-select bg-transparent"
                              onChange={(e) =>
                                this.handleChange(e, "maratialStatus")
                              }
                              value={maratialStatus.value}
                            >
                              <option>Status</option>
                              <option value="married">Married</option>
                              <option value="unmarried">Unmarried</option>
                            </select>
                          </div>
                        </FeildContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                {/* <div className="row"> */}
                <ReactGridLayout
                  autoSize={true}
                  compactType="vertical"
                  className="layout"
                  layout={this.state.formData.fieldLayout}
                  cols={12}
                  rowHeight={60}
                  items={this.state.formData.fieldLayout.length}
                  onLayoutChange={this.onLayoutChange}
                  useCSSTransforms={false}
                >
                  {feilds.map((feild, idx) => {
                    return (
                      <div key={idx}>
                        <Feild
                          key={idx}
                          index={idx}
                          name={feild.name}
                          content={feild.content}
                          color={feild.color}
                          handleShowModal={this.handleShowModal}
                          handleEditorChange={(data, name, isName) =>
                            this.handleEditorChange(data, name, isName, idx)
                          }
                          handleColorChange={this.handleColorChange}
                          handleOnDelete={() => this.handleDeleteField(idx)}
                          handleDeleteCard={(card_index) =>
                            this.handleDeleteCard(card_index, idx)
                          }
                        />
                      </div>
                    );
                  })}
                </ReactGridLayout>
                {feilds.map((feild, idx) => {
                  return (
                    this.state.showModal === true ? (
                      <AddModals
                        key={idx}
                        index={idx}
                        name={feild.name}
                        content={feild.content}
                        color={feild.color}
                        handleAddCard={(html, color, icon) =>
                          this.handleAddCard(html, color, icon, idx)
                        }
                        handleEditCard={(html, color, icon, card_index) =>
                          this.handleEditCard(html, color, icon, card_index, idx)
                        }
                      />
                    ) : null
                  );
                })}

              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-10 px-2 col-lg-8 mr-auto text-left">
                    <button
                      type="button"
                      onClick={() => this.handleAddField("New Field")}
                      className="btn btn-primary py-3 px-5"
                      style={{ backgroundColor: "#223866" }}
                    >
                      Add field
                    </button>
                    {/*typeof this.props.handleSaveTemplateSubmit ==
                    "function" ? (
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#save-template-modal"
                        className="btn btn-primary py-3 px-5 ml-3"
                        style={{ backgroundColor: "#223866" }}
                      >
                        Save as template
                      </button>
                    ) : null*/}
                  </div>
                  <div className="col-2 px-2 col-lg-4 ml-auto text-right">
                    {this.props.isUpdating ? (
                      <button
                        type="submit"
                        disabled
                        className="btn btn-primary py-3 px-5"
                        style={{ backgroundColor: "#223866" }}
                      >
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {this.props.buttonText}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary py-3 px-5"
                        style={{ backgroundColor: "#223866" }}
                      >
                        {this.props.buttonText}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {typeof this.props.handleSaveTemplateSubmit == "function" ? (
          <SaveTemplateDialog onSave={this.handleSaveTemplateSubmit} />
        ) : null}
      </React.Fragment>
    );
  };
}

const styles = {
  input: {
    borderRadius: "0px",
    border: "0px",
    marginTop: "50px!important",
    paddingTop: "5px",
    height: "40px",
  },
  profileImage: {
    width: "80px",
    height: "80px",
    marginRight: "13px",
    cursor: "pointer",
  },
};
