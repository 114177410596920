import React, { Component } from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';
import { API_HOST } from '../services/url';

class RegisteredUsers extends Component {
    constructor(propos) {
        super();
        this.state = {
            data: [],
            open: false,
        };
        this.deleteUser = this.deleteUser.bind(this);
    }

    async deleteUser(id) {
        const token = localStorage.getItem('token');
        console.log(token);
        await axios
            .delete(API_HOST + `admin/deletefromadmin/${id}`, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                if (res.data) {
                    alert(res.data.message);
                }
            })
            .catch(err => {
                alert(err.msg);
            });
        await this.getData();
    }

    async getData() {
        const token = localStorage.getItem('cxtoken');

        await axios
            .get(CX_DEPLOYER + `user`, { headers: { 'x-access-token': token } })
            .then(response => {
                this.setState({
                    data: response.data.users,
                });
            });
    }

    renderTableData() {
        return this.state.data.map((row, index) => {
            const { company, email, isPremiumUser, status, storage, userType, username, _id } = row; //destructuring
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{username}</td>
                    <td>{company}</td>
                    <td>{status}</td>
                    <td>{email}</td>
                    <td>{storage}</td>
                    <td>{String(isPremiumUser)}</td>
                    <td>{userType}</td>
                    {/* <td>{payment}</td> */}
                    <td>
                        <button className="btn btn-danger" onClick={() => this.deleteUser(_id)}>
                            delete
                        </button>
                    </td>
                </tr>
            );
        });
    }

    componentDidMount() {
        this.getData();
    }
    render() {
        return (
            <div>
                {/* <Header />
                <Sidebar /> */}
                <div class="mainContent">
                    <div class="container">
                        <div class="page-header text-center">
                            <h3>Users Registered</h3>
                        </div>
                        <table className="table table-bordered text-center table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">SNo.</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Email ID</th>
                                    <th scope="col">Storage</th>
                                    <th scope="col">Is Premium ?</th>
                                    <th scope="col">User Type</th>
                                    <th scope="col">Delete</th>
                                </tr>
                            </thead>
                            <tbody>{this.renderTableData()}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegisteredUsers;
