import React, { Component} from 'react';
import axios from 'axios';
import { API_HOST } from '../services/url';
import './addTags.css';
class AddTags extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            tag: '',
            tags: [],
            editing: true,
            tagId: '',
            editTagName: '',
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.getTags = this.getTags.bind(this);
    }
    async getTags() {
        const token = localStorage.getItem('cxtoken');
        axios
            .get(API_HOST + 'web/tags', {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                // if(res.data.success){
                this.setState({ tags: res.data.data.tags });
                // }
            });
    }
    async componentDidMount() {
        await this.getTags();
    }

    async onSubmit(e) {
        e.preventDefault();

        const token = localStorage.getItem('cxtoken');
        axios
            .post(
                API_HOST + 'web/tags',
                { tag: this.state.tag },
                {
                    headers: { 'x-access-token': token },
                }
            )
            .then(res => {
                this.setState({ tag: '' });
                this.getTags();
            })
            .catch(error => {
                alert('Failed to add tags');
            });
    }
    async deleteTag(tagId) {
        const token = localStorage.getItem('cxtoken');
        axios
            .delete(API_HOST + `web/tags/${tagId}`, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                // this.setState({tag:""})
                this.getTags();
            })
            .catch(error => {
                alert('Failed to add tags');
            });
    }
    async onEdit(tag) {
        this.setState({
            editing: true,
            tagId: tag._id,
            editTagName: tag.tag,
        });
    }
    async editTag(tagId) {
        const token = localStorage.getItem('cxtoken');
        axios
            .put(
                API_HOST + `web/tags/${tagId}`,
                { tag: this.state.editTagName },
                {
                    headers: { 'x-access-token': token },
                }
            )
            .then(res => {
                this.setState({ tagId: '', editTagName: '', editing: false });
                this.getTags();
            })
            .catch(error => {
                alert('Failed to add tags');
            });
    }
    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    render() {
        return (
            <div>
                <div className="container" >
                    <div className="row">
                        <div className="col-sm-9 col-md-9 mx-auto">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h4 className="card-title text-center">Add Tags</h4>
                                    <form className="form-signin" method="POST">
                                        <div className="form-group add-tag-css">
                                            <div className="add-tags-input-field">
                                                <input
                                                    placeholder="Add New Tags"
                                                    id="title"
                                                    type="text"
                                                    className="form-control"
                                                    name="tag"
                                                    value={this.state.tag}
                                                    onChange={e => this.change(e)}
                                                    required
                                                />
                                            </div>
                                            <div className="add-tag-button-css">
                                                <button
                                                    className="btn btn-info btn-block"
                                                    onClick={e => this.onSubmit(e)}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                    {this.state.tags &&
                                        this.state.tags?.map(item => (
                                            <div
                                                className=" label-tag-css"
                                                key={item._id}
                                            >
                                                <div className="add-tags-input-field">
                                                    {this.state.editing &&
                                                    this.state.tagId == item._id ? (
                                                        <input
                                                            placeholder="Add New Tags"
                                                            id="title"
                                                            type="text"
                                                            className="form-control"
                                                            name="editTagName"
                                                            value={this.state.editTagName}
                                                            onChange={e => this.change(e)}
                                                            required
                                                        />
                                                    ) : (
                                                        <label>{item.tag}</label>
                                                    )}
                                                </div>
                                                <div className="label-tag-button-css">
                                                    {this.state.editing &&
                                                    this.state.tagId == item._id ? (
                                                        <button
                                                            className="btn btn-info btn-block"
                                                            onClick={e => this.editTag(item._id)}
                                                        >
                                                            Save
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-info btn-block"
                                                            onClick={e => this.onEdit(item)}
                                                        >
                                                            Edit
                                                        </button>
                                                    )}
                                                    <button
                                                        className="delete-button btn btn-danger btn-block"
                                                        onClick={e => this.deleteTag(item._id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddTags;
