import React from "react";

export default ({ addNode }) => {
  return (
    <div className="linelane-node__container  d-flex flex-column align-items-stretch">
      <div className="wrapLine" style={{ height: "36px" }}></div>
      <div className="wrapLine" style={{ height: "36px" }}></div>
      <div className="wrapLine" style={{ height: "36px" }}>
        <center>
          <div
            onClick={addNode}
            className="add-node  d-flex align-items-center justify-content-center c-pointer"
          >
            +
          </div>
        </center>
      </div>
      <div className="wrapLine" style={{ height: "36px" }}></div>
      <div className="wrapLine" style={{ height: "36px" }}></div>
    </div>
  );
};
