import React, { Component } from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../../services/url';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import QuillEditor from '../../Editor/quill';
import withRouter from '../../withRouter';
class EditBlog extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            title: '',
            _id: '',
            subtitle: '',
            blog_content: '',
            inputs: [],
            videos: [],
            tags: [],
            selectedFile: null,
            date: '',
            titleImage: [],
            seo_metadata: [],
            description: '',
        };
        //   this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount = () => {
        axios
            .get(CX_DEPLOYER + `blog/blogbyId/${this.props.params.postId}`)
            .then(res => {
                let blogs = res.data.blogs;

                // for (var i = 0; i < blogs.length; i++) {
                // if (this.props.params.postId == blogs[i]._id) {
                this.setState({
                    _id: blogs[0]._id,
                    title: blogs[0].title,
                    description: blogs[0].description,
                    blog_content: blogs[0].blog_content,
                    tags: blogs[0].tags,
                    seo_metadata: blogs[0].seo_metadata,
                    date: blogs[0].uploadDate,
                    // files:blogs[0].titleImage
                });
                //   }
                // }
            })
            .catch(err => console.log(err));
    };
    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onEditorChange = blog_content => {
        this.setState({ blog_content });
    };

    onFileChange = files => {
        console.log(files);
        this.setState({ selectedFile: files[0] });
    };

    async onSubmit(e) {
        e.preventDefault();
        // const data = {
        //   title: this.state.title,
        //   blog_content: this.state.blog_content,
        //   tags: this.state.tags,
        //   uploadDate: this.state.date,
        // };
        // console.log(data);
        // const data = new FormData();
        // data.append("title", this.state.title);

        // data.append("blog_content", this.state.blog_content);
        // data.append("files", this.state.selectedFile);
        const data = new FormData();
        data.append('title', this.state.title);
        data.append('blog_content', this.state.blog_content);
        data.append('tags', JSON.stringify(this.state.tags));
        data.append('seo_metadata', JSON.stringify(this.state.seo_metadata));
        data.append('author', localStorage.getItem('adminId'));
        data.append('uploadDate', this.state.date);
        data.append('description', this.state.description);
        if (this.state.files) {
            data.append('titleImage', this.state.files);
        }
        const token = localStorage.getItem('cxtoken');
        axios
            .put(CX_DEPLOYER + `blog/${this.state._id}`, data, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                alert('Updated');
                window.location.replace('/blogs');
            });
    }

    appendInput() {
        this.setState({ inputs: [...this.state.inputs, ''] });
        console.log(this.state.inputs);
        // var newInput = `input-${this.state.inputs.length}`;
        // this.setState(prevState => ({ inputs: prevState.inputs.concat([newInput]) }));
    }
    handleChange(e, index) {
        this.state.inputs[index] = e.target.value;
        this.setState({ inputs: this.state.inputs });
    }
    handleimagechange(e) {
        console.log(e.target.files);
        this.setState({ files: e.target.files[0] });
    }

    appendVideos() {
        this.setState({ videos: [...this.state.videos, ''] });
        // var newInput = `input-${this.state.inputs.length}`;
        // this.setState(prevState => ({ inputs: prevState.inputs.concat([newInput]) }));
    }
    handleVideosChange(e, index) {
        this.state.videos[index] = e.target.value;
        this.setState({ videos: this.state.videos });
    }

    render() {
        return (
            <div className="container" >
                <div className="row">
                    <div className="col-sm-9 col-md-9 mx-auto">
                        <div className="card card-signin my-5">
                            <div className="card-body">
                                <h4 className="card-title text-center">Edit Blog</h4>
                                <form className="form-signin" method="POST">
                                    <div className="form-group">
                                        <label for="email">Title</label>
                                        <input
                                            id="title"
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            value={this.state.title}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="email">Title Description</label>
                                        <textarea
                                            id="description"
                                            type="text"
                                            className="form-control"
                                            name="description"
                                            value={this.state.description}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="image">Title Image</label>
                                        <input
                                            id="files"
                                            type="file"
                                            className="form-control"
                                            name="files"
                                            onChange={e => this.handleimagechange(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="tags">Tags</label>
                                        <Autocomplete
                                            multiple
                                            id="tags-filled"
                                            options={[]}
                                            freeSolo
                                            onChange={(e, newValue) =>
                                                this.setState({ tags: newValue })
                                            }
                                            value={this.state.tags || []}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        label={option}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Add Tag"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="seo">SEO Metadata</label>
                                        <Autocomplete
                                            multiple
                                            id="seo-filled"
                                            options={[]}
                                            freeSolo
                                            onChange={(e, newValue) =>
                                                this.setState({ seo_metadata: newValue })
                                            }
                                            value={this.state.seo_metadata || []}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        label={option}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Add Tag"
                                                />
                                            )}
                                        />
                                    </div>
                                    {/* <div className="form-group">
                      <label for="subtitle">Subtitle</label>
                      <input
                        id="subtitle"
                        type="text"
                        className="form-control"
                        name="subtitle"
                        value={this.state.subtitle}
                        onChange={(e) => this.change(e)}
                        required
                      />
    </div>*/}
                                    <div className="form-group">
                                        <label for="blog_content">Blog Content</label>
                                        <QuillEditor
                                            placeholder={'Start Posting Something'}
                                            onEditorChange={this.onEditorChange}
                                            onFilesChange={this.onFileChange}
                                            value={this.state.blog_content}
                                        />
                                    </div>

                                    {/*<div className="form-group">
                                                    <label for="email">Image</label>

                                            
                                                        <input 
                                                        className="form-control"
                                                        type="file" 
                                                        onChange={this.onFileChange} />
                            </div>

                            <div className="form-group">
                            <label for="slide_url">
                                Slide URL
                            </label>
                            
                            <button  onClick={ () => this.appendInput() } className = "float-right"><FontAwesomeIcon icon={faPlus} /></button>
                            {
                                this.state.inputs.map((input, index) => {
                                    return (
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={e => this.handleChange(e, index)}
                                            required
                                        />                        
                                    );
                                })
                            }
                            </div>
                            <div className="form-group">
                            <label for="videos">
                                Videos
                            </label>
                            <button  onClick={ () => this.appendVideos() } className = "float-right"><FontAwesomeIcon icon={faPlus} /></button>
                            {
                                this.state.videos.map((input, index) => {
                                    return (
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={e => this.handleVideosChange(e, index)}
                                            required
                                        />                        
                                    );
                                })
                            }
                        </div>*/}
                                    <div className="form-group no-margin">
                                        <button
                                            className="btn btn-info btn-block"
                                            onClick={e => this.onSubmit(e)}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EditBlog);
