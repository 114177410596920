import React from 'react';
import Header from '../header/header';
import Sidebar from '../Sidebar/Sidebar';

export default function payment(props) {
    if (props.location.payment) {
        return (
            <div class="mainContent">
                <div class="container">
                    <div class="page-header text-center">
                        <h3>Payment Status</h3>
                    </div>
                    <table className="table-responsive table-bordered text-center table-hover">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">SNo.</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Cancelled</th>
                                <th scope="col">Email</th>
                                <th scope="col">Paid Status</th>
                                <th scope="col">Payer ID</th>
                                <th scope="col">Date</th>
                                <th scope="col">Payment ID</th>
                                <th scope="col">Token</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.location.payment.map((row, index) => {
                                const {
                                    amount,
                                    cancelled,
                                    email,
                                    paid,
                                    payerID,
                                    paymentDate,
                                    paymentID,
                                    paymentToken,
                                } = row; //destructuring
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{amount}</td>
                                        <td>{String(cancelled)}</td>
                                        <td>{email}</td>
                                        <td>{String(paid)}</td>
                                        <td>{payerID}</td>
                                        <td>{paymentDate}</td>
                                        <td>{paymentID}</td>
                                        <td>{paymentToken}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else if (!props.location.payment) {
        props.history.push('/premiumUsers');
        return <div></div>;
    }
}
