import React, { useState, useEffect } from 'react';
import Card from './card';
import { getCareers } from './api';
import { Link } from 'react-router-dom';
import '../inquiries/inquiries.css';
import ReactPaginate from 'react-paginate';
import { CX_DEPLOYER } from '../services/url';
import axios from 'axios';
export default function AllCareers() {
    const [loading, setloading] = useState(true);
    const [data, setdata] = useState([]);
    const [pageSize, setPageSize] = useState(15);
    const [pageCount, setPageCount] = useState();
    const handlePageChange = ({ selected }) => {
        fetchCareers(selected);
    };
    const fetchCareers = selected => {
        let payload = {
            page_size: pageSize,
            page_number: selected,
        };
        getCareers(payload)
            .then(res => {
                if (res.success) {
                    setdata(res.data);
                    setPageCount(Math.ceil(res.metadata.total_data / pageSize));
                }
            })
            .catch(error => console.log(error))
            .finally(() => setloading(false));
    };
    const handleDelete = e => {
        e.preventDefault();
        const id = e.target.id;
        const token = localStorage.getItem('cxtoken');
        axios
            .delete(CX_DEPLOYER + 'careers/deleteCareer', {
                data: {
                    id: id,
                },
                headers: { 'x-access-token': token },
            })
            .then(res => {
                console.log(res);
                window.location.reload(false);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchCareers(0);
    }, []);
    document.title =
        'careerss | Persona | Empathy | Value | Journey Mapping | Innovation | Kanban | CXDeployer';
    return (
        <div className="pt-5">
            <div className="container">
                <div className="container text-center">
                    <h1>All Careers</h1>
                </div>
                {loading ? (
                    <div className="row mt-5 text-center align-content-center d-flex justify-content-center">
                        <div
                            className="spinner-grow text-primary"
                            style={{ height: '100px', width: '100px' }}
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div
                            className="row mt-5"
                            style={{ padding: '10px', marginBottom: '15px', height: '100%' }}
                        >
                            {data.map(careers => {
                                return (
                                    <div
                                        className="col-lg-4 "
                                        style={{ marginBottom: '5px', height: '100%' }}
                                    >
                                        <div className="card p-2 gap-2">
                                            <Card
                                                title={careers.jobTitle}
                                                // titleImage={API_HOST + careers.titleImage}
                                            />
                                            <Link
                                                className="btn btn-dark"
                                                // to={{ pathname: `/view/${careers._id}`, careers: careers }}
                                            >
                                                Read this article
                                            </Link>
                                            <Link
                                                className="btn btn-dark mt-2"
                                                to={{
                                                    pathname: `/editcareers/${careers._id}`,
                                                }}
                                            >
                                                Edit
                                            </Link>

                                            <Link
                                                className="btn btn-danger mt-2"
                                                name={careers.image}
                                                id={careers._id}
                                                onClick={e =>
                                                    window.confirm(
                                                        `Are you sure you want to delete this article?`
                                                    ) && handleDelete(e)
                                                }
                                            >
                                                Delete this article
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })}
                            <ReactPaginate
                                pageCount={pageCount} // Replace with your actual number of pages
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName="pagination-container"
                                pageClassName="pagination-item"
                                breakClassName="pagination-item"
                                previousClassName="pagination-item"
                                nextClassName="pagination-item"
                                activeClassName="pagination-item active"
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
