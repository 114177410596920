'use client';
import { useState } from 'react';
import { GoComment } from 'react-icons/go';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import styles from './blogCommentCard.module.css';
//  import FormData from './formData';
 import axios from 'axios';
 import { API_HOST } from '../../services/url';
const BlogCommentCard = ({ blogId, blogs, getBlogData }) => {
    const [replyComment, setReplyComment] = useState(null);
    const [comment, setComment] = useState(true);
    const [showAllComments, setShowAllComments] = useState(false);

    const toggleComments = () => {
        setShowAllComments(!showAllComments);
    };
    const deleteComment = async (commentId) => {
        try {
            // Make DELETE request to your API endpoint
            const response = await axios.delete(`${API_HOST}/web/blog/comment/delete`, {
                data: {
                    blogId: blogId,
                    commentId: commentId,
                    
                },
            });

            // Check if the request was successful
            if (response.status === 200) {
                console.log('Reply deleted successfully');
                // Refresh the comments after successful deletion
                getBlogData();
            } else {
                console.error('Failed to delete reply:', response.data.error);
            }
        } catch (error) {
            console.error('Error deleting reply:', error);
        }
    };

    // ... (rest 
    const deleteReplyComment = async (commentId, replyId) => {
        try {
            // Make DELETE request to your API endpoint
            const response = await axios.delete(`${API_HOST}/web/blog/replyComment/delete`, {
                data: {
                    blogId: blogId,
                    commentId: commentId,
                    replyId: replyId,
                },
            });

            // Check if the request was successful
            if (response.status === 200) {
                console.log('Reply deleted successfully');
                // Refresh the comments after successful deletion
                getBlogData();
            } else {
                console.error('Failed to delete reply:', response.data.error);
            }
        } catch (error) {
            console.error('Error deleting reply:', error);
        }
    };

     
     
    const formatDate = (dateString) => {
        const getOrdinalSuffix = (number) => {
            const suffixes = ['th', 'st', 'nd', 'rd'];
            const lastDigit = number % 10;
            const lastTwoDigits = number % 100;
            return suffixes[lastDigit] || suffixes[lastTwoDigits] || suffixes[0];
        };
    
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleDateString(undefined, { month: 'short' });
        const year = date.getFullYear();
        return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    };
    return (
        <div>
            {comment ? (
                <div className="p-3">
                    {/* <FormData
                        submit={'Comment'}
                        close={() => setComment(false)}
                        blogId={blogId}
                        getBlogData={getBlogData}
                    /> */}
                   <h1> Comment section</h1>
                </div>
            ) : (
                <div
                    className="d-flex align-items-center cursor-pointer"
                    onClick={() => setComment(!comment)}
                >
                    <div className={styles.comment_icons_div}>
                        <GoComment color="" />
                    </div>
                    <span className="mt-2">Write a Comment</span>
                </div>
            )}

            <div className={styles.comments_of_user}>
                {blogs?.comments
                    ?.slice()
                    ?.reverse()
                    ?.slice(0, showAllComments ? blogs?.comments.length : 1)
                    ?.map((comment) => (
                        <div className="mb-2" key={comment?.id}>
                            <p className={styles.name_p}>
                                {comment?.name} &nbsp;
                                <span className={styles.date_of_comment}>
                                    {formatDate(comment?.createdAt)}
                                </span>
                            </p>
                            <span className={styles.email_span}>
                                {comment?.email}
                            </span>
                            <p className={styles.comment_message}>
                                {comment?.commentText}
                            </p>
                            <div
                                className={styles.like_icons_div}
                                onClick={() =>  window.confirm(`Are you sure you want to Delete?`) && deleteComment(comment?._id)}
                            >
                                <GoComment />
                                <span>Delete</span>
                                {/* <span onClick={()=>setReplyComment(comment?._id)}>reply</span> */}
                            </div>
                            {/* {replyComment === comment?._id && (
                                <FormData
                                    submit={'Reply'}
                                    close={() => setReplyComment(null)}
                                    commentId={comment?._id}
                                    blogId={blogId}
                                    getBlogData={getBlogData}
                                />
                            )} */}
                            {comment?.subComments
                                ?.slice()
                                ?.reverse()
                                ?.map((subComments, index) => (
                                    <div
                                        className={styles.reply_comment}
                                        key={index}
                                    >
                                        <div>
                                            <p className={styles.name_p}>
                                                {subComments?.name} &nbsp;
                                                <span
                                                    className={
                                                        styles.date_of_comment
                                                    }
                                                >
                                                    {formatDate(
                                                        subComments?.createdAt,
                                                    )}
                                                </span>
                                            </p>
                                            <span className={styles.email_span}>
                                                {subComments?.email}
                                            </span>
                                            <p
                                                className={
                                                    styles.comment_message
                                                }
                                            >
                                                {subComments?.commentText}
                                            </p>
                                            <div
                                className={styles.like_icons_div}
                                onClick={() =>  window.confirm(`Are you sure you want to Delete?`) && deleteReplyComment(comment?._id,subComments?._id)}
                            >
                                <GoComment />
                                <span>Delete</span>
                                
                            </div>
                            
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ))}
                {!showAllComments ? (
                    <button
                        className={styles.viewMoreBtn}
                        onClick={toggleComments}
                    >
                        View More <MdExpandMore size={23} />
                    </button>
                ) : (
                    <button
                        className={styles.viewMoreBtn}
                        onClick={toggleComments}
                    >
                        Show Less <MdExpandLess size={23} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default BlogCommentCard;
