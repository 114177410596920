import Axios from "axios";
import { CX_DEPLOYER } from "../services/url";

Axios.defaults.headers.common["x-access-token"] = localStorage.getItem("cxtoken");
// Axios.defaults.baseURL = "http://localhost:5000/user";
// Axios.defaults.baseURL = "http://35.203.105.146/user/";
Axios.defaults.baseURL = CX_DEPLOYER ;
// Axios.defaults.baseURL = "http://localhost:5000/user/";

export default Axios;
