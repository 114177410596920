import React, { Component } from 'react';

import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

class addUser extends Component {
    constructor() {
        super();

        this.state = {
            email: '',
            planType: '',
            totalAssociatedUser: 1,
        };
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    handleIncrement = () => {
        this.setState(state => ({
            totalAssociatedUser: state.totalAssociatedUser + 1,
        }));
    };

    handleDecrement = () => {
        this.setState(state => ({
            totalAssociatedUser: state.totalAssociatedUser - 1,
        }));
    };

    async onSubmit(e) {
        e.preventDefault();

        try {
            const response = await axios.post(`${CX_DEPLOYER}/add`, this.state);
            if (response.data) {
                alert(response.data.message);
                window.location.reload();
            }
        } catch (err) {
            alert(err);
        }
    }

    render() {
        let displayCounter = this.state.totalAssociatedUser > 1;

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 col-md-9 mx-auto">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h4 className="card-title text-center">Add User</h4>
                                    <form className="form-signin" method="POST">
                                        <div className="form-group">
                                            <label for="email">Email</label>
                                            <input
                                                id="email"
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                value={this.state.email}
                                                onChange={e => this.handleChange(e)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label for="Plan">Plan</label>
                                            <select
                                                className="form-control"
                                                id="planType"
                                                onChange={e => this.handleChange(e)}
                                                required
                                                value={this.state.plan}
                                            >
                                                <option value=" ">Select Plan</option>
                                                <option value="Startup">Start Up</option>
                                                <option value="Business">Business</option>
                                                <option value="Lifetime-1GB">
                                                    Special Lifetime Deal-1GB
                                                </option>
                                                <option value="Lifetime-10GB">
                                                    Special Lifetime Deal-10GB
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group d-flex justify-content-between">
                                            <label for="Users">Users</label>

                                            <ButtonGroup
                                                size="small"
                                                aria-label="small outlined button group"
                                            >
                                                <Button onClick={this.handleIncrement}>+</Button>

                                                <Button>{this.state.totalAssociatedUser}</Button>
                                                {displayCounter && (
                                                    <Button onClick={this.handleDecrement}>
                                                        -
                                                    </Button>
                                                )}
                                            </ButtonGroup>
                                        </div>

                                        <div className="form-group no-margin">
                                            <button
                                                className="btn btn-info btn-block"
                                                onClick={e => this.onSubmit(e)}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default addUser;
