export const API_HOST = 'https://api.appsdeployer.com/api/';
// export const API_HOST = 'http://localhost:8082/api/';

export const CX_DEPLOYER = `${API_HOST}cxdeployer/admin/`;
export const USER_URL = `${API_HOST}cxdeployer/`;

export const CX_URL = `${API_HOST}user/`;
export const KANBAN_CX_DEPLOYER = `${API_HOST}taskdeployer/prefilledTemplate/`;
export const KANBAN_CX_DEPLOYER_FORM = `${API_HOST}form/prefilledFormTemplate/`;

export const cookieToken = localStorage.getItem('cxtoken');

// export const CX_DEPLOYER = `${API_HOST}cxdeployer/admin/`
// export const USER_URL = `${API_HOST}cxdeployer/`

// export const CX_URL = `${API_HOST}user/`
// export const KANBAN_CX_DEPLOYER= `${API_HOST}taskdeployer/prefilledTemplate/`
// export const KANBAN_CX_DEPLOYER_FORM= `${API_HOST}form/prefilledFormTemplate/`
