import React from 'react';
import RoutePage from '../routePage/userPage';
const Page = () => {
    const Data = [
        {
            id: '1',
            routeName: 'Add User',
            popuptext: [
                {
                    title: 'Add',
                    link: '/addUser',
                },
            ],
        },
        {
            id: '2',
            routeName: 'Plans',
            popuptext: [
                {
                    title: 'Add',
                    link: '/addPlan',
                },
                {
                    title: 'View',
                    link: '/ViewPlan',
                },
            ],
        },
        {
            id: '3',
            routeName: 'Coupons',
            popuptext: [
                {
                    title: 'Add',
                    link: '/addCoupons',
                },
                {
                    title: 'View',
                    link: '/ViewCoupons',
                },
            ],
        },
        {
            id: '4',
            routeName: 'Help',
            popuptext: [
                {
                    title: 'Add',
                    link: '/help',
                },
                {
                    title: 'View',
                    link: '/ViewHelp',
                },
            ],
        },
    ];
    return (
        <div>
            <RoutePage Data={Data} title={'Admin'} />
        </div>
    );
};

export default Page;
