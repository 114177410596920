import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CreateBoardPopup from './createBoardPopup';
import { Link } from 'react-router-dom';
import {getTemplate} from '../redux/action/board'
import { useDispatch,useSelector } from 'react-redux';
import { setSelectedBoard } from '../redux/action/board';

const ViewTemplate = () => {
    const dispatch = useDispatch();
    const { allTemplates } = useSelector((store) => store.boardRoot);
    const location = useLocation();
    const [openBoard, setOpenBoard] = useState(null);
    const [createBoardPopup, setCreateBoardPopup] = useState(false); // initialize state variable
    const createBoardhandleClick = () => {
      setCreateBoardPopup(!createBoardPopup);
    };
  
    useEffect(() => {
        fetchData();
    }, []);

     const fetchData =() => {
            dispatch(getTemplate(location?.state?.id));
    };
    const handleSelectBoard = board => {
        dispatch(setSelectedBoard(board));
    };
    const formatDate = dateString => {
        const getOrdinalSuffix = number => {
            const suffixes = ['th', 'st', 'nd', 'rd'];
            const lastDigit = number % 10;
            const lastTwoDigits = number % 100;
            return suffixes[lastDigit] || suffixes[lastTwoDigits] || suffixes[0];
        };

        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleDateString(undefined, { month: 'short' });
        const year = date.getFullYear();
        return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    };

    const renderTableData = () => {
        return allTemplates && allTemplates.map((row, index) => {
            const { boardName, createdAt,categoryId,boardColor } = row;
            return (
                <tr key={index}>
                 
                    <td>{index + 1}</td>
                    <td>{boardName}</td>
                    <td>{formatDate(createdAt)}</td>
                    <td>
                        <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-evenly'}}>
                        <button
                            className="btn btn-primary mr-2"
                            type="button"
                            onClick={() => setOpenBoard(row?._id)}
                        >
                            Edit
                        </button>
                        <Link
                        className="text-decoration-none text-white"
                        to={'/template'}
                        state={{
                            boardId: row._id,
                        }}
                    >
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => handleSelectBoard(row)}
                        >
                            View
                        </button>
                    </Link>

                        
                    </div>
                    </td>
                      
                    {openBoard === row?._id && (
                        <CreateBoardPopup
                            fetchData={fetchData}
                            id={row?._id}
                            edit="edit"
                            title={boardName}
                            boardColor={boardColor}
                            categoryId={categoryId}
                            onClose={setOpenBoard}
                            setCreateBoardPopup={setOpenBoard}
                        />
                    )}
                </tr>
            );
        });
    };

    return (
        <div>
            <div className="mainContent">
                <div className="container">
                    <div className='row mt-2'>
                    {/* <p style={{textAlign:"left"}}>
                    <h3></h3>
                        <span style={{float:"right"}} >
                           Create Template
                        </span>
                    </p> */}
                    <h3 style={{textAlign:'left'}}>
                    {location.state?.category}
                    <span style={{float:'right'}}>
                    <Link
                        className="text-decoration-none text-white mx-3"
                        to={'/add-category'}
                    >
                    <button
                            className="btn btn-primary"
                            type="button"
                        >
                           Back 
                        </button>
                        </Link>

                    <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => createBoardhandleClick()}

                        >
                            Create Template
                        </button>
                    </span>
                </h3>
                    </div>
                    <table className="table table-bordered text-center table-hover">
                        <thead className="thead-light">
                        
                            <tr>
                                <th scope="col">SNo.</th>
                                <th scope="col">Board name</th>
                                <th scope="col">Created Date</th>

                                <th scope="col" colSpan="3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>{renderTableData()}</tbody>
                    </table>
                </div>
                {createBoardPopup && (
        <CreateBoardPopup
          onClose={createBoardhandleClick}
          setCreateBoardPopup={setCreateBoardPopup}
        />
      )}
            </div>
        </div>
    );
};

export default ViewTemplate;
