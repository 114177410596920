import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { GrAttachment } from 'react-icons/gr';
import { addCardAttachment } from '../../redux/action/card';

const AttachmentModal = () => {
    const popupRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const { selectedCard } = useSelector(store => store.cardRoot);
    const { selectedBucket } = useSelector(store => store.bucketRoot);

    const dispatch = useDispatch();
    // console.log("checklist ---------", selectedCard)

    const handleClickOutside = event => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setShowPopup(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleShow = () => {
        if (showPopup === false) {
            setShowPopup(true);
        } else {
            setShowPopup(false);
        }
    };

    const handleClose = () => {
        setShowPopup(!showPopup);
    };

    const handleSelectFile = e => {
        console.log('file select ', e.target.files[0]);
        let formdata = new FormData();
        formdata.append('card-attach', e.target.files[0]);
        let cardId = selectedCard && selectedCard._id;
        let bucketId = selectedBucket && selectedBucket._id;
        let boardId = selectedBucket && selectedBucket.boardId;
        dispatch(
            addCardAttachment(boardId, bucketId, cardId, formdata, async function (card) {
                console.log('image add', card);
            })
        );
        setShowPopup(!showPopup);
    };
    return (
        <>
            <div className="" onClick={handleShow}>
                <a className="card__join ">
                    <span className="btn__text">
                        <GrAttachment size={17} /> Attachment
                    </span>
                </a>
            </div>

            {showPopup ? (
                <Card
                    ref={popupRef}
                    className="attachmentpopup-shadow rounded-0 shadow"
                    style={{
                        position: 'fixed',
                        // right: "210px",
                        top: '125px',
                        width: '370px',
                        zIndex: '1',
                    }}
                >
                    <section>
                        <p className=" text-center text-muted mt-3" style={{ fontSize: '18px' }}>
                            Attach from…
                            <span
                                style={{ position: 'relative', left: '114px' }}
                                className="text-muted"
                                onClick={handleClose}
                            >
                                <i className="fa-solid fa-xmark"></i>
                            </span>
                        </p>
                        <hr className="mx-2" />
                        <div className="Neon Neon-theme-dragdropbox">
                            <input
                                style={{
                                    zIndex: 999,
                                    opacity: 0,
                                    width: 320,
                                    height: 200,
                                    position: 'absolute',
                                    right: 0,
                                    left: 0,
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                }}
                                name="attach_file"
                                id="filer_input2"
                                multiple="multiple"
                                type="file"
                                onChange={e => handleSelectFile(e)}
                            />
                            <div className=" ">
                                <button className="button3 ul__a border-0 m-2 p-2">Computer</button>
                            </div>
                        </div>
                    </section>
                </Card>
            ) : null}
        </>
    );
};

export default React.memo(AttachmentModal);
