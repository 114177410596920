import React, { useState, useEffect, useRef } from "react";
import { BsCreditCard2BackFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedCard, updateCard } from '../../redux/action/card'
import {
  AiOutlineEye
} from "react-icons/ai";

const CardTitle = ({ bucket, handleonclose }) => {
  const inputRef = useRef(null);

  const { selectedCard } = useSelector((store) => store.cardRoot);
  const { selectedBucket } = useSelector((store) => store.bucketRoot);

  // let desc= selectedCard && selectedCard.cardDescription ? selectedCard.cardDescription:null;
  let [description, setDescription] = useState();
  // const [editor, setEditor] = useState(desc);
  const [show, setShow] = useState(false);
  const [showdesc, setShowdesc] = useState(false);
  const [changes, setChanges] = useState("");

  const [title, setTitle] = useState();

  const dispatch = useDispatch();
  let cardTitle = useRef();





  useEffect(() => {

    const timer = setTimeout(() => {
      if (changes === true) {
        console.log("matched")
        handleEditCard()
      }
      else if (changes === false) {
        setChanges("")
        setShow(false);

      }
    }, 5000)

    return () => clearTimeout(timer)
  }, [title, changes])
  const handleEditCard = () => {
    setShow(false)
    let cardDetail = selectedCard && selectedCard;
    let cardId = selectedCard && selectedCard._id;
    let bucketId = selectedBucket && selectedBucket._id
    let boardId = selectedBucket && selectedBucket.boardId
    cardDetail.cardTitle = title;
    dispatch(setSelectedCard(cardDetail))
    dispatch(updateCard(boardId, bucketId, cardId, {update:'title',cardTitle:title}))
  }

  const handleClick = () => {
    let title = selectedCard && selectedCard.cardTitle;
    setTitle(title)
    setShow(true);
    setChanges(false)
  }
  const onEnter = e => {
    console.log("code",e.keyCode)
    if (e.keyCode === 13) {
      e.preventDefault();
      handleEditCard();

    }
  };


  useEffect(() => {
    // Add event listener to document to listen for clicks outside of input element
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        handleEditCard()
        setTimeout(()=>{
          setShow(false)
        },1000)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener when component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShow, handleEditCard]);

  return (
    <>
      <div className="modal-header ">
        {show ? (
          <div className="d-flex align-items-center" 
              ref={inputRef}
          >
            <span className="popup-header-icon me-2">
              <BsCreditCard2BackFill size={25} />
            </span>
            <input

              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              className="form-control"
              onBlur={() => handleEditCard()}
              autoFocus
              // ref={cardTitle}
              style={{
                width: "680px",
                marginLeft: "20px",
                wordBreak:'break-all'

              }}
              onKeyDown={(e)=>onEnter(e)}
            />
          </div>
        ) : (
          <h1
            className="modal-title fs-5 d-flex align-items-center"
            id="staticBackdropLabel"
            style={{
              width: "680px",
              wordBreak:'break-all'
            }} onClick={() => handleClick()}


          >
            <span className="popup-header-icon me-2" style={{

              minWidth: "40px"

            }}>
              <BsCreditCard2BackFill size={20} />
            </span>

            {selectedCard && selectedCard.cardTitle}

          </h1>
        )}

        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => { handleonclose() }}
        />
      </div>
      <p className="popup_list">
        in list <span className="text-decoration-underline">{selectedBucket && selectedBucket.bucketName}</span>{" "}
      </p>

    </>
  );
};

export default CardTitle;