import axios from 'axios';
import { API_HOST, KANBAN_CX_DEPLOYER, KANBAN_CX_DEPLOYER_FORM } from '../../services/url';
export const getBoard = () => {
    return async dispatch => {
        try {
            const response = await axios.get(KANBAN_CX_DEPLOYER + `board/getAllboards`, {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                },
            });
            if (response.data.success === true) {
                dispatch({
                    type: 'SET_BOARD',
                    payload: response.data.allboards,
                });
            }
        } catch (error) {
            console.log('Error in get all workspace ', error);
        }
    };
};

export const getTemplate = categoryId => {
    return async dispatch => {
        try {
            const response = await axios.get(
                KANBAN_CX_DEPLOYER + `board/getTemplates/${categoryId}`,
                {
                    headers: {
                        'x-access-token': localStorage.getItem('cxtoken'),
                    },
                }
            );
            if (response.data.success === true) {
                dispatch({
                    type: 'SET_TEMPLATE',
                    payload: response.data.allboards,
                });
            }
        } catch (error) {
            console.log('Error in get all workspace ', error);
        }
    };
};

export const getFormTemplate = categoryId => {
    return async dispatch => {
        try {
            const response = await axios.get(
                `${KANBAN_CX_DEPLOYER_FORM}form/getFormByCategoryId/${categoryId}`,
                {
                    headers: {
                        'x-access-token': localStorage.getItem('cxtoken'),
                    },
                }
            );

            dispatch({
                type: 'SET_TEMPLATE',
                payload: response.data.data,
            });
        } catch (error) {
            console.log('Error in get all workspace ', error);
        }
    };
};

export const getLabels = boardId => {
    return async dispatch => {
        try {
            const response = await axios.get(KANBAN_CX_DEPLOYER + `board/getLabels/${boardId}`, {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                },
            });
            console.log('get labels of baord ', response);

            if (response.data.success) {
                dispatch({
                    type: 'SET_SELECTED_BOARD',
                    payload: response.data.board,
                });
            } else {
                console.log('errrooooorrr', response.data.message);
            }
        } catch (error) {
            console.log('Error is', error.message);
        }
    };
};

export const createBoard = (inputValue, callback) => {
    return async dispatch => {
        try {
            const response = await axios.post(KANBAN_CX_DEPLOYER + `board/addboard`, inputValue, {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                },
            });
            if (response.data.success === true) {
                dispatch(getTemplate(inputValue.categoryId));
                callback(response);
            }
        } catch (error) {
            console.log('Error in add board', error);
        }
    };
};

export const createFormBoard = (inputValue, callback) => {
    return async dispatch => {
        try {
            const response = await axios.post(KANBAN_CX_DEPLOYER_FORM + `form/add`, inputValue, {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                },
            });
            if (response.data.success === true) {
                dispatch(getFormTemplate(inputValue.categoryId));
                callback(response);
            }
        } catch (error) {
            console.log('Error in add board', error);
        }
    };
};

export const editBoard = (boardId, payload, callback) => {
    return async dispatch => {
        try {
            const response = await axios.post(
                KANBAN_CX_DEPLOYER + `board/editBoard/${boardId}`,
                payload,
                {
                    headers: {
                        'x-access-token': localStorage.getItem('cxtoken'),
                    },
                }
            );
            if (response.data.success === true) {
                dispatch({
                    type: 'UPDATE_BOARD',
                    payload: {
                        boardId,
                        payload,
                    },
                });
                callback(response);
            }
        } catch (error) {
            console.log('Error in add user board', error);
        }
    };
};

export const editFormBoard = (boardId, payload, callback) => {
    return async dispatch => {
        try {
            const response = await axios.patch(
                KANBAN_CX_DEPLOYER_FORM + `form/edit/${boardId}`,
                payload,
                {
                    headers: {
                        'x-access-token': localStorage.getItem('cxtoken'),
                    },
                }
            );
            if (response.data.success === true) {
                dispatch({
                    type: 'UPDATE_BOARD',
                    payload: {
                        boardId,
                        payload,
                    },
                });
                callback(response);
            }
        } catch (error) {
            console.log('Error in add user board', error);
        }
    };
};

export const deleteFormTemplate = (formId, payload, callback) => {
    return async dispatch => {
        try {
            const response = await axios.delete(
                `${KANBAN_CX_DEPLOYER_FORM}form/delete/${formId}`,
                payload,
                {
                    headers: {
                        'x-access-token': localStorage.getItem('cxtoken'),
                    },
                }
            );
            if (response.data.success === true) {
                dispatch({
                    type: 'UPDATE_BOARD',
                    payload: {
                        boardId: formId,
                        payload,
                    },
                });
                callback(response);
            }
        } catch (error) {
            console.log('Error in add user board', error);
        }
    };
};

export const editLabelInBoard = labeldata => {
    return async dispatch => {
        try {
            const response = await axios.put(`${KANBAN_CX_DEPLOYER}board/editLabel`, labeldata, {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                },
            });
            if (response.data.success === true) {
                dispatch(getLabels(labeldata.boardid));
            }
        } catch (error) {
            console.log('Error in add user board', error);
        }
    };
};

export const deleteLabelInBoard = labeldata => {
    return async dispatch => {
        try {
            const response = await axios.put(`${KANBAN_CX_DEPLOYER}board/deleteLabel`, labeldata, {
                headers: {
                    'x-access-token': localStorage.getItem('cxtoken'),
                },
            });
            if (response.data.success === true) {
                dispatch(getLabels(labeldata.boardid));
            }
        } catch (error) {
            console.log('Error in add user board', error);
        }
    };
};

export const createNewLabelInBoard = (labeldata, workspaceId, callback) => {
    return async dispatch => {
        try {
            const userId = localStorage.getItem('user_id');
            const response = await axios.post(
                `${KANBAN_CX_DEPLOYER}board/createNewLabel`,
                labeldata,
                {
                    headers: {
                        'x-access-token': localStorage.getItem('cxtoken'),
                    },
                }
            );
            if (response.data.success) {
                dispatch(getLabels(labeldata.boardid));
            }
        } catch (error) {
            console.log('Error in add user board', error);
        }
    };
};

export const setSelectedBoard = data => {
    return async dispatch => {
        try {
            dispatch({
                type: 'SET_SELECTED_BOARD',
                payload: data,
            });
        } catch (error) {
            console.log('Error in get all workspace ', error);
        }
    };
};
