import React, { Component } from 'react';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import axios from 'axios';
import { CX_DEPLOYER } from '../../../services/url';
import { Link } from 'react-router-dom';
import './style.css';
// import Header from "../../../Header/Header";
// import { Colors } from "../../ideation/colors/Colors";
import { Button } from '@material-ui/core';
// import Footer from "./Footer";
import AddBucket from './AddBucket';
import EditBucket from './EditBucket';
import EditTask from './EditTask';
import AddTask from './AddTask';
import Settings from './Settings';
// import TabLists1 from "../../../Tabs/Tabs1";
import { backendUrl as url } from '../../backendUrl/url';
import { Chip, Avatar, Tooltip } from '@material-ui/core';
import { Face as FaceIcon, Today as DateIcon } from '@material-ui/icons';

const token = localStorage.getItem('cxtoken');
class MainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ideaTitle: '',
            //data
            buckets_array: [],
            tasks: {},
            bucketName: '',
            showBucketModal: false,
            remountVar: false,
            ideaId: null,
            newBucketRank: -1,

            //Helper Variables
            hasBeenFetched: false,
            bucket: '',
            priority: '',

            //Modal Show props.
            showAddModal: false,
            editModal: {},
            showEditModal: false,
            showSettings: false,
            showEditBucketModal: false,

            //Modal Assignee autocomplete array
            currentCompanyUsers: [],

            //Dragging variables for buckets.
            draggedBucketIndex: -1,
            targetBucketIndex: -1,
            isBucketDragged: false,
            isBucketEntered: false,

            //Dragging variables for tasks
            draggedTask: null,
            targetTask: null,
            isTaskDragged: false,
            isTaskEntered: false,
            draggedTaskTargetBucket: null,
            targetBucketForTask: null,

            autoSave: true,
        };
        this.saveData = this.saveData.bind(this);
    }
    smoothScroll() {
        window.scrollTo({
            top: document.body.scrollHeight,
            left: document.body.scrollWidth,
            behavior: 'smooth',
        });
    }
    saveData = async () => {
        this.setState({ showSettings: false });
        await axios
            .post(
                url + 'api/prefilledbuckets/add',
                {
                    buckets: Object.assign({}, this.state.buckets_array),
                    ideaId: this.state.ideaId,
                },
                {
                    headers: {
                        'x-access-token': token,
                    },
                }
            )
            .then(
                response => {
                    console.log(response.data);
                },
                error => {
                    console.log(error);
                    alert('Error. Can not Load Data.');
                }
            );
        await axios
            .post(
                url + 'api/prefilledtasks/add',
                { tasks: this.state.tasks, ideaId: this.state.ideaId },
                {
                    headers: {
                        'x-access-token': token,
                    },
                }
            )
            .then(
                response => {
                    console.log(response.data);
                },
                error => {
                    console.log(error);
                    alert('Error. Can not Load Data.');
                }
            );
        this.setState({ showSettings: false });
    };
    fetchAllData = () => {
        if (this.props.location) {
            const _id = this.props.location.params.subid;
            axios
                .get(url + 'api/prefilledbuckets/' + _id, {
                    headers: {
                        'x-access-token': token,
                    },
                })
                .then(
                    response => {
                        console.log(response.data);
                        this.setState({ newBucketRank: response.data.length + 1 });
                        this.setState({ buckets_array: response.data });
                    },
                    error => {
                        console.log(error);
                    }
                );

            axios
                .get(url + 'api/prefilledtasks/' + _id, {
                    headers: {
                        'x-access-token': token,
                    },
                })
                .then(response => {
                    console.log(response.data);
                    var tasks = response.data;
                    for (var i in tasks) {
                        if (tasks[i].start_date)
                            tasks[i].start_date = new Date(tasks[i].start_date);
                        if (tasks[i].due_date) tasks[i].due_date = new Date(tasks[i].due_date);
                    }

                    this.setState({ tasks: tasks, hasBeenFetched: true });
                });

            const fetchIdeasUrl = url + `api/user/ideas/${this.props.location.params.id}`;
            axios
                .get(fetchIdeasUrl, {
                    headers: {
                        'Access-Control-Allow-Headers': 'x-access-token',
                        'x-access-token': token,
                    },
                })
                .then(async res => {
                    await this.setState({ ideas: res.data });
                    console.log(this.state.ideas);
                    let index = res.data.findIndex(
                        idea => idea._id == this.props.location.params.subid
                    );
                    this.setState({ ideaTitle: res.data[index].ideaTitle });
                })
                .catch(err => {
                    console.log(err);
                });

            axios
                .get(url + 'api/user/mycompanyusers', {
                    headers: {
                        'Access-Control-Allow-Headers': 'x-access-token',
                        'x-access-token': token,
                    },
                })
                .then(({ data: users }) => {
                    this.setState({ currentCompanyUsers: users });
                })
                .catch(err => console.log(err));
        }
    };
    callEditBucket(bucket) {
        axios
            .put(
                `${url}api/prefilledbuckets/edit`,
                { bucket: bucket },
                {
                    headers: {
                        'x-access-token': token,
                    },
                }
            )
            .then(res => {
                console.log('Edited');
                this.setState({ showEditBucketModal: false });
                this.fetchAllData();
            })
            .catch(err => {
                console.log(err);
            });
    }
    async componentDidMount() {
        if (this.props.location) {
            this.setState({ ideaId: this.props.location.params.subid });
        }
        this.setState({ remountVar: false });
        this.setState({
            autoSave: window.localStorage.getItem('autoSave') === 'true' ? true : false,
        });
        this.fetchAllData();
    }

    orderTask = async (task, b_Id) => {
        console.log('Bucket id ' + b_Id);
        var tasks = this.state.tasks;
        var bucketId = task.bucket._id ? task.bucket._id : task.bucket;
        if (b_Id) {
            bucketId = b_Id;
        }
        var rank = -1;
        //Find new rank for new task
        let tasks_ = Object.keys(tasks).filter(id => {
            return tasks[id]['bucket']['_id'] === bucketId;
        });
        if (tasks_.length === 0) {
            rank = 1;
        } else {
            let ranks = tasks_.map(bucket => tasks[bucket].rank);
            rank = Math.max.apply(0, ranks) + 1;
        }
        return rank;
    };

    // This function is used to add a new task
    addTask = async (task, fileData) => {
        task.rank = await this.orderTask(task);
        if (fileData !== null) {
            axios
                .post(`${url}api/user/tasks/files`, fileData, {
                    headers: {
                        'Allow-Access-Control': 'x-access-token',
                        'x-access-token': token,
                    },
                })
                .then(async res => {
                    console.log(res.data);
                    task.attachments = res.data.filenames;
                    //Call Add task API
                    axios
                        .post(
                            `${url}api/prefilledtasks/add`,
                            { task: task },
                            {
                                headers: {
                                    'x-access-token': token,
                                },
                            }
                        )
                        .then(res => {
                            console.log(res.data);
                            this.setState({ showAddModal: false });
                            this.fetchAllData();
                        })
                        .catch(err => {
                            console.log(err);
                        });
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            axios
                .post(
                    `${url}api/prefilledtasks/add`,
                    { task: task },
                    {
                        headers: {
                            'x-access-token': token,
                        },
                    }
                )
                .then(res => {
                    console.log(res.data);
                    this.setState({ showAddModal: false });
                    this.fetchAllData();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    editTask(task) {
        this.setState({ editModal: task });
        this.setState({
            name: task.name,
            description: task.description,
            bucket: task.bucket,
            assignees: task.assignees,
            start_date: task.start_date,
            due_date: task.due_date,
            progress: task.progress,
            label_color: task.label_color,
            priority: task.priority,
            showEditModal: true,
            attachments: task.attachments,
        });
    }

    saveEditTask = async (task, fileData) => {
        //task.rank = await this.orderTask(task, "edit")
        if (fileData !== null) {
            axios
                .post(`${url}api/user/tasks/files`, fileData, {
                    headers: {
                        'Allow-Access-Control': 'x-access-token',
                        'x-access-token': token,
                    },
                })
                .then(async res => {
                    console.log(res.data);
                    await res.data.filenames.forEach(file => {
                        task.attachments.push(file);
                    });
                    //Call Edit task API
                    axios
                        .put(
                            `${url}api/prefilledtasks/edit`,
                            { task: task },
                            {
                                headers: {
                                    'x-access-token': token,
                                },
                            }
                        )
                        .then(res => {
                            console.log(res.data);
                            this.fetchAllData();
                            this.setState({ showEditModal: false });
                        })
                        .catch(err => {
                            console.log(err);
                        });
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            axios
                .put(
                    `${url}api/prefilledtasks/edit`,
                    { task: task },
                    {
                        headers: {
                            'x-access-token': token,
                        },
                    }
                )
                .then(res => {
                    console.log(res.data);
                    this.fetchAllData();
                    this.setState({ showEditModal: false });
                })
                .catch(err => {
                    console.log(err);
                });
        }

        //var tasks = this.state.tasks;
        //tasks[task.id] = task;
        //this.setState({ tasks: tasks, showEditModal: false });

        // if (fileForm.get("attachments")) {
        //   await this.saveData()
        //   fileForm.append("id", task.id)
        //   const config = {
        //     headers: {
        //       'Content-Type': 'multipart/form-data'
        //     }
        //   }
        //   axios.post(url + 'files', fileForm, config).then((res) => {
        //     console.log(res)
        //     this.fetchAllData()
        //   }).catch(err => {
        //     console.log(err)
        //   })
        // } else {
        //   await this.saveData()
        //   this.fetchAllData()
        // }
    };
    deleteTask = task => {
        console.log(task);
        axios
            .delete(`${url}api/prefilledtasks/delete/`, {
                data: { task: task },
                headers: {
                    'x-access-token': token,
                },
            })
            .then(res => {
                if (res.data.message === 'Deleted') {
                    console.log(res.data);
                    this.fetchAllData();
                } else {
                    console.log(res.data);
                }
                this.setState({ showEditModal: false });
            })
            .catch(err => {
                console.log(err);
            });
    };
    //bucket
    deleteBucket = bucket => {
        axios
            .delete(`${url}api/prefilledbuckets/delete`, {
                data: { bucket: bucket },
                headers: {
                    'x-access-token': token,
                },
            })
            .then(res => {
                if (res.data.message === 'Deleted') {
                    this.fetchAllData();
                } else {
                    console.log(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };
    async onRoutechange(project_id, e) {
        e.preventDefault();
        const tokenvalue = localStorage.getItem('cxtoken');
        try {
            const response1 = await axios.get(
                `${CX_DEPLOYER}subproject/${project_id}`,
                (axios.defaults.headers.common['x-access-token'] = tokenvalue),
                {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                    },
                }
            );
            console.log('Sub_project_data', response1.data[0].mapType);
            if (response1.status === 200) {
                let sub_id = response1.data[0]._id;
                let maptype = response1.data[0].mapType;
                if (maptype == 'Customer') this.props.history.push(`/cjm/${project_id}/${sub_id}`);
                else if (maptype == 'Business') {
                    console.log(sub_id);
                    this.props.history.push(`/bmc/${project_id}/${sub_id}`);
                } else if (maptype == 'Persona') {
                    console.log(sub_id);
                    this.props.history.push(`/Persona/${project_id}/${sub_id}`);
                } else if (maptype == 'Empathy') {
                    this.props.history.push(`/empathy/${project_id}/${sub_id}`);
                }
            }
        } catch (error) {
            console.log(error);

            this.props.history.push(`/MapType:${project_id}`);
        }
    }

    // This is the render function
    render() {
        if (this.state.ideaId === null) {
            return <div>No Idea specified</div>;
        }
        return (
            <div className="App" style={{ overflow: 'auto' }}>
                <div
                    className="projectnamenavbar"
                    style={{ position: 'fixed', top: 0, zIndex: 998 }}
                >
                    <div>
                        <div>{this.state.project_name}</div>
                    </div>
                    <div className="projectnamenavbar-links"></div>
                </div>
                {/* ----------------------------------------------Container-------------------------------------------- */}
                <Container className="board" fluid>
                    {/* ---------------------Add Task Button------------------- */}
                    {/* <Row className="mt-2 mb-2">
            <Col></Col>
            <Col>
              <Button
                variant="outline-dark"
                className="float-right mr-4"
                onClick={() => this.setState({ showSettings: true })}
              >
                Settings<i className="fas fa-cog  settings-icon ml-2"></i>
              </Button>
            </Col>
          </Row> */}

                    {/* ---------------------Task Lists------------------- */}

                    <Row className="flex-row flex-nowrap bucket-container" fluid="true">
                        {/* ---------------------Buckets------------------- */}
                        {this.state.buckets_array
                            .sort((a, b) => {
                                return parseInt(a.rank) - parseInt(b.rank);
                            })
                            .map((bucket, idx) => (
                                <div
                                    key={idx}
                                    id={bucket._id}
                                    className="bucket"
                                    draggable={true}
                                    //All drag event handlers for buckets below
                                    onDragStart={() => {
                                        this.setState({ isBucketDragged: true });
                                        this.setState({
                                            draggedBucketIndex:
                                                this.state.buckets_array.indexOf(bucket),
                                        });
                                    }}
                                    onDragOver={() => {
                                        if (bucket !== this.state.targetBucketForTask) {
                                            this.setState({ targetBucketForTask: bucket });
                                        }
                                    }}
                                    onDragEnter={async () => {
                                        if (this.state.isBucketDragged) {
                                            if (
                                                this.state.draggedBucketIndex !==
                                                this.state.buckets_array.indexOf(bucket)
                                            ) {
                                                this.setState({ isBucketEntered: true });
                                            }
                                            await this.setState({
                                                targetBucketIndex:
                                                    this.state.buckets_array.indexOf(bucket),
                                            });
                                        } else if (this.state.isTaskDragged) {
                                            this.setState({ draggedTaskTargetBucket: bucket });
                                        }
                                    }}
                                    onDragEnd={async e => {
                                        if (this.state.isBucketDragged) {
                                            if (this.state.isBucketEntered) {
                                                const buckets = this.state.buckets_array;
                                                const draggedRank =
                                                    buckets[this.state.draggedBucketIndex].rank;
                                                const targetRank =
                                                    buckets[this.state.targetBucketIndex].rank;
                                                buckets[this.state.draggedBucketIndex].rank =
                                                    targetRank;
                                                buckets[this.state.targetBucketIndex].rank =
                                                    draggedRank;
                                                this.setState({ buckets_array: buckets });
                                                axios
                                                    .put(
                                                        `${url}api/prefilledbuckets/bucketswap`,
                                                        {
                                                            bucket1:
                                                                buckets[
                                                                    this.state.draggedBucketIndex
                                                                ],
                                                            bucket2:
                                                                buckets[
                                                                    this.state.targetBucketIndex
                                                                ],
                                                        },
                                                        {
                                                            headers: {
                                                                'x-access-token': token,
                                                            },
                                                        }
                                                    )
                                                    .then(res => {
                                                        console.log(res.data);
                                                        this.fetchAllData();
                                                        this.setState({
                                                            draggedBucketIndex: -1,
                                                            targetBucketIndex: -1,
                                                            isBucketDragged: false,
                                                        });
                                                    })
                                                    .catch(err => {
                                                        console.log(err);
                                                    });
                                            }
                                        } else if (
                                            this.state.isTaskDragged &&
                                            this.state.isTaskEntered
                                        ) {
                                            const tasks = this.state.tasks;
                                            console.log(this.state.draggedTask.bucket);
                                            console.log(this.state.targetBucketForTask);
                                            if (
                                                this.state.draggedTask.bucket._id !==
                                                this.state.targetBucketForTask._id
                                            ) {
                                                let rank = await this.orderTask(
                                                    this.state.draggedTask,
                                                    this.state.targetBucketForTask._id
                                                );
                                                tasks[this.state.draggedTask._id].bucket =
                                                    this.state.targetBucketForTask;
                                                tasks[this.state.draggedTask._id].rank = rank;

                                                axios
                                                    .put(
                                                        `${url}api/prefilledtasks/edit`,
                                                        { task: tasks[this.state.draggedTask._id] },
                                                        {
                                                            headers: {
                                                                'x-access-token': token,
                                                            },
                                                        }
                                                    )
                                                    .then(res => {
                                                        console.log(res.data);
                                                        this.fetchAllData();
                                                    })
                                                    .catch(err => {
                                                        console.log(err);
                                                    });
                                            } else {
                                                const tasks = this.state.tasks;
                                                const draggedRank = this.state.draggedTask.rank;
                                                console.log(this.state.draggedTask);
                                                console.log(this.state.targetTask);
                                                tasks[this.state.draggedTask._id].rank =
                                                    this.state.targetTask.rank;
                                                tasks[this.state.targetTask._id].rank = draggedRank;

                                                axios
                                                    .put(
                                                        `${url}api/prefilledtasks/taskswap`,
                                                        {
                                                            task1: tasks[
                                                                this.state.draggedTask._id
                                                            ],
                                                            task2: tasks[this.state.targetTask._id],
                                                        },
                                                        {
                                                            headers: {
                                                                'x-access-token': token,
                                                            },
                                                        }
                                                    )
                                                    .then(res => {
                                                        console.log(res.data);
                                                        this.fetchAllData();
                                                        this.setState({
                                                            isTaskDragged: false,
                                                            isTaskEntered: false,
                                                            targetTask: null,
                                                            draggedTask: null,
                                                            targetBucketForTask: null,
                                                        });
                                                    })
                                                    .catch(err => {
                                                        console.log(err);
                                                    });
                                            }
                                            this.setState({ tasks: tasks });
                                        }
                                    }}
                                >
                                    <div
                                        className="paper-list"
                                        id={idx + 'bc'}
                                        style={{ width: '350px' }}
                                    >
                                        <div className="bucket-title" style={{ padding: '5px' }}>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <h6
                                                        id={bucket._id}
                                                        style={{ margin: '0' }}
                                                        className="float-left ml-2"
                                                    >
                                                        {bucket.name}
                                                    </h6>
                                                </div>
                                                <div className="d-flex ml-3 bucket-buttons">
                                                    <i
                                                        style={{ cursor: 'pointer' }}
                                                        className="fas fa-trash float-right mr-2 bucket-edit" //Delete Bucket
                                                        onClick={() => {
                                                            if (
                                                                window.confirm(
                                                                    'Are you sure to delete lane?'
                                                                )
                                                            ) {
                                                                this.deleteBucket(bucket);
                                                            }
                                                        }}
                                                    ></i>
                                                    <i
                                                        style={{ cursor: 'pointer' }}
                                                        className="fas fa-pen float-right mr-3 bucket-edit"
                                                        onClick={() =>
                                                            this.setState({
                                                                showEditBucketModal: true,
                                                                bucket: bucket,
                                                            })
                                                        }
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>

                                        {/* ====== Displaying the task cards in bucket ============*/}
                                        {Object.values(this.state.tasks)
                                            .sort((a, b) => {
                                                return parseInt(a.rank) - parseInt(b.rank);
                                            })
                                            .map((task, idx) =>
                                                task.bucket._id === bucket._id ? (
                                                    <Card
                                                        key={idx}
                                                        style={{
                                                            maxWidth: '100%',
                                                        }}
                                                        id={task._id}
                                                        className={'mt-2 task-card'}
                                                        draggable={true}
                                                        onDragStart={e => {
                                                            e.stopPropagation();
                                                            this.setState({
                                                                draggedTask: task,
                                                                isTaskDragged: true,
                                                            });
                                                        }}
                                                        onDragEnter={e => {
                                                            console.log('Task entered');
                                                            if (this.state.draggedTask !== task) {
                                                                console.log(task);
                                                                this.setState({
                                                                    targetTask: task,
                                                                    isTaskEntered: true,
                                                                });
                                                            }
                                                            // Must be set to true even if task is same
                                                            this.setState({ isTaskEntered: true });
                                                        }}
                                                        onDragEnd={e => {
                                                            console.log('Task drag Ended');
                                                        }}
                                                    >
                                                        <Card.Body
                                                            className="task-card-body"
                                                            onClick={() => this.editTask(task)}
                                                        >
                                                            <Card.Text
                                                                style={{
                                                                    whiteSpace: 'break-spaces',
                                                                }}
                                                            >
                                                                <svg
                                                                    style={{
                                                                        color: task.label_color,
                                                                    }}
                                                                    className="bi bi-bookmark-fill"
                                                                    width="1em"
                                                                    height="1em"
                                                                    viewBox="0 0 16 16"
                                                                    fill="currentColor"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M3 3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12l-5-3-5 3V3z"
                                                                    />
                                                                </svg>
                                                                {task.name}
                                                            </Card.Text>

                                                            {/*============ More Details of tasks================= */}
                                                            <Card.Text>
                                                                <Container className="d-flex flex-wrap">
                                                                    {task.assignees.map(
                                                                        assignee => (
                                                                            <div className="p-2">
                                                                                <Tooltip
                                                                                    title={
                                                                                        assignee.username
                                                                                    }
                                                                                    aria-label={
                                                                                        assignee.username
                                                                                    }
                                                                                >
                                                                                    <Avatar>
                                                                                        <small>
                                                                                            {assignee.username
                                                                                                ? (function () {
                                                                                                      const nameArray =
                                                                                                          assignee.username.split(
                                                                                                              ' '
                                                                                                          );
                                                                                                      return (
                                                                                                          assignee.username[0].toUpperCase() +
                                                                                                          nameArray[
                                                                                                              nameArray.length -
                                                                                                                  1
                                                                                                          ][0].toUpperCase()
                                                                                                      );
                                                                                                  })()
                                                                                                : ''}
                                                                                        </small>
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </Container>
                                                            </Card.Text>
                                                            <Card.Text>
                                                                <Container className="d-flex flex-wrap">
                                                                    {task.start_date && (
                                                                        <div className="p-2">
                                                                            <Chip
                                                                                icon={<DateIcon />}
                                                                                color="primary"
                                                                                label={new Date(
                                                                                    task.start_date
                                                                                )
                                                                                    .toDateString()
                                                                                    .substring(
                                                                                        3,
                                                                                        new Date(
                                                                                            task.start_date
                                                                                        ).toDateString()
                                                                                            .length -
                                                                                            5
                                                                                    )}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {task.due_date && (
                                                                        <div className="p-2">
                                                                            <Chip
                                                                                icon={<DateIcon />}
                                                                                color="secondary"
                                                                                label={new Date(
                                                                                    task.due_date
                                                                                )
                                                                                    .toDateString()
                                                                                    .substring(
                                                                                        3,
                                                                                        new Date(
                                                                                            task.due_date
                                                                                        ).toDateString()
                                                                                            .length -
                                                                                            5
                                                                                    )}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Container>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                ) : null
                                            )}
                                        {/* <Card
                    className="mt-2 task-card"
                  >
                    <Card.Body>
                      <Card.Text className="float-left mb-0 mt-1"></Card.Text>
                    </Card.Body>
                  </Card> */}

                                        <Alert
                                            className="add-task"
                                            onClick={() =>
                                                this.setState({
                                                    showAddModal: true,
                                                    bucket: bucket,
                                                })
                                            }
                                        >
                                            <Row>
                                                <Col align="center" className="add-task-content">
                                                    + Add another task
                                                </Col>
                                            </Row>
                                        </Alert>
                                    </div>
                                </div>
                            ))}
                        {this.state.hasBeenFetched ? (
                            <Col id="i-add-bucket">
                                <div
                                    className="add-bucket"
                                    onClick={() => this.setState({ showBucketModal: true })}
                                >
                                    + Add Lane
                                </div>
                            </Col>
                        ) : (
                            <div></div>
                        )}
                    </Row>
                </Container>

                {/* ---------------------Pop Up Modal for Adding NEW Bucket------------------- */}
                <AddBucket
                    visibility={this.state.showBucketModal}
                    toggleVisibility={() => {
                        this.setState({ showBucketModal: !this.state.showBucketModal });
                    }}
                    buckets={this.state.buckets_array}
                    ideaId={this.state.ideaId}
                    saveFunction={this.saveBucket}
                    refetch={this.fetchAllData}
                    token={token}
                    rank={this.state.newBucketRank}
                    hideModal={() => {
                        this.setState({ showBucketModal: false });
                    }}
                />

                {/* ---------------------Pop Up Modal for EDITING Bucket------------------- */}
                <EditBucket
                    visibility={this.state.showEditBucketModal}
                    bucket={this.state.bucket}
                    saveFunction={bucket => {
                        this.callEditBucket(bucket);
                    }}
                    hideModal={() => {
                        this.setState({ showEditBucketModal: false });
                    }}
                />

                {/* ---------------------Pop Up Modal for EDITING TASK details------------------- */}
                <EditTask
                    currentCompanyUsers={this.state.currentCompanyUsers}
                    visibility={this.state.showEditModal}
                    buckets={this.state.buckets_array}
                    taskData={this.state.editModal}
                    saveFunction={this.saveEditTask}
                    deleteFunction={this.deleteTask}
                    hideModal={() => this.setState({ showEditModal: false })}
                    refetch={this.fetchAllData}
                />

                {/* ---------------------Pop Up Modal for ADDING NEW TASK details------------------- */}

                <AddTask
                    currentCompanyUsers={this.state.currentCompanyUsers}
                    visibility={this.state.showAddModal}
                    bucket={this.state.bucket}
                    buckets={this.state.buckets_array}
                    ideaId={this.state.ideaId}
                    // callingBucket={this.state.bucketCallingAddTask}
                    saveFunction={this.addTask}
                    hideModal={() => this.setState({ showAddModal: false })}
                />

                {/* ---------------------Pop Up Modal for SETTINGS------------------- */}

                <Settings
                    visibility={this.state.showSettings}
                    autoSave={this.state.autoSave}
                    toggleAutoSave={checked => this.setState({ autoSave: checked })}
                    saveFunction={this.saveData}
                    hideModal={() => this.setState({ showSettings: false })}
                />
                {/*<div className="footer d-flex" style={{ height: "50px" }}>
          <div style={{ width: "79%" }}>
            <TabLists1
              params={this.props.location.params}
              history={this.props.history}
              data={this.state.tabsdata}
              deleteTab={this.deleteTab}
              tabShift={this.tabShift}
              setTab={this.setTab}
              pages="ideation"
              type="kanban"
              ideaTitle={this.state.ideaTitle}
            />
          </div>
          <div
            className="idea-button"
            style={{
              width: "9%",
              height: "72px",

              overflowY: "hidden",
            }}
          > 
            <div className="new-idea-container">
              <div>
                <Link to={`/ideation/${this.props.location.params.id}`}>
                  {" "}
                  <Button
                    className="ideate-button" 
                    variant="contained"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: Colors.darkOrange,
                      color: "white",
                    }}
                  >
                    View Ideas
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="idea-button"
            style={{
              width: "11%",
              height: "72px",

              overflowY: "hidden",
            }}
          >
            <div className="new-idea-container">
              <div>
                {" "}
                <Button
                  className="ideate-button"
                  variant="contained"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: Colors.darkOrange,
                    color: "white",
                  }}
                  onClick={(e) =>
                    this.onRoutechange(this.props.location.params.id, e)
                  }
                >
                  View Projects
                </Button>
              </div>
            </div>
          </div>
                </div>*/}
            </div>
        );
    }
}

export default MainPage;
