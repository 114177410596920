import React, { Component } from 'react';
import { CardLane, BubbleLane, ImageLane, FileLane, PhaseLane, TextLane } from './Components';

import withRouter from '../../withRouter'
import Lines from "./Components/Lines/Lines";
import "./CJM.css";
import { Link } from "react-router-dom";
import Axios from "../../services/Axios";
import AddLane from "../../Components/AddLane/AddLane";
import { Loader } from "../../Components";
import { v4 as uuid } from "uuid";
import LaneModals from "../../Components/Modal/LaneModal";
import ExpandCollapse from "react-expand-collapse";
import ReactDragListView from "react-drag-listview/lib/index.js";

Axios.defaults.headers.common["x-access-token"] = localStorage.getItem("cxtoken");

const zoomArr = [
  "50%",
  "75%",
  "85%",
  "90%",
  "100%",
  " 120%",
  "130%",
  "150%",
  "180%",
];

class CJM extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      cardWidth: 5,
      laneType: "",
      card_limit: [0, 1, 2, 3, 4],
      indexofArr: 4,
      isLoading: true,
      tabsdata: [],
      joined: false,
      expand: true,
      project_name: "",
      laneType: "",
      show: false,
      showEditModal: false
    };
  }

  componentDidMount = () => {
    const tokenvalue = localStorage.getItem('cxtoken');
    const Sub_project_id = this.props.params.subid;

    Axios.get(`/premap/customermap`).then((response) => {
      let data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === Sub_project_id) {
          this.setState({ project_name: data[i].name });
        }
      }
    });

    Axios.get(`/premap/customermap/lanes/${Sub_project_id}`)
      .then((res) => {
        console.log(res.data, "This is Data");
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].laneType !== "line") {
            if (res.data[i].nodes?.length - 1 >= 0) {
              if (
                res.data[i].nodes?.[res.data[i].nodes.length - 1]?.gridID >=
                this.state.card_limit[this.state.card_limit.length - 1] ?? -1
              ) {
                for (
                  var j = this.state.card_limit.length;
                  j <=
                  (parseInt(res.data[i].nodes?.[res.data[i].nodes.length - 1]?.gridID) ?? -1) + 1;
                  j++
                ) {
                  this.state.card_limit.push(j);
                }
                this.setState(
                  { cardWidth: this.state.card_limit.length - 1 },
                  () => {
                    console.log("cardWidth", this.state.cardWidth);
                  }
                );
              }
            }
          }
        }
        res.data.sort(function (a, b) {
          return parseInt(a.laneGridNo) - parseInt(b.laneGridNo);
        });
        console.log("data", res.data);
        this.setState({ data: res.data, isLoading: false });
      })
      .catch((err) => console.log(err));


    this.expandCollapse();
  };

  componentDidUpdate() {
    this.expandCollapse();
  }

  zoomin = () => {
    var element = document.querySelector(".cjm");
    let value = element.getBoundingClientRect().width / element.offsetWidth;
    if (this.state.indexofArr < zoomArr.length - 1) {
      console.log("Reached");
      this.state.indexofArr += 1;
      value = zoomArr[this.state.indexofArr];
      element.style["zoom"] = value;
    }
  };

  expandCollapse = () => {
    var Expandcollapse = document.getElementsByClassName(
      "react-expand-collapse__button"
    );
    console.log(Expandcollapse.length);
    for (var i = 0; i < Expandcollapse.length; i++) {
      console.log(Expandcollapse[i].getAttribute("aria-expanded"));
      if (Expandcollapse[i].getAttribute("aria-expanded") === "true") {
        const newHTML = '<i class="fa fa-minus" aria-hidden="true"></i>';
        Expandcollapse[i].innerHTML = newHTML;
        Expandcollapse[i].title = "Collapse";
      } else if (Expandcollapse[i].getAttribute("aria-expanded") === "false") {
        const newHTML = '<i class="fa fa-plus" aria-hidden="true"></i>';
        Expandcollapse[i].innerHTML = newHTML;
        Expandcollapse[i].title = "Expand";
      }
    }
    console.log(Expandcollapse);
  };

  zoomout = () => {
    var element = document.querySelector(".cjm");
    let value = element.getBoundingClientRect().width / element.offsetWidth;
    if (this.state.indexofArr > 0) {
      this.state.indexofArr -= 1;
      value = zoomArr[this.state.indexofArr];
      element.style["zoom"] = value;
    }
  };

  shiftByOne = index => {
    const { data } = this.state;
    let idx = parseInt(index);
    console.log(idx);
    for (const lane of data) {
      const { nodes } = lane;
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].gridID = parseInt(nodes[i].gridID);
        if (nodes[i].gridID == idx || nodes[i].gridID > idx) {
          nodes[i].gridID = nodes[i].gridID + 1;
        }
      }
      lane.nodes = nodes;
    }
    console.log('grid data', data);
    return data;
  };
  shiftLane = (laneid, shift) => {
    const { data } = this.state;
    const laneidx = data.findIndex((lane) => lane._id === laneid);

    if (laneidx === -1) {
      console.error("Lane not found.");
      return;
    }

    if (
      (shift === -1 && laneidx - shift < 0) ||
      (shift === 1 && laneidx + shift >= data.length)
    ) {
      console.error("Invalid shift value, operation cannot be performed.");
      return;
    }

    // Make a copy of data to avoid mutation
    const updatedData = [...data];

    try {
      const lane = updatedData[laneidx];
      const shiftlane = updatedData[laneidx + shift];
      const temp = lane.laneGridNo;
      lane.laneGridNo = shiftlane.laneGridNo;
      shiftlane.laneGridNo = temp;
      updatedData[laneidx + shift] = lane;
      updatedData[laneidx] = shiftlane;

      const Sub_project_id = this.props.params.subid;
      const lanes = { lanes: updatedData };
      console.log(this.props, "THis is Props")
      Axios.put(`/premap/customermap/lanes/editgrids/${Sub_project_id}/${laneid}`, lanes)
        .then((res) => {
          this.setState({ data: updatedData });
        })
        .catch((err) => console.error("Error while updating lanes:", err));
    } catch (error) {
      console.error("Error while reordering lanes:", error);
    }
  };

  addNode = (idx, _id, newNode, insertFromMiddle = false) => {
    let { data, cardWidth } = this.state;
    let laneIdx = data.findIndex((lane) => lane._id === _id);
    let lane = data[laneIdx];
    let nodes = lane.nodes;
    if (idx === cardWidth) cardWidth = idx + 1;
    if (insertFromMiddle && nodes[nodes.length - 1].gridID === cardWidth - 1) {
      let lastGridID = parseInt(nodes[nodes.length - 1].gridID);
      cardWidth = lastGridID + 2;
    }
    const existingNode = nodes.find((node) => parseInt(node.gridID) === idx);
    if (existingNode) {

    }
    laneIdx = data.findIndex((lane) => lane._id === _id);
    lane = data[laneIdx];
    nodes = lane.nodes;
    console.log(newNode, "This is Node")
    newNode.gridID = idx;
    nodes.push(newNode);
    data[laneIdx].nodes = nodes;
    const lanes = { lanes: data };
    Axios.put(
      `/premap/customermap/lanes/editgrids/${this.props.params.subid}/${_id}`,
      newNode
    )
      .then((res) => {
        this.componentDidMount()
      })
      .catch((err) => console.log(err));
    if (lane.nodes.length > cardWidth) cardWidth = nodes.length;

    this.setState({ cardWidth });
  };


  getCardWidth = () => {
    return this.state.card_limit[this.state.card_limit.length - 1];
  };

  _onDragEnd(fromIndex, toIndex) {
    console.log(fromIndex, toIndex);
    let { data } = this.state;
    let dragLane = data[fromIndex];
    data.splice(fromIndex, 1);
    data.splice(toIndex, 0, dragLane);
    this.setState({ data });

    console.log("Data", data);

    const lanes = { lanes: data };

    Axios.put(
      `/premap/customermap/lanes/editgrids/${this.props.params.subid}`,
      lanes
    )
      .then((res) => {
        this.setState({ data });
        console.log(res);
      })
      .catch((err) => console.log(err));
  }
  addLane = (html, color, icon) => {
    const newLane = {
      laneType: this.state.laneType,
      laneName: html,
      color: color,
      icon: icon,
      laneGridNo: this.state.data.length,
      date: Date(Date.now()),
    };
    Axios.post(
      `/premap/customermap/lanes/add/${this.props.params.subid}`,
      newLane
    )
      .then((res) => {
        console.log(res);

        this.state.data.push(res.data);
        this.setState({ data: this.state.data }, () => {
          console.log(this.state.data, res.data);
        });
      })
      .catch((err) => console.log(err));
  };
  _onCardDrop = (laneId, idx, card, e) => {
    e.preventDefault();

    let cardCopy = { ...card };
    let { data, cardWidth } = this.state;
    cardCopy._id = uuid();
    let lane = data.find((lane) => lane._id === laneId);

    // Check if lane and lane.nodes exist before accessing nodes
    if (lane && lane.nodes) {
      let nodes = lane.nodes;
      if (idx === cardWidth) cardWidth = idx + 1;

      const existingNode = nodes.find((node) => parseInt(node.gridID) === idx);
      if (existingNode) {
        data = this.shiftByOne(idx);
        console.log(data);
        const lanes = { lanes: data };
        Axios.put(
          `/premap/customermap/lanes/editgrids/${this.props.params.subid}`,
          lanes
        )
          .then((res) => {
            this.setState({ data });
            console.log(res);
          })
          .catch((err) => console.log(err));
      }
      lane = data.find((lane) => lane._id === laneId);
      nodes = lane.nodes;
      cardCopy.gridID = idx;

      nodes.push(cardCopy);
      data.forEach((lane) => {
        if (lane._id === laneId) lane.nodes = nodes;
      });
      const lanes = { lanes: data };

      Axios.put(
        `/premap/customermap/lanes/editgrids/${this.props.params.subid}`,
        lanes
      )
        .then((res) => {
          this.setState({ data, cardWidth });
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };
  editnode = (idx, _id, card_id, newNode) => {
    let { data } = this.state;
    let laneIdx = data.findIndex(lane => lane._id === _id);
    let lane = data[laneIdx];
    console.log(newNode, "This node Edited")
    Object.assign(newNode, { projectid: this.props.params.subid })
    Axios.put(`/premap/customermap/lanes/node/edit/${_id}`, newNode)
      .then(res => {
        lane = res.data;
        console.log('response', res);
        data[laneIdx] = lane;
        this.setState({ data }, () => {
          console.log('edited data', res.data);
        });
      })
      .catch(err => console.log(err));
  };
  editImage = (idx, _id, laneType, newNode) => {
    let { data } = this.state;
    newNode.append('projectid', this.props.params.subid);
    let laneIdx = data.findIndex(lane => lane._id === _id);
    let lane = data[laneIdx];
    console.log('editednode', newNode);
    Axios.put(`/premap/customermap/lanes/${laneType}/edit/${_id}`, newNode)
      .then(res => {
        lane = res.data;
        console.log('response', res);
        data[laneIdx] = lane;
        this.setState({ data }, () => {
          console.log('edited data', res.data);
        });
      })
      .catch(err => console.log(err));
  };

  deleteNode = (_id, laneid, laneType) => {
    if (laneType == 'image' || laneType == 'file') {
      let body = {};

      if (laneType == 'image') body = { imageid: _id, projectId: this.props.params.subid };
      else if (laneType == 'file') body = { fileid: _id, projectId: this.props.params.subid };
      console.log(body);
      Axios.post(`/premap/customermap/lanes/${laneType}/delete/${laneid}`, body).then(
        res => {
          const { data } = this.state;
          let laneIdx = data.findIndex(lane => lane._id === laneid);
          let lane = data[laneIdx];
          let nodeIdx = lane.nodes.findIndex(node => node._id === _id);
          lane.nodes.splice(nodeIdx, 1);
          console.log('response', res);
          data[laneIdx] = lane;

          this.setState({ data }, () => { });
        }
      );
    } else {
      Axios.delete(
        `/premap/customermap/lanes/node/delete/${laneType}/${this.props.params.subid}/${laneid}/${_id}`
      )
        .then(res => {
          const { data } = this.state;
          let laneIdx = data.findIndex(lane => lane._id === laneid);
          let lane = data[laneIdx];

          lane = res.data;
          console.log('response', res);
          data[laneIdx] = lane;

          this.setState({ data }, () => { });
        })
        .catch(err => console.log(err));
    }
  };
  updateShowEditModal = value => {
    this.setState({ showEditModal: value });
  };
  deleteLane = (laneid, laneType) => {
    Axios.delete(
      `/premap/customermap/lanes/delete/${laneType}/${this.props.params.subid}/${laneid}`
    )
      .then(res => {
        const { data } = this.state;
        let laneIdx = data.findIndex(lane => lane._id === laneid);
        data.splice(laneIdx, 1);
        this.setState({ data }, () => { });
      })
      .catch(err => console.log(err));
  };
  expand = (lane, expanded) => {
    const newlane = {
      _id: lane._id,
      laneType: lane.laneType,
      laneName: lane.laneName,
      icon: lane.icon,
      color: lane.color,
      date: Date(Date.now()),
      laneGridNo: lane.laneGridNo,
      expand: expanded,
    };

    this.editLane(lane._id, newlane);
  };
  editLane = (_id, newlane) => {
    let projectid = this.props.params.subid
    newlane.projectid = projectid;
    let { data } = this.state;
    let laneIdx = data.findIndex((lane) => lane._id === _id);
    let lane = data[laneIdx];

    Axios.put(`/premap/customermap/lanes/edit/`, newlane)
      .then((res) => {
        lane = res.data;
        data[laneIdx] = lane;

        this.setState({ data });
      })
      .catch((err) => console.error(err));
  };

  removeCard = (laneId, cardId) => {
    console.log(laneId, cardId);
    let { data } = this.state;
    const lane = data.find((lane) => lane._id === laneId);

    if (lane.nodes) {
      const nodes = lane.nodes.filter((node) => node._id !== cardId);
      lane.nodes = nodes;
      this.setState({ data });

      const lanes = { lanes: data };
      Axios.put(
        `/premap/customermap/lanes/editgrids/${this.props.params.subid}`,
        lanes
      )
        .then((res) => {
          this.setState({ data });
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };


  moveCard = (fromLaneId, toLaneId, cardId, atIndex) => {
    let { data } = this.state;
    const fromLane = data.find((lane) => lane._id === fromLaneId);
    const toLane = data.find((lane) => lane._id === toLaneId);

    if (fromLane.nodes && toLane.nodes) { // Check if both 'fromLane.nodes' and 'toLane.nodes' exist
      const cardToMove = fromLane.nodes.find((node) => node._id === cardId);

      if (cardToMove) { // Check if 'cardToMove' exists
        fromLane.nodes = fromLane.nodes.filter((node) => node._id !== cardId);
        toLane.nodes.splice(atIndex, 0, cardToMove);
        this.setState({ data });

        const lanes = { lanes: data };
        Axios.put(
          `/premap/customermap/lanes/editgrids/${this.props.params.subid}`,
          lanes
        )
          .then((res) => {
            this.setState({ data });
            console.log(res);
          })
          .catch((err) => console.log(err));
      }
    }
  };


  joinLanes = (idx1, idx2) => {
    let { data } = this.state;

    if (data[idx1] && data[idx1].nodes && data[idx2] && data[idx2].nodes) {
      data[idx1].nodes = data[idx1].nodes.concat(data[idx2].nodes);
      data.splice(idx2, 1);
      this.setState({ data });
    }
  };
  setType = (type) => {
    this.setState({ laneType: type })
  }
  createLane = (laneData) => {
    const { data, cardWidth } = this.state;
    laneData._id = uuid();
    if (cardWidth === 0) cardWidth = 1;
    data.push(laneData);
    this.setState({ data, cardWidth });
  };

  showModal = () => {
    this.setState({ joined: true });
  };

  hideModal = () => {
    this.setState({ joined: false });
  };
  addImage = (idx, _id, newNode, insertFromMiddle = false) => {
    console.log("add by url", _id)
    let { data, cardWidth } = this.state;
    let laneIdx = data.findIndex((lane) => lane._id === _id);
    let lane = data[laneIdx];
    let nodes = lane.nodes;
    if (idx === cardWidth) cardWidth = idx + 1;
    if (insertFromMiddle && nodes[nodes.length - 1].gridID === cardWidth - 1) {
      let lastGridID = parseInt(nodes[nodes.length - 1].gridID);
      cardWidth = lastGridID + 2;
    }


    // Shift all the Nodes if node is added in middle.
    const existingNode = nodes.find((node) => parseInt(node.gridID) === idx);
    if (existingNode) {
      let laneIdx = data.findIndex((lane) => lane._id === _id);
      let lane = data[laneIdx];
      let nodes1 = lane.nodes;
      console.log(nodes1, "data");
      for (let i = 0; i < nodes1.length; i++) {
        nodes1[i].gridID = parseInt(nodes1[i].gridID);
        if (nodes1[i].gridID === idx || nodes1[i].gridID > idx) {
          nodes1[i].gridID = nodes1[i].gridID + 1;
        }
      }
      data[laneIdx].nodes = nodes1;
      var new_data = data;
      console.log(nodes1, data[laneIdx].nodes, new_data, "data");
      const lanes = { lanes: new_data };
      Axios.put(
        `/premap/customermap/lanes/editlanes/${this.props.params.subid}`,
        lanes
      )
        .then((res) => {
          // this.setState({ data });
          laneIdx = data.findIndex((lane) => lane._id === _id);
          lane = data[laneIdx];
          Axios.post(`/premap/customermap/lanes/image/add/${_id}`, newNode)
            .then((res) => {
              lane = res.data;
              data[laneIdx] = lane;
              this.componentDidMount();

            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    } else {
      Axios.post(`/premap/customermap/lanes/image/add/${_id}`, newNode)
        .then((res) => {
          lane = res.data;
          data[laneIdx] = lane;
          this.componentDidMount();

        })
        .catch((err) => console.error(err));
    }
    // Set cardWidth.
    if (lane.nodes.length > cardWidth) cardWidth = nodes.length;

    this.setState({ cardWidth });

    // Set new data.
  };
  addFile = (idx, _id, newNode, insertFromMiddle = false) => {
    let { data, cardWidth } = this.state;

    let laneIdx = data.findIndex((lane) => lane._id === _id);
    let lane = data[laneIdx];

    let nodes = lane.nodes;
    if (idx === cardWidth) {
      cardWidth = idx + 1;
    }
    if (insertFromMiddle && nodes[nodes.length - 1].gridID === cardWidth - 1) {
      let lastGridID = parseInt(nodes[nodes.length - 1].gridID);
      cardWidth = lastGridID + 2;
    }
    // Find an existing node at the target index
    console.log("CJMMMM", _id)
    Axios.post(`/premap/customermap/lanes/file/add/${_id}`, newNode)
      .then((res) => {
        console.log("new file added ", res);
        lane = res.data;
        data[laneIdx] = lane;

        this.componentDidMount();
      })
      .catch((err) => {
        console.error("add new file err", err);
      });

    // Set cardWidth.
    if (lane.nodes.length > cardWidth) cardWidth = nodes.length;

    this.setState({ cardWidth });
  };

  render = () => {
    const { data, cardWidth, isLoading } = this.state;
    const projectId = this.props.params.subid
    const uniqueid = uuid();
    const that = this;
    console.log("this lane", data)
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const { data } = that.state;
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({ data });
        that.draglane(data);
      },
      nodeSelector: ".react-expand-collapse__content",
      handleSelector: ".shift",
    };
    return !isLoading ? (
      <div className="mainContent">
        <div className="container">

          <div
            className="projectnamenavbar"
          // style={{ position: "fixed"}}
          >
            <div>
              <div>{this.state.project_name}</div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="card-container cjm" style={{ width: "103%" }}>
                <div className="cjm-header" style={{ marginTop: "56px" }}>
                  <h1>{this.state.project_name}</h1>
                  <div className="right-icons">
                    <Link to={`/edit/${this.props.params.subid}`}>
                      <i className="fas fa-edit"></i>
                    </Link>
                    <a
                      href={`http://localhost:8080/report/customer-map/${this.props.params.subid}`}
                    >
                      <i className="fas fa-print"></i>
                    </a>
                  </div>
                </div>
                <div style={{ width: "fit-content" }}>
                  {data.map((lane, index) => {
                    switch (lane.laneType) {
                      case "card":
                        return (
                          <ExpandCollapse
                            key={lane._id}
                            previewHeight="25px"
                            expanded={lane.expand}
                            onExpandClick={() => {
                              this.expand(lane, true);
                            }}
                            onCollapseClick={() => {
                              this.expand(lane, false);
                            }}
                            expandText=" "
                            collapseText=" "
                          >
                            <CardLane
                              key={lane._id}
                              lane={lane}
                              index={index}
                              addNode={this.addNode}
                              laneIndex={index}
                              onCardDrop={this._onCardDrop}
                              editLane={this.editLane}
                              deleteLane={this.deleteLane}
                              cardWidth={cardWidth}
                              editNode={this.editnode}
                              shiftlane={this.shiftLane}
                              moveCard={this.moveCard}
                              deleteNode={this.deleteNode}
                            // updateCard={this.updateCard}
                            />
                          </ExpandCollapse>

                        );
                      case "bubble":
                        return (
                          <ExpandCollapse
                            key={lane._id}
                            previewHeight="25px"
                            expanded={lane.expand}
                            onExpandClick={() => {
                              this.expand(lane, true);
                            }}
                            onCollapseClick={() => {
                              this.expand(lane, false);
                            }}
                            expandText=" "
                            collapseText=" "
                          >
                            <BubbleLane
                              key={lane._id}
                              lane={lane}
                              index={index}
                              shiftlane={this.shiftLane}
                              cardWidth={cardWidth}
                              addNode={this.addNode}
                              editNode={this.editnode}

                              editLane={this.editLane}
                              laneIndex={index}
                              deleteNode={this.deleteNode}
                              onCardDrop={this._onCardDrop}
                              deleteLane={this.deleteLane}
                              moveCard={this.moveCard}
                            />
                          </ExpandCollapse>

                        );
                      case "image":
                        return (
                          <ExpandCollapse
                            key={lane._id}

                            previewHeight="25px"
                            expanded={lane.expand}
                            onExpandClick={() => {
                              this.expand(lane, true);
                            }}
                            onCollapseClick={() => {
                              this.expand(lane, false);
                            }}
                            expandText=" "
                            collapseText=" "
                          >
                            <ImageLane
                              cardWidth={cardWidth}
                              key={lane._id}
                              lane={lane}
                              index={index}
                              laneIndex={index}
                              editLane={this.editLane}
                              addNode={this.addImage}
                              editNode={this.editImage}
                              onCardDrop={this._onCardDrop}
                              deleteLane={this.deleteLane}
                              moveCard={this.moveCard}
                              projectId={projectId}
                            />
                          </ExpandCollapse>

                        );
                      case "file":
                        return (
                          <ExpandCollapse
                            key={lane._id}
                            previewHeight="25px"
                            expanded={lane.expand}
                            onExpandClick={() => {
                              this.expand(lane, true);
                            }}
                            onCollapseClick={() => {
                              this.expand(lane, false);
                            }}
                            expandText=" "
                            collapseText=" "
                          >
                            <FileLane
                              cardWidth={cardWidth}
                              key={lane._id}
                              lane={lane}
                              index={index}
                              laneIndex={index}
                              shiftlane={this.shiftLane}
                              addNode={this.addFile}
                              onCardDrop={this._onCardDrop}
                              deleteLane={this.deleteNode}
                              editLane={this.editLane}
                              moveCard={this.moveCard}
                              projectId={projectId}

                            />
                          </ExpandCollapse>

                        );
                      case "phase":
                        return (
                          <ExpandCollapse
                            key={lane._id}
                            previewHeight="25px"
                            expanded={lane.expand}
                            onExpandClick={() => {
                              this.expand(lane, true);
                            }}
                            onCollapseClick={() => {
                              this.expand(lane, false);
                            }}
                            expandText=" "
                            collapseText=" "
                          >
                            <PhaseLane
                              key={lane._id}
                              cardWidth={cardWidth}
                              lane={lane}
                              addNode={this.addNode}
                              index={index}
                              laneIndex={index}
                              editLane={this.editLane}
                              deleteLane={this.deleteLane}
                              shiftlane={this.shiftLane}
                              moveCard={this.moveCard}
                              editNode={this.editnode}
                              // updateCard={this.updateCard}
                              deleteNode={this.deleteNode}
                            />
                          </ExpandCollapse>
                        );
                      case "line":
                        return (
                          <ExpandCollapse
                            key={lane._id}
                            previewHeight="25px"
                            expanded={lane.expand}
                            onExpandClick={() => {
                              this.expand(lane, true);
                            }}
                            onCollapseClick={() => {
                              this.expand(lane, false);
                            }}
                            expandText=" "
                            collapseText=" "
                          ><Lines
                              lane={lane}
                              card_limit={this.state.cardWidth}
                              addNode={this.addNode}
                              deleteLane={this.deleteLane}
                              editLane={this.editLane}
                              editNode={this.editnode}
                              shiftlane={this.shiftLane}
                              projectid={this.props.params.subid}
                            /></ExpandCollapse>
                        )
                      case "text":
                        return (
                          <ExpandCollapse
                            key={lane._id}
                            previewHeight="25px"
                            expanded={lane.expand}
                            onExpandClick={() => {
                              this.expand(lane, true);
                            }}
                            onCollapseClick={() => {
                              this.expand(lane, false);
                            }}
                            expandText=" "
                            collapseText=" "
                          >
                            <TextLane
                              key={lane._id}
                              cardWidth={cardWidth}
                              lane={lane}
                              shiftlane={this.shiftLane}
                              editLane={this.editLane}
                              index={index}
                              addNode={this.addNode}
                              laneIndex={index}
                              editNode={this.editnode}
                              onCardDrop={this._onCardDrop}
                              deleteLane={this.deleteLane}
                              deleteNode={this.deleteNode}
                              moveCard={this.moveCard}
                            />
                          </ExpandCollapse>
                        );
                      default:
                        return null;
                    }
                  })}
                </div>
              </div>
            )}
          </div>
          <AddLane
            setType={this.setType}
            id={uniqueid}
            title="Add Lane"
            updateShowEditModal={this.updateShowEditModal}

          />
          {this.state.showEditModal === true && (
            <LaneModals
              show={this.state.showEditModal}
              close={() => this.setState({ showEditModal: false })}
              id={uniqueid}
              icon=""
              html={this.state.html}
              title="Add the Lane"
              lanetype={this.state.laneType}
              handleSubmit={this.addLane}
              datasource="computer"
            />
          )}

        </div>
      </div>
    ) : null;
  }
}

export default withRouter(CJM);
