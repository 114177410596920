import React, { Component, Fragment } from "react";
import AddNodeMiddle from "../AddNodeMiddle";
import AddNode from "../AddNode";
import htmlReactParser from "html-react-parser";
import LaneModal from "../../../../Components/Modal/LaneModal";
import { v4 as uuid } from "uuid";
import { Menu, Item, Separator, MenuProvider } from "react-contexify";
// import "react-contexify/dist/ReactContexify.min.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Comment from "../../../../Components/Modal/Comment";
import { Popover } from "@material-ui/core";
// import { IconPickerItem } from "react-fa-icon-picker";
const initialState = {
  mouseX: null,
  mouseY: null,
};
export default class CardLane extends Component {
  constructor() {
    super();
    this.state = {
      addNodeAt: "",
      laneid: "",
      html: "",
      cardid: "",
      editing: false,
      color: "",
      history: "",
      status: "",
      icon: "",
      nodeIdx: "",
      mouse: initialState,
      anchorEl: null,
      comment: [],
      editingphase:false,
      show:false
    };
  }
  editLane = (html, color, icon) => {
    const newlane = {
      _id: this.props.lane._id,
      laneType: "card",
      laneName: html,
      color: color,
      date: Date(Date.now()),
      icon: icon,
      laneGridNo: this.props.lane.laneGridNo,
      expand: this.props.lane.expand,
    };
    this.props.editLane(this.props.lane._id, newlane);
  };
  addNode = (html, color, icon, status) => {
    const idx = this.state.addNodeAt;
    console.log("icon", this.state.editing);
    if (this.state.editingphase == false) {
      const newNode = {
        // _id: this.props.lane._id,
        laneType: "card",
        cardHTML: html,
        gridID: idx.toString(),
        date: Date(Date.now()),
        icon: icon,
        color: color,
        cardStatus: status,
        cardCategory: "Customer",
      };
      this.props.addNode(idx,this.props.lane._id, newNode);
    } else {
      let comment = this.state.comment;
      // for (var i = 0; i < comment.length; i++) {
      //   comment[i].userid = comment[i].userId._id;
      // }
      const newNode = {
        laneType: "card",
        cardHTML: html,
        gridID: idx.toString(),
        _id: this.state.cardid,
        date: Date(Date.now()),
        icon: icon,
        color: color,
        cardStatus: status,
        cardCategory: "Customer",
        history: this.state.history,
        comment: comment,
      };
      this.props.editNode(idx, this.state.laneid, this.state.cardid, newNode);
    }
  };
  ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#",
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }
    return rgb;
  }
  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  openAddNodeAt = (idx, laneid, id) => {

    this.setState({ addNodeAt: idx, laneid, editing: false,show:true,
			editingphase:false });
  };
  openEditNodeAt = (
		idx,
		laneid,
		cardid,
		html,
		color,
		history,
		status,
		icon,
		comment,
	) => {
		// Update the state first
console.log(cardid, "This is cardid")
		this.setState({
			...this.state,
			html,
			editing: true,
			addNodeAt: idx,
			editingphase: true,
			show: false, editing: false,
			laneid,
			cardid,
			color,
			history: history === undefined ? [] : history,
			status,
			icon,
			comment,
		}, () => {
			console.log(this.state, "This is State")
		});

	};
  openModal = (id) => {
		this.setState({editing:true,show:false,editingphase:false})

	};
  onDragStart = (event, idx) => {
    console.log(idx);
    this.setState({ nodeIdx: idx });
  };
  onDragOver = (event) => {
    event.preventDefault();
  };
  onDrag = (event) => {
    event.preventDefault();
  };
  onDrop = (event, idx) => {
    let nodes = this.props.lane.nodes;
    let cardWidth = this.props.cardWidth;
    console.log(idx);
    console.log(event.target);
    let nodeIdx = nodes.findIndex((node) => node.gridID == this.state.nodeIdx);
    let dropIdx = nodes.findIndex((node) => node.gridID == idx);
    console.log("drop", dropIdx);
    if (dropIdx != -1) {
      for (var i = 0; i < nodes.length; i++) {
        let gridID = parseInt(nodes[i].gridID);
        if (gridID >= idx) nodes[i].gridID = gridID + 1;
        if (nodes[i].gridID == cardWidth) {
          cardWidth += 1;
        }
      }
    }
    console.log(nodeIdx);
    nodes[nodeIdx].gridID = idx;
    if (idx == cardWidth) cardWidth = cardWidth + 1;
    console.log(nodes);
    let lane = this.props.lane;
    lane.nodes = nodes;
    this.props.dragNode(lane, cardWidth);
  };
  addComment = (text) => {
    this.props.addComment(
      "CardLane",
      this.props.lane._id,
      this.state.nodeIdx,
      text
    );
  };

  commentClick = (event, nodeIdx, comment, bool) => {
    console.log(event);
    if (bool)
      this.setState(
        {
          anchorEl: event.currentTarget,
          mouse: initialState,
          nodeIdx,
          comment,
        },
        console.log(this.state.anchorEl)
      );
    else
      this.setState(
        {
          anchorEl: event.event.target,
          mouse: initialState,
          nodeIdx,
          comment,
        },
        console.log(this.state.anchorEl)
      );
  };

  commentClose = () => {
    this.setState({ anchorEl: null });
  };
  render = () => {
    let {
      lane: { nodes },
      cardWidth,
    } = this.props;
    const { comment, nodeIdx } = this.state;
    const uniqueid = uuid();
    const that = this;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    console.log("open", open);
    const chatid = open ? "simple-popover" : undefined;
    return (
      <div
        className="lane d-flex"
        style={{
          // width: `${(cardWidth + 1) * 291 + "px"}`,
          background: this.hexToRGB(`${this.props.lane.color}`, 0.2),
          borderTop: "1px solid " + `${this.props.lane.color}`,
          width: "200%",
					overflowX: 'auto'
        }}
      >
        {this.state.editing == true ? 
          <LaneModal
            id={uniqueid + "l"}
            title="Edit Lane"
            handleSubmit={this.editLane}
            html={this.props.lane.laneName}
            color={this.props.lane.color}
            history={this.props.lane.history}
            icon={this.props.lane.icon}
            show={true}
						close={() => this.setState({ editing: false })}
          />
         : this.state.editingphase == true ? 
         <LaneModal
            id={uniqueid}
            title="Edit Card"
            show={true}
            handleSubmit={this.addNode}
            html={this.state.html}
            history={this.state.history}
            status={this.state.status}
            color={this.state.color}
            icon={this.state.icon}
						close={() => this.setState({ editingphase: false })}

          />
        : this.state.show == true &&
        <LaneModal
         id={uniqueid}
            title="Add Card"
            show={this.state.show}
            handleSubmit={this.addNode}
            color={this.props.lane.color}
						close={() => this.setState({ show: false })}

        />}
        <div class="parentlane">
          <div
            className="lanename"
            data-toggle="modal"
            data-target={"#" + uniqueid + "l"}
            data-dismiss="modal"
            onClick={() => this.openModal(uniqueid)}
            style={{
              background: this.hexToRGB(`${this.props.lane.color}`, 0.4),
            }}
          >
            {htmlReactParser(this.props.lane.laneName || "")}
          </div>
          <div style={{ marginLeft: "-30px" }}>
            {" "}
            {this.props.lane.icon && this.props.lane.icon !=''? (
              <div style={{ marginBottom: "70px" }}>
                {/* <IconPickerItem
                  icon={this.props.lane?.icon===''?'FaWaveSquare':this.props.lane.icon}
                  // icon=
                  size={18}
                  color="#000"
                /> */}
              </div>
            ) : (
              <div style={{ marginBottom: "100px", marginLeft: "23px" }}></div>
            )}
            <div className="shift d-flex">
              <div
                onClick={() => this.props.shiftlane(this.props.lane._id, 1)}
                class="shiftdown"
              >
                <FaChevronDown aria />
              </div>
              <div
                onClick={() => this.props.shiftlane(this.props.lane._id, -1)}
                class="shiftup"
              >
                <FaChevronUp />
              </div>
            </div>
            <div
							className="deletelaneicon"
							onClick={() =>
								this.props.deleteLane(
									this.props.lane._id,
									this.props.lane.laneType
								)
							}
						>
							<RiDeleteBin6Line className="text-danger" />
						</div>
          </div>
        </div>
        <div className="nodes" onDragOver={(event) => this.onDragOver(event)}>
          {Array(cardWidth)
            .fill(0)
            .map((_, idx) => {
              const node = nodes.find((node) => parseInt(node.gridID) == idx);
              const nodeindex = nodes.findIndex(
                (node) => parseInt(node.gridID) == idx
              );

              if (node) {
                return (
                  <Fragment>
                    <AddNodeMiddle
                      openAddNodeAt={() =>
                        this.openAddNodeAt(idx, this.props.lane._id, uniqueid)
                      }
                      id={uniqueid}
                    />
                    <div
                      class="parent nodedrag"
                      key={idx}
                      draggable="true"
                      onDragStart={(event) => this.onDragStart(event, idx)}
                      onDrag={(event) => {
                        this.onDrag(event);
                      }}
                      onDrop={(event) => this.onDrop(event, idx)}
                    >
                      <MenuProvider id={node._id}>
                        <div
                          className="cardlane-node"
                          data-toggle="modal"
                          data-target={"#" + uniqueid}
                          data-dismiss="modal"
                          aria-controls={node._id}
                          style={{
                            background: this.hexToRGB(`${node.color}`, 0.8),
                          }}
                          onClick={() =>
                            this.openEditNodeAt(
                              idx,
                              this.props.lane._id,
                              node._id,
                              node.cardHTML,
                              node.color,
                              node.history,
                              node.status,
                              node.icon,
                              node.comment,
                              uniqueid
                            )
                          }
                        >
                          {htmlReactParser(node.cardHTML || "")}
                        </div>
                      </MenuProvider>

                      {node.hasOwnProperty("icon") ? (
                        <div class="icon-box">
                          {/* <IconPickerItem
                            icon={node.icon}
                            size={18}
                            color="#000"
                          /> */}
                        </div>
                      ) : (
                        ""
                      )}
                     
                      <div
                        class="deleteicon"
                        onClick={() =>
                          window.confirm(
                            `Are you sure you want to delete card?`
                          ) &&
                          this.props.deleteNode(
                            node._id,
                            this.props.lane._id,
                            this.props.lane.laneType
                          )
                        }
                      >
                        <i className="fas text-danger fa-trash-alt"></i>
                      </div>
                      <Menu id={node._id}>
                        <Item
                          onClick={() => {
                            this.openEditNodeAt(
                              idx,
                              this.props.lane._id,
                              node._id,
                              node.cardHTML,
                              node.color,
                              node.history,
                              node.status,
                              node.icon,
                              node.comment,
                              uniqueid
                            );
                          }}
                        >
                          Edit
                        </Item>
                        <Separator />
                        <Item
                          onClick={() => {
                            window.confirm(
                              `Are you sure you want to delete card?`
                            ) &&
                              this.props.deleteNode(
                                node._id,
                                this.props.lane._id,
                                this.props.lane.laneType
                              );
                          }}
                        >
                          Delete
                        </Item>
                        <Separator />
                        <Item
                          onClick={(e) => {
                            this.commentClick(
                              e,
                              nodeindex,
                              node.comment,
                              false
                            );
                          }}
                          aria-describedby={chatid}
                          variant="contained"
                        >
                          Comment
                        </Item>
                      </Menu>
                    </div>
                  </Fragment>
                );
              }
              return (
                <AddNode
                  openAddNodeAt={() =>
                    this.openAddNodeAt(idx, this.props.lane._id, uniqueid)
                  }
                  id={uniqueid}
                  index={idx}
                  onDrop={(event) => this.onDrop(event, idx)}
                />
              );
            })}

          <AddNode
            openAddNodeAt={() =>
              this.openAddNodeAt(cardWidth, this.props.lane._id, uniqueid)
            }
            id={uniqueid}
            index={cardWidth}
            onDrop={(event) => this.onDrop(event, cardWidth)}
          />
          <Popover
            id={chatid}
            open={open}
            anchorEl={anchorEl}
            onClose={this.commentClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {nodeIdx !== "" && this.props.lane.nodes[nodeIdx] ? (
              <Comment
                addComment={this.addComment}
                comment={this.props.lane.nodes[nodeIdx].comment}
              />
            ) : (
              <Comment addComment={this.addComment} comment={comment} />
            )}
          </Popover>
        </div>{" "}
      </div>
    );
  };
}
