import React from 'react';
import RoutePage from '../routePage/userPage';
const userPage = () => {
    const Data = [
        {
            id: '1',
            routeName: 'Blog',
            popuptext: [
                {
                    title: 'View',
                    link: '/blogs',
                },
                {
                    title: ' Add',
                    link: '/AddBlog',
                },
            ],
        },
        {
            id: '2',
            routeName: 'Career',
            popuptext: [
                {
                    title: 'Add Career',
                    link: '/AddCareer"',
                },
                {
                    title: 'Add Tags',
                    link: '/add-tags',
                },
                {
                    title: 'View Career',
                    link: '/careers',
                },
            ],
        },
        {
            id: '3',
            routeName: 'Team Page',
            popuptext: [
                {
                    title: 'View',
                    link: '/team',
                },
            ],
        },
        {
            id: '4',
            routeName: 'WhatsApp Bot',
            popuptext: [
                {
                    title: 'Add Response',
                    link: '/add-bot-response',
                },
                {
                    title: 'View Response',
                    link: '/view-bot-response',
                },
                {
                    title: 'View Conversation',
                    link: '/view-table-conversation',
                },
            ],
        },
        {
            id: '5',
            routeName: 'Product Update on Webiste',
            popuptext: [
                {
                    title: 'Add  ',
                    link: '/addUpdate',
                },
                {
                    title: 'View',
                    link: '/update',
                },
            ],
        },
        {
            id: '6',
            routeName: 'Inquiries',
            popuptext: [
                {
                    title: 'View',
                    link: '/inquiries',
                },
            ],
        },
    ];

    return (
        <div>
            <RoutePage Data={Data} title={'Website'} />
        </div>
    );
};

export default userPage;
