import React, { Component } from 'react';
import Modal from './modal';
import EditModal from './editModal';

import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';
// import Model from './modal';
const token = localStorage.getItem('cxtoken');
export default class table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            edit: false,
            row: [],
            show: false,
            editshow: false,
            id: '',
        };
    }

    openModal = () => {
        this.setState({ edit: false, row: [], show: true });
        // $("#exampleModal").modal("show");
    };
    delete = id => {
        let url = CX_DEPLOYER + `/premap/${this.props.type}/delete/`;
        if (this.props.type == 'kanban') {
            url = CX_DEPLOYER + `/prefilledkanbans/delete`;
            axios
                .delete(
                    url,
                    { data: { _id: id } },
                    {
                        headers: { 'x-access-token': token },
                    }
                )
                .then(res => {
                    const { data } = this.state;
                    let Idx = data.findIndex(tab => tab._id === this.state.id);
                    data.splice(Idx, 1);
                    this.setState({
                        data,
                        edit: false,
                        row: [],
                        show: false,
                    });
                    alert('Deleted Successfully');
                    // $("#exampleModal").modal("hide");
                })
                .catch(err => console.log(err));
        } else {
            axios
                .delete(url + id, {
                    headers: { 'x-access-token': token },
                })
                .then(res => {
                    const { data } = this.state;
                    let Idx = data.findIndex(tab => tab._id === this.state.id);
                    data.splice(Idx, 1);
                    this.setState({
                        data,
                        show: false,
                    });
                    alert('Deleted Successfully');
                    // $("#exampleModal").modal("hide");
                    // window.location.reload();
                })
                .catch(err => console.log(err));
        }
    };
    submit = body => {
        console.log('submit');
        if (!this.state.edit) {
            let url = CX_DEPLOYER + `/premap/${this.props.type}`;
            if (this.props.type == 'kanban') url = CX_DEPLOYER + `/prefilledkanbans/add`;
            if (this.props.type == 'persona') {
                body = { name: body.name, description: body.desc };
            }
            axios
                .post(url, body, {
                    headers: {
                        'Access-Control-Allow-Headers': 'x-access-token',
                        'x-access-token': token,
                    },
                })
                .then(response => {
                    console.log(response);
                    const { data } = this.state;
                    if (this.props.type == 'business' || this.props.type == 'persona') {
                        data.push(response.data);
                        this.setState({ data });
                    } else {
                        data.push(response.data.data);
                        this.setState({ data });
                    }
                });
        } else {
            this.setState({ edit: false });
            let url = CX_DEPLOYER + `/premap/${this.props.type}/${this.state.id}`;
            if (this.props.type == 'kanban')
                url = CX_DEPLOYER + `/prefilledkanbans/edit/${this.state.id}`;
            axios
                .put(url, body, {
                    headers: {
                        'Access-Control-Allow-Headers': 'x-access-token',
                        'x-access-token': token,
                    },
                })
                .then(response => {
                    console.log(response);
                    const { data } = this.state;
                    let index = data.findIndex(item => item._id == this.state.id);
                    data[index].name = body.name;
                    data[index].description = body.desc;
                    this.setState({ data });
                });
        }
    };

    handleEdit = id => {
        this.setState({ edit: true, id: id });
    };
    renderTableData = () => {
        return this.state.data.map((row, index) => {
            const { description, name, _id } = row; //destructuring
            console.log(row);
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ whiteSpace: 'break-spaces' }}>{name}</td>
                    <td style={{ whiteSpace: 'break-spaces' }}>{description}</td>
                    <td>
                        <EditModal
                            submit={this.submit}
                            edit={true}
                            id={_id}
                            history={this.props.history}
                            type={this.props.type}
                            delete={this.delete}
                            handleEdit={this.handleEdit}
                        />
                    </td>
                </tr>
            );
        });
    };
    getData = () => {
        let url = CX_DEPLOYER + `premap/${this.props.type}`;
        if (this.props.type == 'kanban') url = CX_DEPLOYER + `prefilledkanbans/`;

        axios
            .get(url, {
                headers: { 'x-access-token': token },
            })
            .then(response => {
                if (this.props.type == 'persona') {
                    console.log(response.data);
                    this.setState({ data: response.data });
                } else {
                    console.log(response.data.data);
                    this.setState({ data: response.data.data });
                }
            });
    };

    componentDidMount = () => {
        this.getData();
    };
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props != prevProps) this.getData();
    };
    handleclose = () => {
        this.setState({ show: false });
    };
    render() {
        console.log(this.props.type);
        const { edit, row, data } = this.state;
        return (
            <div>
                <Modal
                    submit={this.submit}
                    edit={edit}
                    row={row}
                    history={this.props.history}
                    type={this.props.type}
                    delete={this.delete}
                    show={this.state.show}
                    openModal={this.openModal}
                    handleclose={this.handleclose}
                />
                {/* <div>
          {" "}
          <button
            type="button"
            className="btn rounded-1 text-white bg-primary float-right mb-2"
            onClick={this.openModal}
          >
            Add
          </button>
        </div> */}

                {data && data.length != 0 ? (
                    <table className="table table-bordered text-center table-hover">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">SNo.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Edit</th>
                            </tr>
                        </thead>
                        <tbody> {this.renderTableData()}</tbody>
                    </table>
                ) : (
                    ''
                )}
            </div>
        );
    }
}
