import { Popover } from "@material-ui/core";
import Attachment from "@material-ui/icons/Attachment";
import htmlReactParser from "html-react-parser";
import React, { Component, Fragment } from "react";
import { Item, Menu, MenuProvider, Separator } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import { IconPickerItem } from "react-fa-icon-picker";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { v4 as uuid } from "uuid";
import Comment from "../../../../Components/Modal/Comment";
import "../../CJM.css";
import LaneModal from "../../../../Components/Modal/LaneModal";

const initialState = {
	mouseX: null,
	mouseY: null,
};
export default class FileLane extends Component {
	constructor() {
		super();
		this.state = {
			data: {
				nodes: [],
			},
			cardWidth: "",
			nodeIdx: "",
			mouse: initialState,
			anchorEl: null,
			comment: [],
			theInputKey: "",
			error: true,
			editing: false
		};
		this._handleImageEdit.bind(this);
	}
	ColorLuminance(hex, lum) {
		// validate hex string
		hex = String(hex).replace(/[^0-9a-f]/gi, "");
		if (hex.length < 6) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		lum = lum || 0;

		// convert to decimal and change luminosity
		var rgb = "#",
			c,
			i;
		for (i = 0; i < 3; i++) {
			c = parseInt(hex.substr(i * 2, 2), 16);
			c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
			rgb += ("00" + c).substr(c.length);
		}
		return rgb;
	}
	hexToRGB(hex, alpha) {
		var r = parseInt(hex.slice(1, 3), 16),
			g = parseInt(hex.slice(3, 5), 16),
			b = parseInt(hex.slice(5, 7), 16);

		if (alpha) {
			return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
		} else {
			return "rgb(" + r + ", " + g + ", " + b + ")";
		}
	}
	_handleImageChange(e, idx, lane_id) {
		e.preventDefault();
		const inputElement = e.target;
		inputElement.removeEventListener("change", this._handleImageChange);
		console.log('file lane id', lane_id)


		let file = e.target.files[0];

		let formData = new FormData();
		formData.append("_id", this.props.lane._id);
		formData.append("file", file);
		formData.append("date", Date(Date.now()));
		formData.append("gridID", idx);
		formData.append("projectid", this.props.projectId)
		formData.append("laneType", "file");
		this.props.addNode(idx, lane_id, formData);


	}
	_handleImageEdit(e, idx, id, comment, lane_id) {
		e.preventDefault();
		if (!this.state.error) {
			let file = e.target.files[0];

			lane_id = lane_id;

			// for (var i = 0; i < comment.length; i++) {
			//   comment[i].userid = comment[i].userId._id;
			// }
			const formData = new FormData();
			formData.append("fileid", id);
			formData.append("file", file);
			formData.append("comment", comment);
			formData.append("gridID", idx.toString());
			formData.append("projectid", this.props.projectId)

			formData.forEach((value, key) => {
				console.log("key %s: value %s", key, value);
			});
			this.setState({ error: true }, () => {
				this.props.editImage(idx, lane_id, "file", formData);
			});
		}
	}
	editLane = (html, color, icon) => {
		const newlane = {
			_id: this.props.lane._id,
			laneType: "file",
			laneName: html,
			date: Date(Date.now()),
			color: color,
			icon: icon,
			laneGridNo: this.props.lane.laneGridNo,
			expand: this.props.lane.expand,
		};
		this.props.editLane(this.props.lane._id, newlane);
	};
	openModal = (id) => {
		this.setState({
			editing: true
		})
	};
	onDragStart = (event, idx) => {
		this.setState({ nodeIdx: idx });
	};
	onDragOver = (event) => {
		event.preventDefault();
	};
	onDrag = (event) => {
		event.preventDefault();
	};
	onDrop = (event, idx) => {
		let nodes = this.props.lane.nodes;
		let cardWidth = this.props.cardWidth;

		let nodeIdx = nodes.findIndex((node) => node.gridID == this.state.nodeIdx);
		let dropIdx = nodes.findIndex((node) => node.gridID == idx);

		if (dropIdx != -1) {
			for (var i = 0; i < nodes.length; i++) {
				let gridID = parseInt(nodes[i].gridID);
				if (gridID >= idx) nodes[i].gridID = gridID + 1;
				if (nodes[i].gridID == cardWidth) {
					cardWidth += 1;
				}
			}
		}

		nodes[nodeIdx].gridID = idx;
		if (idx == cardWidth) cardWidth = cardWidth + 1;

		let lane = this.props.lane;
		lane.nodes = nodes;
		this.props.dragNode(lane, cardWidth);
	};
	addComment = (text) => {
		this.props.addComment(
			"FileLane",

			this.props.lane._id,
			this.state.nodeIdx,
			text
		);
	};

	onEditClick = (id, idx, node_id, nodecomment) => {
		try {
			document.getElementById(id).click();
			document
				.getElementById(id)
				.addEventListener(
					"change",
					(e) =>
						this._handleImageEdit(
							e,
							idx,
							node_id,
							nodecomment,
							this.props.lane._id
						),
					false
				);
			this.setState({ error: false });
		} catch (error) {
			console.log(error.message);
		}
	};

	commentClick = (event, nodeIdx, comment, bool) => {
		if (bool)
			this.setState({
				anchorEl: event.currentTarget,
				mouse: initialState,
				nodeIdx,
				comment,
			});
		else
			this.setState({
				anchorEl: event.event.target,
				mouse: initialState,
				nodeIdx,
				comment,
			});
	};

	commentClose = () => {
		this.setState({ anchorEl: null });
	};
	functionThatResetsTheFileInput() {
		let randomString = Math.random().toString(36);

		this.setState({
			theInputKey: randomString,
		});
	}
	onAddClick = (id, idx) => {
		console.log(this.props.lane._id, "this.props.lane._id")
		// document.getElementById(idx).click();
		document.getElementById("file" + `${idx}`).click();

		document
			.getElementById("file" + `${idx}`)
			.addEventListener(
				"change",
				(e) => this._handleImageChange(e, idx, this.props.lane._id),
				false
			);
		this.setState({ error: false });
	};
	render = () => {
		let {
			lane: { nodes },
			cardWidth,
		} = this.props;
		const uniqueid = uuid();
		const { anchorEl, comment, nodeIdx } = this.state;
		const open = Boolean(anchorEl);
		const chatid = open ? "simple-popover" : undefined;
		return (
			<div
				className="lane d-flex"
				style={{
					// width: `${(cardWidth + 1) * 291 + "px"}`,
					background: this.hexToRGB(`${this.props.lane.color}`, 0.2),
					borderTop: "1px solid " + `${this.props.lane.color}`,
					width: "200%",
					overflowX: 'auto'
				}}
			>
				{this.state.editing &&
					<LaneModal
						title="Edit Lane"
						handleSubmit={this.editLane}
						html={this.props.lane.laneName}
						color={this.props.lane.color}
						history={this.props.lane.history}
						icon={this.props.lane.icon}
						show={this.state.editing}
						close={() => this.setState({
							editing: false
						})}
					/>}
				<div className="parentlane">
					<div
						className="lanename"
						data-toggle="modal"
						data-target={"#" + uniqueid + "lan"}
						data-dismiss="modal"
						onClick={() => this.openModal(uniqueid)}
						style={{
							background: this.hexToRGB(`${this.props.lane.color}`, 0.4),
						}}
					>
						{htmlReactParser(this.props.lane.laneName || "")}
					</div>
					<div style={{ marginLeft: "-30px" }}>
						{this.props.lane.icon ? (
							<div style={{ marginBottom: "70px" }}>
								<IconPickerItem
									icon={this.props.lane.icon}
									size={18}
									color="#000"
								/>
							</div>
						) : (
							<div style={{ marginBottom: "100px", marginLeft: "23px" }}></div>
						)}
						{this.props.lane.icon ? (
							<div style={{ marginBottom: "70px" }}>
								<IconPickerItem
									icon={this.props.lane.icon}
									size={18}
									color="#000"
								/>
							</div>
						) : (
							<div style={{ marginBottom: "100px", marginLeft: "23px" }}></div>
						)}
						<div className="shift d-flex">
							<div
								onClick={() => this.props.shiftlane(this.props.lane._id, 1)}
								className="shiftdown"
							>
								<FaChevronDown aria />
							</div>
							<div
								onClick={() => this.props.shiftlane(this.props.lane._id, -1)}
								className="shiftup"
							>
								<FaChevronUp />
							</div>
						</div>
						<div
							className="deletelaneicon"
							onClick={() =>
								// window.confirm(`Are you sure you want to delete lane?`) &&
								this.props.deleteLane(
									this.props.lane._id,
									this.props.lane.laneType
								)
							}
						>
							<RiDeleteBin6Line className="text-danger" />
						</div>
					</div>
				</div>
				<div className="nodes" onDragOver={(event) => this.onDragOver(event)}>
					{Array(cardWidth)
						.fill(0)
						.map((_, idx) => {
							const node = nodes.find((node) => parseInt(node.gridID) === idx);
							const nodeindex = nodes.findIndex((node) => parseInt(node.gridID) === idx);

							if (node) {
								return (
									<Fragment key={idx}>
										<div className="addnode-middle">
											<Attachment
												onClick={() => {
													this.setState({ error: false });
													// document.getElementById("file" + `${idx}`).click();
													this.onAddClick("fil" + `${idx}`, idx);
												}}
											/>
											<input
												id={"file" + `${idx}`}
												type="file"
												placeholder="select file"
												onChange={() => {
													this.onAddClick("fil" + `${idx}`, idx);
												}}
												// onChange={(e) =>
												// 	this._handleImageChange(e, idx, this.props.lane._id)
												// }
												style={{ display: "none" }}
											/>
										</div>
										<div
											className="parent"
											key={idx}
											draggable="true"
											onDragStart={(event) => this.onDragStart(event, idx)}
											onDrag={(event) => {
												this.onDrag(event);
											}}
											onDrop={(event) => this.onDrop(event, idx)}
										>
											<MenuProvider id={node._id}>
												<div
													className="filelane-node"
													style={{
														border:
															"2px dashed " +
															this.hexToRGB(`${this.props.lane.color}`, 0.8),
														cursor: "pointer",
													}}
												>
													<a href={node.file}>{node.fileName}</a>
												</div>
											</MenuProvider>
											<Menu id={node._id}>
												<Item
													onClick={() => {
														this.onEditClick(
															"file" + `${node.gridID}`,
															idx,
															node._id,
															node.comment
														);
														// Add your code here for handling file change
													}}
												>
													Change File
													<input
														id={"file" + node.gridID}
														key={this.state.theInputKey || ""}
														type="file"
														style={{ display: "none" }}
														onClick={(e) => {
															e.target.value = null;
														}}
														ref={(ref) => {
															this.fileInput = ref;
														}}
													/>
												</Item>
												<Separator />
												{/* <Item
													onClick={(e) => {
														this.props.deleteLane(
															node._id,
															this.props.lane._id,
															this.props.lane.laneType
														);
													}}
												>
													Delete
												</Item> */}
												<Separator />
												<Item
													onClick={(e) => {
														this.commentClick(e, nodeindex, node.comment, false);
													}}
													aria-describedby={chatid}
													variant="contained"
												>
													Comment
												</Item>
											</Menu>

											<div className="icon-box">
												{/* Switch statement for icon */}
											</div>
											{node.comment?.length !== 0 ? (
												<div
													className="comment-icon"
													style={{ marginLeft: "-25px" }}
													onClick={(e) => {
														this.commentClick(e, nodeindex, node.comment, true);
													}}
													aria-describedby={chatid}
													variant="contained"
													title="Comments"
												>
													<i className="fa fa-comments" aria-hidden="true"></i>
												</div>
											) : (
												""
											)}
											{/* <div
												className="deleteicon"
												onClick={() =>
													this.props.deleteLane(
														node._id,
														this.props.lane._id,
														this.props.lane.laneType
													)
												}
											>
												<RiDeleteBin6Line className="text-danger" />
											</div> */}
										</div>
									</Fragment>
								);
							}

							return (
								<div
									className="addnode"
									index={idx}
									onDrop={(event) => this.onDrop(event, idx)}
								>
									<Attachment
										onClick={() => {
											this.setState({ error: false });
											this.onAddClick("fil" + `${idx}`, idx);
										}}
									/>
									<input
										id={"file" + `${idx}`}
										type="file"
										// onChange={(e) =>
										// 	this._handleImageChange(e, idx, this.props.lane._id)
										// }
										onChange={() => {
											this.onAddClick("fil" + `${idx}`, idx);
										}}
										style={{ display: "none" }}
									/>
								</div>
							);
						})}
					<div
						className="addnode"
						index={cardWidth}
						onDrop={(event) => this.onDrop(event, cardWidth)}
					>
						<Attachment
							onClick={() => {
								this.setState({ error: false });
								document.getElementById("file" + `${cardWidth}`).click();
							}}
						/>
						<input
							id={"file" + `${cardWidth}`}
							type="file"
							onChange={() => {
								this.onAddClick("fil" + `${cardWidth}`, cardWidth);
							}}
							// onChange={(e) =>
							// 	this._handleImageChange(e, cardWidth, this.props.lane._id)
							// }
							style={{ display: "none" }}
						/>
					</div>
					<Popover
						id={chatid}
						open={open}
						anchorEl={anchorEl}
						onClose={this.commentClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						{nodeIdx !== "" && this.props.lane.nodes[nodeIdx] ? (
							<Comment
								addComment={this.addComment}
								comment={this.props.lane.nodes[nodeIdx].comment}
							/>
						) : (
							<Comment addComment={this.addComment} comment={comment} />
						)}
					</Popover>
				</div>;


			</div>
		);
	};
}
