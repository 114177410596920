import React, { Component } from "react"
import Modal from "../../Modal/Modal"
import PropTypes from "prop-types";

export default function AddModals(props) {
    const { name, color, content, index, handleAddCard, handleEditCard } = props;
    const addModalId = "add-modal-" + index;
    const editModalId = 'edit-modal-' + index;
    return (
        <React.Fragment>
            {
                Array.isArray(content) &&
                content.map((card, index) => {
                    return (
                        <React.Fragment>
                            <Modal modalId={editModalId + "-" + index} title={"Edit " + name} color={card.color} html={card.html} handleSubmit={(html, color, icon) => handleEditCard(html, color, icon, index)} />
                        </React.Fragment>
                    )
                })
            }
            <Modal modalId={addModalId} title={"Add " + name} color={color} handleSubmit={(html, color, icon) => handleAddCard(html, color, icon)} />

        </React.Fragment>
    )
}

AddModals.propTypes = {
    /**
     * Name of the field
     */
    name: PropTypes.string,
    /**
     * Color of the field, used as default header color
     */
    color: PropTypes.string,
    /**
     * @type Array<{html:String, color:String}>
     * The content of the fields, all the cards array
     */
    content: PropTypes.arrayOf(PropTypes.object),
    /**
     * Index of the field
     */
    index: PropTypes.number,
    /**
     * @type <Function(html:String, color:String, icon:Component, index:Number)>
     * Handler for submit card
     * @param {String} html html content will be passes as the parameter on submit
     * @param {String} color The selected color in the editor will be passed as the parameter
     */
    handleAddCard: PropTypes.func,

    /**
     * @type <Function(html:String, color:String, icon:Component)>
     * Handler for submit card
     * @param {String} html html content will be passes as the parameter on submit
     * @param {String} color The selected color in the editor will be passed as the parameter
     * @param {Component} icon Not used as of now
     * @param {Number} index Index of the card in the particular field
     */
    handleEditCard: PropTypes.func

}
