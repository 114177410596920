import React, { useEffect, useRef, useState } from 'react';
import {  Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { createBoard, editBoard } from '../redux/action/board';
import { getCategory } from '../redux/action/category';
import { SwatchesPicker } from 'react-color';
import { useSelector, useDispatch } from 'react-redux';
import './createBoardPopup.css';

const CreateBoardPopup = props => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const ref = useRef();
    const { allCategory } = useSelector(store => store.categoryRoot);

    const dispatch = useDispatch();
    const [labelColor, setLabelColor] = useState(false);
    const [categoryId, setcategoryId] = useState(props.categoryId && props.categoryId);
    const [boardName, setBoardName] = useState(props.title && props.title);
    const [boardColor, setBoardColor] = useState(props.boardColor?props.boardColor:"#2196f3");
    const handleChangeColor = (color, e) => {
        setBoardColor(color.hex);
        setLabelColor(!labelColor);
    };

    const handleSubmit = async () => {
        const inputValue = {
            boardColor: boardColor,
            boardName: boardName,
            categoryId: categoryId,
        };
        if (props.edit === 'edit') {
            dispatch(
                editBoard(props.id, inputValue, async function (response) {
                    if (response) {
                        props.onClose();
                        props.setCreateBoardPopup(false);
                    } else {
                        props.onClose();
                    }
                })
            ).then(() => {
                props.fetchData();
            });
        } else {
         
            dispatch(
                createBoard(inputValue, async function (response) {
                    if (response) {
                        props.onClose();
                        props.setCreateBoardPopup(false);
                    } else {
                        props.onClose();
                    }
                })
            );
        }
    };

    useEffect(() => {
        dispatch(getCategory());
    }, []);

    useEffect(() => {
        const handleOutsideClick = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                props.onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [ref, props]);

    return (
        <Modal show={props.setCreateBoardPopup} onHide={handleClose} size="md">
            <p className=" text-center m-0 mt-2">
               {props.edit !== 'edit' ? "Create Template" : "Edit Tempalte"} 
                <span
                    className="text-start"
                    style={{
                        position: 'relative',
                        left: '150px',
                        cursor: 'pointer',
                    }}
                    onClick={() => props.setCreateBoardPopup(false)}
                >
                    X
                </span>
            </p>
            <hr style={{ marginBottom: '0px' }} />
            <Modal.Body>
                {/* Board Name and Board Color Section */}
                <section className="create-board-popup">
                    <Form className="px-2">
                        <Form.Group className="mb-2" controlId="formBasicTitle">
                            <p className="fw-bold Board-Title">Template Title</p>
                            <Form.Control
                                type="text"
                                placeholder="Enter board title"
                                name="boardName"
                                value={boardName}
                                style={{ fontSize: '12px' }}
                                autocomplete="off"
                                onChange={e => setBoardName(e.target.value)}
                            />
                        </Form.Group>
                       
                                <Form.Group className="mb-2" controlId="formBasicColor">
                                    <p className="fw-bold ">Category</p>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name="categoryId"
                                        style={{ fontSize: '12px' }}
                                        onChange={e => setcategoryId(e.target.value)}
                                        value={categoryId}
                                    >
                                        <option>Select Category</option>
                                        {allCategory &&
                                            allCategory.map(item => (
                                                <option key={item._id} value={item._id}>
                                                    {item.category}
                                                </option>
                                            ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="formBasicColor">
                                    <p className="fw-bold ">Template Color</p>
                                    <div
                                        className="colorPicker"
                                        style={{
                                            background: boardColor,
                                        }}
                                        onClick={() => setLabelColor(!labelColor)}
                                    ></div>
                                    {labelColor && (
                                        <SwatchesPicker
                                            name="boardColor"
                                            onChange={handleChangeColor}
                                        />
                                    )}
                                </Form.Group>
                        
                        <Button className="CreateBoardPopup-create-btn mb-2" onClick={handleSubmit}>
                            {props.edit === 'edit' ? 'Edit' : 'Create'}
                        </Button>
                    </Form>
                </section>
            </Modal.Body>
        </Modal>
    );
};

export default CreateBoardPopup;
