import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {CX_DEPLOYER} from '../../services/url'
import BlogCommentCard from "./blogCommentCard";
function PostPage(props) {

  const params =useParams();
  // console.log("props.loc",location)
  // if (!props.location.blog) {
  //   props.history.push("/blogs");
  // }
  const [post, setPost] = useState([]);

  useEffect(() => {
  
    getBlogData();
  }, []);
  const getBlogData = async()=>{
    const response = await axios.get(`${CX_DEPLOYER}blog/blogbyId/${params.postId}`)
    console.log(response)
    if(response.data.success){
      setPost(response.data.blogs[0])
    }
  }
  document.title =
    "View Blog | Persona | Empathy | Value | Journey Mapping | Innovation | Kanban | CXDeployer";

  return (
    <div className="container">
      
      <div className="postPage" style={{ width: "80%", margin: "2rem auto" }}>
      <Link to="/blogs">
      <button className="btn btn-primary">go back to blog page</button></Link>
        <br />
        <br/>
        <h1 className="mb-5">{post.title}</h1>{" "}

        <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
        <div dangerouslySetInnerHTML={{ __html: post.blog_content }} />
      </div>
      <div style={{ width: "80%", margin: "2rem auto" }}>

      <BlogCommentCard
       blogs={post}
       blogId={post._id}
       getBlogData={getBlogData}
      />
      </div>
    </div>
  );
}

export default PostPage;
