import React, { Component } from 'react';
import './header.css';

class Header extends Component {
    constructor(propos) {
        super();
    }

    render() {
        return (
            <div className="header-top container-fluid" style={{position: "fixed", top: 0, zIndex: 4}}>
                <h1>
                    <i className="fas fa-box"></i> Admin Dashboard
                </h1>
            </div>
        );
    }
}

export default Header;
