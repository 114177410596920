import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';

export const getCareers = async payload => {
    try {
        const res = await axios.post(CX_DEPLOYER + 'careers/allCareers', payload);
        return res.data;
    } catch (error) {
        return { success: false };
    }
};
