import React, { Component } from 'react';
import './App.css';
import { Link, Route, BrowserRouter as Router, Navigate, Routes } from 'react-router-dom';
import Login from './login/login';
import AddBlog from './shared/add_blog/add_blog';
import EditBlog from './shared/add_blog/edit_blog';
import RegisteredUsers from './registered_users/registered_users';
import PremiumUsers from './registered_users/premium_users';
import AddUser from './registered_users/addUser';
import Help from './help/help';
import ViewHelp from './help/view_help';
import EditHelp from './help/edithelp';
import AddImage from './AddImage/addImage';
import Image from './AddImage/viewImage';
import ViewBlogs from './shared/view_blog/view_blog';
import AllBlogs from './shared/view_blog/AllBlogs';
import Blog from './shared/view_blog/Blog';
import PostPage from './shared/view_blog/PostPage';
import ViewTemplateList from './prefilledMaps/viewTemplateLists';
import { CustomerJourneyMap, KanbanApp, PersonaPrefilled } from './pages';
import BuisnessModalCanvas from './pages/BuisnessModelCanvas/BuisnessModalCanvas';
import Payment from './registered_users/payment';
import AddPlan from './Plan/addPlan';
import ViewPlan from './Plan/view_plan';
import EditPlan from './Plan/editPlan';
import AddCoupon from './Coupon/addCoupon';
import ViewCoupon from './Coupon/view_coupon';
import EditCoupon from './Coupon/editCoupon';
import UsersActivity from './UsersActivity/UsersActivity';
import UserRegister from './UserRegister/UserRegister';
import UserEngagement from './UserEngagement/UserEngagement';
import ViewVisitedUser from './UserEngagement/viewUser';
import ViewVisitedPages from './UserEngagement/viewPage';
import AddCareer from './careers/addCareer';
import AddTags from './careers/addTags';
import Team from './team/teamImage';
import EditCareer from './careers/editCareer';
import ViewCareer from './careers/viewCareer';
import Templates from './template/bucket';
import Header from './header/header';
import Sidebar from './Sidebar/Sidebar';
import Inquiries from './inquiries/inquiries';
import AddCategory from './template/addCategory';
import ViewTemplate from './template/viewTemplate';
import AddBotResponse from './whatsAppBot/add_botResponse/add_response';
import EditBotResponse from './whatsAppBot/add_botResponse/edit_response';
import ViewBotResponse from './whatsAppBot/view_response';
import ViewConversation from './whatsAppBot/conversation/chat';
import ViewTableConversation from './whatsAppBot/conversation/viewTable';
import AddUpdate from './updates/addupdate';
import EditUpdate from './updates/editupdate';
import ViewUpdate from './updates/viewUpdates';
import AddFormCategory from './template/addFormCategory';
import ViewFormTemplate from './template/viewFormTemplate';
import FormTemlate from './template/formTemplate';
import UserPage from './userPage/userPage';
import Website from './website/website';
import Product from './product/product';
import Admin from './admin/adminPage';

class App extends Component {
    constructor() {
        super();
        this.state = {
            route: 'signin',
            isSignedIn: false,
            logginStatus: true,
        };
    }

    onRouteChange = route => {
        if (route === 'signout') {
            this.setState({ isSignedIn: false });
        } else if (route === 'home') {
            this.setState({ isSignedIn: true });
        }
        this.setState({ route: route });
    };

    render() {
        return (
            <Router>
                <div>
                    {localStorage.getItem('cxtoken') ? (
                        <div>
                            <Header />
                            <div style={{ display: 'flex', marginTop: '80px' }}>
                                <div style={{ zIndex: '4', width: '200px' }}>
                                    <Sidebar />
                                </div>

                                <div style={{ marginLeft: '20px', width: '100%' }}>
                                    <Routes>
                                        <Route
                                            exact
                                            path={'/users'}
                                            element={<RegisteredUsers />}
                                        />
                                        <Route exact path={'/userPage'} element={<UserPage />} />
                                        <Route exact path={'/website'} element={<Website />} />
                                        <Route exact path={'/product'} element={<Product />} />
                                        <Route exact path={'/admin'} element={<Admin />} />
                                        <Route exact path={'/AddBlog'} element={<AddBlog />} />
                                        <Route exact path={'/AddCareer'} element={<AddCareer />} />
                                        <Route exact path={'/add-tags'} element={<AddTags />} />
                                        <Route exact path={'/team'} element={<Team />} />

                                        <Route
                                            exact
                                            path={'/editcareers/:id'}
                                            element={<EditCareer />}
                                        />
                                        <Route exact path={'/careers'} element={<ViewCareer />} />

                                        <Route
                                            exact
                                            path={'/user_register'}
                                            element={<UserRegister />}
                                        />
                                        <Route exact path={'/inquiries'} element={<Inquiries />} />
                                        <Route exact path={'/template'} element={<Templates />} />
                                        <Route exact path={'/form-template'} element={<FormTemlate />} />
                                        <Route
                                            exact
                                            path={'/view-template'}
                                            element={<ViewTemplate />}
                                        />
                                        <Route exact path={'/view-form-template'} element={<ViewFormTemplate />} />
                                        <Route
                                            exact
                                            path={'/user_engagement'}
                                            element={<UserEngagement />}
                                        />
                                        <Route
                                            exact
                                            path={'/viewVisitedUser'}
                                            element={<ViewVisitedUser />}
                                        />
                                        <Route
                                            exact
                                            path={'/viewVisitedPages/:userId'}
                                            element={<ViewVisitedPages />}
                                        />
                                        <Route
                                            exact
                                            path={'/premiumUsers'}
                                            element={<PremiumUsers />}
                                        />
                                        <Route exact path={'/addUser'} element={<AddUser />} />
                                        <Route exact path={'/addImage'} element={<AddImage />} />
                                        <Route exact path={'/image'} element={<Image />} />
                                        <Route exact path={'/help'} element={<Help />} />
                                        <Route
                                            exact
                                            path={'/edithelp/:helpid'}
                                            element={<EditHelp />}
                                        />
                                        <Route exact path={'/ViewHelp'} element={<ViewHelp />} />
                                        <Route
                                            exact
                                            path={'/view/:postId'}
                                            element={<PostPage />}
                                        />
                                        <Route
                                            exact
                                            path={'/editblog/:postId'}
                                            element={<EditBlog />}
                                        />
                                        <Route
                                            exact
                                            path={'/add-category'}
                                            element={<AddCategory />}
                                        />
                                        <Route exact path={'/add-form-category'} element={<AddFormCategory />} />
                                        <Route
                                            exact
                                            path={'/add-bot-response'}
                                            element={<AddBotResponse />}
                                        />
                                        <Route
                                            exact
                                            path={'/edit-bot-response'}
                                            element={<EditBotResponse />}
                                        />
                                        <Route
                                            exact
                                            path={'/view-bot-response'}
                                            element={<ViewBotResponse />}
                                        />
                                        <Route
                                            exact
                                            path={'/view-conversation'}
                                            element={<ViewConversation />}
                                        />
                                        <Route
                                            exact
                                            path={'/view-table-conversation'}
                                            element={<ViewTableConversation />}
                                        />
                                        <Route exact path={'/addUpdate'} element={<AddUpdate />} />
                                        <Route
                                            exact
                                            path={'/edit-update/:id'}
                                            element={<EditUpdate />}
                                        />
                                        <Route exact path={'/update'} element={<ViewUpdate />} />
                                        <Route
                                            exact
                                            path={'/PrefilledMaps'}
                                            element={<ViewTemplateList />}
                                        />
                                        <Route exact path={'/'} element={<UsersActivity />} />
                                        <Route exact path={'/ViewBlogs'} element={<ViewBlogs />} />
                                        <Route exact path="/blogs" element={<AllBlogs />} />
                                        <Route exact path="/blog/:slug" element={<Blog />} />

                                        <Route exact path={'/payment/:id'} element={<Payment />} />
                                        <Route exact path={'/addPlan'} element={<AddPlan />} />
                                        <Route
                                            exact
                                            path={'/editPlan/:planid'}
                                            element={<EditPlan />}
                                        />
                                        <Route exact path={'/ViewPlan'} element={<ViewPlan />} />
                                        <Route exact path={'/addCoupons'} element={<AddCoupon />} />
                                        <Route
                                            exact
                                            path={'/editCoupon/:couponid'}
                                            element={<EditCoupon />}
                                        />
                                        <Route
                                            exact
                                            path={'/ViewCoupons'}
                                            element={<ViewCoupon />}
                                        />
                                        <Route
                                            exact
                                            path="/business/:subid"
                                            element={<BuisnessModalCanvas />}
                                        />
                                        <Route
                                            exact
                                            path="/kanban/:subid"
                                            element={<KanbanApp />}
                                        />
                                        <Route
                                            exact
                                            path="/persona/:subid"
                                            element={<PersonaPrefilled />}
                                        />
                                        <Route
                                            exact
                                            path="/customermap/:subid"
                                            element={<CustomerJourneyMap />}
                                        />
                                    </Routes>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Routes>
                            <Route exact path={'/'} element={<Login />} />
                        </Routes>
                    )}
                </div>
            </Router>
        );
    }
}

export default App;
