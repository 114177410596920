import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Card from './Components/Card';
import { DragDropContext } from 'react-beautiful-dnd';
import withRouter from '../../withRouter';
import cc from './CC-BY-SA_icon.png';
import Tooltip from '@material-ui/core/Tooltip';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Loader from '../../Components/Loader/Loader';
import Axios from '../../services/Axios';
import { Button } from '@material-ui/core';

const zoomArr = ['50%', '75%', '85%', '90%', '100%', ' 120%', '130%', '150%', '180%'];

class BuisnessModalCanvas extends Component {
    constructor() {
        super();
        this.state = {
            project_desc: '',
            project_name: '',
            Project_id: '',
            subProjectId: '',
            Project_data: [],
            isLoading: true,
            sub_project_data: [],
            indexofArr: 4,
            tabsdata: [],

            data: {
                channel: [],
                keyPartners: [],
                keyResources: [],
                costStructure: [],
                keyActivities: [],
                revenueStreams: [],
                customerSegment: [],
                valuePreposition: [],
                customerRelationships: [],
                _id: '',
                tabIndex: 5,
            },
        };
    }

    componentDidMount() {
        const subProjectId = this.props.params.subid
        // const tooltipElement = document.querySelector('[data-toggle="tooltip"]');

        // if (tooltipElement) {
        //     tooltipElement.addEventListener('mouseover', () => {
        //         // Create and display your tooltip
        //         const tooltip = document.createElement('div');
        //         tooltip.textContent = 'Your Tooltip Text'; // Set your tooltip text
        //         tooltip.className = 'tooltip';
        //         tooltipElement.appendChild(tooltip);
        //     });

        //     tooltipElement.addEventListener('mouseout', () => {
        //         // Remove the tooltip when the mouse leaves
        //         const tooltip = document.querySelector('.tooltip');
        //         if (tooltip) {
        //             tooltipElement.removeChild(tooltip);
        //         }
        //     });
        // }

        Axios.get(`/premap/business`).then(response => {
            console.log(response.data.data);
            let data = response.data.data;
            for (var i = 0; i < data.length; i++) {
                if (data[i]._id == subProjectId) {
                    this.setState({ project_name: data[i].name });
                }
            }
        });

        Axios.get(`/premap/business/${subProjectId}`)
            .then(({ data }) => {
                console.log(data);
                this.setState({ data, subProjectId, isLoading: false }, () =>
                    console.log({ data })
                );
            })
            .catch(err => alert(err) || console.log(err));
    }

    zoomin = () => {
        var element = document.querySelector('.maindiv');
        console.log(element);
        let value = element.getBoundingClientRect().width / element.offsetWidth;
        if (this.state.indexofArr < zoomArr.length - 1) {
            this.state.indexofArr += 1;
            value = zoomArr[this.state.indexofArr];
            element.style['zoom'] = `${value}`;
        }
    };

    redirect() {
        this.props.navigation.navigate(`/MapType:${this.state.Project_id}`);
    }
    zoomout = () => {
        var element = document.querySelector('.maindiv');
        console.log(element);
        let value = element.getBoundingClientRect().width / element.offsetWidth;

        if (this.state.indexofArr > 0) {
            this.state.indexofArr -= 1;
            value = zoomArr[this.state.indexofArr];

            element.style.zoom = `${value}`;
        }
    };
    dragEnd = result => {
        const { data } = this.state;
        const { source, destination } = result;

        console.log(source, destination);
        if (!destination) return;

        if (destination.droppableId !== source.droppableId) return;
        const item = data[source.droppableId][source.index];
        delete data[source.droppableId].splice(source.index, 1);
        data[destination.droppableId].splice(destination.index, 0, item);

        Axios.put('/premap/business/node/editgrid', {
            source: {
                id: source.droppableId,
                index: source.index,
            },
            destination: {
                id: destination.droppableId,
                index: destination.index,
            },
            _id: this.state.data._id,
        })
            .then(res => {
                if (res.data.msg) {
                    const item = data[destination.droppableId][destination.index];
                    data[destination.droppableId].splice(destination.index, 1);
                    data[source.droppableId].splice(source.index, 0, item);
                    this.setState({ data });
                    alert('An Error Occured');
                    console.log(res.data.msg);
                }
            })
            .catch(err => {
                const item = data[destination.droppableId][destination.index];
                data[destination.droppableId].splice(destination.index, 1);
                data[source.droppableId].splice(source.index, 0, item);
                this.setState({ data });
                alert('An Error Occured');
                console.log(err);
            });
    };

    addNode = (html, droppableKey, backgroundColor) => {
        const { data } = this.state;
        Axios.post(`/premap/business/node/add/${data._id}`, {
            nodeType: droppableKey,
            text: html,
            date: Date.now(),
            backgroundColor,
        })
            .then(({ data }) => {
                this.setState({ data });
            })
            .catch(err => console.log(err));
    };

    editNode = (text, nodeType, id, nodeIndex, backgroundColor, history) => {
        Axios.put(`/premap/business/node/edit/${id}`, {
            nodeType,
            text,
            date: Date.now(),
            nodeIndex,
            history: history || [],
            backgroundColor,
        })
            .then(res => {
                this.setState({ data: res.data });
            })
            .catch(err => console.log(err) || alert('An Error occurred'));
    };

    deleteNode = (_id, nodeType) => {
        const bmcId = this.state.data._id;
        Axios.put(`/premap/business/node/delete/${bmcId}`, {
            nodeType,
            _id,
        })
            .then(res => {
                const { data } = this.state;
                const idx = data[nodeType].findIndex(ele => ele._id === _id);
                data[nodeType].splice(idx, 1);
                this.setState({ data });
            })
            .catch(err => console.log(err));
    };

    render = () => {
        const { data, isLoading } = this.state;
        return (
            <Fragment>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <div
                            className="projectnamenavbar"
                            style={{ position: 'fixed', top: 0, zIndex: 998 }}
                        >
                            <div>
                                <div>{this.state.project_name}</div>
                            </div>
                            <div className="projectnamenavbar-links"></div>
                        </div>
                        <div id="view-only" style={{ margin: '80px 0 100px 0' }}>
                            <div className="maindiv" style={{
                                zoom: '100%', width: "100%",
                                left: "153px",
                                position: "relative"
                            }}>
                                <DragDropContext onDragEnd={this.dragEnd}>
                                    <div className="d-flex mt-5 px-5">
                                        <div style={styles.column}>
                                            <Card
                                                deleteNode={this.deleteNode}
                                                title="Key Partners"
                                                addNode={this.addNode}
                                                editNode={this.editNode}
                                                id={data._id}
                                                data={data.keyPartners}
                                                droppableKey="keyPartners"
                                                tooltipText="List the key partners and what are the key resources received from these partners. What key activities are performed by these partners?"
                                            />
                                        </div>
                                        <div style={styles.column}>
                                            <div style={{ height: '100%' }}>
                                                <div style={{ height: '50%' }}>
                                                    <Card
                                                        deleteNode={this.deleteNode}
                                                        title="Key Activities"
                                                        addNode={this.addNode}
                                                        editNode={this.editNode}
                                                        id={data._id}
                                                        data={data.keyActivities}
                                                        droppableKey="keyActivities"
                                                        tooltipText="List the unique and key activities which are necessary to deliver the value proposition(s). What is required to produce, market and deliver the value proposition?"
                                                    />
                                                </div>
                                                <div style={{ height: '50%' }}>
                                                    <Card
                                                        deleteNode={this.deleteNode}
                                                        title="Key Resources"
                                                        addNode={this.addNode}
                                                        editNode={this.editNode}
                                                        id={data._id}
                                                        data={data.keyResources}
                                                        droppableKey="keyResources"
                                                        tooltipText="List the key resources and assets that are necessary to deliver the value proposition(s). What resources are needed by distribution channels, revenue streams and to maintain customer relationships?"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={styles.column}>
                                            <Card
                                                deleteNode={this.deleteNode}
                                                title="Value Propositions"
                                                addNode={this.addNode}
                                                editNode={this.editNode}
                                                id={data._id}
                                                data={data.valuePreposition}
                                                droppableKey="valuePreposition"
                                                tooltipText="List the core values provided to the customer and products or services offered to each customer segment. What problems of the customer are solved and what needs are being satisfied?"
                                            />
                                        </div>
                                        <div style={styles.column}>
                                            <div style={{ height: '100%' }}>
                                                <div style={{ height: '50%' }}>
                                                    <Card
                                                        deleteNode={this.deleteNode}
                                                        title="Customer Relationships"
                                                        addNode={this.addNode}
                                                        editNode={this.editNode}
                                                        id={data._id}
                                                        data={data.customerRelationships}
                                                        droppableKey="customerRelationships"
                                                        tooltipText="List the type of relationship and interaction with the customer through their ‘journey’. How these interactions differ amongst customer segments?"
                                                    />
                                                </div>
                                                <div style={{ height: '50%' }}>
                                                    <Card
                                                        deleteNode={this.deleteNode}
                                                        title="Channel"
                                                        addNode={this.addNode}
                                                        editNode={this.editNode}
                                                        id={data._id}
                                                        data={data.channel}
                                                        droppableKey="channel"
                                                        tooltipText="List the channels used to deliver the value proposition(s) and to reach customer segments. Are supply, distribution, marketing and communication channels are cost-efficient, well-integrated and effectively utilized?  How customers find the solution?"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={styles.column}>
                                            <Card
                                                deleteNode={this.deleteNode}
                                                title="Customer Segments"
                                                addNode={this.addNode}
                                                editNode={this.editNode}
                                                id={data._id}
                                                data={data.customerSegment}
                                                droppableKey="customerSegment"
                                                tooltipText="List the important customers who are targets for the value proposition(s). Who needs your solution?"
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex mb-5 px-5">
                                        <div style={{ width: '50%' }}>
                                            <Card
                                                deleteNode={this.deleteNode}
                                                title="Cost Structure"
                                                addNode={this.addNode}
                                                editNode={this.editNode}
                                                id={data._id}
                                                data={data.costStructure}
                                                droppableKey="costStructure"
                                                tooltipText="List the key costs of the business model and key cost drivers. How do key activities and key resources relate to the cost structure? How do costs linked to revenue streams?"
                                            />
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <Card
                                                deleteNode={this.deleteNode}
                                                title="Revenue Streams"
                                                addNode={this.addNode}
                                                editNode={this.editNode}
                                                id={data._id}
                                                data={data.revenueStreams}
                                                droppableKey="revenueStreams"
                                                tooltipText="List key ways the business earns revenue from the value proposition(s). How customers are currently paying, what channels they prefer to pay? Are there multiple methods of generating revenue?"
                                            />
                                        </div>
                                    </div>
                                </DragDropContext>
                            </div>
                        </div>
                        <div className="mb-5 align-items-center" align="center">
                            <img src={cc} width="90px" height="33px"></img>
                            <a href="https://www.strategyzer.com/">strategyzer.com </a>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    };
}

const styles = {
    column: {
        minWidth: '20%',
        maxWidth: '20%',
        height: '550px',
    },
};
export default withRouter(BuisnessModalCanvas);