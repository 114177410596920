import './style/List.css';

import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import Card from './Card';
import ListEditor from './ListEditor';
import NewCard from './newCard';
import { editBucket } from '../redux/action/bucket';
// import shortid from "shortid";
import { useDispatch, useSelector } from 'react-redux';

import { archiveBucket } from '../redux/action/bucket';
const List = ({ list, index }) => {
    const [addingCard, setAddingCard] = useState(false);
    const [editingTitle, setEditingTitle] = useState(false);
    const [title, setTitle] = useState(list.bucketName);
    const dispatch = useDispatch();
    const { selectedBoard } = useSelector(store => store.boardRoot);
    const toggleAddingCard = () => setAddingCard(!addingCard);

    const toggleEditingTitle = () => setEditingTitle(!editingTitle);

    const handleChangeTitle = e => setTitle(e.target.value);

    const editListTitle = async () => {
        toggleEditingTitle();
        console.log('Edit save---------', title, list._id);
        dispatch(editBucket(list._id, title, async function (response) {}));
    };

    const deleteList = async () => {
        const { listId, list, dispatch } = this.props;

        if (window.confirm('Are you sure to delete this list?')) {
            dispatch({
                type: 'DELETE_LIST',
                payload: { listId, cards: list.cards },
            });
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const listClose = () => {
        setIsOpen(false);
    };
    const handleArchiveList = bucketId => {
        dispatch(archiveBucket(bucketId, selectedBoard && selectedBoard._id));
    };
    return (
        <Draggable draggableId={list._id} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="List"
                >
                    {editingTitle ? (
                        <>
                            <ListEditor
                                list={list}
                                title={title}
                                handleChangeTitle={handleChangeTitle}
                                saveList={editListTitle}
                                onClickOutside={editListTitle}
                                deleteList={deleteList}
                            />
                        </>
                    ) : (
                        <>
                            <div className="List-Title" onClick={toggleEditingTitle}>
                                {list.bucketName}
                            </div>
                        </>
                    )}

                    {/* List Popup starts here */}
                    <div className="dropdown-container">
                        <button onClick={toggleDropdown} className="list-dropdown">
                            ...
                        </button>
                        {isOpen && (
                            <div className="dropdown-content">
                                <button className="list-dropdown-close" onClick={listClose}>
                                    x
                                </button>
                                <h6>List Actions</h6>
                                <hr />
                                <span className="dropdown-item" onClick={() => toggleAddingCard()}>
                                    <i className="bi bi-plus-lg"></i>Add Card...
                                </span>
                                <hr />
                                <span
                                    className="dropdown-item"
                                    onClick={() => handleArchiveList(list._id)}
                                >
                                    Archive this list
                                </span>
                            </div>
                        )}
                    </div>
                    {/* List Popup ends here */}

                    {/* List starts here */}
                    <Droppable droppableId={list._id}>
                        {(provided, _snapshot) => (
                            <div ref={provided.innerRef} className="Lists-Cards">
                                {list.cards &&
                                    list.cards.map((cardId, index) => (
                                        <Card
                                            key={cardId}
                                            card={cardId}
                                            list={list}
                                            index={index}
                                            listId={list._id}
                                        />
                                    ))}

                                {provided.placeholder}
                                {addingCard ? (
                                    <NewCard
                                        handleCloseCard={toggleAddingCard}
                                        bucketId={list._id}
                                        onClickOutside={toggleAddingCard}
                                    />
                                ) : (
                                    <div className="Toggle-Add-Card" onClick={toggleAddingCard}>
                                        + Add a card
                                    </div>
                                )}
                            </div>
                        )}
                    </Droppable>
                </div>
            )}
        </Draggable>
    );
};

export default List;
