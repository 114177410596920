import React, { useState, useEffect, useRef } from "react";
import { Card, } from "react-bootstrap";
import {
  editLabelInBoard,
  deleteLabelInBoard,
  createNewLabelInBoard,
} from "../../redux/action/board";
import "./labelPop.css";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

const LabelEditPop = (props) => {
  const popupRef = useRef(null);
  const [selectedLabel, setSelectedLabel] = useState(props?.labelId ? props?.labelId?.labelTitle : "",);
  const dispatch = useDispatch();
  const [LabelID, setLabelID] = useState(props.labelId);
  const [labelColor, setLabelColor] = useState(
    props && props.labelId && props.labelId
      ? props.labelId.labelBackgroundColor
      : "#eee",
  );
  const { selectedBoard } = useSelector((store) => store.boardRoot);

  const [title, setTitle] = useState(
    props?.labelId ? props?.labelId?.labelTitle : "",
  );
  const [show, setShow] = useState(props.setShowPopup);

  const handleClose = () => {
    setShow(false);
  };
  const handleallClose = () => {
    setShow(false);
  };
  const handleEditLabels = () => {
    setShow(false);

    if (props.toDone === false) {
      let payload = {
        boardid: selectedBoard._id,
        labelTitle: title,
        labelBackgroundColor: labelColor,
        labelid: props.labelId._id,
      };

      dispatch(editLabelInBoard(payload)).catch((err) => {
        console.error(err.message);
      });
    } else {
      let payload = {
        boardid: selectedBoard._id,
        labelTitle: title,
        labelBackgroundColor: labelColor,
      };
      dispatch(createNewLabelInBoard(payload));
    }
  };
  const handleColor = (Color) => {
    setLabelColor(Color);
  };
  const handleDelete = () => {
    if (props.labelId._id) {
      let payload = {
        boardid: selectedBoard._id,
        labelid: props.labelId._id,
      };
      setShow(false);

      dispatch(deleteLabelInBoard(payload));
    } else {
      setShow(false);
    }
  };

  const handleRemoveColor = () => {
    setLabelColor("rgba(9, 30, 66, 0.04)");
  };

  useEffect(() => {
    props &&
      props.labelId &&
      setSelectedLabel(
        selectedBoard.boardLabels.filter(
          (obj) => obj._id === props.labelId._id,
        )[2],
      );
  }, []);

  console.log("props", selectedBoard._id)

  return (
    <>
      {show && (
        <Card
          ref={popupRef}
          className="shadow rounded-0 shadow"
          style={{
            position: "fixed",
            right: "210px",
            top: "10px",
            width: "340px",
            zIndex: "1",
          }}
        >
          <section>
            <p
              className=" text-center text-muted mt-3"
              style={{ fontSize: "16px" }}
            >
              <span
                style={{ position: "relative", right: "100px" }}
                className="text-muted"
                onClick={handleClose}
              >
                <i
                  className="fa-solid fa-angle-left fa-s"
                  onClick={handleClose}
                ></i>
              </span>
              {LabelID ? "Edit Label" : "Create Label"}
              <span
                style={{ position: "relative", left: "100px" }}
                className="text-muted"
                onClick={handleClose}
              >
                <i
                  className="fa-solid fa-xmark fa-s"
                  onClick={handleallClose}
                ></i>
              </span>
            </p>
            <hr className="mx-2" />
            <div className="show_color">
              <div className="label_div">
                <div
                  className="label01_div_text pd_left label_wh"
                  style={{
                    backgroundColor: labelColor,
                    color: labelColor === "#091E42" ? "white" : "black",
                  }}
                >
                  <span className="ms-4">{title}</span>
                </div>
              </div>
            </div>
            <div className="px-2">
              <label className="text-muted mx-2 pop-over-section">Title</label>
              <input
                type="search"
                className="label_input"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <p className="text-muted mx-2 pop-over-section">Select a color</p>
            <div className="scrollable-member">
              <div className="colorBox">
                <div className="colorBox_section">
                  <div
                    className="section_box bg1"
                    onClick={() => handleColor("#B7DDB0")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg2"
                    onClick={() => handleColor("#FAD29C")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg3"
                    onClick={() => handleColor("#EFB3AB")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg4"
                    onClick={() => handleColor("#DFC0EB")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg5"
                    onClick={() => handleColor("#7BC86C")}
                  ></div>
                </div>

                <div className="colorBox_section">
                  <div
                    className="section_box bg6"
                    onClick={() => handleColor("#F5DD29")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg7"
                    onClick={() => handleColor("#FFAF3F")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg8"
                    onClick={() => handleColor("#EF7564")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg9"
                    onClick={() => handleColor("#CD8DE5")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg10"
                    onClick={() => handleColor("#5AAC44")}
                  ></div>
                </div>

                <div className="colorBox_section">
                  <div
                    className="section_box bg11"
                    onClick={() => handleColor("#E79217")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg12"
                    onClick={() => handleColor("#E6C60D")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg13"
                    onClick={() => handleColor("#CF513D")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg14"
                    onClick={() => handleColor("#A86CC1")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg15"
                    onClick={() => handleColor("#8BBDD9")}
                  ></div>
                </div>

                <div className="colorBox_section">
                  <div
                    className="section_box bg16"
                    onClick={() => handleColor("#8BBDD9")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg17"
                    onClick={() => handleColor("#F9C2E4")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg18"
                    onClick={() => handleColor("#505F79")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg19"
                    onClick={() => handleColor("#5BA4CF")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg20"
                    onClick={() => handleColor("#29CCE5")}
                  ></div>
                </div>

                <div className="colorBox_section">
                  <div
                    className="section_box bg21"
                    onClick={() => handleColor("#6DECA9")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg22"
                    onClick={() => handleColor("#FF8ED4")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg23"
                    onClick={() => handleColor("##00AECC")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg24"
                    onClick={() => handleColor("#026AA7")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg25"
                    onClick={() => handleColor("#00AECC")}
                  ></div>
                </div>

                <div className="colorBox_section">
                  <div
                    className="section_box bg26"
                    onClick={() => handleColor("#4ED583")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg27"
                    onClick={() => handleColor("#E568AF")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg28"
                    onClick={() => handleColor("#091E42")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg29"
                    onClick={() => handleColor("#505F79")}
                  ></div>
                </div>
                <div className="colorBox_section">
                  <div
                    className="section_box bg30"
                    onClick={() => handleColor("#CF513D")}
                  ></div>
                </div>
              </div>
            </div>

            <div className="m-3">
              {selectedBoard.boardLabels && selectedBoard.boardLabels.length > 6 && (
                <button
                  className="label_btn_wh label_btn "
                  onClick={() => handleRemoveColor()}
                >
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                    style={{ marginRight: "10px" }}
                  ></i>{" "}
                  Remove color
                </button>
              )}
            </div>
            <hr />
            <div className="d-flex justify-content-between mb-3">
              <button
                type="button"
                className="btn btn-primary btn-sm ms-2"
                onClick={() => handleEditLabels()}
              >
                Save
              </button>
              {LabelID ?
                <button
                  type="button"
                  className="btn btn-danger btn-sm me-2"
                  onClick={() => handleDelete()}
                >
                  Delete
                </button>
                :
                <></>}

            </div>
          </section>
        </Card>
      )}
    </>
  );
};
LabelEditPop.propTypes = {
  labelId: PropTypes.node.isRequired,
  setShowPopup: PropTypes.node.isRequired,
  toDone: PropTypes.node.isRequired,
  boardid: PropTypes.node.isRequired,
};
export default LabelEditPop;
