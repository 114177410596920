import React, { Component } from 'react';
import axios from 'axios';
import { API_HOST } from '../services/url';
import { Link } from 'react-router-dom';

class ViewPlan extends Component {
    constructor(propos) {
        super();
        this.state = {
            data: [],
        };
    }

    async getData() {
        const token = localStorage.getItem('cxtoken');

        await axios
            .get(API_HOST + `admin/whatsappBotChat/preparedAnswer`, {
                headers: { 'x-access-token': token },
            })
            .then(response => {
                console.log(response);
                this.setState({
                    data: response.data.data,
                });
            });
    }

    handleDelete = e => {
        e.preventDefault();
        const token = localStorage.getItem('cxtoken');
        const url = API_HOST + 'admin/whatsappBotChat/preparedAnswer/' + e.target.id;
        console.log(url);
        axios.delete(url, { headers: { 'x-access-token': token } }).then(response => {
            console.log(response);
            this.getData();
        });
    };

    renderTableData() {
        return this.state.data.map((row, index) => {
            const { key, answer } = row; //destructuring
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{key}</td>
                    <td dangerouslySetInnerHTML={{ __html: answer }}></td>

                    <td>
                        <button className="btn btn-primary" type="button">
                            <Link
                                to={'/edit-bot-response'}
                                state={{ id: row._id, title: key, data: answer }}
                                className="text-no-underline text-white"
                            >
                                Edit
                            </Link>
                        </button>
                    </td>
                    <td>
                        <button
                            className="btn btn-danger"
                            id={row._id}
                            type="button"
                            onClick={e =>
                                window.confirm('Are you sure you want to delete?') &&
                                this.handleDelete(e)
                            }
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            );
        });
    }

    componentDidMount() {
        this.getData();
    }
    render() {
        return (
            <div>
                <div className="mainContent">
                    <div className="container">
                        <div className="page-header text-center">
                            <h3>Whatsapp bot prepared answer Table</h3>
                        </div>
                        <table className="table table-bordered text-center table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">SNo.</th>
                                    <th scope="col">User Question</th>
                                    <th scope="col">Bot Response</th>
                                    <th scope="col" colSpan="3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{this.renderTableData()}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewPlan;
