import React, { useState, useEffect } from 'react';
import Card from './Card';
import { getBlogs } from './Api';
import axios from 'axios';
import { CX_DEPLOYER } from '../../services/url';
import { Link } from 'react-router-dom';
import '../../inquiries/inquiries.css';
import ReactPaginate from 'react-paginate';
export default function AllBlogs() {
    const [loading, setloading] = useState(true);
    const [data, setdata] = useState([]);
    const [pageSize, setPageSize] = useState(15);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
        fetchBlogs(selected);
    };
    const fetchBlogs = selected => {
        let payload = {
            page_size: pageSize,
            page_number: selected,
        };
        getBlogs(payload)
            .then(res => {
                if (res.success) {
                    setdata(res.data);
                    setPageCount(Math.ceil(res.metadata.total_data / pageSize));
                }
            })
            .catch(error => console.log(error))
            .finally(() => setloading(false));
    };
    const handleDelete = e => {
        e.preventDefault();
        const id = e.target.id;
        const name = e.target.name;
        const token = localStorage.getItem('cxtoken');
        axios
            .delete(CX_DEPLOYER + 'blog', {
                data: {
                    id: id,
                    file: name,
                },
                headers: { 'x-access-token': token },
            })
            .then(res => {
                console.log(res);
                window.location.reload(false);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchBlogs(0);
    }, []);
    document.title =
        'Blogs | Persona | Empathy | Value | Journey Mapping | Innovation | Kanban | CXDeployer';
    return (
        // <div className="pt-5">
        <div className="container">
            <div className=" text-center">
                <h1>All Blogs</h1>
            </div>
            {loading ? (
                <div className="row mt-5 text-center align-content-center d-flex justify-content-center">
                    <div
                        className="spinner-grow text-primary"
                        style={{ height: '100px', width: '100px' }}
                        role="status"
                    >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    <div
                        className="row mt-5"
                        style={{ padding: '10px', marginBottom: '15px', height: '450px' }}
                    >
                        {data.map(blog => {
                            return (
                                <div
                                    className="col-lg-4 "
                                    style={{ marginBottom: '5px', height: '450px' }}
                                >
                                    <div className="card p-2 gap-2">
                                        {/*<div className="card-body">
                                                        <h5 className="card-title">
                                                          {blog.title}
                                                          <sub className="d-flex justify-content-end p-1">
                                                            - {blog.uploadDate && blog.uploadDate.split("T")[0]}
                                                          </sub>
                                                        </h5>
                                                        <div
                                                          className="card-text"
                                                          style={{
                                                            height: 150,
                                                            overflowY: "scroll",
                                                            marginTop: 10,
                                                          }}
                                                        >
                                                          <div
                                                            dangerouslySetInnerHTML={{
                                                              __html: blog.blog_content,
                                                            }}
                                                          />
                                                        </div>
                                              </div>*/}
                                        <Card title={blog.title} titleImage={blog.titleImage} />
                                        <Link
                                            className="btn btn-dark"
                                            to={{ pathname: `/view/${blog._id}`, blog: blog }}
                                        >
                                            Read this article
                                        </Link>
                                        <Link
                                            className="btn btn-dark mt-2"
                                            to={{
                                                pathname: `/editblog/${blog._id}`,
                                                blog: blog,
                                            }}
                                        >
                                            Edit
                                        </Link>

                                        <Link
                                            className="btn btn-danger mt-2"
                                            name={blog.image}
                                            id={blog._id}
                                            onClick={e =>
                                                window.confirm(
                                                    `Are you sure you want to delete this article?`
                                                ) && handleDelete(e)
                                            }
                                        >
                                            Delete this article
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                        <ReactPaginate
                            pageCount={pageCount} // Replace with your actual number of pages
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName="pagination-container"
                            pageClassName="pagination-item"
                            breakClassName="pagination-item"
                            previousClassName="pagination-item"
                            nextClassName="pagination-item"
                            activeClassName="pagination-item active"
                        />
                    </div>
                </>
            )}
        </div>
        // </div>
    );
}
