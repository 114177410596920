import React, { useCallback, useState, useEffect, useRef } from "react";
import CardLane from "./drop-down-assets/card-lane.svg";
import BubbleLane from "./drop-down-assets/bubbl-lane.svg";
import PhaseLane from "./drop-down-assets/phase-lane.svg";
import LineLane from "./drop-down-assets/line-lane.svg";
import ImageLane from "./drop-down-assets/image-lane.svg";
import FileLane from "./drop-down-assets/file-lane.svg";
import TextLane from "./drop-down-assets/text-lane.svg";
import Tooltip from "@material-ui/core/Tooltip";
import "./AddLane.css";

const LaneCard = ({ img, text, tooltipText, onClick, id }) => {
  return (
    <div
      className="col-12 hover-effect"
      onClick={onClick}
      data-toggle="modal"
      data-target={"#" + id}
      data-dismiss="modal"
      id="click"
    >
      <Tooltip title={<span>{tooltipText}</span>}>
        <div className="row align-items-center p-3 no-gutters" style={{ cursor: "pointer" }}>
          <div className="col-6">
            <img src={img} alt={text} className="img-fluid" />
          </div>
          <div className="col-6">
            <h6 className="text-center mb-0">{text}</h6>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

const AddLane = (props) => {
  const [isVisible, setVisible] = useState(false);
  const ref = useRef(null);

  const handleClick = useCallback(
    (type) => {
      props.setType(type);
      setVisible(false);
      props.updateShowEditModal(true);
    },
    [props]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ height: "110vh", overflow: "hidden" }}>
      <div
        className="px-5 py-4 c-pointer hover-effect11 mt-1"
        onClick={() => setVisible(!isVisible)}
        id="click"
      >
        <h4 className="mb-0">
          <span className="fas fa-plus mr-2"></span> Add Lane
        </h4>
      </div>
      {isVisible && (
        <div className="row" style={{ zIndex: "100", marginTop: "20px" }}>
          <div style={{ width: "400px" }}>
            <div className="card shadow-sm" ref={ref}>
              <div className="card-body py-0">
                <div className="row">
                  <LaneCard
                    text="Phase Lane"
                    img={PhaseLane}
                    id={props.id}
                    onClick={() => handleClick("phase")}
                    tooltipText="Select this lane to map stages of a customer journey or user experience to define the basic structure. As an example, stages or phases could be before, during and after or pre, during or post etc."
                  />
                  <LaneCard
                    text="Card Lane"
                    img={CardLane}
                    id={props.id}
                    onClick={() => handleClick("card")}
                    tooltipText="Select this lane to capture and map the customer activites, steps, interactions   and moments of truth."
                  />
                  <LaneCard
                    text="Bubble Lane"
                    img={BubbleLane}
                    id={props.id}
                    onClick={() => handleClick("bubble")}
                    tooltipText="Select this lane to map the goals, problems, ideas, opportunities, and other comments."
                  />

                  <LaneCard
                    text="Line Lane"
                    img={LineLane}
                    id={props.id}
                    onClick={() => handleClick("line")}
                    tooltipText="Select this lane to capture the emotional state of a
                  customer or user which represents their level of
                  satisfaction at each step or interaction on a five-point
                  Likert scale where 0 is neutral, +2 is very satisfied and
                  -2 is very unsatisfied."
                  />
                  <LaneCard
                    text="Image Lane"
                    img={ImageLane}
                    id={props.id}
                    onClick={() => handleClick("image")}
                    tooltipText="Select this lane to storyboard your journey map by adding
                      visuals through icons, photos, and screenshots etc."
                  />
                  <LaneCard
                    text="File Lane"
                    img={FileLane}
                    id={props.id}
                    onClick={() => handleClick("file")}
                    tooltipText=" Select this lane to attach documents and other assets
                      related to a step or interaction to provide more context
                      and information."
                  />
                  <LaneCard
                    text="Text Lane"
                    img={TextLane}
                    id={props.id}
                    onClick={() => handleClick("text")}
                    tooltipText=" Select this lane to attach documents and other assets
                      related to a step or interaction to provide more context
                      and information."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddLane;
