import { Popover } from "@material-ui/core";
import htmlReactParser from "html-react-parser";
import React, { Component, Fragment } from "react";
import { Item, Menu, MenuProvider } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import { IconPickerItem } from "react-fa-icon-picker";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { v4 as uuid } from "uuid";
import { Modal as EditingModal } from "../../../../Components";
import Comment from "../../../../Components/Modal/Comment";
import AddNode from "../AddNode";
import AddNodeMiddle from "../AddNodeMiddle";
import LaneModal from "../../../../Components/Modal/LaneModal";

import "../../CJM.css";
const initialState = {
	mouseX: null,
	mouseY: null,
};
export default class TextLane extends Component {
	constructor() {
		super();
		this.state = {
			addNodeAt: "",
			laneid: "",
			html: "",
			cardid: "",
			editing: false,
			color: "",
			history: "",
			status: "",
			icon: "",
			nodeIdx: "",
			mouse: initialState,
			anchorEl: null,
			comment: [],
			show:false,
			editingtext:false
		};
	}

	addNode = (html, color, icon, status) => {
		const idx = this.state.addNodeAt;

		if (this.state.editingtext == false) {
			const newNode = {
				laneType: "text",
				textHTML: html,
				gridID: idx.toString(),
				date: Date(Date.now()),
				color: color,
				icon: icon,
				textStatus: status,
				textCategory: "Customer",
			};
			this.props.addNode(idx, this.state.laneid, newNode);
		} else {
			let comment = this.state.comment;
			// for (var i = 0; i < comment.length; i++) {
			//   comment[i].userid = comment[i].userId._id;
			// }
			const newNode = {
				laneType: "text",
				textHTML: html,
				gridID: idx,
				date: Date(Date.now()),
				color: color,
				icon: icon,
				_id: this.state.cardid,
				textStatus: status,
				textCategory: "Customer",
				history: this.state.history,
				comment: comment,
			};
			
			this.props.editNode(idx, this.state.laneid, this.state.cardid, newNode);
		}
	};
	ColorLuminance(hex, lum) {
		// validate hex string
		hex = String(hex).replace(/[^0-9a-f]/gi, "");
		if (hex.length < 6) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		lum = lum || 0;

		// convert to decimal and change luminosity
		var rgb = "#",
			c,
			i;
		for (i = 0; i < 3; i++) {
			c = parseInt(hex.substr(i * 2, 2), 16);
			c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
			rgb += ("00" + c).substr(c.length);
		}
		return rgb;
	}
	hexToRGB(hex, alpha) {
		var r = parseInt(hex.slice(1, 3), 16),
			g = parseInt(hex.slice(3, 5), 16),
			b = parseInt(hex.slice(5, 7), 16);

		if (alpha) {
			return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
		} else {
			return "rgb(" + r + ", " + g + ", " + b + ")";
		}
	}

	openAddNodeAt = (idx, laneid, id) => {
		this.setState({ addNodeAt: idx, laneid, editing: false,show:true,editingtext:false });
	};
	openEditNodeAt = (
		idx,
		laneid,
		cardid,
		html,
		color,
		history,
		status,
		icon,
		comment,
		id
	) => {
		// try {
		// 	const modal = document.getElementById(id);
		// 	if (modal) {
		// 		modal.style.display = "block";
		// 	}
		// } catch (error) {
		// 	console.log(error.message);
		// }

		this.setState({
			html,
			editing: false,
			editingtext:true,
			show:false,
			addNodeAt: idx,
			laneid,
			cardid,
			color,
			history: history === undefined ? [] : history,
			status,
			comment,
			icon,
		});
	};

	editLane = (html, color, icon) => {
		const newlane = {
			_id: this.props.lane._id,
			laneType: "text",
			laneName: html,
			icon: icon,
			color: color,
			date: Date(Date.now()),
			laneGridNo: this.props.lane.laneGridNo,
			expand: this.props.lane.expand,
		};
		this.props.editLane(this.props.lane._id, newlane);
	};

	openModal = () => {
		this.setState({editingtext:false,editing:true})
	};
	onDragStart = (event, idx) => {
		console.log(idx);
		this.setState({ nodeIdx: idx });
	};
	onDragOver = (event) => {
		event.preventDefault();
	};
	onDrag = (event) => {
		event.preventDefault();
	};
	onDrop = (event, idx) => {
		let nodes = this.props.lane.nodes;
		let cardWidth = this.props.cardWidth;
		console.log(idx);
		console.log(event.target);
		let nodeIdx = nodes.findIndex((node) => node.gridID == this.state.nodeIdx);
		let dropIdx = nodes.findIndex((node) => node.gridID == idx);
		console.log("drop", dropIdx);
		if (dropIdx != -1) {
			for (var i = 0; i < nodes.length; i++) {
				let gridID = parseInt(nodes[i].gridID);
				if (gridID >= idx) nodes[i].gridID = gridID + 1;
				if (nodes[i].gridID == cardWidth) {
					cardWidth += 1;
				}
			}
		}
		console.log(nodeIdx);
		nodes[nodeIdx].gridID = idx;
		if (idx == cardWidth) cardWidth = cardWidth + 1;
		console.log(nodes);
		let lane = this.props.lane;
		lane.nodes = nodes;
		this.props.dragNode(lane, cardWidth);
	};
	addComment = (text) => {
		this.props.addComment(
			"TextLane",
			this.props.lane._id,
			this.state.nodeIdx,
			text
		);
	};
	commentClick = (event, nodeIdx, comment, bool) => {
		console.log(event);
		if (bool)
			this.setState(
				{
					anchorEl: event.currentTarget,
					mouse: initialState,
					nodeIdx,
					comment,
				},
				console.log(this.state.anchorEl)
			);
		else
			this.setState(
				{
					anchorEl: event.event.target,
					mouse: initialState,
					nodeIdx,
					comment,
				},
				console.log(this.state.anchorEl)
			);
	};

	commentClose = () => {
		this.setState({ anchorEl: null });
	};
	render = () => {
		let {
			lane: { nodes },
			cardWidth,
		} = this.props;
		console.log(nodes, "This is Card")
		const uniqueid = uuid();
		const { anchorEl, comment, nodeIdx } = this.state;
		const open = Boolean(anchorEl);
		const chatid = open ? "simple-popover" : undefined;
		return (
			<div
				className="lane d-flex"
				style={{
					// width: `${(cardWidth + 1) * 291 + "px"}`,

					background: this.hexToRGB(`${this.props.lane.color}`, 0.2),
					borderTop: "1px solid " + `${this.props.lane.color}`,
					width: "200%",
					overflowX: 'auto'
				}}
			>
				{/* {this.state.editing == true ? (
					<EditingModal
						id={uniqueid}
						title="Edit Text"
						handleSubmit={this.addNode}
						html={this.state.html}
						history={this.state.history}
						status={this.state.status}
						color={this.state.color}
						icon={this.state.icon}
					/>
				) : (
					<EditingModal
						id={uniqueid}
						title="Add Text"
						handleSubmit={this.addNode}
						color={this.props.lane.color}
					/>
				)}
				<EditingModal
					id={uniqueid + "la"}
					title="Edit Lane"
					handleSubmit={this.editLane}
					html={this.props.lane.laneName}
					color={this.props.lane.color}
					history={this.props.lane.history}
					icon={this.props.lane.icon}
				/> */}
				<div className="parentlane">
					<div
						className="lanename"
						data-toggle="modal"
						data-target={"#" + uniqueid + "la"}
						data-dismiss="modal"
						onClick={()=>this.openModal()}
						style={{
							background: this.hexToRGB(`${this.props.lane.color}`, 0.4),
						}}
					>
						{htmlReactParser(this.props.lane.laneName || "")}
					</div>
					<div style={{ marginLeft: "-30px" }}>
						{" "}
						{this.props.lane.icon ? (
							<div style={{ marginBottom: "70px" }}>
								<IconPickerItem
									icon={this.props.lane.icon}
									size={18}
									color="#000"
								/>
							</div>
						) : (
							<div style={{ marginBottom: "100px", marginLeft: "23px" }}></div>
						)}
						<div className="shift d-flex">
							<div
								onClick={() => this.props.shiftlane(this.props.lane._id, 1)}
								className="shiftdown"
							>
								<FaChevronDown aria />
							</div>
							<div
								onClick={() => this.props.shiftlane(this.props.lane._id, -1)}
								className="shiftup"
							>
								<FaChevronUp />
							</div>
						</div>
						<div
							className="deletelaneicon"
							onClick={() =>
								// window.confirm(`Are you sure you want to delete lane?`) &&
								this.props.deleteLane(
									this.props.lane._id,
									this.props.lane.laneType
								)
							}
						>
							<RiDeleteBin6Line className="text-danger" />
						</div>
					</div>
				</div>
				<div className="nodes" onDragOver={(event) => this.onDragOver(event)}>
					{Array(cardWidth)
						.fill(0)
						.map((_, idx) => {
							const node = nodes.find((node) => parseInt(node.gridID) === idx);
							const nodeindex = nodes.findIndex(
								(node) => parseInt(node.gridID) === idx
							);
							if (node) {
								return (
									<Fragment key={idx}>
										<AddNodeMiddle
											openAddNodeAt={() =>
												this.openAddNodeAt(idx, this.props.lane._id, uniqueid)
											}
											id={uniqueid}
										/>
										<div
											className="parent"
											key={idx}
											draggable="true"
											onDragStart={(event) => this.onDragStart(event, idx)}
											onDrag={(event) => {
												this.onDrag(event);
											}}
											onDrop={(event) => this.onDrop(event, idx)}
										>
											<MenuProvider id={node._id}>
												<div
													className="textlane-node"
													data-toggle="modal"
													data-target={"#" + uniqueid}
													data-dismiss="modal"
													style={{
														border:
															"2px dashed " +
															this.hexToRGB(`${node.color}`, 0.8),
														borderRadius: "5%",
														cursor: "pointer",
													}}
													onClick={() =>
														this.openEditNodeAt(
															idx,
															this.props.lane._id,
															node._id,
															node.textHTML,
															node.color,
															node.history,
															node.status,
															node.icon,
															node.comment,
															uniqueid
														)
													}
												>
													{htmlReactParser(node.textHTML || "")}
												</div>
											</MenuProvider>
											{node.icon ? (
												<div className="icon-box">
													<IconPickerItem
														icon={node.icon}
														size={18}
														color="#000"
													/>
												</div>
											) : (
												""
											)}
											{node.comment?.length !== 0 ? (
												<div
													className="comment-icon"
													onClick={(e) => {
														this.commentClick(e, nodeindex, node.comment, true);
													}}
													aria-describedby={chatid}
													variant="contained"
													title="Comments"
												>
													<i className="fa fa-comments" aria-hidden="true"></i>
												</div>
											) : (
												""
											)}
											<div
												className="deleteicon"
												onClick={() =>
													// window.confirm(
													//   `Are you sure you want to delete text?`
													// ) &&
													this.props.deleteNode(
														node._id,
														this.props.lane._id,
														this.props.lane.laneType
													)
												}
											>
												<RiDeleteBin6Line className="text-danger" />
											</div>
											<Menu id={node._id}>
												<Item
													onClick={() => {
														this.openEditNodeAt(
															idx,
															this.props.lane._id,
															node._id,
															node.textHTML,
															node.color,
															node.history,
															node.status,
															node.icon,
															node.comment,
															uniqueid
														);
													}}
												>
													Edit
												</Item>
												<Item
													onClick={() => {
														// window.confirm(
														//   `Are you sure you want to delete card?`
														// ) &&
														this.props.deleteNode(
															node._id,
															this.props.lane._id,
															this.props.lane.laneType
														);
													}}
												>
													Delete
												</Item>
												<Item
													onClick={(e) => {
														this.commentClick(
															e,
															nodeindex,
															node.comment,
															false
														);
													}}
													aria-describedby={chatid}
													variant="contained"
												>
													Comment
												</Item>
											</Menu>
										</div>
									</Fragment>
								);
							}
							return (
								<AddNode
									openAddNodeAt={() =>
										this.openAddNodeAt(idx, this.props.lane._id, uniqueid)
									}
									id={uniqueid}
									index={idx}
									onDrop={(event) => this.onDrop(event, idx)}
								/>
							);
						})}

					<AddNode
						openAddNodeAt={() =>
							this.openAddNodeAt(cardWidth, this.props.lane._id, uniqueid)
						}
						id={uniqueid}
						index={cardWidth}
						onDrop={(event) => this.onDrop(event, cardWidth)}
					/>
					<Popover
						id={chatid}
						open={open}
						anchorEl={anchorEl}
						onClose={this.commentClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						{nodeIdx !== "" && this.props.lane.nodes[nodeIdx] ? (
							<Comment
								addComment={this.addComment}
								comment={this.props.lane.nodes[nodeIdx].comment}
							/>
						) : (
							<Comment addComment={this.addComment} comment={comment} />
						)}
					</Popover>
				</div>
				{this.state.editing === true ? 
					<LaneModal 
						title="Edit Lane"
						handleSubmit={this.editLane}
						html={this.props.lane.laneName}
						history={this.props.lane.history}
						color={this.props.lane.color}
						status={this.props.lane.status}
						icon={this.props.lane.icon}
						show={true} 
						close={() => this.setState({ editing: false })}
						/>
				 : this.state.editingtext == true ? 
				<LaneModal 
						title="Edit Text"
						handleSubmit={this.addNode}
						html={this.state.html}
						history={this.state.history}
						color={this.state.color}
						status={this.state.status}
						icon={this.state.icon}
						show={true} 
						close={() => this.setState({ editingtext: false })}
						/>: this.state.show == true &&
					<LaneModal
						title="Add Text"
						show={this.state.show} 
						// html={this.props.lane.laneName}
						handleSubmit={this.addNode}
						// color={this.props.lane.color}
						close={() => this.setState({ show: false })}

					/>
				}
			</div>
		);
	};
}
