import "./style/ListEditor.css";
import React, { useState ,useRef,useEffect} from "react";

import TextareaAutosize from "react-textarea-autosize";

const ListEditor =({ title, handleChangeTitle, deleteList ,saveList,onClickOutside}) =>{

  const editBucketRef = useRef(null);

 const  onEnter = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
     saveList();
    }
  };

  const handleClickOutside = (event) => {
    if (editBucketRef.current && !editBucketRef.current.contains(event.target)) {
      onClickOutside()
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
    return (
      <div className="List-Title-Edit"
       
       >
        <TextareaAutosize
                ref={editBucketRef}

          autoFocus
          className="List-Title-Textarea"
          placeholder="Enter list title..."
          value={title}
          onChange={handleChangeTitle}
          onKeyDown={onEnter}
          style={{ width: deleteList ? 220 : 245 }}
        />
        {deleteList && <ion-icon name="trash" onClick={deleteList} />}
      </div>
    );

}

export default ListEditor;
