import "./style/Card.css";
import React,{useCallback} from "react";
import { Draggable } from "react-beautiful-dnd";
import NewCard from "./newCard";
import EditCard from "./EditCard";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedCard } from "../redux/action/card";
import { setSelectedBucket } from '../redux/action/bucket'
const Card = ({ card, index, list }) => {

  const [hover, setHover] = useState(false);
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState();
  const dispatch = useDispatch()

  const startHover = () => setHover(true);
  const endHover = () => setHover(false);

  const startEditing = () => {
    setHover(false);
    setEditing(true);
    setText(card.text)
  }

  const endEditing = () => { setHover(false); setEditing(false) }

  const handleSelectCard =useCallback( (card, bucketId) => {

    dispatch(setSelectedCard(card));
    dispatch(setSelectedBucket(bucketId));
  },[])

  return (
    <>
      {
        editing ?
          <NewCard
            handleCloseCard={endEditing}
          /> : <>
            <Draggable draggableId={card._id} index={index}>
             
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  // className="Card"
                  onMouseEnter={startHover}
                  onMouseLeave={endHover}
                  
                >
                 
                  {/*............... Pencil Icon hover effect............... */}
                  {/* {hover && (
                    <div className="Card-Icons">
                      <div className="Card-Icon" onClick={startEditing}>
                        <ion-icon name="create" />
                      </div>
                    </div>
                  )} */}

                  {/* {card.cardTitle} */}
                  <EditCard
                    handleSelectCard={handleSelectCard}
                    card={card}
                    bucket={list}
                    />
                </div>
              )}
            </Draggable>
          </>

      }
    </>

  )


}


export default Card;
