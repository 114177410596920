import React from 'react';
import PropTypes from 'prop-types';

export default function SaveTemplateDialog({ onSave }) {
    const [templateName, setTemplateName] = React.useState('');

    const handleClose = () => {
        setTemplateName('');
        window.$('#save-template-modal .close').click();
    };

    const handleSave = () => {
        handleClose();
        onSave(templateName);
    };

    return (
        <div className="modal" id="save-template-modal" data-backdrop="">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content border-0">
                    <div className="modal-header bg-primary text-white rounded-top border-0">
                        <h4 className="modal-title">Template name</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleClose}
                        >
                            &times;
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="form-group">
                            <label for="template-name">Template Name</label>
                            <input
                                type="text"
                                className="form-control rounded-1 pl-3"
                                id="template-name"
                                value={templateName}
                                onChange={e => setTemplateName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn rounded-1 btn-block text-white bg-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
SaveTemplateDialog.propTypes = {
    /**
     * @callback saveTemplateCallback
     * @type <Function(name:String)>
     * @param {String} name The name of the template
     */
    onSave: PropTypes.func,
};
