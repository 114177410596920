import React from "react";
import { Droppable } from "react-beautiful-dnd";
import NodeDraggable from "./NodeDraggable";
import { v4 as uuid } from "uuid";

export default ({ nodeContainerIndex, node, addNode, color, deleteNode }) => {
  return (
    <div className="linelane-node__container d-flex flex-column align-items-stretch">
      {Array(5)
        .fill(0)
        .map((b, index) => {
          return (
            <div className="wrapLine">
              <Droppable
                droppableId={`${nodeContainerIndex}-${index}`}
                key={uuid()}
              >
                {(provided) => {
                  return (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <NodeDraggable
                        nodeContainerIndex={nodeContainerIndex}
                        index={index}
                        isVisible={index === node.index}
                        isAdd={typeof node.index !== "number"}
                        addNode={addNode}
                        color={color}
                        deleteNode={deleteNode}
                      />
                    </div>
                  );
                }}
              </Droppable>
            </div>
          );
        })}
    </div>
  );
};
