import React from "react";

export default (props) => {
  return (
    <div
      className="addnode-middle"
      data-toggle="modal"
      data-target={"#" + props.id}
      data-dismiss="modal"
      onClick={props.openAddNodeAt}
      style={{ height: props.height + " !important" }}
    >
      <div className="fas fa-2x fa-plus"></div>
    </div>
  );
};
