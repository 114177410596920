import { useState,useRef,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBucket } from "../redux/action/bucket";
import React from "react";
import { RxCross2 } from "react-icons/rx";
import TextareaAutosize from "react-textarea-autosize";

const AddNewBucket = ({ handleCloseBucket }) => {
  const [bucketName, setbucketName] = useState("");
  const dispatch = useDispatch();
  const { selectedBoard } = useSelector((store) => store.boardRoot);
  const { allBucket } = useSelector((store) => store.bucketRoot);
  const cardaddRef = useRef(null);
 
  const handleClose = () => {
    handleCloseBucket();
  };
  const onEnter = (e) =>{
    console.log(e.keyCode,"keycode")
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSaveClick();
    
    }
  }
  const handleClickOutside = (event) => {
    if (cardaddRef.current && !cardaddRef.current.contains(event.target)) {
      handleSaveClick()
  setTimeout(()=>{
    handleClose()
  },1000)
    }
  };
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleSaveClick = (e) => {
      let boardId = selectedBoard._id;
      let bucketIndex = allBucket.length;
      if(bucketName){
      let payload = {
        bucketName,
        bucketIndex,
        boardId,
      };
  
      dispatch(
        addBucket(payload, boardId, async function (response) {
          if (response) {
            handleCloseBucket();
          } else {
            handleCloseBucket();
          }
        })
      );
      }
    
    
  };
  return (
    <div  className="Add-List-Editor" style={{backgroundColor:"#dfe3e6", borderRadius:"10px"}}>



      <TextareaAutosize
        autoFocus
        className="p-1  List-Title-Textarea"
        style={{
          resize: "none",
          width: "246px",
          padding: "1.25rem",
          marginBottom:"-5px"
        }}
        ref={cardaddRef}
        placeholder="Enter bucket name"
        value={bucketName}
        onChange={(e) => setbucketName(e.target.value)}
        onKeyDown={(e) => onEnter(e)}

      />
      {/* <button onClick={() => handleSaveClick()} className="btn btn-primary m-2" style={{padding: "0px 5px"}}>
          <i className="fa-solid fa-floppy-disk"></i>
        </button>
        <button onClick={() => handleClose()} className="btn btn-danger m-2" style={{padding: "0px 5px", fontSize: "14px"}}>
          <i className="fa-solid fa-trash"></i>
        </button> */}

      <button
        onClick={(e) => handleSaveClick(e)}
        className=" m-2 add-card-btncross"
        style={{ padding: "5px 5px" }}
      >
        Add bucket
      </button>
      <span
        onClick={() => handleClose()}
        className=" "
        style={{ padding: "0px 5px", border: "none" }}
      >
        <RxCross2 size={20} />
      </span>

    </div>
  );
};
export default AddNewBucket;
