import React, { Component } from 'react';
import axios from 'axios';
import { CX_DEPLOYER, API_HOST } from '../services/url';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import QuillEditor from './quill';

class AddCareer extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            title: '',
            allTags: [],
            tags: [],
            formLink: '',
            description: '',
            jobJd: '',
        };
        //   this.onSubmit = this.onSubmit.bind(this);
    }

    async getTags() {
        const token = localStorage.getItem('cxtoken');
        axios
            .get(API_HOST + 'web/tags', {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                const lowercaseTags = res.data.data.tags.map(tag => tag.tag.toLowerCase());
                this.setState({ allTags: lowercaseTags });
            });
    }
    async componentDidMount() {
        await this.getTags();
    }
    async onSubmit(e) {
        e.preventDefault();
        let payload = {
            jobTitle: this.state.title,
            jobDesc: this.state.description,
            formLink: this.state.formLink,
            tags: this.state.tags,
            jobJd: this.state.jobJd,
            adminId: localStorage.getItem('adminId'),
        };

        const token = localStorage.getItem('cxtoken');
        axios
            .post(CX_DEPLOYER + 'careers', payload, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                alert('Career Added');
                window.location.replace('/careers');
            });
    }
    onEditorChange = jobJd => {
        this.setState({ jobJd });
    };
    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 col-md-9 mx-auto">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h4 className="card-title text-center">Add Career</h4>
                                    <form className="form-signin" method="POST">
                                        <div className="form-group">
                                            <label for="email">Title</label>
                                            <input
                                                id="title"
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                value={this.state.title}
                                                onChange={e => this.change(e)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label for="email">Title Description</label>
                                            <textarea
                                                id="description"
                                                type="text"
                                                className="form-control"
                                                name="description"
                                                value={this.state.description}
                                                onChange={e => this.change(e)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label for="email">Form Link</label>
                                            <input
                                                id="formLink"
                                                type="text"
                                                className="form-control"
                                                name="formLink"
                                                value={this.state.formLink}
                                                onChange={e => this.change(e)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label for="tags">Tags</label>
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                options={this.state.allTags}
                                                freeSolo
                                                onChange={(e, newValue) =>
                                                    this.setState({ tags: newValue })
                                                }
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Add Tag"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label for="email">JD</label>
                                            <QuillEditor
                                                placeholder={'Start Posting Something'}
                                                onEditorChange={this.onEditorChange}
                                            />
                                        </div>
                                        <div className="form-group no-margin mt-2">
                                            <button
                                                className="btn btn-info btn-block"
                                                onClick={e => this.onSubmit(e)}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddCareer;
