import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { deleteFormTemplate, getFormTemplate, setSelectedBoard } from '../redux/action/board';
import CreateFormPopup from './createFormBoard';

const ViewFormTemplate = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { allTemplates } = useSelector(store => store.boardRoot);
    const [openBoard, setOpenBoard] = useState(null);
    const [createBoardPopup, setCreateBoardPopup] = useState(false); //

    const createBoardhandleClick = () => {
        setCreateBoardPopup(!createBoardPopup);
    };

    const fetchData = useCallback(() => {
        dispatch(getFormTemplate(location?.state?.id));
    }, [dispatch, location?.state?.id]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSelectBoard = board => {
        dispatch(setSelectedBoard(board));
    };
    const formatDate = dateString => {
        const getOrdinalSuffix = number => {
            const suffixes = ['th', 'st', 'nd', 'rd'];
            const lastDigit = number % 10;
            const lastTwoDigits = number % 100;
            return suffixes[lastDigit] || suffixes[lastTwoDigits] || suffixes[0];
        };

        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleDateString(undefined, { month: 'short' });
        const year = date.getFullYear();
        return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    };

    const handleDeleteFormTemplate = useCallback(
        form => {
            dispatch(deleteFormTemplate(form?._id));
            fetchData();
        },
        [dispatch, fetchData]
    );

    const renderTableData = () => {
        return allTemplates?.map((row, index) => {
            const { formTitle, createdAt, categoryId, boardColor } = row;
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{formTitle}</td>
                    <td>{formatDate(createdAt)}</td>
                    <td>
                        <div className="d-flex flex-wrap justify-content-evenly">
                            <button
                                className="btn btn-primary mr-2"
                                type="button"
                                onClick={() => setOpenBoard(row?._id)}
                            >
                                Edit
                            </button>
                            <button
                                className="btn btn-danger "
                                type="button"
                                onClick={handleDeleteFormTemplate.bind(this, row)}
                            >
                                Delete
                            </button>
                            <Link
                                className="text-decoration-none text-white"
                                to={'/form-template'}
                                state={{
                                    formId: row._id,
                                    formData: row,
                                }}
                            >
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => handleSelectBoard(row)}
                                >
                                    View form template
                                </button>
                            </Link>
                        </div>
                    </td>

                    {openBoard === row?._id && (
                        <CreateFormPopup
                            fetchData={fetchData}
                            id={row?._id}
                            edit="edit"
                            title={formTitle}
                            boardColor={boardColor}
                            categoryId={categoryId}
                            onClose={setOpenBoard}
                            setCreateBoardPopup={setOpenBoard}
                        />
                    )}
                </tr>
            );
        });
    };

    return (
        <div>
            <div className="mainContent">
                <div className="container">
                    <div className="row mt-2">
                        <h3 className="text-start">
                            {location.state?.category}
                            <span className="float-end">
                                <Link
                                    className="text-decoration-none text-white mx-3"
                                    to={'/add-form-category'}
                                >
                                    <button className="btn btn-primary" type="button">
                                        Back
                                    </button>
                                </Link>

                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={createBoardhandleClick}
                                >
                                    Create Template
                                </button>
                            </span>
                        </h3>
                    </div>
                    <table className="table table-bordered text-center table-hover">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">SNo.</th>
                                <th scope="col">Board name</th>
                                <th scope="col">Created Date</th>

                                <th scope="col" colSpan="3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>{renderTableData()}</tbody>
                    </table>
                </div>
                {createBoardPopup && (
                    <CreateFormPopup
                        onClose={createBoardhandleClick}
                        setCreateBoardPopup={setCreateBoardPopup}
                    />
                )}
            </div>
        </div>
    );
};

export default ViewFormTemplate;
