import React, { Fragment, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Lists from './Lists';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { SwatchesPicker } from 'react-color';
import htmlToDraft from 'html-to-draftjs';
import htmlReactParser from 'html-react-parser';
import { Modal, Button } from 'react-bootstrap';

const getEditorState = (html) => {
    const contentBlock = htmlToDraft(html || '<span></span>');
    if (!contentBlock) return null;
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
};

const Card = (props) => {
    const { title, data, droppableKey, addNode, editNode, deleteNode, id, tooltipText } = props;
    const [editorState, setEditorState] = useState('');
    const [editEditorState, setEditEditorState] = useState('');
    const [history, setHistory] = useState([]);
    const [editConfig, setEditConfig] = useState({ droppableKey: '', index: '' });
    const [isVisible, setVisible] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('#20b2aa');
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);

    const handleSubmit = () => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setAddModalOpen(false);
        setEditorState('');
        addNode(html, droppableKey, backgroundColor || '#4cc1bb');
    };

    const openEditModal = (html, index, droppableKey, backgroundColor, history) => {
        setEditModalOpen(true);
        setEditConfig({ index, droppableKey });
        setBackgroundColor(backgroundColor);
        setHistory(history);
        setEditEditorState(getEditorState(html));
    };

    const handleEditSubmit = () => {
        const html = draftToHtml(convertToRaw(editEditorState.getCurrentContent()));
        editNode(html, editConfig.droppableKey, id, editConfig.index, backgroundColor, history);
        setEditEditorState('');
        setHistory([]);
        setEditConfig({ droppableKey: '', index: '' });
        setEditModalOpen(false);
    };

    const clearInlineStyles = () => {
        const currentContent = editorState.getCurrentContent();
        setEditorState(getEditorState(currentContent.getPlainText('')));
    };

    return (
        <Fragment>
            <Droppable droppableId={droppableKey}>
                {provided => (
                    <div
                        className="card h-100 rounded-0"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ overflow: 'auto', minHeight: '275px' }}
                    >
                        <div className="card-body p-0">
                            <div
                                data-toggle="tooltip"
                                data-container="body"
                                data-placement="top"
                                title={tooltipText}
                                style={{ cursor: 'pointer' }}
                            >
                                <div
                                    className="pl-3 pt-3 pb-2 mb-0 sticky-top bg-white"
                                    style={{ fontSize: '18px' }}
                                >
                                    <strong>{title}</strong>
                                </div>
                            </div>
                            {data.map(({ text, backgroundColor, _id, history }, idx) => (
                                <Lists
                                    title={title}
                                    backgroundColor={backgroundColor}
                                    onClick={openEditModal}
                                    history={history}
                                    deleteNode={deleteNode}
                                    droppableKey={droppableKey}
                                    text={text}
                                    idx={idx}
                                    _id={_id}
                                    key={droppableKey + '-' + idx}
                                />
                            ))}
                            <div
                                onClick={() => {
                                    setAddModalOpen(true);
                                    console.log('isAddModalOpen is true:', isAddModalOpen);
                                }}
                                style={{
                                    background: "#c9c9c9 !important",
                                    height: "20px",
                                    alignItems: "center",
                                    borderRadius: "4px",
                                    fontSize: "23px",
                                    cursor: "pointer"
                                }}
                                className="d-flex sticky-bottom hover-effect justify-content-center align-item-center py-3"
                            >
                                +
                            </div>
                        </div>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>

            {/* Add Node Modal */}
            {isAddModalOpen && (
                <Modal show={isAddModalOpen} onHide={() => setAddModalOpen(false)} centered>
                    <Modal.Header className="bmc-modal" style={{ backgroundColor: 'rgb(135, 182, 194)' }} closeButton>
                        <Modal.Title>{title}</Modal.Title>
                        {/* <Button variant="link" className="close text-white" onClick={() => setAddModalOpen(false)}>
                            <span aria-hidden="true">&times;</span>
                        </Button> */}
                    </Modal.Header>
                    <Modal.Body className="text-dark">
                        <div className="row mb-2">
                            <div className="col-2" style={{ maxWidth: '10%' }}>
                                <div className="p-1 border shadow-sm border-light rounded-sm" style={{ width: '53px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div
                                        style={{
                                            ...styles.colorPicker,
                                            backgroundColor: backgroundColor,
                                        }}
                                        onClick={() => setVisible(!isVisible)}
                                        title="Fill Color"
                                    ></div>
                                </div>
                                {isVisible && (
                                    <div style={{ position: 'absolute', zIndex: '100' }}>
                                        <SwatchesPicker
                                            color={backgroundColor}
                                            onChangeComplete={(color_para) => {
                                                setBackgroundColor(color_para.hex);
                                                setVisible(false);
                                            }}
                                            width="290px"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="col-2" style={{ maxWidth: '10%', marginLeft: '5px' }}>
                                <div className="removebutton" onClick={() => clearInlineStyles()} title="Remove All Formatting">
                                    <i className="fas fa-remove-format"></i>
                                </div>
                            </div>
                        </div>
                        <Editor
                            editorState={editorState}
                            editorClassName="demo-editor"
                            onEditorStateChange={(newState) => setEditorState(newState)}
                            toolbar={{
                                options: [
                                    'history',
                                    'fontFamily',
                                    'fontSize',
                                    'inline',
                                    'textAlign',
                                    'list',
                                    'link',
                                    'colorPicker',
                                ],
                                textAlign: { inDropdown: true },
                                list: { inDropdown: true },
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                },
                                history: {
                                    options: ['undo', 'redo'],
                                },
                            }}
                        />
                        <Button
                            className="btn btn-primary mt-3 px-5"
                            onClick={handleSubmit}
                            style={{ backgroundColor: 'rgb(135, 182, 194)', color: '#fff' }}
                        >
                            Add
                        </Button>
                    </Modal.Body>
                </Modal>
            )}

            {/* Edit Node Modal */}
            {isEditModalOpen && (
                <Modal show={isEditModalOpen} onHide={() => setEditModalOpen(false)} centered>
                    <Modal.Header className="bmc-modal" style={{ backgroundColor: 'rgb(135, 182, 194)' }} closeButton>
                        <Modal.Title className="text-dark">{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-dark">
                        <div className="row mb-2">
                            <div className="col-2" style={{ maxWidth: '10%' }}>
                                <div className="p-1 border shadow-sm border-light rounded-sm" style={{ width: '53px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div
                                        style={{
                                            ...styles.colorPicker,
                                            backgroundColor: backgroundColor,
                                        }}
                                        onClick={() => setVisible(!isVisible)}
                                        title="Fill Color"
                                    ></div>
                                </div>
                                {isVisible && (
                                    <div style={{ position: 'absolute', zIndex: '100' }}>
                                        <SwatchesPicker
                                            color={backgroundColor}
                                            onChangeComplete={(color_para) => {
                                                setBackgroundColor(color_para.hex);
                                                setVisible(false);
                                            }}
                                            width="290px"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="col-2" style={{ maxWidth: '10%', marginLeft: '5px' }}>
                                <div className="removebutton" onClick={() => clearInlineStyles()} title="Remove All Formatting">
                                    <i className="fas fa-remove-format"></i>
                                </div>
                            </div>
                        </div>
                        <Editor
                            editorState={editEditorState}
                            editorClassName="demo-editor"
                            toolbar={{
                                options: [
                                    'history',
                                    'fontFamily',
                                    'fontSize',
                                    'inline',
                                    'textAlign',
                                    'list',
                                    'link',
                                    'colorPicker',
                                ],
                                textAlign: { inDropdown: true },
                                list: { inDropdown: true },
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                },
                                history: {
                                    options: ['undo', 'redo'],
                                },
                            }}
                            onEditorStateChange={(newState) => setEditEditorState(newState)}
                        />
                        <Button
                            className="btn btn-block mt-3 px-5"
                            onClick={handleEditSubmit}
                            style={{ backgroundColor: 'rgb(135, 182, 194)' }}
                        >
                            Edit
                        </Button>
                    </Modal.Body>
                    {history && (
                        <div className="card history" style={{ maxHeight: '150px' }}>
                            <div className="card-body" style={{ maxHeight: '150px', overflow: 'auto' }}>
                                {history.map((item) => (
                                    <div className="text-dark" key={item.date}>
                                        <small>[{item.date.split('GMT')[0]}] </small>
                                        <small>
                                            {item.action} {htmlReactParser(item.html)}
                                        </small>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </Modal>
            )}

        </Fragment>
    );
};

const styles = {
    colorPicker: {
        width: '40px',
        height: '20px',
        backgroundColor: '#000',
    },
};

export default Card;
