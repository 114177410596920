import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './userpage.css'; // Import your component-specific CSS file

const UserPage = ({ Data, title }) => {
    // State to manage the visibility of popups for each card
    const [activePopup, setActivePopup] = useState(null);
    const popupRef = useRef(null);

    // Toggle visibility of the popup for each card
    const togglePopup = id => {
        setActivePopup(activePopup === id ? null : id);
    };

    const closePopupOnOutsideClick = e => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            setActivePopup(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', closePopupOnOutsideClick);

        return () => {
            document.removeEventListener('mousedown', closePopupOnOutsideClick);
        };
    }, []);

    return (
        <div className="your-component">
            {/* Page Title */}
            <h1>{title} Page</h1>

            {/* Cards */}
            <div className="card-container">
                {Data.map((data, index) => (
                    <div className="card_of_route" key={index}>
                        <div>
                            <p>{data?.routeName}</p>
                        </div>
                        <div className="popup-trigger" onClick={() => togglePopup(data?.id)}>
                            {/* Three-dot icon */}
                            <span>&#8942;</span>
                        </div>
                        {activePopup === data?.id && (
                            <div className="popup" ref={popupRef}>
                                <ul>
                                    {data.popuptext?.map(li => (
                                        <Link to={li.link} key={li.link}>
                                            <li>{li?.title}</li>
                                        </Link>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserPage;
