import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { CX_DEPLOYER } from '../services/url';
import UserDeleteModal from '../Components/Modal/UserDeleteModal';

class PremiumUsers extends Component {
    constructor(propos) {
        super();
        this.state = {
            data: [],
        };
    }

    async getData() {
        const token = localStorage.getItem('cxtoken');

        await axios
            .get(CX_DEPLOYER + `user/paid`, { headers: { 'x-access-token': token } })
            .then(response => {
                this.setState({
                    data: response.data.users,
                });
            });
    }
    async sendDeleteOtp(id) {
        const token = localStorage.getItem('cxtoken');
        await axios
            .delete(CX_DEPLOYER + `/user/delete/${id}`, { headers: { 'x-access-token': token } })
            .then(res => {
                if (res) {
                    alert('otp has been send to the mail');
                }
            })
            .catch(err => {
                alert(err.msg);
            });
    }
    async deleteUser(id, otp) {
        const token = localStorage.getItem('cxtoken');
        const data = { otp };
        await axios
            .delete(CX_DEPLOYER + `/user/delete/premium/${id}`, data, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                if (res) {
                    alert(res.data.message);
                }
            })
            .catch(err => {
                alert(err.msg);
            });
    }

    renderTableData() {
        return this.state.data.map((row, index) => {
            const {
                _id,
                company,
                email,
                subscriptionYear,
                mobile,
                status,
                storage,
                userType,
                username,
                payment,
            } = row; //destructuring
            return (
                <>
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{username}</td>
                        <td>{company}</td>
                        <td>{status}</td>
                        <td>{email}</td>
                        <td>{storage}</td>
                        <td>{mobile}</td>
                        <td>{userType}</td>
                        <td>{String(subscriptionYear).split('T')[0]}</td>
                        <td>
                            <Link to={{ pathname: `/payment/${row._id}`, payment: payment }}>
                                View
                            </Link>
                        </td>
                        <td>
                            <button
                                className="btn btn-primary"
                                onClick={() => this.sendDeleteOtp(_id)}
                            >
                                generate otp
                            </button>
                        </td>
                        <td>
                            <UserDeleteModal
                                _id={_id}
                                username={username}
                                company={company}
                                deleteUser={this.deleteUser}
                            />
                        </td>
                    </tr>
                </>
            );
        });
    }

    componentDidMount() {
        this.getData();
    }
    render() {
        return (
            <div>
                <div class="mainContent">
                    <div class="container">
                        <div class="page-header text-center">
                            <h3>Users Registered</h3>
                        </div>

                        <table className="table table-bordered text-center table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">SNo.</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Email ID</th>
                                    <th scope="col">Storage</th>
                                    <th scope="col">Mobile</th>
                                    <th scope="col">User Type</th>
                                    <th scope="col">Subscription Year</th>
                                    <th scope="col">View Details</th>
                                    <th scope="col">OTP generate</th>
                                    <th scope="col">Delete User</th>
                                </tr>
                            </thead>
                            <tbody>{this.renderTableData()}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default PremiumUsers;
