import React, { useEffect, useState } from 'react';
import '../UserEngagement/user_register.css';
import axios from 'axios';
import { API_HOST } from '../services/url';
import '../inquiries/inquiries.css';
import ReactPaginate from 'react-paginate';

const UserRegister = () => {
    const [users, setUser] = useState([]);
    const [pageSize, setPageSize] = useState(15);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
        getData(selected);
    };
    useEffect(() => {
        getData(0);
    }, []);
    const getData = async selected => {
        let payload = {
            page_size: pageSize,
            page_number: selected,
        };
        const response = await axios.post(
            `${API_HOST}cxdeployer/admin/user/getallRegisterUsers`,
            payload
        );
        if (response.data.success) {
            setUser(response.data.data);
            setPageCount(Math.ceil(response.data.metadata.total_data / pageSize));
        }
    };
    return (
        <div>
            <div className="mainContent">
                <div className="container">
                    <div className="page-header text-center">
                        <h3>User Registered </h3>
                    </div>
                    <div className="tableFixHead">
                        <table className="table table-bordered text-center table-hover">
                            <thead className="thead-grey">
                                <tr>
                                    <th scope="col">SNo.</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Account Type</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Total no of workspaces</th>
                                    <th scope="col">Total no of board</th>
                                    <th scope="col">Invited member in workspaces</th>
                                    <th scope="col">Invited member in board</th>
                                    <th scope="col">view member</th>
                                    <th scope="col">view visitedpage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users &&
                                    users.map((user, key) => (
                                        <tr>
                                            <td>{key + 1}</td>
                                            <td>{user.username ? user.username : 'N/A'}</td>
                                            <td>{user.email}</td>
                                            <td>{user.account_type}</td>
                                            <td>{user.status === true ? 'Active' : 'Active'}</td>
                                            <td>{user.totalNoOfWorkspace}</td>
                                            <td>{user.totalNoOfBoard}</td>
                                            <td>{user.WIM}</td>
                                            <td>{user.BIM}</td>
                                            <td>
                                                <button
                                                    className="btn btn-view"
                                                    style={{
                                                        fontSize: '0.8rem',
                                                        padding: '0.155rem 0.5rem',
                                                    }}
                                                >
                                                    view
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-view"
                                                    style={{
                                                        fontSize: '0.8rem',
                                                        padding: '0.155rem 0.5rem',
                                                    }}
                                                >
                                                    view
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount} // Replace with your actual number of pages
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination-container"
                        pageClassName="pagination-item"
                        breakClassName="pagination-item"
                        previousClassName="pagination-item"
                        nextClassName="pagination-item"
                        activeClassName="pagination-item active"
                    />
                </div>
            </div>
        </div>
    );
};

export default UserRegister;
