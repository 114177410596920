import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import './user_register.css';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';
import { Link } from 'react-router-dom';
import ViewUser from './viewUser';
const UserRegister = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [fromDate, setFromDate] = useState('');
    const [visitsdate, setVisiteddate] = useState();

    useEffect(() => {
        getData();
    }, [currentPage]);

    const itemPerPage = 10;

    const getData = async () => {
        try {
            const formattedFromDate = fromDate ? new Date(fromDate).toISOString() : '';
            const response = await axios.get(`${CX_DEPLOYER}engagement/getEngagementUser`, {
                params: { findDate: formattedFromDate },
            });
            if (response.data.success) {
                setData(response.data.trackData);
                setTotalPages(response.data.totalPages);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleFilterApply = e => {
        e.preventDefault();
        setCurrentPage(1); // Reset page to 1 when applying filters
        // You can add additional validation for the date range if needed
        getData();
    };

    const handeldate = date => {
        setVisiteddate(date);
    };

    return (
        <div className="mainContent">
            <div className="container">
                <div className="page-header text-center">
                    <h3>User Engagement</h3>
                </div>
                {/* Date Filter */}
                <form onSubmit={handleFilterApply}>
                    <div className="row mb-3">
                        <div className="col-md-3">
                            <label htmlFor="fromDate">Find By Date:</label>
                            <input
                                type="date"
                                className="form-control"
                                id="fromDate"
                                value={fromDate}
                                onChange={e => setFromDate(e.target.value)}
                            />
                        </div>
                        <div className="col-md-2 mt-4 d-flex align-items-center ">
                            <button type="submit" className="btn btn-primary">
                                Apply Filter
                            </button>
                        </div>
                    </div>
                </form>
                {/* User Engagement Table */}
                <div className="tableFixHead">
                    <table className="table table-bordered text-center table-hover">
                        <thead className="thead-grey">
                            <tr>
                                <th scope="col">SNo.</th>
                                <th scope="col">Date</th>
                                <th scope="col">Total Number of VisitedUser</th>
                                <th scope="col">View all User</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data?.reverse()?.map((item, key) => (
                                    <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{item.VisitedDate}</td>
                                        <td>{item?.totalNumberOfUser}</td>
                                        <td>
                                            <button
                                                className="btn btn-view"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    padding: '0.155rem 0.5rem',
                                                }}
                                                onClick={() => handeldate(item.VisitedDate)}
                                            >
                                                view
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                {visitsdate && <ViewUser visitsdate={visitsdate} />}
            </div>
        </div>
    );
};

export default UserRegister;
