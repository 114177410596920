import React, { Component } from 'react';
import axios from 'axios';
import { API_HOST } from '../services/url';
import QuillEditor from './quill';

class AddUpdate extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            heading: '',
            content: '',
            selectedFile:''
        };
        //   this.onSubmit = this.onSubmit.bind(this);
    }


    async onSubmit(e) {
        e.preventDefault();
        let payload = {
            heading:this.state.heading,
            content:this.state.content
        };

        const token = localStorage.getItem('cxtoken');
        axios
            .post(API_HOST + 'admin/webUpdate', payload, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                alert('new update Added');
                window.location.replace('/updates');
            });
    }
    onEditorChange = content => {
        this.setState({ content });
    };
    onFileChange = files => {
        console.log(files);
        this.setState({ selectedFile: files[0] });
    };
    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    render() {
        return (
            <div>
                <div className="container" >
                    <div className="row">
                        <div className="col-sm-9 col-md-9 mx-auto">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h4 className="card-title text-center">Add Update</h4>
                                    <form className="form-signin" method="POST">
                                        <div className="form-group">
                                            <label for="email">Heading</label>
                                            <input
                                                id="title"
                                                type="text"
                                                className="form-control"
                                                name="heading"
                                                value={this.state.heading}
                                                onChange={e => this.change(e)}
                                                required
                                            />
                                        </div>
                                       
                                        <div className="form-group">
                                            <label for="email">Content</label>
                                            <QuillEditor
                                            placeholder={'Start Posting Something'}
                                            onEditorChange={this.onEditorChange}
                                            onFilesChange={this.onFileChange}
                                        />
                                        </div>
                                        <div className="form-group no-margin mt-2">
                                            <button
                                                className="btn btn-info btn-block"
                                                onClick={e => this.onSubmit(e)}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddUpdate;
