import React, { useCallback, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import htmlReactParser from "html-react-parser";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SwatchesPicker } from "react-color";
import { IconPickerItem } from "react-fa-icon-picker";
import Modal from 'react-bootstrap/Modal';
import { FaRemoveFormat } from "react-icons/fa";

import Button from 'react-bootstrap/Button';

const getEditorState = (html) => {
    const contentBlock = htmlToDraft(html || "<span></span>");
    if (!contentBlock) return null;

    const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
};

const LaneModal = (props) => {
    const { title, show } = props;

    const [editorState, setEditorState] = useState("");
    const [status, setStatus] = useState(props.status || "");
    const [isVisible, setVisible] = useState(false);
    const [color, setColor] = useState(props.color || "#20b2aa");
    const [icon, setIcon] = useState("");
    const [spreadSheet, setSpreadSheet] = useState(false);
    const [fileDropdown, setFileDropdown] = useState(false)


    const handleEditSubmit = useCallback(() => {
        try {
            const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            console.log(props.title, 'This is HTML')
            if (props.title === "Edit Lane Name" || props.title === "Add the Lane") {
                props.handleSubmit(html, color, icon);
            } else if (props.title === 'Edit Lane') {
                props.handleSubmit(html, color, icon);
            } else {
                props.handleSubmit(html, color, icon);

            }
            setIcon(props.icon || "");
            setEditorState(EditorState.createEmpty());
            props.close();
        } catch (error) {
            props.close();
        }
    }, [color, editorState, icon, props]);

    const _getLengthOfSelectedText = () => {
        const currentSelection = editorState.getSelection();
        const isCollapsed = currentSelection.isCollapsed();

        let length = 0;

        if (!isCollapsed) {
            const currentContent = editorState.getCurrentContent();
            const startKey = currentSelection.getStartKey();
            const endKey = currentSelection.getEndKey();
            const startBlock = currentContent.getBlockForKey(startKey);
            const isStartAndEndBlockAreTheSame = startKey === endKey;
            const startBlockTextLength = startBlock.getLength();
            const startSelectedTextLength =
                startBlockTextLength - currentSelection.getStartOffset();
            const endSelectedTextLength = currentSelection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);
            console.log(currentSelection);
            if (isStartAndEndBlockAreTheSame) {
                length +=
                    currentSelection.getEndOffset() - currentSelection.getStartOffset();
            } else {
                let currentKey = startKey;

                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } else {
                        length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                    }

                    currentKey = currentContent.getKeyAfter(currentKey);
                }
            }
        }

        return length;
    };
    const clearInlineStyles = () => {
        const styles = ["colorPicker"];
        const currentContent = editorState.getCurrentContent();
        setEditorState(getEditorState(currentContent.getPlainText("")));

    };

    const _handleBeforeInput = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText("").length;
        const selectedTextLength = _getLengthOfSelectedText();

        if (currentContentLength - selectedTextLength > 50 - 1) {
            console.log("you can type max 50 characters");

            return "handled";
        }
    };

    const _handlePastedText = (pastedText) => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText("").length;
        const selectedTextLength = _getLengthOfSelectedText();

        if (currentContentLength + pastedText.length - selectedTextLength > 50) {
            console.log("you can type max 50 characters");

            return "handled";
        }
    };

    useEffect(() => {
        setEditorState(getEditorState(props.html));

        setColor(props.color || "#20b2aa");
        setIcon(props.icon || "");
        // console.log(props.history);

        // $("body").click(function (e) {
        //   if (e.target.tabIndex === -1) {
        //     setVisible(false);
        //   }
        // });
        var wrap = document.querySelectorAll(".rdw-colorpicker-wrapper");
        for (let i = 0; i < wrap.length; i++)
            wrap[i].attributes.title.value = "Text Color";
    }, [props.html, props.color, props.icon]);

    return (
        <Modal show={show} onHide={() => props.close()} style={{ paddingLeft: "207px" }}>
            <div>
                <Modal.Header style={{ alignItems: "center", color: "black", height: "56px" }} closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="" role="document">
                        <div
                            className=" modal-content__new"
                            style={{ padding: '16px', width: "inherit" }}
                        >
                            <div className="modal-button-actions__new">
                                <div style={{
                                    width: "fit-content",
                                    padding: "3px",
                                    border: "0.3px solid #cfcccc",
                                    borderRadius: "3px",
                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                }}>
                                    <div
                                        style={{
                                            ...styles.colorPicker,
                                            backgroundColor: color,
                                        }}
                                        onClick={() => setVisible(!isVisible)}
                                        title="Fill Color"
                                    ></div>
                                    {isVisible && (
                                        <div style={{ position: "absolute", zIndex: "100" }}>
                                            <SwatchesPicker
                                                color={color}
                                                onChangeComplete={(color_para) => {
                                                    setColor(color_para.hex);
                                                    setVisible(false);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <IconPickerItem
                                    value={icon || "FaRegSmile"}
                                    onChange={(v) => setIcon(v)}
                                />
                                <div
                                    className="removebutton"
                                    onClick={(editorState) => clearInlineStyles(editorState)}
                                    title="Remove All Formatting"
                                >
                                    <FaRemoveFormat />
                                </div>
                            </div>
                            <div className="modal-body__new--editor">
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                            marginBottom: "1rem",
                                        }}
                                    >


                                    </div>
                                    {props.title === "Edit Lane Name" ||
                                        props.title === "Add Lane Name" ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.5rem",
                                            }}
                                        >
                                            <Editor
                                                editorState={editorState}
                                                style={{
                                                    boxSizing: "content-box !important",
                                                    position: "relative !important",
                                                    marginLeft: "-49px !important",
                                                    width: "447px !important"
                                                }}
                                                // toolbarClassName="editor-toolbar__new"
                                                // editorClassName="editor__new"
                                                onEditorStateChange={(newState) => setEditorState(newState)}
                                                handleBeforeInput={_handleBeforeInput}
                                                handlePastedText={_handlePastedText}
                                                toolbar={{
                                                    options: [
                                                        "history",
                                                        "fontFamily",
                                                        "fontSize",
                                                        "inline",
                                                        "textAlign",
                                                        "list",
                                                        "link",
                                                        "colorPicker",
                                                    ],

                                                    textAlign: { inDropdown: true },
                                                    list: { inDropdown: true },
                                                    inline: {
                                                        options: ["bold", "italic", "underline"],
                                                    },

                                                    history: {
                                                        options: ["undo", "redo"],
                                                    },
                                                }}
                                            />
                                            <div
                                                style={{ display: "flex", flexDirection: "row-reverse" }}
                                            >
                                                {editorState &&
                                                    editorState.getCurrentContent().getPlainText("").length}
                                                /50
                                            </div>
                                        </div>
                                    ) : (
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="editor-toolbar__new"
                                            editorClassName="editor__new"
                                            onEditorStateChange={(newState) => setEditorState(newState)}
                                            toolbar={{
                                                options: [
                                                    "history",
                                                    "fontFamily",
                                                    "fontSize",
                                                    "inline",
                                                    "textAlign",
                                                    "list",
                                                    "link",

                                                    "colorPicker",
                                                ],

                                                textAlign: { inDropdown: true },
                                                list: { inDropdown: true },
                                                inline: {
                                                    options: ["bold", "italic", "underline"],
                                                },
                                                history: {
                                                    options: ["undo", "redo"],
                                                },
                                            }}
                                        />
                                    )}
                                </div>
                                {props.history && (
                                    <div className="card-body__new">
                                        {props.history.map((history, idx) => {
                                            return (
                                                <div key={idx}>
                                                    <small style={{ display: "flex", flexWrap: "wrap" }}>
                                                        [{history.date.split("GMT")[0]}]
                                                    </small>
                                                    <small style={{ textAlign: "left" }}>
                                                        {history.action}{" "}
                                                    </small>
                                                    <small style={{ textAlign: "left" }}>
                                                        {htmlReactParser(history.html.substring(0, 100))}
                                                    </small>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.close()}>
                        Close
                    </Button>
                    {props?.title === "Edit Lane" ?
                        <Button variant="primary" onClick={handleEditSubmit}>
                            Edit
                        </Button> :
                        <Button variant="primary" onClick={handleEditSubmit}>
                            Add
                        </Button>}
                </Modal.Footer>
            </div>
        </Modal >

    );
};

const styles = {
    colorPicker: {
        width: "40px",
        height: "20px",
        backgroundColor: "#000",
    },
};

export default LaneModal;