import React from 'react';
import RoutePage from '../routePage/userPage';
const Page = () => {
    const Data = [
        {
            id: '1',
            routeName: 'Task Template',
            popuptext: [
                {
                    title: 'View',
                    link: '/add-category',
                },
            ],
        },
        {
            id: '2',
            routeName: 'CX Templates',
            popuptext: [
                {
                    title: 'View',
                    link: '/PrefilledMaps',
                },
            ],
        },
        {
            id: '3',
            routeName: 'Form Templates',
            popuptext: [
                {
                    title: 'Add',
                    link: '/add-form-category',
                },
                {
                    title: 'View',
                    link: '/add-form-category',
                },
            ],
        },
        {
            id: '4',
            routeName: 'Images',
            popuptext: [
                {
                    title: 'Add',
                    link: '/addImage',
                },
                {
                    title: 'View',
                    link: '/image',
                },
            ],
        },
    ];
    return (
        <div>
            <RoutePage Data={Data} title={'Product'} />
        </div>
    );
};

export default Page;
