import axios from 'axios';
import { CX_DEPLOYER } from '../../services/url';

export const getBlogs = async payload => {
    try {
        const res = await axios.post(CX_DEPLOYER + 'blog/allblogs', payload);
        return res.data;
    } catch (error) {
        return { success: false };
    }
};
