import React, { Component } from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';

class ViewPlan extends Component {
    constructor(propos) {
        super();
        this.state = {
            data: [],
        };
    }

    async getData() {
        const token = localStorage.getItem('cxtoken');

        await axios
            .get(CX_DEPLOYER + `/stripe/getPlan`, {
                headers: { 'x-access-token': token },
            })
            .then(response => {
                console.log(response);
                this.setState({
                    data: response.data.data,
                });
            });
    }

    handleDelete = e => {
        e.preventDefault();
        const token = localStorage.getItem('cxtoken');
        const url = CX_DEPLOYER + '/stripe/deletePlan/' + e.target.id;
        console.log(url);
        axios.delete(url, { headers: { 'x-access-token': token } }).then(response => {
            console.log(response);
            window.location.reload(false);
        });
    };

    renderTableData() {
        return this.state.data.map((row, index) => {
            const { price, validity, storage, planName, totalAssociatedUser } = row; //destructuring
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{planName}</td>
                    <td>{price.INR}</td>
                    <td>{price.USD}</td>

                    <td>{validity}</td>
                    <td>{storage}</td>
                    <td>{totalAssociatedUser}</td>
                    <td>
                        <a
                            className="btn btn-primary"
                            href={'https://www.cxdeployer.com/createpayment/' + row._id}
                        >
                            Payment Link
                        </a>
                    </td>
                    <td>
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={e => {
                                this.props.history.push(`/editPlan/${row._id}`);
                            }}
                        >
                            Edit
                        </button>
                    </td>
                    <td>
                        <button
                            className="btn btn-danger"
                            id={row._id}
                            type="button"
                            onClick={e =>
                                window.confirm('Are you sure you want to delete?') &&
                                this.handleDelete(e)
                            }
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            );
        });
    }

    componentDidMount() {
        this.getData();
    }
    render() {
        return (
            <div>
                <div className="mainContent">
                    <div className="container">
                        <div className="page-header text-center">
                            <h3>Plans</h3>
                        </div>
                        <table className="table table-bordered text-center table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">SNo.</th>
                                    <th scope="col">Plan Name</th>
                                    <th scope="col">Indian Price</th>
                                    <th scope="col">USD Price</th>
                                    <th scope="col">Validity</th>
                                    <th scope="col">Storage Limit</th>
                                    <th scope="col">Max Users</th>

                                    <th scope="col" colSpan="3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{this.renderTableData()}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewPlan;
