
import React, { useState, useEffect } from "react";
import "./Bucket.css";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSelector, useDispatch } from "react-redux";
import {  getBucket } from "../redux/action/bucket";
import AddNewBucket from "./addNewBucket";

import { DragandDropCard } from "../redux/action/card";
import { DragandDropBucket } from "../redux/action/bucket";

import List from "./List";
import "./Board.css";
import BoardHeader from './boardHeader'
import { useLocation } from "react-router-dom";
const TaskCard = () => {
  const { selectedBoard } = useSelector((store) => store.boardRoot);
  const { allBucket } = useSelector((store) => store.bucketRoot);
  const location = useLocation();

  const dispatch = useDispatch();

  const [showbucket,setshowBucket]= useState(false);
 
  useEffect(() => 
  {
      getBoard();
  }, []);
 
  const getBoard = async () => {
      dispatch(getBucket(location?.state?.boardId))
  };
 


  const handleCloseBucket = (e) => {
    setshowBucket(false);
  };

  useEffect(() => {
    console.log("src bucket update");
  }, [allBucket]);
  const dropBucket = async ({ destination, source, type }) => {
    console.log("src", destination, source);

    // move bucket
    if (type === "COLUMN") {
      console.log("update bkt==", destination, source, type);

      if (destination.index !== source.index) {
        const copyListItems = [...allBucket];
        const dragItemContent = copyListItems[source.index];
        const dest = copyListItems[destination.index];
        const newLists = allBucket;
        const [removedList] = newLists.splice(source.index, 1);
        newLists.splice(destination.index, 0, removedList);

        try {
          let payload = [
            {
              id: dragItemContent._id,
              index: destination.index,
            },
            {
              id: dest._id,
              index: source.index,
            },
          ];
          let boardId = selectedBoard ? selectedBoard._id : "";

          dispatch(DragandDropBucket(payload, boardId));
        } catch (error) {
          console.log(error, "error-------");
          // alert.error("error in update bucket");
        }
      }
    } else if (
      source.index !== destination.index ||
      source.droppableId !== destination.droppableId
    ) {
      console.log("update card==", destination, source, type);

      const sourceItem = allBucket.find(
        (obj) => obj._id === source.droppableId
      );
      console.log("sourceItem", sourceItem);
      //👇🏻 Gets the item that was dragged
      const itemMoved = {
        ...sourceItem.cards[source.index],
      };
      //👇🏻 Removes the item from the its source
      sourceItem.cards.splice(source.index, 1);
      console.log("itemMoved", itemMoved);

      // //👇🏻 Add the item to its destination using its destination index
      allBucket.map((obj) =>
        obj._id === destination.droppableId
          ? obj.cards.splice(destination.index, 0, itemMoved)
          : obj
      );
      console.log("bucket", allBucket);
      try {
        let payload = {
          source: source,
          destination: destination,
        };
        let boardId = selectedBoard ? selectedBoard._id : "";
        dispatch(DragandDropCard(payload, boardId));
      } catch {
        // alert.show("error in update card");
      }
    }
  };

  return (
    <div class = "mainContent">
   <div class = "container-fuild">
    <BoardHeader/>
    <DragDropContext onDragEnd={dropBucket}>
      <Droppable droppableId="board" direction="horizontal" type="COLUMN">
        {(provided, _snapshot) => (
          <div className="Board" ref={provided.innerRef}>
            {allBucket && allBucket.map((bucket, index) => {
              return <List list={bucket} key={bucket._id} index={index} />;
            })}

            {provided.placeholder}
            <div className="Add-List">
              {showbucket ? (
                <AddNewBucket 
                handleCloseBucket={handleCloseBucket}
                 />
              ) : (
                <div
                  onClick={() => setshowBucket(true)}
                  className="Add-List-Button"
                >
                  <ion-icon name="add" /> Add another list 
                </div>
              )}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
    </div>
    </div>
  );
};

export default TaskCard;
