import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedCard, updateCard } from "../../redux/action/card";
import {  Card } from "react-bootstrap";
import { HiMenuAlt2 } from "react-icons/hi";
import "./assignMemberTime.css"


import { BiPlus } from "react-icons/bi";
import "./labelPop.css";
import LabelEditPop from "./labelEditPop";



const AssignMemberTime = ({show, showdesc, handleonclose, setShowdesc, setShow,}) => {
  // Label popup starts here
  const popupRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [labelPop, setLabelPOP] = useState(false);
  const [labelPop2, setLabelPOP2] = useState(false);
  const dispatch = useDispatch();
  const { selectedBoard } = useSelector((store) => store.boardRoot);
  const { selectedCard } = useSelector((store) => store.cardRoot);
  const { selectedBucket } = useSelector((store) => store.bucketRoot);

  let boardLabels = selectedBoard && selectedBoard.boardLabels;
  let boardid = selectedBoard && selectedBoard._id;
  const [labelId, setLabelId] = useState();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleShow = () => {
    if (showPopup === false) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };
  const handleLabelPOP = (idd) => {
    setLabelId(idd);
    if (labelPop === false) {
      setLabelPOP(true);
    } else {
      setLabelPOP(false);
    }
  };
  const handleLabelPOP2 = () => {
    if (labelPop2 === false) {
      setLabelPOP2(true)
    }
    else {
      setLabelPOP2(false);
    }
  }
  const handleClose = () => {
    setShowPopup(!showPopup);
  };
  const handleClick = (labelId) => {
    let cardLabel = selectedCard && selectedCard.cardLabel;
    console.log("label id", labelId, selectedCard.cardLabel);

    let findIndex = cardLabel.findIndex((item) => String(item) === labelId);
    console.log("findindex", findIndex);
    if (findIndex==-1) {
      cardLabel.push(labelId);
    } else {
      cardLabel.splice(findIndex, 1);
    }
    selectedCard.cardLabel = cardLabel;
    dispatch(setSelectedCard(selectedCard));
    let cardId = selectedCard && selectedCard._id;
    let bucketId = selectedBucket && selectedBucket._id;
    let boardId = selectedBucket && selectedBucket.boardId;

    dispatch(updateCard(boardId, bucketId, cardId, selectedCard));
  };

  const [description, setDescription] = useState();
  const [datePopup, setDatePopup] = useState(false);
  // const dispatch = useDispatch();

  const [isDirty, setIsDirty] = useState(false); // new state variable
  const handleChangeEditorSave = () => {
    let cardDetail = selectedCard && selectedCard;
    let cardId = selectedCard && selectedCard._id;
    let bucketId = selectedBucket && selectedBucket._id;
    let boardId = selectedBucket && selectedBucket.boardId;
    cardDetail.cardDescription = description.trim();
    dispatch(setSelectedCard(cardDetail));
    dispatch(updateCard(boardId, bucketId, cardId, {update:'description',cardDescription:description}));
    // setShow(false)
    // setShowdesc(false)
    setIsDirty(false)
    handleonclose();
  };

  const handlesetdesc = (evt) => {
    console.log("handle Click ***********");
    setDescription(evt.target.value);
    setIsDirty(true)

    setShow(true);
  };

  const handleEditDesc = () => {
    setDescription(selectedCard.cardDescription);
    setShow(true);
    setShowdesc(true);
  };

  const handleIsChecked = () => {

    let cardDate = selectedCard && selectedCard.cardDate;

    cardDate.status = cardDate.status === "complete" ? "pending" : "complete"
    selectedCard.cardDate = cardDate;
    // console.log(selectedCard,"card date")
    let cardId = selectedCard && selectedCard._id;
    let bucketId = selectedBucket && selectedBucket._id;
    let boardId = selectedBucket && selectedBucket.boardId;
    dispatch(setSelectedCard(selectedCard))
    dispatch(updateCard(boardId, bucketId, cardId, selectedCard))
    setShow(false)
    setShowdesc(false)
    handleonclose();
  }

  return (
    <>
      <div className="row" style={{ marginTop: "0px !important" }}>
        <div className="card-detail-data">

          {selectedCard && selectedCard.cardMembers && selectedCard.cardMembers.length > 0 ? (
            <div className="card-detail-item ">
              <h6 className="mx-2 text-muted fw-bold">Member</h6>
              {selectedBoard &&
                selectedBoard.boardMember &&
                selectedBoard.boardMember.map((member) =>
                  member.b_memberid ? (
                    selectedCard.cardMembers &&
                      selectedCard.cardMembers.includes(member.b_memberid._id) ? (
                        
                      <>


                        <span className="popup_circle me-1" style={{
                          backgroundColor: member.b_memberid
                            ? member.b_memberid.userColor
                            : "namecircle_color",
                        }}>
                          
                          {member.b_memberid.username && member.b_memberid.username.toUpperCase().charAt(0)}
                          {
                           member.b_memberid.username &&  member.b_memberid.username.split().length > 1 ?
                              member.b_memberid.username.split()[1].toUpperCase().charAt(0)
                              :
                              member.b_memberid.username &&  member.b_memberid.username.toUpperCase().charAt(1)
                          }
                        
                        </span>
                      </>
                    ) : null
                  ) : null
                )}
            </div>
          ) : null}
         

         {/* --------- Label start */}
         {selectedCard?.cardLabel?.length > 0 && (
                    <div className="card-detail-item ">
                        <span className=" ms-2">Label</span>
                        <div className="label_item ms-2 mt-1">
                            {selectedBoard?.boardLabels?.map(board =>
                                selectedCard.cardLabel?.includes(board._id) ? (
                                    <span
                                        className="circle_labelBtn label_item_btn label_01item"
                                        style={{
                                            backgroundColor: board?.labelBackgroundColor,
                                            color: board?.labelBackgroundColor
                                                ===
                                                "#091E42"
                                                ? "white"
                                                : "black",
                                        }}
                                        key={board._id}
                                    >
                                        {board.labelTitle}
                                    </span>
                                ) : null
                            )}

                            {/* label popup starts here */}
                            <>
                                <div className=" " onClick={handleShow}>
                                    <span className="inside_card_join">
                                        <span className="btn__text">
                                            {' '}
                                            <BiPlus size={20} />
                                        </span>
                                    </span>
                                </div>

                                {/*============= lable pop ============== */}
                                {showPopup ? (
                                    <Card
                                        ref={popupRef}
                                        className="labelpopup-shadow rounded-0 shadow"
                                        style={{
                                            position: 'fixed',
                                            right: '210px',
                                            top: '110px',
                                            width: '340px',
                                            zIndex: '1',
                                        }}
                                    >
                                        <section>
                                            <p
                                                className=" text-center text-muted mt-3"
                                                style={{ fontSize: '16px' }}
                                            >
                                                Labels
                                                <span
                                                    style={{
                                                        position: 'relative',
                                                        left: '114px',
                                                    }}
                                                    className="text-muted"
                                                    onClick={handleClose}
                                                >
                                                    <i className="fa-solid fa-xmark fa-s"></i>
                                                </span>
                                            </p>
                                            <hr className="mx-2" />

                                            <p className="text-muted mx-2 pop-over-section">
                                                Labels
                                            </p>

                                            <div
                                                className="scrollable-member"
                                                style={{
                                                    paddingRight: '11px',
                                                    paddingLeft: '13px',
                                                }}
                                            >
                                                <div className="label_ul">
                                                    {boardLabels?.map((label, index) => (
                                                        <div className="d-flex" key={index}>
                                                            <label className="label_1 label_01">
                                                                <div className="  check_main">
                                                                    <input
                                                                        className="form-check-input input_checkbox"
                                                                        type="checkbox"
                                                                        readOnly
                                                                        checked={selectedCard?.cardLabel?.includes(
                                                                            label._id
                                                                        )}
                                                                        onClick={() =>
                                                                            handleClick(label._id)
                                                                        }
                                                                    />
                                                                </div>
                                                                <span
                                                                    className="label_top_span"
                                                                    onClick={() =>
                                                                        handleClick(label._id)
                                                                    }
                                                                >
                                                                    <div className="label_div">
                                                                        <div
                                                                            className="label_color label01_div_text pd_left label_wh"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    label.labelBackgroundColor,
                                                                            }}
                                                                        >
                                                                            <spam className="ms-4">
                                                                                {label.labelTitle}
                                                                            </spam>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </label>

                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="mx-3 mb-3">
                                                <button
                                                    className="label_btn_wh label_btn "
                                                    onClick={handleLabelPOP2}
                                                >
                                                    Create a new Label
                                                </button>
                                            </div>
                                        </section>
                                    </Card>
                                ) : null}
                                {labelPop ? (
                                    <LabelEditPop
                                        setLabelPOP={true}
                                        setShowPopup={true}
                                        boardid={boardid}
                                        labelId={labelId}
                                        toDone={false}
                                    />
                                ) : null}
                                {labelPop2 ? (
                                    <LabelEditPop
                                        setLabelPOP={true}
                                        setShowPopup={true}
                                        boardid={boardid}
                                        toDone={true}
                                    />
                                ) : null}
                            </>
                            {/* label popup ends here */}
                        </div>
                    </div>
                )}
        </div>
        <div className="row">
          <div className="my-4">
            <span className="card-detail-item">
              <HiMenuAlt2 size={25} />
            </span>
            <h6 className="card-detail-item">
              <div className="">Description</div>
            </h6>
            { selectedCard && selectedCard.cardDescription ? (
              <div
                className="card-detail-item pop_section "
                style={{ marginTop: "-6px" }}
                onClick={() => handleEditDesc()}
              >
                Edit
              </div>
            ) : null}




            {selectedCard && 
              selectedCard.cardDescription !== " " && !selectedCard.cardDescription && !showdesc ? (
                <div
                  className="  description_box"
                  onClick={() => handleEditDesc()}
                  style={{ width: "100%", height: "60px", marginLeft: "35px", marginTop: "-5px", padding: "8px 12px", color: "rgb(110 110 110)", backgroundColor: "#efefef", fontSize: "15px" }}
                >
                  Add a more detailed description
                </div>
              ) : null
            }

          </div>
          <div>
            <div className="row gutter">
              {showdesc ? (
                <textarea
                  className="assignmember_textarea"
                  style={{ marginTop: "-23px" }}
                  value={description}
                  onChange={(e) => handlesetdesc(e)}
                  // onKeyDown={(e)=>onEnter(e)}
                  autoFocus
                />
              ) : selectedCard && selectedCard.cardDescription ? (


                selectedCard && selectedCard.cardDescription
                  ? <Card style={{ width: "520px", height: "auto", borderRadius: "0px", marginTop: "-23px", marginLeft: "-4px", border: "none" }} onClick={() => handleEditDesc()}> {selectedCard.cardDescription}
                  </Card> : ""



              ) : null}
            </div>
            {show ? (
              <div className="gutter">
                <button
                  onClick={() => handleChangeEditorSave()}
                  className="btn-memberTime button-primary mt-2"
                >
                  save
                </button>
                <button
                  className="btn-memberTime button--cancle ms-2 mt-2"
                  onClick={() => {
                    setShowdesc(false);
                    setShow(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AssignMemberTime);