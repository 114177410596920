import React, { Component } from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';

class AddPlan extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            planName: '',
            INR: 0,
            USD: 0,
            storage: 0,
            totalAssociatedUser: 0,
            validity: '',
        };
        //   this.onSubmit = this.onSubmit.bind(this);
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    async onSubmit(e) {
        e.preventDefault();
        const data = {
            planName: this.state.planName,
            price: { INR: this.state.INR, USD: this.state.USD },
            storage: this.state.storage,
            totalAssociatedUser: this.state.totalAssociatedUser,

            validity: this.state.validity,
        };

        console.log(data);
        const token = localStorage.getItem('cxtoken');
        axios
            .post(CX_DEPLOYER + '/stripe/addPlan', data, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                if (res.data.msg) {
                    console.log(res.data.msg);
                    alert(res.data.msg);
                    window.location.reload();
                } else {
                    alert(res.data.err);
                }
            });
    }

    render() {
        return (
            <div className="container" >
                <div className="row">
                    <div className="col-sm-9 col-md-9 mx-auto">
                        <div className="card card-signin my-5">
                            <div className="card-body">
                                <h4 className="card-title text-center">Add Plan</h4>
                                <form className="form-signin" method="POST">
                                    <div className="form-group">
                                        <label for="email">Plan Name</label>
                                        <input
                                            id="planName"
                                            type="text"
                                            className="form-control"
                                            name="planName"
                                            value={this.state.planName}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="INRprice">Indian Price</label>
                                        <input
                                            id="INR"
                                            type="number"
                                            className="form-control"
                                            name="INR"
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="INRprice">Others Price(in USD)</label>
                                        <input
                                            id="USD"
                                            type="number"
                                            className="form-control"
                                            name="USD"
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="INRprice">Storage Limit(in MB)</label>
                                        <input
                                            id="storage"
                                            type="number"
                                            className="form-control"
                                            name="storage"
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="INRprice">Validity</label>
                                        <select
                                            id="validity"
                                            className="form-control"
                                            name="validity"
                                            value={this.state.validity}
                                            onChange={e => this.change(e)}
                                            required
                                        >
                                            <option value="">Select Validity</option>

                                            <option value="Annual">Annual</option>

                                            <option value="Lifetime">Lifetime</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="users">Users Limit</label>
                                        <input
                                            id="totalAssociatedUser"
                                            type="number"
                                            className="form-control"
                                            name="totalAssociatedUser"
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>

                                    <div className="form-group no-margin">
                                        <button
                                            className="btn btn-info btn-block"
                                            onClick={e => this.onSubmit(e)}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddPlan;
