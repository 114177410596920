import React, { useState ,useRef,useEffect} from "react";
import { useDispatch ,useSelector} from "react-redux";
import { addCard } from "../redux/action/card";
import { RxCross2 } from "react-icons/rx";
import TextareaAutosize from "react-textarea-autosize";

const NewCard = ({ handleCloseCard,bucketId,onClickOutside }) => {
  const [cardTitle, setCardTitle] = useState("");
  const dispatch = useDispatch();
  const { selectedBoard } = useSelector((store) => store.boardRoot);
  const cardaddRef = useRef(null);

  const boardId = selectedBoard && selectedBoard._id;
  const handleSaveCard = () => {

    console.log('save card data',cardTitle)
    if(cardTitle!==""){
    let payload = {
      bucketId,
      cards: {
        cardTitle,
      },
    };
    console.log(payload, "add card payload");
    dispatch(
      addCard(payload, boardId, async function(response) {
        if (response) {
          handleCloseCard();
        } else {
          handleCloseCard();
        }
      })
    );
    }
  };
  const handleClickOutside = (event) => {
    if (cardaddRef.current && !cardaddRef.current.contains(event.target)) {
      handleSaveCard()
  setTimeout(()=>{
    onClickOutside()
  },1000)
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleClose = (index) => {
    handleCloseCard();
  };
  
  const onEnter = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSaveCard();

    }
  };
  return (
    <div  style={{margin: "0.5rem"}}>
        <TextareaAutosize
            ref={cardaddRef}
            autoFocus
            className="Edit-Card-Textarea"
            placeholder="Enter the text for this card..."
            style={{height:"45px", width:"100%"}}
            value={cardTitle}
            onChange={(e) => setCardTitle(e.target.value)}
            onKeyDown={onEnter}
            // onBlur={()=>handleClickOutside()}
          />
    
        <button
          onClick={() => handleSaveCard()}
          className=" m-2 add-card-btncross"
          style={{ padding: "5px 5px" }}
        > 
          Add card 
        </button>
        <span
          onClick={() => handleClose()}
          className=" "
          style={{ padding: "0px 5px", border:"none" }}
        > 
          <RxCross2 size={27} />
        </span>
    
    </div>
  );
};
export default NewCard;
