import React, { Component, useState, Fragment } from 'react';
import axios from 'axios';
import { API_HOST } from '../services/url';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import image from '../assets/image.jpeg';
import './teamImage.css';
class AddTags extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            image: '',
            images: [],
            editing: true,
            imageId: '',
            editTagName: '',
            headerImage: '',
            headerImageId: '',
            navTitle: '',
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitHeaderImage = this.onSubmitHeaderImage.bind(this);

        this.getImage = this.getImage.bind(this);
    }
    async getImage() {
        const token = localStorage.getItem('cxtoken');
        axios
            .get(API_HOST + 'web/team', {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        images: res.data.data.images,
                        headerImage: res.data.data.headerImage,
                        navTitle: res.data.navTitle,
                    });
                }
            });
    }
    async componentDidMount() {
        await this.getImage();
    }

    async onSubmitHeaderImage(e) {
        e.preventDefault();

        const formData = new FormData();
        formData.append('headerImage', this.state.headerImage);
        const token = localStorage.getItem('cxtoken');
        axios
            .post(API_HOST + 'web/team/headerImage', formData, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                this.setState({ image: '' });
                this.getImage();
            })
            .catch(error => {
                alert('Failed to add tags');
            });
    }
    async onSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('image', this.state.image);
        const token = localStorage.getItem('cxtoken');
        axios
            .post(API_HOST + 'web/team', formData, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                this.setState({ image: '' });
                this.getImage();
            })
            .catch(error => {
                alert('Failed to add tags');
            });
    }
    async deleteHeaderImage() {
        const token = localStorage.getItem('cxtoken');
        axios
            .delete(API_HOST + `web/team/${this.state.headerImageId}`, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                // this.setState({tag:""})
                this.getImage();
            })
            .catch(error => {
                alert('Failed to add tags');
            });
    }
    async deleteImage(imageId) {
        const token = localStorage.getItem('cxtoken');
        axios
            .delete(API_HOST + `web/team/${imageId}`, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                // this.setState({tag:""})
                this.getImage();
            })
            .catch(error => {
                alert('Failed to add tags');
            });
    }

    async onSubmitnavTitle(e) {
        e.preventDefault();
        const token = localStorage.getItem('cxtoken');
        axios
            .post(
                API_HOST + 'web/navTitle',
                { title: this.state.navTitle },
                {
                    headers: { 'x-access-token': token },
                }
            )
            .then(res => {
                this.setState({ navTitle: '' });
                this.getImage();
            })
            .catch(error => {
                alert('Failed to add tags');
            });
    }
    change = e => {
        this.setState({
            [e.target.name]: e.target.files[0],
        });
    };
    changeTitle = e => {
        this.setState({
            navTitle: e.target.value,
        });
    };
    render() {
        return (
            <div>
                <div className="container" >
                    <div className="row">
                        <div className="col-sm-9 col-md-9 mx-auto">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h4 className="card-title text-center">
                                        Nav Title For Website
                                    </h4>
                                    <form className="form-signin" method="POST">
                                        <div className="form-group add-tag-css">
                                            <div className="add-tags-input-field">
                                                <input
                                                    placeholder="Add New NavTitle"
                                                    id="title"
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.navTitle}
                                                    name="navTitle"
                                                    onChange={e => this.changeTitle(e)}
                                                    required
                                                />
                                            </div>
                                            <div className="add-tag-button-css">
                                                <button
                                                    className="btn btn-info btn-block"
                                                    onClick={e => this.onSubmitnavTitle(e)}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-body">
                                    <h4 className="card-title text-center">
                                        Add Team Header Image
                                    </h4>
                                    <form className="form-signin" method="POST">
                                        <div className="form-group add-tag-css">
                                            <div className="add-tags-input-field">
                                                <input
                                                    placeholder="Add New Tags"
                                                    id="title"
                                                    type="file"
                                                    className="form-control"
                                                    name="headerImage"
                                                    onChange={e => this.change(e)}
                                                    required
                                                />
                                            </div>
                                            <div className="add-tag-button-css">
                                                <button
                                                    className="btn btn-info btn-block"
                                                    onClick={e => this.onSubmitHeaderImage(e)}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="row team-image-css">
                                        <div className=" col-4 team-image-box">
                                            <img
                                                src={this.state.headerImage}
                                                alt="pls add team background image"
                                            />
                                            <button
                                                className="button-on-image"
                                                onClick={e =>
                                                    window.confirm(
                                                        `Are you sure you want to delete this article?`
                                                    ) && this.deleteHeaderImage()
                                                }
                                            >
                                                remove
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h4 className="card-title text-center">Add Team Image</h4>
                                    <form className="form-signin" method="POST">
                                        <div className=" add-tag-css">
                                            <div className="add-tags-input-field">
                                                <input
                                                    placeholder="Add New Tags"
                                                    id="title"
                                                    type="file"
                                                    className="form-control"
                                                    name="image"
                                                    // value={this.state.image}
                                                    onChange={e => this.change(e)}
                                                    required
                                                />
                                            </div>
                                            <div className="add-tag-button-css">
                                                <button
                                                    className="btn btn-info btn-block"
                                                    onClick={e => this.onSubmit(e)}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <h3 className="card-title text-center">All Team Image</h3>

                                    <div className="row team-image-css">
                                        {this.state.images &&
                                            this.state.images?.map((item, key) => (
                                                <div className=" col-4 team-image-box" key={key}>
                                                    <>
                                                        <img src={item.URL} alt="#" />
                                                        <button
                                                            className="button-on-image"
                                                            onClick={e =>
                                                                window.confirm(
                                                                    `Are you sure you want to delete this article?`
                                                                ) && this.deleteImage(item._id)
                                                            }
                                                        >
                                                            remove
                                                        </button>
                                                    </>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddTags;
