import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Table from "./table";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, type, history, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Table type={type} history={history} />
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TempTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Customer Journey Maps" {...a11yProps(0)} />
          <Tab label="Business Modal Canvas" {...a11yProps(1)} />
          <Tab label="Persona" {...a11yProps(2)} />
          <Tab label="Kanban" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel
        value={value}
        index={0}
        type="customermap"
        history={props.history}
      ></TabPanel>
      <TabPanel
        value={value}
        index={1}
        type="business"
        history={props.history}
      ></TabPanel>
      <TabPanel
        value={value}
        index={2}
        type="persona"
        history={props.history}
      ></TabPanel>
      <TabPanel
        value={value}
        index={3}
        type="kanban"
        history={props.history}
      ></TabPanel>
    </div>
  );
}
