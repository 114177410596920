import React, { Component, Fragment } from "react";
import AddNodeMiddle from "../AddNodeMiddle";
import AddNode from "../AddNode";
import htmlReactParser from "html-react-parser";
import { v4 as uuid } from "uuid";
import { Menu, Item, Separator, MenuProvider } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import Comment from "../../../../Components/Modal/Comment";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Popover } from "@material-ui/core";
import { IconPickerItem } from "react-fa-icon-picker";
import LaneModals from '../../../../Components/Modal/LaneModal'
const initialState = {
	mouseX: null,
	mouseY: null,
};
export default class PhaseLane extends Component {
	constructor() {
		super();
		this.state = {
			addNodeAt: "",
			laneid: "",
			html: "",
			cardid: "",
			editing: false,
			color: "",
			history: [],
			status: "",
			phaseWidth: "",
			icon: "",
			nodeIdx: "",
			resizing: false,
			resizingNode: null,
			mouse: initialState,
			anchorEl: null,
			comment: [],
			resize: false,
			editingphase: false,
			contextMenuVisible: false,
			contextMenuX: 0,
			contextMenuY: 0,
			show: false
		};
	}
	async componentDidMount() {
		if (this.state.resize) {
			this.setupResizing();
		}
	}
	handleContextMenu = (event) => {
		event.preventDefault();
		const x = event.clientX;
		const y = event.clientY;

		this.setState({
			contextMenuVisible: true,
			contextMenuX: x,
			contextMenuY: y,
		}, () => {
			console.log(this.state.contextMenuVisible)
		});
	};

	hideContextMenu = () => {
		this.setState({
			contextMenuVisible: false,
		});
	};

	setupResizing() {
		const resizable = document.querySelectorAll(".phaselane-node");
		const fun = this;

		for (let i = 0; i < resizable.length; i++) {
			const resizer = resizable[i].querySelector(".res");

			resizer.addEventListener("mousedown", initResize);

			function initResize(e) {
				// Only start resizing if the left mouse button is clicked
				if (e.button === 0) {
					// Remove any existing drag event listeners
					document.documentElement.removeEventListener("mousemove", doResize, false);
					document.documentElement.removeEventListener("mouseup", stopResize, false);

					const startX = e.clientX;
					const startWidth = parseInt(window.getComputedStyle(resizable[i]).getPropertyValue("width"), 10);

					const parentElements = document.getElementsByClassName("parent");
					for (let i = 0; i < parentElements.length; i++) {
						parentElements[i].removeAttribute("draggable");
					}

					document.documentElement.addEventListener("mousemove", doResize, false);
					document.documentElement.addEventListener("mouseup", stopResize, false);

					fun.setState({
						resizing: true,
						resizingNode: resizable[i],
						startX: startX,
						startWidth: startWidth,
					});
				}
			}

			function doResize(e) {
				const { resizingNode, startX, startWidth } = fun.state;
				if (resizingNode) {
					const newWidth = startWidth + e.clientX - startX;
					resizingNode.style.width = newWidth + "px";
					// Update the phaseWidth of the node
					resizingNode.phaseWidth = newWidth;
				}
			}

			function stopResize() {
				const { resizingNode } = fun.state;
				if (resizingNode) {
					const width = resizingNode.style.width;
					// Your logic for updating the node's width
					// Set the phaseWidth state after resizing
					fun.setState({ phaseWidth: parseInt(width, 10) }, () => {
						console.log(fun.state, "WIDTH")
					});

					// Remove the classes and reset state
					resizingNode.classList.remove("resizing");
					fun.setState({
						resizing: false,
						startX: 0,
						startWidth: 0,
					});

					const parentElements = document.getElementsByClassName("parent");
					for (let i = 0; i < parentElements.length; i++) {
						parentElements[i].setAttribute("draggable", "true");
					}

					// Remove the doResize event listener
					document.documentElement.removeEventListener("mousemove", doResize, false);

					// You can call the appropriate function here to handle the new width
					// fun.handleNodeResize(width);
				}
			}
		}
	}

	componentDidUpdate(_prevProps, prevState) {
		try {
			if (prevState.resize !== this.state.resize) {
				this.cleanupResizing(prevState.resize);
				console.log(prevState.resize, this.state.resize, "Working")
				// this.setState({phaseWidth: })
				if (this.state.resize) {
					this.setupResizing();
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	cleanupResizing(isResizing) {
		if (isResizing) {
			const resizable = document.querySelectorAll(".phaselane-node");
			const parentElements = document.getElementsByClassName("parent");

			for (let i = 0; i < resizable.length; i++) {
				const node = resizable[i];
				const resizer = node.querySelector(".res");
				resizer.removeEventListener("mousedown", this.initDrag);
			}

			for (let i = 0; i < parentElements.length; i++) {
				parentElements[i].setAttribute("draggable", "true");
			}
		}
	}
	ColorLuminance(hex, lum) {
		// validate hex string
		hex = String(hex).replace(/[^0-9a-f]/gi, "");
		if (hex.length < 6) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		lum = lum || 0;

		// convert to decimal and change luminosity
		var rgb = "#",
			c,
			i;
		for (i = 0; i < 3; i++) {
			c = parseInt(hex.substr(i * 2, 2), 16);
			c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
			rgb += ("00" + c).substr(c.length);
		}
		return rgb;
	}
	hexToRGB(hex, alpha) {
		var r = parseInt(hex.slice(1, 3), 16),
			g = parseInt(hex.slice(3, 5), 16),
			b = parseInt(hex.slice(5, 7), 16);

		if (alpha) {
			return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
		} else {
			return "rgb(" + r + ", " + g + ", " + b + ")";
		}
	}
	addNode = (html, color, icon, status) => {
		const idx = this.state.addNodeAt;
		// console.log(idx, "This is error")
		if (this.state.editingphase === false) {
			try {
				const newNode = {
					laneType: "phase",
					date: Date(Date.now()),
					phaseHTML: html,
					gridID: idx,
					color: color,
					icon: icon,
					phaseStatus: status,
					phaseCategory: "Customer",
					phaseWidth: 200,
				};
				console.log("This is Node22222", newNode);
				this.props.addNode(idx, this.state.laneid, newNode);
			} catch (error) {
				console.log("This is error", error)
			}
		} else {
			let comment = this.state.comment;
			// for (var i = 0; i < comment.length; i++) {
			//   comment[i].userid = comment[i].userId._id;
			// }
			try {
				const newNode = {
					laneType: "phase",
					phaseHTML: html,
					date: Date(Date.now()),
					gridID: idx,
					_id: this.state.cardid,
					color: color || this.state.color,
					icon: icon,
					phaseWidth: this.state.phaseWidth || 200,
					phaseStatus: status || "start",
					phaseCategory: "Customer",
					history: this.state.history,
					comment: comment,
				};
				console.log("This is Node22222", newNode);
				this.props.editNode(idx, this.props.lane._id, this.state.cardid, newNode);
			} catch (error) {
				console.log("This is error", error)
			}
		}
	};

	openAddNodeAt = (idx, laneid, id) => {
		this.setState({
			...this.state,
			addNodeAt: idx,
			laneid,
			editing: false,
			show: true,
			editingphase: false
		});
	};
	openEditNodeAt = (
		idx,
		laneid,
		cardid,
		html,
		phaseWidth,
		color,
		history,
		status,
		icon,
		comment,
		id
	) => {

		// Update the state first
		console.log(cardid, "This is cardid")
		this.setState({
			...this.state,
			html,
			editing: true,
			addNodeAt: idx,
			editingphase: true,
			show: false, editing: false,
			laneid,
			cardid,
			color,
			history: history === undefined ? [] : history,
			status,
			phaseWidth,
			icon,
			comment,
		}, () => {
			console.log(this.state, "This is State")
		});

	};
	editLane = (html, color, icon) => {
		const newlane = {
			_id: this.props.lane._id,
			laneType: "phase",
			laneName: html,
			icon: icon,
			color: color,
			date: Date(Date.now()),
			laneGridNo: this.props.lane.laneGridNo,
			expand: this.props.lane.expand,
		};
		this.props.editLane(this.props.lane._id, newlane);
	};
	openModal = (id) => {
		this.setState({ editing: true, show: false, editingphase: false })
	};
	onDragStart = (event, idx) => {
		this.setState({ nodeIdx: idx });
	};
	onDragOver = (event) => {
		event.preventDefault();
	};
	onDrag = (event) => {
		event.preventDefault();
	};
	onDrop(event, idx) {
		let nodes = this.props.lane.nodes;
		let cardWidth = this.props.cardWidth;
		if (nodes && nodes.length > 0) {
			let nodeIdx = nodes.findIndex((node) => node.gridID == this.state.nodeIdx);

			if (nodeIdx != -1) {
				let dropIdx = nodes.findIndex((node) => node.gridID == idx);

				if (dropIdx !== -1) {
					for (var i = 0; i < nodes.length; i++) {
						let gridID = parseInt(nodes[i].gridID);
						if (gridID >= idx) nodes[i].gridID = gridID + 1;
						if (nodes[i].gridID == cardWidth) {
							cardWidth += 1;
						}
					}
				}

				nodes[nodeIdx].gridID = idx;
				nodes[nodeIdx].phaseWidth = nodes[nodeIdx].phaseWidth;

				let lane = this.props.lane;
				lane.nodes = nodes;
				this.props.dragNode(lane, cardWidth);
			} else {
				console.error("nodeIdx not found in nodes array");
			}
		} else {
			console.error("nodes array is not initialized or empty");
		}
	}

	addComment = (text) => {
		this.props.addComment(
			"PhaseLane",
			this.props.lane._id,
			this.state.nodeIdx,
			text
		);
	};
	commentClick = (event, nodeIdx, comment, bool) => {
		if (bool)
			this.setState(
				{
					anchorEl: event.currentTarget,
					mouse: initialState,
					nodeIdx,
					comment,
				},
			);
		else
			this.setState(
				{
					anchorEl: event.event.target,
					mouse: initialState,
					nodeIdx,
					comment,
				},
			);
	};

	commentClose = () => {
		this.setState({ anchorEl: null });
	};
	render = () => {
		let { lane: { nodes }, cardWidth, } = this.props;
		const uniqueid = uuid();
		const { anchorEl, comment, nodeIdx, resize } = this.state;
		const open = Boolean(anchorEl);
		const chatid = open ? "simple-popover" : undefined;
		return (
			<div
				className="lane d-flex"
				style={{
					background: this.hexToRGB(`${this.props.lane.color}`, 0.2),
					// width: `${(cardWidth + 1) * 291 + "px"}`,
					borderTop: "1px solid " + `${this.props.lane.color}`,
					height: "85px",
					width: "200%",
					overflowX: 'auto'
				}}
			>
				{this.state.editing === true ?

					<LaneModals
						id={uniqueid + "lanes"}
						title="Edit Lane"
						handleSubmit={this.editLane}
						html={this.props.lane.laneName}
						color={this.props.lane.color}
						history={this.props.lane.history}
						icon={this.props.lane.icon}
						show={true}
						close={() => this.setState({ editing: false })}
					/>
					: this.state.editingphase == true ?
						<LaneModals
							title="Edit Phase"
							handleSubmit={this.addNode}
							html={this.state.html}
							history={this.state.history}
							color={this.state.color}
							status={this.state.status}
							icon={this.state.icon}
							show={true}
							close={() => this.setState({ editingphase: false })}
						/>

						: this.state.show == true &&
						<LaneModals
							title="Add Phase"
							handleSubmit={this.addNode}
							color={this.props.lane.color}
							show={true}
							close={() => this.setState({ show: false })}

						/>
				}

				<div class="parentlane">
					<div
						className="lanename"
						data-toggle="modal"
						data-target={"#" + uniqueid + "lanes"}
						data-dismiss="modal"
						onClick={() => this.openModal(uniqueid)}
						style={{
							background: this.hexToRGB(`${this.props.lane.color}`, 0.4),
						}}
					>
						{htmlReactParser(this.props.lane.laneName || "")}
					</div>
					<div style={{ marginLeft: "-30px" }}>
						{" "}
						{this.props.lane.icon ? (
							<div style={{ marginBottom: "25px" }}>
								<IconPickerItem
									icon={this.props.lane.icon}
									size={18}
									color="#000"
								/>
							</div>
						) : (
							<div style={{ marginBottom: "55px", marginLeft: "23px" }}></div>
						)}
						<div className="shift d-flex">
							<div
								onClick={() => this.props.shiftlane(this.props.lane._id, 1)}
								class="shiftdown"
							>
								<FaChevronDown aria />
							</div>
							<div
								onClick={() => this.props.shiftlane(this.props.lane._id, -1)}
								class="shiftup"
							>
								<FaChevronUp />
							</div>
						</div>
						<div
							className="deletelaneicon"
							onClick={() =>
								// window.confirm(`Are you sure you want to delete lane?`) &&
								this.props.deleteLane(
									this.props.lane._id,
									this.props.lane.laneType
								)
							}
						>
							<RiDeleteBin6Line className="text-danger" />
						</div>
					</div>
				</div>
				<div className="nodes" onDragOver={(event) => this.onDragOver(event)}>
					{Array(cardWidth)
						.fill(0)
						.map((_, idx) => {
							const node = nodes.find((node) => parseInt(node.gridID) === idx);
							const nodeindex = nodes.findIndex(
								(node) => parseInt(node.gridID) === idx
							);
							if (node) {
								return (
									<Fragment>
										<AddNodeMiddle
											openAddNodeAt={() =>
												this.openAddNodeAt(idx, this.props.lane._id, uniqueid)
											}
											id={uniqueid}
											height="65px"
										/>
										<div
											className="parent nodedrag"
											key={idx}
											draggable="true"
											onDragStart={(event) => this.onDragStart(event, idx)}
											onDrag={(event) => {
												this.onDrag(event);
											}}
											onContextMenu={this.handleContextMenu}
											onDragOver={(event) => {
												this.onDragOver(event);
											}}
											onDrop={(event) => this.onDrop(event, idx)}
										>
											<MenuProvider id={node._id}>
												<div
													className="phaselane-node"
													data-toggle="modal"
													id={node._id}
													data-target={`#${uniqueid}`}
													aria-describedby={node._id}
													style={{
														background: this.hexToRGB(`${node.color}`, 0.8),
														width: `${node.phaseWidth}px`,
														cursor: "pointer",
													}}
													onClick={(e) => {
														// e.preventDefault();
														// console.log(e, "Working")
														this.openEditNodeAt(
															idx,
															this.props.lane._id,
															node._id,
															node.phaseHTML,
															node.phaseWidth,
															node.color,
															node.history,
															node.status,
															node.icon,
															node.comment,
															uniqueid
														);
													}}
												>
													{htmlReactParser(node.phaseHTML || "")}

													<div
														class={resize ? "res resizer" : "res"}
														idx={idx}
														lane_id={this.props.lane._id}
														card_id={node._id}
														html={node.phaseHTML}
														color={node.color}
														history={JSON.stringify(node.history)}
														status={node.status}
														icon={node.icon}
														comment={JSON.stringify(node.comment)}
													></div>
												</div>
											</MenuProvider>
											<div
												class="triangle-right"
												style={{
													borderLeft:
														"30px solid " + this.hexToRGB(`${node.color}`, 0.8),
												}}
											>
												{" "}
											</div>{" "}
											{node.hasOwnProperty("icon") ? (
												<div
													class="icon-box"
													style={{
														marginLeft: "-30px",
													}}
												>
													<IconPickerItem
														icon={node.icon}
														size={18}
														color="#000"
													/>
												</div>
											) : (
												""
											)}
											{/*node.comment.length != 0 ? (
                        <div
                          style={{
                            marginTop: "23px",
                            marginLeft: "-20px",
                            paddingRight: " 5px",
                            zIndex: 3,
                          }}
                          onClick={(e) => {
                            this.commentClick(e, nodeindex, node.comment, true);
                          }}
                          aria-describedby={chatid}
                          variant="contained"
                          title="Comments"
                        >
                          <i class="fa fa-comments" aria-hidden="true"></i>
                        </div>
                      ) : (
                        ""
                      )*/}
											<div
												class="deleteicon"
												style={{ marginTop: "40px" }}
												onClick={() =>
													window.confirm(
														`Are you sure you want to delete phase?`
													) &&
													this.props.deleteNode(
														node._id,
														this.props.lane._id,
														this.props.lane.laneType
													)
												}
											>
												<i className="fas text-danger fa-trash-alt"></i>
											</div>
											<Menu id={node._id}>
												<Item
													onClick={() => {
														this.openEditNodeAt(
															idx,
															this.props.lane._id,
															node._id,
															node.phaseHTML,
															node.phaseWidth,
															node.color,
															node.history,
															node.status,
															node.icon,
															node.comment,
															uniqueid
														);
													}}
												>
													Edit
												</Item>
												<Separator />
												{resize ? (
													<Item
														onClick={() => {
															this.setState({ resize: false });
														}}
													>
														Drag
													</Item>
												) : (
													<Item
														onClick={() => {
															this.setState({ resize: true });
														}}
													>
														Resize
													</Item>
												)}
												<Separator />
												<Item
													onClick={() => {
														window.confirm(
															`Are you sure you want to delete card?`
														) &&
															this.props.deleteNode(
																node._id,
																this.props.lane._id,
																this.props.lane.laneType
															);
													}}
												>
													Delete
												</Item>
												<Separator />
												<Item
													onClick={(e) => {
														this.commentClick(e, nodeindex);
													}}
													aria-describedby={chatid}
													variant="contained"
												>
													Comment
												</Item>
											</Menu>
										</div>
									</Fragment>
								);
							}
							return (
								<AddNode
									openAddNodeAt={() => {
										this.openAddNodeAt(idx, this.props.lane._id, uniqueid)
									}
									}
									id={uniqueid}
									index={idx}
									onDrop={(event) => this.onDrop(event, idx)}
									height="65px"
								/>
							);
						})}

					<AddNode
						openAddNodeAt={() => {
							this.openAddNodeAt(cardWidth, this.props.lane._id, uniqueid)
						}
						}
						id={uniqueid}
						index={cardWidth}
						onDrop={(event) => this.onDrop(event, cardWidth)}
						height="65px"
					/>
					<Popover
						id={chatid}
						open={open}
						anchorEl={anchorEl}
						onClose={this.commentClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						{this.props.lane.nodes[nodeIdx] ? (
							<Comment
								addComment={this.addComment}
								comment={this.props.lane.nodes[nodeIdx].comment}
							/>
						) : (
							<Comment addComment={this.addComment} comment={comment} />
						)}
					</Popover>
				</div>
			</div>
		);
	};
}
