import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { USER_URL } from '../services/url';
import Card from './card';
import Graph from './graph';

function UsersActivity() {
    const [year, setYear] = useState([]);
    const [month, setMonth] = useState([]);
    const [week, setWeek] = useState([]);
    const [today, setToday] = useState();
    const [totalweek, setTotalweek] = useState();
    const [totalmonth, setTotalmonth] = useState();
    const [totalyear, setTotalyear] = useState();

    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const d = new Date();
    const currentMonth = d.getMonth();
    const currentYear = d.getFullYear();

    useEffect(() => {
        const data = {};

        Axios.post(USER_URL + `user/activeuser`, data)
            .then(response => {
                if (response) {
                    const arr1 = [];
                    const arr2 = [];
                    const arr3 = [];

                    response.data.data.year.forEach(element =>
                        arr1.push({
                            month: monthNames[element.month - 1],
                            Active_users: element.count,
                        })
                    );

                    response.data.data.week.forEach(element =>
                        arr2.push({
                            date: element.date + ' ' + monthNames[currentMonth],
                            Active_users: element.count,
                        })
                    );

                    response.data.data.month.forEach(element =>
                        arr3.push({
                            date: element.date,
                            Active_users: element.count,
                        })
                    );

                    setToday(response.data.data.day);
                    setYear(arr1);
                    arr2.reverse();
                    setWeek(arr2);
                    setMonth(arr3);

                    setTotalweek(response.data.data.totalUsersInWeek);

                    setTotalyear(response.data.data.totalUsersInYear);

                    setTotalmonth(response.data.data.totalUsersInMonth);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, {});

    return (
        <div style={{ overflow: 'hidden' }}>
            <div>
                <div style={{ marginLeft: 100, paddingBottom: '20px' }}>
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                        <Card title="Today" data={today} />
                        <Card title="Week" data={totalweek} />
                        <Card title="Month" data={totalmonth} />
                        <Card title="Year" data={totalyear} />
                    </div>

                    <Graph data={week} info="date" text="Week" />
                    <Graph data={month} info="date" text="Month" text1={monthNames[currentMonth]} />
                    <Graph data={year} info="month" text="Year" text1={currentYear} />
                </div>
            </div>
        </div>
    );
}

export default UsersActivity;
