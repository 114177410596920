import React, { Component } from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../../services/url';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import QuillEditor from '../../Editor/quill';

class AddBlog extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            title: '',
            subtitle: '',
            blog_content: '',
            inputs: [],
            videos: [],
            tags: [],
            seo_metadata: [],
            selectedFile: null,
            files: null,
            description: '',
        };
        //   this.onSubmit = this.onSubmit.bind(this);
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onEditorChange = blog_content => {
        this.setState({ blog_content });
    };

    onFileChange = files => {
        console.log(files);
        this.setState({ selectedFile: files[0] });
    };

    async onSubmit(e) {
        e.preventDefault();
        
        const data = new FormData();
        data.append('title', this.state.title);
        data.append('description', this.state.description);
        data.append('blog_content', this.state.blog_content);
        data.append('tags', this.state.tags); // directly add the array
        data.append('seo_metadata', this.state.seo_metadata); // directly add the array
        data.append('author', String(localStorage.getItem('adminId')));
        data.append('titleImage', this.state.files);
        
        const token = localStorage.getItem('cxtoken');
        
        try {
            const response = await axios.post(CX_DEPLOYER + 'blog', data, {
                headers: { 'x-access-token': token },
            });
    
            alert('Blog Added');
            window.location.replace('/blogs');
        } catch (error) {
            console.error(error);
            // Handle error appropriately, e.g., show an error message to the user
        }
    }
    

    appendInput() {
        this.setState({ inputs: [...this.state.inputs, ''] });
        console.log(this.state.inputs);
        // var newInput = `input-${this.state.inputs.length}`;
        // this.setState(prevState => ({ inputs: prevState.inputs.concat([newInput]) }));
    }
    handleChange(e, index) {
        this.state.inputs[index] = e.target.value;
        this.setState({ inputs: this.state.inputs });
    }
    handleimagechange(e) {
        console.log(e.target.files);
        this.setState({ files: e.target.files[0] });
    }

    appendVideos() {
        this.setState({ videos: [...this.state.videos, ''] });
        // var newInput = `input-${this.state.inputs.length}`;
        // this.setState(prevState => ({ inputs: prevState.inputs.concat([newInput]) }));
    }
    handleVideosChange(e, index) {
        this.state.videos[index] = e.target.value;
        this.setState({ videos: this.state.videos });
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 col-md-9 mx-auto">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h4 className="card-title text-center">Add Blog</h4>
                                    <form className="form-signin" method="POST">
                                        <div className="form-group">
                                            <label for="email">Title</label>
                                            <input
                                                id="title"
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                value={this.state.title}
                                                onChange={e => this.change(e)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label for="email">Title Description</label>
                                            <textarea
                                                id="description"
                                                type="text"
                                                className="form-control"
                                                name="description"
                                                value={this.state.description}
                                                onChange={e => this.change(e)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label for="image">Title Image</label>
                                            <input
                                                id="files"
                                                type="file"
                                                className="form-control"
                                                name="files"
                                                onChange={e => this.handleimagechange(e)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label for="tags">Tags</label>
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                options={[]}
                                                freeSolo
                                                onChange={(e, newValue) =>
                                                    this.setState({ tags: newValue })
                                                }
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Add Tag"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label for="seo">SEO Metadata</label>
                                            <Autocomplete
                                                multiple
                                                id="seo-filled"
                                                options={[]}
                                                freeSolo
                                                onChange={(e, newValue) =>
                                                    this.setState({ seo_metadata: newValue })
                                                }
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="SEO Metadata"
                                                    />
                                                )}
                                            />
                                        </div>
                                        {/* <div className="form-group">
                      <label for="subtitle">Subtitle</label>
                      <input
                        id="subtitle"
                        type="text"
                        className="form-control"
                        name="subtitle"
                        value={this.state.subtitle}
                        onChange={(e) => this.change(e)}
                        required
                      />
    </div>*/}
                                        <div className="form-group">
                                            <label for="blog_content">Blog Content</label>
                                            <QuillEditor
                                                placeholder={'Start Posting Something'}
                                                onEditorChange={this.onEditorChange}
                                                onFilesChange={this.onFileChange}
                                            />
                                        </div>

                                        {/*<div className="form-group">
                                                    <label for="email">Image</label>

                                            
                                                        <input 
                                                        className="form-control"
                                                        type="file" 
                                                        onChange={this.onFileChange} />
                            </div>

                            <div className="form-group">
                            <label for="slide_url">
                                Slide URL
                            </label>
                            
                            <button  onClick={ () => this.appendInput() } className = "float-right"><FontAwesomeIcon icon={faPlus} /></button>
                            {
                                this.state.inputs.map((input, index) => {
                                    return (
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={e => this.handleChange(e, index)}
                                            required
                                        />                        
                                    );
                                })
                            }
                            </div>
                            <div className="form-group">
                            <label for="videos">
                                Videos
                            </label>
                            <button  onClick={ () => this.appendVideos() } className = "float-right"><FontAwesomeIcon icon={faPlus} /></button>
                            {
                                this.state.videos.map((input, index) => {
                                    return (
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={e => this.handleVideosChange(e, index)}
                                            required
                                        />                        
                                    );
                                })
                            }
                        </div>*/}
                                        <div className="form-group no-margin">
                                            <button
                                                className="btn btn-info btn-block"
                                                onClick={e => this.onSubmit(e)}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddBlog;
