import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './chat.module.css';
import axios from 'axios';
import { API_HOST } from '../../services/url';
const Chat = () => {
    const location = useLocation();
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getConversation();
    }, []);
    const getConversation = () => {
        const token = localStorage.getItem('cxtoken');
        setLoading(true);
        const response = axios
            .get(API_HOST + `admin/whatsappBotChat/conversation/${location?.state?.id}`, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                console.log('1', res?.data?.data);
                setMessages(res?.data?.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            });
    };
    const formatDate = dateString => {
        const getOrdinalSuffix = number => {
            const suffixes = ['th', 'st', 'nd', 'rd'];
            const lastDigit = number % 10;
            const lastTwoDigits = number % 100;
            return suffixes[lastDigit] || suffixes[lastTwoDigits] || suffixes[0];
        };

        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleDateString(undefined, { month: 'short' });
        const year = date.getFullYear();
        return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    };
    console.log('first', messages);
    return (
        <div>
            <div className={styles.chat_messages}>
                {loading ? (
                    <div
                        className=" d-flex align-items-center justify-content-center"
                        style={{ height: '500px', width: '100%' }}
                    >
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    messages && (
                        <div>
                            {messages?.conversation?.length !== 0 && (
                                <p className={styles.date}>
                                    Date:&nbsp;{formatDate(messages?.createdAt)}
                                </p>
                            )}
                            {messages.conversation?.map((message, index) =>
                                message.sender !== 'user' ? (
                                    message.message === '' || message.message === null ? null : (
                                        <div className={styles.comment_message} key={index}>
                                            <p className={styles.message}>
                                                <span className={styles.bot_p}>Bot</span>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: message.message,
                                                    }}
                                                ></span>
                                            </p>
                                        </div>
                                    )
                                ) : message.message === '' ? null : (
                                    <div className={styles.user_comment_message} key={index}>
                                        <p className={styles.message_user}>
                                            <span>User</span>
                                            {message.message}
                                        </p>
                                    </div>
                                )
                            )}
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default Chat;
