import React from "react";
import $ from "jquery";
import PropTypes from "prop-types";

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}
export default function Card(props) {
  const { modalId, color } = props;
  return (
    <div
      className="card-node d-flex flex-column"
      style={{
        background: hexToRGB(`${color}`, 0.8),
        padding: "10px",
      }}
    >
      <div
        data-toggle="modal"
        data-target={"#" + modalId}
        data-dismiss="modal"
        className="flex-grow-1"
        onClick={() => props.handleShowModal(true)}
      >
        {props.children}
      </div>
      <div
        onClick={props.deleteNode.bind(this)}
        className="d-flex flex-row-reverse"
      >
        <div className="fas fa-trash-alt text-danger"></div>
      </div>
    </div>
  );
}

Card.propTypes = {
  /**
   * The modal Id which will be pop-up on editing this card-node
   */
  modalId: PropTypes.string,

  /**
   * Color of this card-node
   */
  color: PropTypes.string,

  /**
   * @type <Function( index:Number)>
   * @param {Number} index Index of the card in field
   */
  deleteNode: PropTypes.func,
};
