import React, { Component } from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';

class ViewCoupon extends Component {
    constructor(propos) {
        super();
        this.state = {
            data: [],
        };
    }

    async getData() {
        const token = localStorage.getItem('cxtoken');

        await axios
            .get(CX_DEPLOYER + `/stripe/getCoupon`, {
                headers: { 'x-access-token': token },
            })
            .then(response => {
                console.log(response);
                this.setState({
                    data: response.data.data,
                });
            });
    }

    handleDelete = e => {
        e.preventDefault();
        const token = localStorage.getItem('cxtoken');
        const url = CX_DEPLOYER + '/stripe/deleteCoupon/' + e.target.id;
        console.log(url);
        axios.delete(url, { headers: { 'x-access-token': token } }).then(response => {
            console.log(response);
            alert('Coupon Deleted');
            window.location.reload();
        });
    };

    renderTableData() {
        return this.state.data.map((row, index) => {
            const { discount, planType, couponName, couponCode } = row; //destructuring
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{couponName}</td>
                    <td>{couponCode}</td>
                    <td>{discount}</td>

                    <td>{planType}</td>

                    <td>
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={e => {
                                this.props.history.push(`/editCoupon/${row._id}`);
                            }}
                        >
                            Edit
                        </button>
                    </td>
                    <td>
                        <button
                            className="btn btn-danger"
                            id={row._id}
                            type="button"
                            onClick={e =>
                                window.confirm('Are you sure you want to delete?') &&
                                this.handleDelete(e)
                            }
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            );
        });
    }

    componentDidMount() {
        this.getData();
    }
    render() {
        return (
            <div className="mainContent">
                <div className="container">
                    <div className="page-header text-center">
                        <h3>Coupons</h3>
                    </div>
                    <table className="table table-bordered text-center table-hover">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">SNo.</th>
                                <th scope="col">Coupon Name</th>
                                <th scope="col">Coupon Code</th>
                                <th scope="col">Discount (in %)</th>
                                <th scope="col">Plan Name</th>

                                <th scope="col" colSpan="2">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>{this.renderTableData()}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ViewCoupon;
