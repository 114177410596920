import React, { Component } from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../../services/url';

class ViewBlogs extends Component {
    constructor(propos) {
        super();
        this.state = {
            data: [],
        };
    }

    async getData() {
        await axios.get(CX_DEPLOYER + `blog`).then(response => {
            console.log(response.data.blogs);
            this.setState({
                data: response.data.blogs,
            });
        });
    }

    async handleDelete(e) {
        e.preventDefault();
        const id = e.target.id;
        const name = e.target.name;
        const token = localStorage.getItem('token');
        await axios
            .delete(CX_DEPLOYER + 'blog', {
                data: {
                    id: id,
                    file: name,
                },
                headers: { 'x-access-token': token },
            })
            .then(res => {
                window.location.reload(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    renderTableData() {
        return this.state.data.map((row, index) => {
            const { blog_content, image, subtitle, title, videos } = row; //destructuring
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{title}</td>
                    <td>{subtitle}</td>
                    <td>{String(image).split('/').pop()}</td>
                    <td>{blog_content}</td>
                    <td>
                        <button
                            class="btn btn-danger"
                            name={image}
                            id={row._id}
                            onClick={e => this.handleDelete(e)}
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            );
        });
    }

    componentDidMount() {
        this.getData();
    }
    render() {
        return (
            <div class="mainContent">
                <div class="container">
                    <div class="page-header text-center">
                        <h3>Blogs Present</h3>
                    </div>
                    <table className="table table-bordered text-center table-hover">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">SNo.</th>
                                <th scope="col">Title</th>
                                <th scope="col">Subtitle</th>
                                <th scope="col">Image</th>
                                <th scope="col">Blog Content</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderTableData()}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ViewBlogs;
