import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
export default function AddNode(props) {
  return (
    <div
      className="addnode"
      data-toggle="modal"
      data-target={"#" + props.modalId}
      data-dismiss="modal"
      onClick={() => props.handleShowModal(true)}
      onDrop={props.onDrop}
    >
      +
    </div>
  );
}

AddNode.propTypes = {
  /**
   * Id of the Modal to pop-up
   */
  modalId: PropTypes.string,
};
