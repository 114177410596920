import React, { Component } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import LineDroppable from "./Components/LineDroppable";
import { v4 as uuid } from "uuid";
import AddLineDroppable from "./Components/AddLineDroppable";
import Axios from "axios";
import { CX_DEPLOYER } from "../../../../services/url";
import htmlReactParser from "html-react-parser";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./Lines.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "../../CJM.css";
import { IconPickerItem } from "react-fa-icon-picker";
import LaneModal from "../../../../Components/Modal/LaneModal";
const URL = CX_DEPLOYER + "premap/customermap/";
Axios.defaults.headers.common["x-access-token"] = localStorage.getItem("cxtoken");
const styles = {
  default: {
    color: {
      width: "36px",
      height: "14px",
      borderRadius: "2px",
    },
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
    },
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  },
};
const fillEmptyIndex = (nodes) => {
  nodes = nodes.sort((a, b) => a.gridId - b.gridId);
  for (let i in nodes) {
    i = parseInt(i);
    if (!nodes[i + 1]) continue;
    if (nodes[i].gridId + 1 !== nodes[i + 1].gridId) {
      const newNode = {
        gridId: nodes[i].gridId + 1,
      };
      nodes.splice(i + 1, 0, newNode);
    }
  }
  return nodes;
};

export default class Lines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardLimit: props.card_limit + 1,
      data: props.lane,
      editing: false
    };
  }

  addNode = (index) => {
    const { data } = this.state;
    const idx = data.nodes.findIndex((a) => a.gridId === index);
    if (idx !== -1) {
      data.nodes[idx].index = 2;
    } else {
      data.nodes.push({ index: 2, gridId: index });
    }
    this.saveToDB(fillEmptyIndex(data.nodes));
  };
  deleteNode = (index) => {
    const { data } = this.state;
    const idx = data.nodes.findIndex((a) => a.gridId === index);
    console.log(index, idx);
    if (idx !== -1) {
      data.nodes.splice(idx, 1);
    }
    console.log(data.nodes);
    this.saveToDB(fillEmptyIndex(data.nodes));
  };

  handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const sourceGridId = parseInt(source.droppableId.split("-")[0]);
    const destinationGridId = parseInt(destination.droppableId.split("-")[0]);
    const { data } = this.state;

    if (sourceGridId === destinationGridId) {
      data.nodes[sourceGridId] = {
        index: destination.index,
        gridId: data.nodes[sourceGridId].gridId,
      };
    } else {
      const existingNode = data.nodes[destinationGridId];
      existingNode.gridId = sourceGridId;
      data.nodes[destinationGridId] = {
        index: destination.index,
        gridId: destinationGridId,
      };
      data.nodes[sourceGridId] = existingNode;
    }

    this.saveToDB(data.nodes);
  };
  editLane = (html, color, icon) => {
    const newlane = {
      _id: this.props.lane._id,
      laneType: "line",
      laneName: html,
      color: color,
      icon: icon,
      date: Date(Date.now()),
      laneGridNo: this.props.lane.laneGridNo,
      expand: this.props.lane.expand,
    };
    this.props.editLane(this.props.lane._id, newlane);
  };
  ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#",
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }
    return rgb;
  }
  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  connectNodes = () => {
    const svgs = document.querySelectorAll(`.line-${this.state.data._id} svg`);

    if (svgs.length > 0) svgs.forEach((svg) => svg.remove());
    const statenodes = this.state.data.nodes;
    statenodes.sort(function (a, b) {
      var keyA = a.gridId,
        keyB = b.gridId;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    const lastNodeIndex =
      statenodes.length > 0 ? statenodes[statenodes.length - 1].gridId : 0;
    const nodes = document.querySelectorAll(
      `.line-${this.state.data._id} .node`
    );
    const lineLaneContainer = document.querySelector(
      `.line-${this.state.data._id}`
    );
    const lineLaneCoordinates = lineLaneContainer.getBoundingClientRect();

    const [lineLaneX, lineLaneY] = [
      lineLaneCoordinates.x,
      lineLaneCoordinates.y,
    ];

    for (let i in nodes) {
      i = parseInt(i);
      if (!nodes[i + 1]) return;

      let currentNodePoints = nodes[i].getBoundingClientRect();
      let nextNodePoints = nodes[i + 1].getBoundingClientRect();

      currentNodePoints = {
        x: lineLaneContainer.scrollLeft + (currentNodePoints.x - lineLaneX),
        y: currentNodePoints.y - lineLaneY,
      };
      nextNodePoints = {
        x: lineLaneContainer.scrollLeft + (nextNodePoints.x - lineLaneX),
        y: nextNodePoints.y - lineLaneY,
      };

      document.querySelector(`.line-${this.state.data._id}`).insertAdjacentHTML(
        "afterbegin",
        `
        <svg
          width="${300 * (lastNodeIndex + 1)}px"
          height="${lineLaneContainer.clientHeight}px"
        >
        <line
          x1=${currentNodePoints.x + 18}
          y1=${currentNodePoints.y + 18}
          x2=${nextNodePoints.x + 18}
          y2=${nextNodePoints.y + 18}
          stroke="black"
          />
          </svg>`
      );
    }
  };

  componentDidMount = () => {
    console.log("called");

    this.setState(
      { data: this.props.lane, cardLimit: this.props.card_limit + 1 },
      () => this.connectNodes()
    );
    window.$("#mymodal").modal("show");
  };

  saveToDB = (nodes) => {
    const { _id } = this.state.data;
    console.log(nodes);
    Axios.put(`${URL}lanes/line/edit/${_id}`, {
      nodes,
      projectid: this.props.projectid,
    })
      .then((res) => {
        this.setState({ data: res.data }, this.connectNodes);
      })
      .catch((err) => console.log(err));
  };
  openModal = (id) => {
    this.setState({
      editing: true
    })
  };
  render = () => {
    console.log(this.props.card_limit);
    const nodes = this.state.data.nodes;

    const cardLimit = this.props.card_limit + 1;
    const extraSpace = cardLimit > nodes.length ? cardLimit - nodes.length : 0;

    const uniqueid = uuid();
    return (
      <div
        className="lane d-flex"
        style={{
          background: this.hexToRGB(`${this.props.lane.color}`, 0.2),
          // width: `${cardLimit * 291 + "px"}`,
          height: "180px",
          borderTop: "1px solid " + `${this.props.lane.color}`,
          width: "200%",
					// overflowX: 'auto'
        }}
      >
        {
          this.state.editing === true && <LaneModal
            id={uniqueid + "lane"}
            title="Edit Lane"
            handleSubmit={this.editLane}
            html={this.props.lane.laneName}
            color={this.props.lane.color}
            history={this.props.lane.history}
            icon={this.props.lane.icon}
            show={this.state.editing}
            close={() => this.setState({
              editing: false
            })}
          />
        }

        <div class="parentlane">
          <div
            className="lanename"
            data-toggle="modal"
            data-target={"#" + uniqueid + "lane"}
            data-dismiss="modal"
            onClick={() => this.openModal(uniqueid)}
            style={{
              background: this.hexToRGB(`${this.props.lane.color}`, 0.4),
            }}
          >
            {htmlReactParser(this.props.lane.laneName || "")}
          </div>
          <div style={{ marginLeft: "-30px" }}>
            {" "}
            {this.props.lane.icon ? (
              <div style={{ marginBottom: "120px" }}>
                <IconPickerItem
                  icon={this.props.lane.icon}
                  size={18}
                  color="#000"
                />
              </div>
            ) : (
              <div style={{ marginBottom: "150px", marginLeft: "23px" }}></div>
            )}
            <div className="shift d-flex" title="Drag">
              <div
                onClick={() => this.props.shiftlane(this.props.lane._id, 1)}
                class="shiftdown"
              >
                <FaChevronDown aria />
              </div>
              <div
                onClick={() => this.props.shiftlane(this.props.lane._id, -1)}
                class="shiftup"
              >
                <FaChevronUp />
              </div>
            </div>
            <div
							className="deletelaneicon"
							onClick={() =>
								// window.confirm(`Are you sure you want to delete lane?`) &&
								this.props.deleteLane(
									this.props.lane._id,
									this.props.lane.laneType
								)
							}
						>
							<RiDeleteBin6Line className="text-danger" />
						</div>
          </div>
        </div>
        <div>
          <DragDropContext onDragEnd={this.handleDragEnd}>
            <div
              className={`linelane-container line-${this.state.data._id || ""
                } d-flex flex-row align-items-stretch`}
            >
              {nodes.map((node, nodeContainerIndex) => {
                return (
                  <div key={uuid()}>
                    <LineDroppable
                      nodeContainerIndex={nodeContainerIndex}
                      node={node}
                      color={this.hexToRGB(`${this.props.lane.color}`, 0.8)}
                      addNode={() => this.addNode(nodeContainerIndex)}
                      deleteNode={() => this.deleteNode(nodeContainerIndex)}
                    />
                  </div>
                );
              })}
              {Array(extraSpace)
                .fill(1)
                .map((_, index) => {
                  return (
                    <AddLineDroppable
                      key={uuid()}
                      addNode={() => this.addNode(nodes.length + index)}
                    />
                  );
                })}
            </div>
          </DragDropContext>
        </div>
      </div>
    );
  };
}
