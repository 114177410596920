import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

const token = localStorage.getItem('cxtoken');

const EditModal = (props) => {
    const [state, setState] = useState({
        name: '',
        desc: '',
    });
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const onSubmit = () => {
        // Reset the form fields
        setState({ name: '', desc: '' });

        props.submit(state);
        modalClose();
    };

    const toMap = () => {
        console.log("Working", props.type, props.id)
        navigate(`/${props.type}/${props.id}`);
    };

    const modalClose = () => {
        setState({ name: '', desc: '' });
        setShow(false);
    };

    const Delete = (id) => {
        setState({ name: '', desc: '' });
        props.delete(id);
    };

    useEffect(() => {
        console.log('state', state.name);
    }, [state.name]);

    const handleShow = (evt) => {
        setShow(true);
        const id = evt.target.id;
        const type = evt.target.name;
        props.handleEdit(id);
        let url = CX_DEPLOYER + `premap/${type}/${id}`;
        if (type === 'kanban') url = CX_DEPLOYER + `prefilledkanbans/${id}`;

        axios
            .get(url, {
                headers: { 'x-access-token': token },
            })
            .then((response) => {
                console.log('beta-----------', response.data, 'type', type);

                if (type === 'persona' || type === 'business') {
                    setState({ name: response.data.name, desc: response.data.description });
                } else if (type === 'kanban') {
                    setState({
                        name: response.data.data[0].name,
                        desc: response.data.data[0].description,
                    });
                } else {
                    console.log('data--------', response.data.data.name);

                    setState({
                        name: response.data.data.name,
                        desc: response.data.data.description,
                    });
                }
            });
    }

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#editexampleModal"
                id={props.id}
                name={props.type}
                onClick={(evt) => handleShow(evt)}
            >
                Edit
            </button>

            <Modal
                show={show}
                onHide={() => modalClose()}
                animation={false}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Prefilled Map</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body pt-0 p-3 mt-3 rounded-bottom">
                        <form>
                            <div className="form-group">
                                <label htmlFor="name">Prefilled Map Name {props.edit}</label>
                                <input
                                    type="text"
                                    value={state.name}
                                    name="name"
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Project name"
                                    required
                                    className="form-control rounded-1 pl-3"
                                    id="name"
                                    aria-describedby="emailHelp"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Description</label>
                                <textarea
                                    rows="3"
                                    name="desc"
                                    onChange={(e) => handleChange(e)}
                                    value={state.desc}
                                    placeholder="Project description"
                                    className="form-control rounded-1 p-3"
                                    id="exampleInputPassword1"
                                ></textarea>
                            </div>

                            <div
                                style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    marginTop: '5px',
                                }}
                            >
                                <div style={{ marginRight: '2px' }}>
                                    <button
                                        type="button"
                                        onClick={toMap}
                                        className="btn rounded-1 btn-block text-white bg-primary mr-2"
                                    >
                                        View Map
                                    </button>
                                </div>
                                <div style={{ marginRight: '2px' }}>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            window.confirm('Are you sure you want to delete?') &&
                                            Delete(props.id)
                                        }
                                        className="btn rounded-1 btn-block text-white bg-danger mr-2"
                                    >
                                        Delete Map
                                    </button>
                                </div>
                                <div style={{ marginRight: '2px' }}>
                                    <button
                                        type="button"
                                        onClick={onSubmit}
                                        className="btn rounded-1 btn-block text-white bg-primary"
                                    >
                                        {props.edit ? 'Edit Name and Description' : 'Add'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default React.memo(EditModal);
