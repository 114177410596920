import axios from "axios"

import { KANBAN_CX_DEPLOYER } from "../../services/url"
import { getBucket } from "./bucket"

export const setSelectedCard = (card) => {
    return async (dispatch) => {

        try {
         dispatch({
                    type: "SET_SELECTED_CARD",
                    payload: card,
                })
        }
        catch (error) {
            console.log("Error in get all workspace ", error)
        }
    }
}

export const handbookCard = (card) => {
    return async (dispatch) => {

        try {
         dispatch({
                    type: "SET_SELECTED_HANDBOOK_CARD",
                    payload: card,
                })
        }
        catch (error) {
            console.log("Error in get all workspace ", error)
        }
    }
}
export const addCard = (inputValue,boardId,callback) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(KANBAN_CX_DEPLOYER + `card/addcard`,inputValue,{
                headers:{
                    'x-access-token':localStorage.getItem("cxtoken")
                }
            })
            if (response.data.success === true) {
               dispatch(getBucket(boardId))
               callback(response)
            }
        }
        catch (error) {

            console.log("Error in add board", error)
        }
    }
} 

export const DragandDropCard = (inputValue,boardId) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(KANBAN_CX_DEPLOYER + `card/dndCard`,inputValue,{
                headers:{
                    'x-access-token':localStorage.getItem("cxtoken")
                }
            })
            console.log(boardId,"response dnd card")
            if (response.data.success === true) {
            //    dispatch({
            //     type:"SET_DND_BUCKET",
            //     payload:allBucket
            //    })
            dispatch(getBucket(boardId))
            }
        }
        catch (error) {

            console.log("Error in add board", error)
        }
    }
} 

export const updateCard = (boardId,bucketId,cardId,payload) =>{
    return async (dispatch) => {
        try {
            const response = await axios.post(KANBAN_CX_DEPLOYER + `card/editCard/${bucketId}/${cardId}`,payload,{
                headers:{
                    'x-access-token':localStorage.getItem("cxtoken")
                }
            })
            if (response.data.success === true) {
               dispatch(getBucket(boardId))
               let updateCard = response.data.updatedBucket;
               dispatch(setSelectedCard(updateCard))
            }
        }
        catch (error) {

            console.log("Error in add board", error)
        }
    }
}


export const addCardAttachment = (boardId,bucketId,cardId,payload,callback) =>{
    return async (dispatch) => {
        try {
            const response = await axios.post(KANBAN_CX_DEPLOYER + `card/editAttachmentCard/${bucketId}/${cardId}`,payload
            ,{
                headers:{
                    'x-access-token':localStorage.getItem("cxtoken")
                }
            })
            
            if (response.data.success === true) {
               dispatch(getBucket(boardId))
               console.log(response,"response")

               let allCard = response.data.updatedBucket.cards;
               console.log(allCard,"all card update")

               let sourceItem = allCard.find(
                (item) => String(item._id) === cardId
                );
                console.log(sourceItem,"Card update")
               dispatch(setSelectedCard(sourceItem))
               callback(sourceItem)

            }
        }
        catch (error) {

            console.log("Error in add board", error)
        }
    }

}


export const postImageWithActivity = (boardId,bucketId,cardId,payload,callback) =>{
    return async (dispatch) => {
        try {
            const response = await axios.post(KANBAN_CX_DEPLOYER + `card/postImageWithActivity/${bucketId}/${cardId}`,
            payload
            ,{
                headers:{
                    'x-access-token':localStorage.getItem("cxtoken")
                }
            })
            
            if (response.data.success === true) {
               dispatch(getBucket(boardId))
               console.log(response,"response")

               let allCard = response.data.updatedBucket[0].cards;
               console.log(allCard,"all card update")

               let sourceItem = allCard.find(
                (item) => String(item._id) === cardId
                );
                console.log(sourceItem,"Card update")
               dispatch(setSelectedCard(sourceItem))
               callback(sourceItem)

            }
        }
        catch (error) {

            console.log("Error in add board", error)
        }
    }

}