const initialState = {
    allBoards: [],
    allTemplates: [],
    selectedBoard: {},
    new_board: false,
    showBoardMember: {},
    error: '',
};

const BoardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BOARD':
            return {
                ...state,
                allBoards: action.payload,
            };
        case 'SET_TEMPLATE':
            return {
                ...state,
                allTemplates: action.payload,
            };
        case 'SET_SELECTED_BOARD':
            return {
                ...state,
                selectedBoard: action.payload,
            };
        case 'HANDBOOK_BOARD':
            return {
                ...state,
                handbookBoard: action.payload,
            };
        case 'UPDATE_BOARD':
            return {
                ...state,
                allBoards: state.allBoards.map(board =>
                    board._id === action.payload.boardId
                        ? {
                              ...board,
                              boardName: action.payload.boardName,
                              boardColor: action.payload.boardColor,
                              categoryId: action.payload.categoryId,
                          }
                        : board
                ),
                selectedBoard: { ...state.selectedBoard, boardName: action.payload.boardName },
            };
        case 'FETCH_BOARD_MEMBER_SUCCESS':
            return {
                ...state,
                showBoardMember: action.payload,
                error: '',
            };
        // case 'HANDBOOK_BOARD' :
        //     return {
        //         ...state,
        //         selectedBoard:action.payload
        //     }
        case 'ADD_MEMBER_SUCCESS':
            return {
                ...state,
                showBoardMember: action.payload,
                error: '',
            };
        case 'SET_NEWUSERFORBOARD':
            return {
                ...state,
                new_board: action.payload,
            };
        case 'BOARD_CLEAR':
            return {
                ...state,
                allBoards: [],
                selectedBoard: {},
                new_board: false,
                showBoardMember: {},
            };

        default:
            return state;
    }
};

export default BoardReducer;
