import React, { Component } from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {Editor} from 'ckeditor5-build-balloon-custom';
import FeildContainer from './FeildContainer';
import Card from './Card';
import AddNode from './AddNode';
import htmlReactParser from 'html-react-parser';

export default class Feild extends Component {
    
    render() {
        const {
            name,
            content,
            color,
            index,
            handleColorChange,
            handleEditorChange,
            handleDeleteCard,
            handleOnDelete,
            handleShowModal
        } = this.props;
        const addModalId = 'add-modal-' + index;
        const editModalId = 'edit-modal-' + index;
        return (
            <FeildContainer color={color} name={name} onChange={handleColorChange}>
                <div
                    className="form-group persona-form-feild d-flex flex-column align-content-stretch px-4"
                    style={{ height: 'inherit' }}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <label className="text-uppercase mb-1" style={styles.label}>
                            <CKEditor
                                editor={Editor}
                                data={name}
                                onInit={editor => {
                                    editor.setData(name);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleEditorChange(data, name, true);
                                }}
                            />
                        </label>
                        {/*      <div {...dragHandleProps}>
              <i className="fas fa-bars text-light p-1 rounded-sm mb-0" style={{
                backgroundColor: `${color}`
              }}></i>
            </div>*/}
                    </div>

                    <div className="d-flex overflow-auto">
                        <div
                            style={styles.content}
                            className="d-flex flex-grow-1"
                            style={{ flexWrap: 'wrap' }}
                        >
                            {Array.isArray(content) &&
                                content.map((card, index) => {
                                    return (
                                        <React.Fragment>
                                            <Card
                                                modalId={editModalId + '-' + index}
                                                color={card.color}
                                                deleteNode={() => handleDeleteCard(index)}
                                                handleShowModal={handleShowModal}
                                            >
                                                {htmlReactParser(card.html)}
                                            </Card>
                                        </React.Fragment>
                                    );
                                })}
                            <AddNode modalId={addModalId} handleShowModal={handleShowModal}/>
                        </div>
                    </div>
                    <div className="d-flex flex-column-reverse align-self-end mt-auto">
                        <i onClick={handleOnDelete} className="fas text-danger fa-trash-alt"></i>
                    </div>
                </div>
            </FeildContainer>
        );
    }
}

const styles = {
    label: {
        minHeight: '30px',
        height: 'auto',
    },
    content: {
        minHeight: '70px',
        height: 'auto',
    },
};
