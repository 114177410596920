
const initialState={
    allCategory:[],
    loader:true
}
const WorkspaceReducer = (state=initialState,action) => {
    // console.log("in the reducer")
    switch(action.type)
    {   
        case "SET_CATEGORY":
            return {
                ...state,
                allCategory:action.payload
            } 
       
        case "WORKSPACE_CLEAR" :
            return {
                ...state,
                allCategory:[]
            }  
        default:
            return state
    }
  
}

export default WorkspaceReducer
