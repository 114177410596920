import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { BiLabel } from 'react-icons/bi';
import { BsPencil } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { updateCard } from '../../redux/action/card';
import LabelEditPop from './labelEditPop';
import './labelPop.css';

const LabelPOP = () => {
    const popupRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [labelPop, setLabelPOP] = useState(false);
    const [labelPop2, setLabelPOP2] = useState(false);
    const dispatch = useDispatch();
    const { selectedBoard,selectedBoardLabels } = useSelector(store => store.boardRoot);
    const { selectedCard } = useSelector(store => store.cardRoot);
    const { selectedBucket } = useSelector(store => store.bucketRoot);
    let boardLabels = selectedBoard && selectedBoard.boardLabels;
    let boardid = selectedBoard & selectedBoard._id
    const [labelId, setLabelId] = useState();
    
    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setShowPopup(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
    const handleShow = useCallback(() => {
        setShowPopup(true);
    }, []);
    const handleLabelPOP = useCallback(idd => {
        setLabelId(idd);
        setLabelPOP(prevLabelPop => !prevLabelPop);
    }, []);

    const handleLabelPOP2 = useCallback(() => {
        setLabelPOP2(prevLabelPop2 => !prevLabelPop2);
    }, []);
    const handleClose = useCallback(() => {
        setShowPopup(prevShowPopup => !prevShowPopup);
    }, []);
    const handleClick = useCallback(
        labelId => {
            const cardLabel = [...selectedCard?.cardLabel]; // Create a copy of the cardLabel array

            const findIndex = cardLabel.findIndex(item => String(item) === labelId);

            if (findIndex === -1) {
                cardLabel.push(labelId);
            } else {
                cardLabel.splice(findIndex, 1);
            }

            const updatedCard = {
                ...selectedCard,
                cardLabel: cardLabel,
            };

            const cardId = selectedCard && selectedCard._id;
            const bucketId = selectedBucket && selectedBucket._id;
            const boardId = selectedBucket && selectedBucket.boardId;

            const payload = {
                cardLabel: cardLabel,
                update: 'label',
            };

            dispatch(updateCard(boardId, bucketId, cardId, payload));
        },
        [selectedCard, selectedBucket, dispatch]
    );

    return (
        <span>
            <div className=" " onClick={handleShow}>
                <div className="card__join ">
                    <BiLabel size={17} />
                    <span className="btn__text">Labels</span>
                </div>
            </div>

            {/*============= lable pop ============== */}
            {showPopup && (
                <Card
                    ref={popupRef}
                    className="labelpopup-shadow rounded-0 shadow"
                    style={{
                        position: 'fixed',
                        width: '340px',
                        zIndex: '1',
                    }}
                >
                    <section>
                        <p className=" text-center text-muted mt-3" style={{ fontSize: '16px' }}>
                            Labels
                            <span
                                style={{ position: 'relative', left: '100px' }}
                                className="text-muted"
                                onClick={handleClose}
                            >
                                <i className="fa-solid fa-xmark fa-s">X</i>
                            </span>
                        </p>
                        <hr className="mx-2" />


                        <p className="text-muted mx-2 pop-over-section">Labels</p>

                        <div
                            className="scrollable-member"
                            style={{ paddingRight: '11px', paddingLeft: '13px' }}
                        >
                            <div className="label_ul">
                                {boardLabels &&
                                    boardLabels.map((label, index) => (
                                        <div className="d-flex" key={index}>

                                            <label className="label_1 label_01">
                                                <div className="check_main">
                                                    <input
                                                        className="form-check-input input_checkbox"
                                                        type="checkbox"
                                                        checked={
                                                            selectedCard?.cardLabel &&
                                                            selectedCard?.cardLabel.includes(
                                                                label._id
                                                            )
                                                        }
                                                        onClick={() => handleClick(label._id)}
                                                    />
                                                </div>
                                                <span
                                                    className="label_top_span"

                                                >
                                                    <div className="label_div">
                                                        <div
                                                            className="label_color label01_div_text pd_left label_wh "
                                                            style={{
                                                                backgroundColor:
                                                                    label.labelBackgroundColor,
                                                                color: label.labelBackgroundColor === "#091E42" ? "white" : "black"
                                                            }}
                                                        >                                                  
                                                            <span
                                                                className=""

                                                            >
                                                                {label.labelTitle}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </span>
                                            </label>
                                            <BsPencil
                                                className="lable__pen lable__pen_icon"
                                                onClick={() => handleLabelPOP(label)}
                                            />
                                        </div>
                                ))}
                            </div>
                        </div>

                        <div className="mx-3 mb-3">
                            <button className="label_btn_wh label_btn " onClick={handleLabelPOP2}>
                                Create a new Label
                            </button>
                        </div>
                    </section>
                </Card>
            )}
            {labelPop && (
                <LabelEditPop
                    setLabelPOP={true}
                    setShowPopup={true}
                    boardid={boardid}
                    labelId={labelId}
                    toDone={false}
                />
            )}
            {labelPop2 && (
                <LabelEditPop
                    setLabelPOP={true}
                    setShowPopup={true}
                    boardid={boardid}
                    toDone={true}
                />
            )}


        </span>
    );
};

export default React.memo(LabelPOP);
