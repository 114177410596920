import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import axios from 'axios';

import QuillEditor from '../../Editor/quill';

import withRouter from '../../withRouter';
import { API_HOST } from '../../services/url';
const EditResponse = () => {
    const location = useLocation();
    const [title, setTitle] = useState(location.state?.title);
    const [responseData, setResponseData] = useState(location.state?.data);
    const change = e => {
        setTitle(e.target.value);
    };
    console.log('first', location.state?.data, responseData);
    const onEditorChange = Data => {
        setResponseData(Data);
    };
    const onSubmit = e => {
        e.preventDefault();
        let data = {
            key: title,
            answer: responseData,
        };
        const token = localStorage.getItem('cxtoken');
        axios
            .put(API_HOST + `admin/whatsappBotChat/preparedAnswer/${location.state?.id}`, data, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                alert('Data Added');
                window.location.replace('/view-bot-Response');
            });
    };

    return (
        <div className="container" >
            <div className="row">
                <div className="col-sm-9 col-md-9 mx-auto">
                    <div className="card card-signin my-5">
                        <div className="card-body">
                            <h4 className="card-title text-center">
                                Edit Whatsapp bot prepared answer
                            </h4>
                            <form className="form-signin" method="POST">
                                <div className="form-group">
                                    <label for="email">Whatsapp Bot prepared Question</label>
                                    <input
                                        id="title"
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        value={title}
                                        onChange={e => change(e)}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label for="blog_content">
                                        Whatsapp bot prepared answer Content
                                    </label>
                                    <QuillEditor
                                        placeholder={'Start Posting Something'}
                                        onEditorChange={onEditorChange}
                                        value={responseData}
                                    />
                                </div>

                                <div className="form-group no-margin">
                                    <button
                                        className="btn btn-info btn-block"
                                        onClick={e => onSubmit(e)}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(EditResponse);
