import axios from "axios"

import { KANBAN_CX_DEPLOYER } from "../../services/url"
// 
export const getBucket = (boardId) => {
    return async (dispatch) => {

        try {
            const response = await axios.get(KANBAN_CX_DEPLOYER + `card/getLanesByBoardId/${boardId}`
            ,{
                headers:{
                    'x-access-token':localStorage.getItem("cxtoken")
                }
            })
            if (response.data.success === true) {
                dispatch({
                    type: "SET_BUCKET",
                    payload: response.data.allcards,
                })
            } else{
                dispatch({
                    type: "SET_BUCKET",
                    payload: [],
                })
            }
        }
        catch (error) {
            console.log("Error in get all workspace ", error)
        }
    }
}

export const getHanbookBucket = (boardId) => {
    return async (dispatch) => {
//  64391692ef984664508a32f7  6447c578b0ceb05dbca28dc6
        try {
            const response = await axios.get(KANBAN_CX_DEPLOYER + `bucket/getHandbookBucket/6447c578b0ceb05dbca28dc6`)
            const boardResponse = await axios.get(KANBAN_CX_DEPLOYER + `board/handbookBoard/6447c578b0ceb05dbca28dc6`)
            if (response.data.success === true) {
                dispatch({
                    type: "HANDBOOK_BUCKET",
                    payload: response.data.allcards,
                })
                dispatch({
                    type:"HANDBOOK_BOARD",
                    payload:boardResponse.data.board
                })
             
            } else{
                dispatch({
                    type: "HANDBOOK_BUCKET",
                    payload: [],
                })
            }
        }
        catch (error) {
            console.log("Error in get all workspace ", error)
        }
    }
}
export const handbookBucket = (data) =>{
    return async (dispatch) => {

        try {
         dispatch({
                    type: "SET_SELECTED_HANDBOOK_BUCKET",
                    payload: data,
                })
        }
        catch (error) {
            console.log("Error in get all workspace ", error)
        }
    }
}
export const setSelectedBucket = (data) => {
    return async (dispatch) => {

        try {
         dispatch({
                    type: "SET_SELECTED_BUCKET",
                    payload: data,
                })
        }
        catch (error) {
            console.log("Error in get all workspace ", error)
        }
    }
}

export const addBucket = (inputValue,boardId,callback) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(KANBAN_CX_DEPLOYER + `bucket/addbucket`,inputValue,{
                headers:{
                    'x-access-token':localStorage.getItem("cxtoken")
                }
            })
            console.log("response add bucket",response)
            if (response.data.success === true) {
               dispatch(getBucket(boardId))
               callback(response)
            }
        }
        catch (error) {

            console.log("Error in add board", error)
        }
    }
} 
export const archiveBucket = (bucketId,boardId) =>{
    return async (dispatch) =>{
        try{
            const response = await axios.get(KANBAN_CX_DEPLOYER + `bucket/archiveBucket/${bucketId}`,{
                headers:{
                    'x-access-token':localStorage.getItem("cxtoken")
                }
            });
            if (response.data.success === true) {
                console.log("Printing Close1")
                dispatch(getBucket(boardId));
            }
        }catch(error){
            console.log("error in acrchive bucket")
        }
    }
}

export const editBucket = (bucketId,bucketName,callback) => {

    console.log(bucketId,"----------edit bucket")
    return async (dispatch) => {
        try {
            let payload ={bucketName}
            const response = await axios.post(KANBAN_CX_DEPLOYER + `bucket/editBucket/${bucketId}`,payload,{
                headers:{
                    'x-access-token':localStorage.getItem("cxtoken")
                }
            })
            console.log("response add bucket",response)
            if (response.data.success === true) {
            //    dispatch(getBucket(boardId))
            dispatch({
                type:'UPDATE_BUCKET',
                payload:{
                    bucketId,
                    bucketName
                }
           })
               callback(response)
            }
        }
        catch (error) {

            console.log("Error in add board", error)
        }
    }
} 
export const DragandDropBucket = (inputValue,boardId) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(KANBAN_CX_DEPLOYER + `bucket/dndBucket`,inputValue,{
                headers:{
                    'x-access-token':localStorage.getItem("cxtoken")
                }
            })
            if (response.data.success === true) {
                dispatch(getBucket(boardId))

            
            }
        }
        catch (error) {

            console.log("Error in add board", error)
        }
    }
} 

