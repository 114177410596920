import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './sidebar.css';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(e) {
        e.preventDefault();
        this.clearAuthData();
        window.location.reload(false);
    }

    clearAuthData() {
        localStorage.removeItem('cxtoken');
        localStorage.removeItem('expiration');
    }

    render() {
        return (
            <div className="sidenav mb-2">
                <Link className="text-white blog_tag" to="/">
                    Dashboard
                </Link>
                <Link className="text-white blog_tag" to="/userPage">
                    UserPage
                </Link>
                <Link className="text-white blog_tag" to="/website">
                    Website
                </Link>
                <Link className="text-white blog_tag" to="/product">
                    Product
                </Link>
                <Link className="text-white blog_tag" to="/admin">
                    Admin
                </Link>

                <a
                    href="#"
                    className="text-white blog_tag "
                    onClick={e =>
                        window.confirm(`Are you sure you want to Logout?`) && this.onSubmit(e)
                    }
                >
                    Logout
                </a>
            </div>
        );
    }
}

export default Sidebar;
