import React, {  useState } from "react";
import "./boardHeader.css";
import {
  AiOutlineStar,
} from "react-icons/ai";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const BoardHeader = () => {
  const { selectedBoard } = useSelector((store) => store.boardRoot);
  const locationObj = useLocation();

  let  pathname = ['/','/members','/guests','/pending','/userboard','/workspace_setting']
  const ispath =pathname.includes(locationObj.pathname)

  const [show, setShow] = useState(false);


  return (
      <div className="second_header">
        {/* <div className="board-header u-clearfix  " >
          <div className="board-header-btn mod-board-name" > */}
            {/* {show ? (
              <EditBoard handleClose={handleClose} setShow={setShow} />  board-header-btn-text
            ) : ( */}
            {/* )} */}
            <h4
                dir="auto"
                style={{textAlign:'left' ,color: ispath
                   ||selectedBoard && selectedBoard.boardColor == "" ? "black" : "white", marginTop: "3px" }}>
                    {selectedBoard ? selectedBoard.boardName : "Board Name"}
                    <span style={{float:'right'}}>
                    <Link
                        className="text-decoration-none text-white mx-3"
                        to={'/view-template'}
                    >
                    <button
                            className="btn btn-primary"
                            type="button"
                            state={{
                            id: selectedBoard.categoryId,
                        }}
                        >
                           Back 
                        </button>
                        </Link>
                    </span>
                </h4>
          {/* </div>
        </div> */}
      </div>
  );
};
export default BoardHeader;
