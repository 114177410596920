import React, { Component } from 'react';
import Tabs from './tabs';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';

class ViewTemplateList extends Component {
    constructor(propos) {
        super();
        this.state = {
            data: [],
        };
    }

    async getData() {
        const token = localStorage.getItem('cxtoken');

        await axios
            .get(CX_DEPLOYER + `/help`, { headers: { 'x-access-token': token } })
            .then(response => {
                this.setState({
                    data: response.data.data,
                });
            });
    }

    handleDelete = e => {
        e.preventDefault();
        const token = localStorage.getItem('cxtoken');
        const url = CX_DEPLOYER + '/help/' + e.target.id;
        console.log(url);
        axios.delete(url, { headers: { 'x-access-token': token } }).then(response => {
            console.log(response);
            window.location.reload(false);
        });
    };

    renderTableData() {
        return this.state.data.map((row, index) => {
            const { description, title, postedTime } = row; //destructuring
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{title}</td>
                    <td>{description}</td>
                    <td>{String(postedTime).split('T')[0]}</td>
                    <td>{String(postedTime).split('T')[1].split('.')[0]}</td>
                    <td>
                        <button
                            className="btn btn-danger"
                            id={row._id}
                            type="button"
                            onClick={e => this.handleDelete(e)}
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            );
        });
    }

    componentDidMount() {
        this.getData();
    }
    render() {
        return (
            <div className="mainContent">
                <div className="container">
                    <div className="page-header text-center mb-2">
                        <h3>Prefilled Maps</h3>
                    </div>
                    <div style={{
                        position: "relative",
                        top: "34px"
                    }}>
                        <Tabs history={this.props.history} />
                    </div>
                    {/* <table className="table table-bordered text-center table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">SNo.</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Time</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderTableData()}
                            </tbody>
        </table>*/}
                </div>
            </div>
        );
    }
}

export default ViewTemplateList;
