import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Menu, Item, Separator, Submenu } from "react-contexify";
import svg1 from "../svgs/1.svg";
import svg2 from "../svgs/2.svg";
import svg3 from "../svgs/3.svg";
import svg4 from "../svgs/4.svg";
import svg5 from "../svgs/5.svg";

const emojis = [svg1, svg2, svg3, svg4, svg5];

export default ({
  nodeContainerIndex,
  index,
  isVisible,
  isAdd,
  addNode,
  color,
  deleteNode,
}) => {
  return (
    <Draggable draggableId={`${nodeContainerIndex}-${index}`} index={index}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="linelane-node__droppable d-flex justify-content-center"
          >
            {isVisible ? (
              <div>
                {/* <MenuProvider id={`${nodeContainerIndex}-${index}`}> */}
                  <div
                    className="node d-flex align-items-center justify-content-center"
                    style={{ backgroundColor: `${color}` }}
                  >
                    <img
                      src={emojis[index]}
                      alt=""
                      width="30px"
                      height="30px"
                    />
                  </div>
                {/* </MenuProvider> */}
                <Menu id={`${nodeContainerIndex}-${index}`}>
                  <Item
                    onClick={() =>
                      window.confirm(`Are you sure you want to delete card?`) &&
                      deleteNode()
                    }
                  >
                    Delete
                  </Item>
                </Menu>
              </div>
            ) : (
              <div />
            )}
            {isAdd && index === 2 && (
              <div
                onClick={addNode}
                className="add-node d-flex align-items-center justify-content-center c-pointer"
              >
                +
              </div>
            )}
          </div>
        );
      }}
    </Draggable>
  );
};
