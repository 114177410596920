import React from "react";
import { useLocation } from "react-router-dom";
import MainPage from "./components/MainPage";

function KanbanApp(props) {
  //document.title = "Kanban | Edunomics";
  console.log(props.match);
  const location = useLocation();
  console.log(location);
  return (
    <div>
      <MainPage location={props.match} history={props.history} />
    </div>
  );
}

export default KanbanApp;
