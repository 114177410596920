import React, { Fragment, Component } from "react";
import { Draggable } from "react-beautiful-dnd";
import ReactHtmlParser from "react-html-parser";
import { PureComponent } from "react";

export default class extends PureComponent {
  render() {
    const {
      idx,
      _id,
      droppableKey,
      text,
      onClick,
      backgroundColor,
      history,
      deleteNode,
    } = this.props;

    return (
      <Fragment>
        <Draggable draggableId={droppableKey + "-" + idx} index={idx}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div
                className="card m-1 buisness-model-list hover-effect border-top-0 border-left-0 border-right-0  p-2"
                style={{
                  backgroundColor,
                  borderBottom: "1px solid #dee2e6!important",
                }}
              >
                <div
                  className="card-body bmc-card m-0 p-0 d-flex justify-content-between align-items-center"
                  // style={{ wordBreak: "break-all" }}
                >
                  <div
                    onClick={onClick.bind(
                      this,
                      text,
                      idx,
                      droppableKey,
                      backgroundColor,
                      history
                    )}
                    className="flex-fill"
                  >
                    {ReactHtmlParser(text)}
                  </div>
                  <div
                    onClick={() =>
                      window.confirm(`Are you sure you want to delete card?`) &&
                      deleteNode(_id, droppableKey)
                    }
                  >
                    <i className="fas text-danger fa-trash-alt"></i>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Draggable>
      </Fragment>
    );
  }
}
