import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { API_HOST } from '../services/url';

const Modal = (props) => {
const [state, setState] = useState({
name: '',
desc: '',
});
const [selectedFile, setSelectedFile] = useState(null);

const handleChange = event => {
const name = event.target.name;
setState({
...state,
[name]: event.target.value,
});
};
const onSubmit = () => {
$('#exampleInputPassword1').val('');
$('#name').val('');

props.submit(state);

modalClose();
};

const toMap = () => {
props.history.push(`/${props.type}/${props.row._id}`);
};
console.log(state);
const modalClose = () => {
setState({ name: '', desc: '' });
props.handleclose();
};
const Delete = id => {
setState({ name: '', desc: '' });
props.delete(id);
};
useEffect(() => {
if(selectedFile){
handleAddFile(selectedFile)
}
}, [selectedFile]);
const handleAddFile =async(selectedFile)=>{
    const formData = new FormData();
formData.append("file", selectedFile);
const response = await axios.post(`${API_HOST}/cxdeployer/saveGAIPersona`,formData)
if(response.data.message){
    setSelectedFile(null)
alert(`file ${response.data.message}`)
}
}
const handleShow = () => {
props.openModal();
};
const handleFileChange = (event) => {
const file = event.target.files[0];
setSelectedFile(file)
} 
return (
<>
<button
type="button"
className="btn btn-primary mb-2"
data-toggle="modal"
data-target="#exampleModal"
onClick={() => handleShow()}
>
Add
</button>
<label className='btn btn-primary mt-0 mx-5 mb-2' >
<input type="file" accept=".xls, .xlsx" onChange={handleFileChange} style={{ display: 'none' }} />
Add GenAI Persona

</label>
<div className="modal" id="exampleModal" data-backdrop="">
<div className="modal-dialog modal-dialog-centered" role="document">
<div className="modal-content rounded upmodal">
<div className="modal-header bg-primary d-flex aling-items-center text-white rounded-top border-0 text-center">
<h5 className="modal-title" id="exampleModalLabel">
{props.edit ? 'Edit Prefilled Map' : 'Add Prefilled Map'}
</h5>
<button
type="button"
className="close text-white d-flex aling-items-center "
data-dismiss="modal"
aria-label="Close"
onClick={() => modalClose()}
>
<span aria-hidden="true">&times;</span>
</button>
</div>
<div className="modal-body pt-0 p-3 mt-3 rounded-bottom">
<form>
<div className="form-group">
<label htmlFor="name">Prefilled Map Name</label>
<input
type="text"
value={state.name}
name="name"
onChange={e => handleChange(e)}
placeholder="Project name"
required
className="form-control rounded-1 pl-3"
id="name"
aria-describedby="emailHelp"
/>
</div>

<div className="form-group">
<label htmlFor="exampleInputPassword1">Description</label>
<textarea
rows="3"
name="desc"
onChange={e => handleChange(e)}
value={state.desc}
placeholder="Project description"
className="form-control rounded-1 p-3"
id="exampleInputPassword1"
></textarea>
</div>
{props.edit ? (
<React.Fragment>
<button
type="button"
onClick={toMap}
className="btn rounded-1 btn-block text-white bg-primary"
>
View Map
</button>
<button
type="button"
onClick={() =>
window.confirm(
`Are you sure you want to delete?`
) && Delete(props.row._id)
}
className="btn rounded-1 btn-block text-white bg-danger"
>
Delete Map
</button>
</React.Fragment>
) : (
''
)}
<button
type="button"
onClick={onSubmit}
className="btn rounded-1 btn-block text-white bg-primary"
>
{props.edit ? 'Edit Name and Description' : 'Add'}
</button>
</form>
</div>
</div>
</div>
</div>
</>
);
};
export default Modal;
