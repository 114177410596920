import React, { Component } from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';
import { Navigate } from 'react-router-dom';
import './login.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isLoggedIn: false,
            tokenTimer: '',
            disabled: false,
            otp: null,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.sendOtp = this.sendOtp.bind(this);
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    async onSubmit(e) {
        e.preventDefault();

        const data = {
            email: this.state.email,
            password: this.state.password,
            otp: this.state.otp,
        };
        await axios
            .post(CX_DEPLOYER + 'login', data)
            .then(res => {
                console.log(res);
                const { token, data } = res.data;
                if (token) {
                    const expiresInDuration = 3600;
                    this.setAuthTimer(expiresInDuration);
                    const now = new Date();
                    this.state.isLoggedIn = true;
                    const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
                    localStorage.setItem('adminId', data._id);
                    localStorage.setItem('cxtoken', token);
                    localStorage.setItem('expiration', expirationDate.toString());
                    // this.props.navigation.navigate("/UsersActivity");
                    window.location.reload();
                    window.location.replace('/');
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => {
                alert((err = err && err.msg ? err.msg : err));
            });
    }

    sendOtp = async e => {
        e.preventDefault();
        const data = { email: this.state.email };
        console.log(data);

        await axios
            .post(CX_DEPLOYER + 'login/otp', data)
            .then(res => {
                console.log(res.data);
                alert(res.data.message);
                this.setState({ disabled: true });
            })
            .catch(err => alert(err));
    };

    setAuthTimer(duration) {
        this.setState.tokenTimer = setTimeout(() => {
            this.logout();
        }, duration * 1000);
    }

    logout() {
        clearTimeout(this.state.tokenTimer);
        this.setState.isLoggedIn = false;
        this.clearAuthData();
        this.props.navigation.navigate('/UsersActivity');
    }

    clearAuthData() {
        localStorage.removeItem('token');
        localStorage.removeItem('expiration');
    }

    render() {
        if (localStorage.getItem('cxtoken')) return <Navigate to="/users"></Navigate>;
        return (
            <div className="container">
                <div className="login_div">
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card card-signin my-5">
                            <div className="card-body">
                                <h4 className="card-title text-center">Login</h4>
                                <form className="form-signin" method="POST">
                                    <div className="form-group">
                                        <label for="email">User Email</label>

                                        <input
                                            id="email"
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            value={this.state.email}
                                            onChange={e => this.change(e)}
                                            required
                                            autofocus
                                        />
                                    </div>
                                    {this.state.disabled && (
                                        <>
                                            <div className="form-group">
                                                <label for="password">Password</label>
                                                <input
                                                    id="password"
                                                    type="password"
                                                    className="form-control"
                                                    name="password"
                                                    value={this.state.password}
                                                    onChange={e => this.change(e)}
                                                    required
                                                    data-eye
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label for="otp">Enter otp</label>
                                                <input
                                                    id="otp"
                                                    type="text"
                                                    className="form-control"
                                                    name="otp"
                                                    value={this.state.otp}
                                                    onChange={e => this.change(e)}
                                                    required
                                                    data-eye
                                                />
                                            </div>
                                            <div className="form-group no-margin mt-2">
                                                <button
                                                    className="btn btn-info btn-block"
                                                    onClick={e => this.onSubmit(e)}
                                                >
                                                    Login
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    <div className="form-group no-margin mt-2">
                                        <button
                                            className="btn btn-info btn-block"
                                            onClick={e => this.sendOtp(e)}
                                            disabled={this.state.disabled}
                                            hidden={this.state.disabled}
                                        >
                                            Generate otp
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
