import React, { Component, useState, Fragment } from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';

class AddCoupon extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            couponName: '',
            couponCode: '',
            planName: '',
            discount: 0,
            plans: [],
        };
        //   this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount = async () => {
        const token = localStorage.getItem('cxtoken');

        await axios
            .get(CX_DEPLOYER + `/stripe/getPlan`, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                console.log(res);

                this.setState({
                    plans: res.data.data,
                });
            })
            .catch(err => {
                console.log(err);
            });

        await axios
            .get(CX_DEPLOYER + `/stripe/getCoupon`, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                console.log(res);
                let coupon = res.data.data;
                for (var i = 0; i < coupon.length; i++) {
                    if (this.props.match.params.couponid == coupon[i]._id) {
                        this.setState({
                            data: res.data.data,
                            _id: coupon[i]._id,
                            couponName: coupon[i].couponName,
                            couponCode: coupon[i].couponCode,
                            discount: coupon[i].discount,

                            planName: coupon[i].planType,
                        });
                    }
                }
            });
    };
    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    async onSubmit(e) {
        e.preventDefault();
        const data = {
            couponName: this.state.couponName,
            couponCode: this.state.couponCode,
            discount: this.state.discount,
            planType: this.state.planName,
        };

        console.log(data);
        const token = localStorage.getItem('cxtoken');
        axios
            .put(CX_DEPLOYER + '/stripe/updateCoupon/' + this.props.match.params.couponid, data, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                console.log(res.data.message);
                alert('Coupon Updated');
                window.location.reload();
            });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-9 col-md-9 mx-auto">
                        <div className="card card-signin my-5">
                            <div className="card-body">
                                <h4 className="card-title text-center">Edit Coupon</h4>
                                <form className="form-signin" method="POST">
                                    <div className="form-group">
                                        <label for="email">Coupon Name</label>
                                        <input
                                            id="couponName"
                                            type="text"
                                            className="form-control"
                                            name="couponName"
                                            value={this.state.couponName}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="email">Coupon Code</label>
                                        <input
                                            id="couponCode"
                                            type="text"
                                            className="form-control"
                                            name="couponCode"
                                            value={this.state.couponCode}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="email">Plan Name</label>
                                        <select
                                            id="planName"
                                            className="form-control"
                                            name="planName"
                                            value={this.state.planName}
                                            onChange={e => this.change(e)}
                                            required
                                        >
                                            <option value="">Select a plan</option>
                                            {this.state.plans.map((item, index) => {
                                                return (
                                                    <option value={item.planName}>
                                                        {item.planName}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="INRprice">Discount (in %)</label>
                                        <input
                                            id="discount"
                                            type="number"
                                            className="form-control"
                                            name="discount"
                                            value={this.state.discount}
                                            onChange={e => this.change(e)}
                                            required
                                        />
                                    </div>

                                    <div className="form-group no-margin">
                                        <button
                                            className="btn btn-info btn-block"
                                            onClick={e => this.onSubmit(e)}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddCoupon;
