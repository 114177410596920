import React, { Component } from 'react';
import PersonaForm from './PersonaForm';
import Axios from '../../services/Axios';
import withRouter from '../../withRouter';
import './persona.css';

// import ChooseTemplate from './Components/ChooseTemplate';

import Loader from '../../Components/Loader/Loader';
import * as htmlToImage from 'html-to-image';

class Persona extends Component {
    constructor() {
        super();
        this.state = {
            isAdding: true,
            isEditing: false,
            data: { personas: [] },
            formData: {},
            isLoading: true,
            Sub_project_id: '',
            tabsdata: [],
            isUpdating: false,
            templates: [],
            project_name: '',
        };

        // socket.on("projectChanged", (result) => {
        //   console.log("socket result", result);
        //   if (result.msg == "Refresh API") {
        //     console.log("called");
        //     this.componentDidMount();
        //   }
        // });
    }

    async componentDidMount() {
        const subProjectId = this.props.params.subid;

        Axios.get(`/premap/persona/${subProjectId}`)
            .then(res => {
                const { data } = res;
                data.personas = data.personas ? data.personas : [];
                if (data.personas.length > 0) {
                    data.persona = data.personas.reverse();
                    this.setState({
                        isEditing: true,
                        isAdding: false,
                        data: data,
                        isLoading: false,
                        Sub_project_id: subProjectId,
                        project_name: data.name,
                        formData: data.personas[0],
                    });
                } else
                    this.setState({
                        data: data,
                        isAdding: true,
                        isLoading: false,
                        project_name: data.name,
                    });
            })
            .catch(err => console.log({ err }));
        // Axios.get(`/premap/personatemplate/${subProjectId}`)
        //     .then(({ data }) => {
        //         if (!Array.isArray(data)) data = [];
        //         this.setState({ templates: data });
        //     })
        //     .catch(err => console.log(err));
    }
    // projectChanged() {
    //   socket.emit(
    //     "projectChanged",
    //     { room: this.props.params.subid },
    //     (err) => {
    //       if (err) {
    //         console.log(err);
    //       }
    //       console.log("ProjectChanged");
    //     }
    //   );
    // }
    tabShift = (tabsdata, shift) => {
        const _id = this.props.params.subid;
        let Idx = tabsdata.findIndex(tab => tab._id === _id);
        let tab = tabsdata[Idx];

        if (shift == 1 && Idx !== tabsdata.length - 1) {
            let temp = tabsdata[Idx + 1];
            tabsdata[Idx + 1] = tab;
            tabsdata[Idx] = temp;
        } else if (shift == -1 && Idx !== 0) {
            let temp = tabsdata[Idx - 1];
            tabsdata[Idx - 1] = tab;
            tabsdata[Idx] = temp;
        }
        for (var i = 0; i < tabsdata.length; i++) {
            let addMoreOptions = eval('(' + '{"' + 'position' + '":' + i + '}' + ')');
            var textObject = JSON.stringify(tabsdata[i]);
            textObject = textObject.substring(1, textObject.length - 1);
            var AddElement = JSON.stringify(addMoreOptions);
            console.log(
                eval(
                    '(' +
                    '{' +
                    textObject +
                    ',' +
                    AddElement.substring(1, AddElement.length - 1) +
                    '}' +
                    ')'
                )
            );
            tabsdata[i] = eval(
                '(' +
                '{' +
                textObject +
                ',' +
                AddElement.substring(1, AddElement.length - 1) +
                '}' +
                ')'
            );
        }
        console.log(tabsdata);
        Axios.post(`/subproject/rearrange`, { subprojects: tabsdata })
            .then(res => {
                this.setState({ tabsdata });
            })
            .catch(err => console.log(err));
    };
    handleSubmit = data => {
        const formData = new FormData();

        if (data.photo) formData.append('photo', data.photo, data.photo.name);
        delete data.photo;
        const subProjectId = this.props.params.subid;

        formData.append('data', JSON.stringify(data));

        formData.append('subProjectId', subProjectId);
        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
        this.setState({ isUpdating: true });
        Axios.post('/premap/persona/add', formData)
            .then(res => {
                let { data } = res;
                console.log(data);
                data.personas = data.personas.reverse();
                const lastPersona = data.personas[0];
                this.setState({
                    data: data,
                    formData: lastPersona,
                    isAdding: false,
                    isEditing: true,
                    isUpdating: false,
                });
            })
            .catch(err => console.log(err));
    };

    handleEditSubmit = data => {
        const formData = new FormData();

        if (data.photo.name) {
            formData.append('photo', data.photo, data.photo.name);
            delete data.photo;
        }
        console.log(this.state.data._id);

        const subProjectId = this.props.params.subid;
        formData.append('data', JSON.stringify(data));
        formData.append('subProjectId', subProjectId);

        this.setState({ isUpdating: true });
        Axios.put(`/premap/persona/edit/${this.state.data._id}`, formData)
            .then(({ data }) => {
                data.personas = data.personas.reverse();
                this.setState({ data, isUpdating: false });
            })
            .catch(err => console.log(err) || alert('An error occurrred'));
    };

    handleDeletePersona = async persona => {
        const subProjectId = this.props.params.subid;
        const { data } = await Axios.delete(`/premap/persona/delete/${subProjectId}`, {
            data: persona,
        });
        if (data.message == 'deleted') {
            this.componentDidMount();
            this.setState({
                formData: {},
                isAdding: true,
                isEditing: false,
                isUpdating: false,
            });
        } else {
            alert('This Persona has empathy. Delete that first');
        }
    };

    handleSaveTemplateSubmit = (name, fieldLayout, fields) => {
        const subProjectId = this.props.params.subid;
        Axios.post('/premap/personatemplate/add', {
            subProjectId: subProjectId,
            name: name,
            layout: { fieldLayout: fieldLayout, feilds: fields },
        })
            .then(({ data }) => {
                const templates = [...this.state.templates];
                templates.push(data.template);
                this.setState({ templates: templates });
                this.handleTemplateSelect(this.state.templates.length - 1);
            })
            .catch(err => console.log(err));
    };

    handleTemplateSelect = index => {
        let formData = { ...this.state.formData };
        if (index > -1 && index < this.state.templates.length) {
            formData = { ...this.state.templates[index].layout };
        } else {
            formData = {
                feilds: [
                    { color: '#efeff0', gridNo: '0', name: 'History', content: '' },
                    {
                        color: '#efeff0',
                        gridNo: '1',
                        name: 'Lifestyle',
                        content: '',
                    },
                    {
                        color: '#efeff0',
                        gridNo: '2',
                        name: 'Background',
                        content: '',
                    },
                    {
                        color: '#efeff0',
                        gridNo: '3',
                        name: 'Digital Habits',
                        content: '',
                    },
                    { color: '#efeff0', gridNo: '4', name: 'Goals', content: '' },
                ],
                fieldLayout: [
                    { i: '0', x: 0, y: 0, w: 12, h: 3, minH: 3, minW: 3 },
                    { i: '1', x: 0, y: 3, w: 12, h: 3, minH: 3, minW: 3 },
                    { i: '2', x: 0, y: 6, w: 12, h: 3, minH: 3, minW: 3 },
                    { i: '3', x: 0, y: 9, w: 12, h: 3, minH: 3, minW: 3 },
                    { i: '4', x: 0, y: 12, w: 12, h: 3, minH: 3, minW: 3 },
                ],
            };
        }
        this.setState({
            isAdding: true,
            isEditing: false,
            formData: formData,
        });
    };
    onRoutechange(Sub_project_id, mapType, e) {
        e.preventDefault();
        const Project_id = this.props.params.id;
        if (mapType == 'Customer') {
            this.props.history.push(`/Board/${Project_id}/${Sub_project_id}`);
            window.location.reload();
        } else if (mapType == 'Business') {
            this.props.history.push(`/bmc/${Project_id}/${Sub_project_id}`);
        } else if (mapType == 'Persona') {
            this.props.history.push(`/Persona/${Project_id}/${Sub_project_id}`);
        }
    }

    redirect() {
        const Project_id = this.props.params.id;
        this.props.history.push(`/MapType:${Project_id}`);
    }

    deleteTab = (id, shift) => {
        Axios.delete(`/subproject/movetorecyclebin/${id}`)
            .then(res => {
                const { tabsdata } = this.state;
                const Project_id = this.props.params.id;
                let tabIdx = tabsdata.findIndex(tab => tab._id === id);
                tabsdata.splice(tabIdx, 1);
                this.setState({ tabsdata }, () => {
                    if (tabsdata.length == 0) {
                        this.props.history.push(`/MapType:${Project_id}`);
                    } else if (shift) {
                        let tab;
                        if (tabIdx == tabsdata.length) tab = tabsdata[tabIdx - 1];
                        else tab = tabsdata[tabIdx];
                        if (tab.mapType == 'Customer') {
                            this.props.history.push(`/cjm/${Project_id}/${tab._id}`);
                            window.location.reload();
                        } else if (tab.mapType == 'Business') {
                            this.props.history.push(`/bmc/${Project_id}/${tab._id}`);
                            window.location.reload();
                        } else if (tab.mapType == 'Persona') {
                            this.props.history.push(`/Persona/${Project_id}/${tab._id}`);
                            window.location.reload();
                        } else if (tab.mapType == 'Empathy') {
                            this.props.history.push(`/empathy/${Project_id}/${tab._id}`);
                            window.location.reload();
                        }
                    }
                });
            })
            .catch(err => console.log(err));
    };
    setTab = tabsdata => {
        this.setState({ tabsdata });
    };
    exportImage = () => {
        var container = document.getElementById('persona');
        console.log(container);
        htmlToImage
            .toJpeg(document.getElementById('persona'), {
                quality: 0.95,
                backgroundColor: '#fff',
            })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'persona.jpeg';
                link.href = dataUrl;
                link.click();
            });
    };
    render = () => {
        const { data, isLoading, formData, isUpdating } = this.state;
        return isLoading ? (
            <Loader />
        ) : (
            <div className="personacontainer">
                <div
                    className="projectnamenavbar"
                    style={{ position: 'fixed', zIndex: 998, top: 0 }}
                >
                    <div>
                        <div>{this.state.project_name}</div>
                    </div>
                    <div className="projectnamenavbar-links"></div>
                </div>

                <div className="row" style={{ maxWidth: '100vw' }} id="persona">
                    <div className="col-12 px-5 mt-3">
                        {/*<div className="profiles">
              <div
                className={`d-flex align-items-center overflow-auto profileImages`}
              >
                <div
                  data-toggle="modal"
                  data-target="#persona-templates-modal"
                  className="btn d-flex align-items-center justify-content-center btn-outline-primary rounded-circle"
                  style={styles.profileImage}
                  data-backdrop="false"
                >
                  <i className="fas fa-plus "></i>
                </div>

                {data.personas.map((persona, idx) => (
                  <React.Fragment>
                    <ContextMenuTrigger id={`context-menu-tab-persona-${idx}`}>
                      <img
                        key={idx}
                        src={`${CX_URL}${persona.photo}`}
                        alt="..."
                        className="ml-2"
                        style={
                          formData === persona
                            ? styles.largeProfileImage
                            : styles.profileImage
                        }
                        onClick={() =>
                          this.setState({
                            isEditing: true,
                            isAdding: false,
                            formData: data.personas[idx],
                          })
                        }
                      />
                    </ContextMenuTrigger>
                    <ContextMenu
                      className="context-menu"
                      id={`context-menu-tab-persona-${idx}`}
                    >
                      <MenuItem
                        className="context-menu-item"
                        onClick={this.handleDeletePersona.bind(this, persona)}
                      >
                        Delete
                      </MenuItem>
                    </ContextMenu>
                  </React.Fragment>
                ))}
              </div>
            </div>
                      */}
                    </div>
                    <div className="col-12">
                        <div className="mt-3 h-100" style={{ overflow: 'visible' }}>
                            {this.state.isAdding && (
                                <PersonaForm
                                    handleSaveTemplateSubmit={this.handleSaveTemplateSubmit}
                                    buttonText="Add"
                                    handleSubmit={this.handleSubmit}
                                    isUpdating={isUpdating}
                                    formData={formData}
                                />
                            )}
                            {this.state.isEditing && (
                                <PersonaForm
                                    formData={formData}
                                    handleSubmit={this.handleEditSubmit}
                                    isUpdating={isUpdating}
                                    buttonText="Update"
                                />
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className="footer d-flex">
          <div style={{ width: "79%" }}>
            <TabLists1
              params={this.props.params}
              history={this.props.history}
              data={this.state.tabsdata}
              deleteTab={this.deleteTab}
              tabShift={this.tabShift}
              setTab={this.setTab}
            />
          </div>
          <div
            className="idea-button"
            style={{
              width: "9%",
              height: "72px",
              padding: "8px 5px",
              overflowY: "hidden",
            }}
          >
            <div className="new-idea-container">
              <div>
                <Link to={`/ideation/${this.props.params.id}`}>
                  {" "}
                  <Button
                    className="ideate-button"
                    variant="contained"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: Colors.darkOrange,
                      color: "white",
                    }}
                  >
                    View Ideas
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="idea-button"
            style={{
              width: "11%",
              height: "72px",
              padding: "8px 5px",
              overflowY: "hidden",
            }}
          >
            <Ideas
              tabIndex={this.state.tabIndex}
              navigate={() => {
                console.log("navigate");
              }}
              projectId={this.props.params.id}
              fetchData={() => {
                console.log("datafetched");
              }}
            />
          </div>
            </div>*/}
                {/* <ChooseTemplate
                    onSelectTemplate={this.handleTemplateSelect}
                    templates={this.state.templates}
                /> */}
            </div>
        );
    };
}

export default withRouter(Persona);