import React, { Component} from 'react';
import axios from 'axios';
import { CX_DEPLOYER } from '../services/url';
axios.defaults.headers.common['x-access-token'] = localStorage.getItem('cxtoken');
class AddImage extends Component {
    constructor(propos) {
        super(propos);
        var date = new Date();
        if (date.getMonth() < 10) var mm = '0' + (date.getMonth() + 1);
        else var mm = date.getMonth() + 1;

        var year = date.getFullYear();
        var displayDate = date.getDate();
        this.state = {
            title: '',
            file: [],
        };
        //   this.onSubmit = this.onSubmit.bind(this);
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    handleImageChange = e => {
        e.stopPropagation();
        e.preventDefault();

        if (e.currentTarget && e.currentTarget.files && e.currentTarget.files.length > 0) {
            const file = e.currentTarget.files[0];
            this.setState({ file });
        }
    };

    async onSubmit(e) {
        e.preventDefault();
        // const data = {
        //   title: this.state.title,
        //   content: this.state.content,

        //   tags: this.state.tags,
        // };
        const data = new FormData();

        data.append('title', this.state.title);

        data.append('imageName', this.state.file);

        axios.post(CX_DEPLOYER + '/library', data).then(res => {
            console.log(res.data.message);
            alert('Image Uploaded');
        });
    }
    onEditorChange = content => {
        this.setState({ content });
    };

    onFileChange = files => {
        this.setState({ selectedFile: files[0] });
    };

    render() {
        console.log(this.state.tags);
        return (
            <div>
                <div className="container" >
                    <div className="row">
                        <div className="col-sm-9 col-md-7 col-lg-8 mx-auto">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h4 className="card-title text-center">Add Image</h4>
                                    <form className="form-signin" method="POST">
                                        <div className="form-group">
                                            <label for="email">Title</label>
                                            <input
                                                id="title"
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                value={this.state.title}
                                                onChange={e => this.change(e)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label for="subtitle">Image</label>
                                            <br />
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={e => {
                                                    this.handleImageChange(e);
                                                }}
                                            />
                                        </div>

                                        <div className="form-group no-margin">
                                            <button
                                                className="btn btn-info btn-block"
                                                onClick={e => this.onSubmit(e)}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddImage;
