import React, { Component, useState, Fragment } from 'react';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../header/header';
import axios from 'axios';
import { API_HOST } from '../../services/url';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import QuillEditor from '../../Editor/quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

class AddBlog extends Component {
    constructor(propos) {
        super(propos);
        this.state = {
            title: '',
            blog_content: '',
        };
        //   this.onSubmit = this.onSubmit.bind(this);
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onEditorChange = blog_content => {
        this.setState({ blog_content });
    };
    // onFileChange = files => {
    //     console.log(files);
    //     this.setState({ selectedFile: files[0] });
    // };

    async onSubmit(e) {
        e.preventDefault();
        const data = {
            key: this.state.title,
            answer: this.state.blog_content,
        };

        const token = localStorage.getItem('cxtoken');
        axios
            .post(API_HOST + 'admin/whatsappBotChat/preparedAnswer', data, {
                headers: { 'x-access-token': token },
            })
            .then(res => {
                alert('Data Added');
                window.location.replace('/view-bot-Response');
            });
    }

    render() {
        return (
            <div>
                <div className="container" >
                    <div className="row">
                        <div className="col-sm-9 col-md-9 mx-auto">
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h4 className="card-title text-center">
                                        Add Whatsapp bot prepared answer
                                    </h4>
                                    <form className="form-signin" method="POST">
                                        <div className="form-group">
                                            <label for="email">
                                                Whatsapp bot prepared Question
                                            </label>
                                            <input
                                                id="title"
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                value={this.state.title}
                                                onChange={e => this.change(e)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label for="blog_content">
                                                Whatsapp bot prepared answer Content
                                            </label>
                                            <QuillEditor
                                                placeholder={'Start Posting Something'}
                                                onEditorChange={this.onEditorChange}
                                                // onFilesChange={this.onFileChange}
                                            />
                                        </div>

                                        <div className="form-group no-margin">
                                            <button
                                                className="btn btn-info btn-block"
                                                onClick={e => this.onSubmit(e)}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddBlog;
